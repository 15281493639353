import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSingleDatepickerProps } from "hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "../../../redux";
import { MedHelpHCMWebApiResponseHealthCaseActivity } from "swagger/healthCaseManagement";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { PageTypes } from "../OngoingCaseItem/OngoingCaseItem";
import { toast } from "react-toastify";
import { newDateString } from "utils/date";
import { ModalCommonFooter, SingleDatepicker } from "@medhelp/ui";
import ModalFrame from "@medhelp/ui/Modal/ModalFrame";
import Select from "components/Select";
import Icon from "components/Icon";
import { useAppDispatch } from "store/hooks";

interface IProps {
    isEdit: boolean;
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
    setPage?: (page: PageTypes) => void;
    activity?: MedHelpHCMWebApiResponseHealthCaseActivity;
}

const CaseActivityModal = (props: IProps) => {
    const { isEdit, healthCase, modalIsOpen, setModalIsOpen, setPage, activity } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const translatedTitle = t(activity?.title ?? "");
    const [title, setTitle] = useState(translatedTitle);
    const [date, setDate] = useState(activity?.date);
    const [description, setDescription] = useState(activity?.description);

    const activityIsValid =
        title &&
        date &&
        (title !== translatedTitle || date !== activity?.date || description !== activity?.description);

    const selectValues = [
        {
            value: t("meeting"),
            description: t("meeting"),
        },
        {
            value: t("phoneVideoCall"),
            description: t("phoneVideoCall"),
        },
        {
            value: t("preparations"),
            description: t("preparations"),
        },
        {
            value: t("followUpFeedback"),
            description: t("followUpFeedback"),
        },
        {
            value: t("mappingInvestigation"),
            description: t("mappingInvestigation"),
        },
        {
            value: t("informationEducation"),
            description: t("informationEducation"),
        },
        {
            value: t("primaryCare"),
            description: t("primaryCare"),
        },
        {
            value: t("occupationalHealthCare"),
            description: t("occupationalHealthCare"),
        },
        {
            value: t("insuranceCompany"),
            description: t("insuranceCompany"),
        },
        {
            value: t("internal"),
            description: t("internal"),
        },
        {
            value: t("other"),
            description: t("other"),
        },
    ];

    const handleSaveActivity = () => {
        setModalIsOpen();
        setPage && setPage("caseActivities");

        if (isEdit) {
            dispatch(
                thunkActions.updateCaseActivity({
                    healthCaseId: healthCase?.id ?? "",
                    activityModel: {
                        id: activity?.id,
                        title: title,
                        description: description,
                        date: date,
                    },
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("activityUpdated")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("activityNotUpdated")}`, {
                        type: "error",
                    }),
                );
        } else {
            dispatch(
                thunkActions.saveCaseActivity({
                    healthCaseId: healthCase?.id,
                    title: title,
                    description: description,
                    date: date,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("activitySaved")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("activityNotSaved")}`, {
                        type: "error",
                    }),
                );
        }
    };

    const datepickerProps = useSingleDatepickerProps({
        onChangeDate: (v) => setDate(v ?? newDateString()),
        givenSelectedDate: date,
        placeholder: t("addDate"),
    });

    return (
        <ModalFrame
            id="case-activity-modal"
            header={<h1>{isEdit ? t("editActivity") : t("addActivity")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                <div className="px-3 sm:px-0">
                    <p className="border-b border-mhgrey-light pb-10">
                        {isEdit ? t("editActivityModalInfo") : t("addActivityModalInfo")}
                    </p>
                    <h5 className="pt-8 font-bold">{t("chooseActivityType")}</h5>
                    <Select
                        id="select-reason"
                        placeholder={t("selectType")}
                        selectedValue={title ?? ""}
                        onChange={(v) => setTitle(v ?? "")}
                        hidePlaceHolderInList
                        values={selectValues}
                    />
                    <h5 className="pt-8 font-bold">{t("activityDate")}</h5>
                    <SingleDatepicker heading={t("activityDate")} {...datepickerProps} />
                    <div className={"flex flex-col pt-8"}>
                        <label htmlFor="note" className="font-bold">
                            {t("note")}
                        </label>
                        <textarea
                            id="note"
                            className="border border-mhgrey-dark bg-transparent p-2"
                            rows={5}
                            onChange={(e) => setDescription(e.target.value)}
                            value={description ?? ""}
                        />
                    </div>
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("save")}
                    primaryOnClick={handleSaveActivity}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                    primaryDisabled={!activityIsValid}
                />
            }
        />
    );
};

export default CaseActivityModal;
