import { isNull } from "lodash/fp";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import { IProps, EvenType } from "./ActivitesLog.utils";
import EditAbsenceValue from "../EditAbsenceValue";
import { getDateTimeDisplayValue } from "utils/date";

const Message = ({ contacts, text }: IProps) => {
    const [showMessage, setShowMessage] = React.useState(false);
    const { t } = useTranslation("followup");
    if (!text) return null;
    return (
        <>
            <div className="flex items-center gap-2" role="button" onClick={() => setShowMessage(!showMessage)}>
                <Icon icon={`${showMessage ? "caretFullDown" : "caretFullRight"}`} size={8} />
                <p>{showMessage ? t("hideMessage") : t("showMessage")}</p>
            </div>

            {showMessage && (
                <div className="mt-10 flex flex-col gap-4 text-mhdarkgreen-original">
                    <div className="text-base font-bold">{t("message")}</div>
                    <div
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: text.replace(/(<\/?h[0-9]?\/?>)/g, ""),
                        }}
                    />
                    <div className="mt-6 text-base font-bold">{t("receiver")}</div>
                    <div className="flex flex-col">
                        {contacts.map(({ name, contactKey }) => {
                            return (
                                <div className="flex flex-1 flex-col flex-wrap justify-between gap-4 align-baseline sm:flex-row sm:gap-0">
                                    <div className="w-[250px] truncate">{name}</div>
                                    <div className="flex-1">{contactKey}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
};
const ActivitiesLog = (props: IProps) => {
    const { date, eventType, performedBy, reportedVia, text, editValues } = props;
    const { t } = useTranslation("followup");

    const RenderBody = useMemo(() => {
        if (editValues) {
            return <EditAbsenceValue data={editValues} />;
        }
        return <Message {...props} />;
    }, [editValues, props]);

    const EventTypeName = useMemo(() => editValues?.[0].propertyName ?? eventType, [editValues, eventType]);
    const ReportedVia = useMemo(
        () =>
            reportedVia && reportedVia.match(/[0-7]/)
                ? t(`followUpAbsence.performedVia.${reportedVia}`)
                : t("followUpAbsence.performedVia.0"),
        [reportedVia, t],
    );

    const renderLog = () => {
        let name;
        if (reportedVia === "5" || reportedVia === "6") {
            name = t("followUpAbsence.system");
        } else {
            name = t(performedBy) || t("followUpAbsence.unknown");
        }
        return {
            __html: t(`followUpAbsence.eventType.${EventTypeName || ""}`, {
                name: name,
                app: ReportedVia,
            }),
        };
    };

    return (
        <div className="border-b-solid border-b-1 border-background-300">
            <div className="flex justify-between py-5">
                <div className="flex gap-5">
                    <div className="flex">
                        <div className="relative flex h-12 w-12 rounded-full bg-grey-300 p-3">
                            <EvenType event={eventType} />
                        </div>
                    </div>
                    <div className={`${isNull(text) && isNull(editValues) && "flex items-center"}`}>
                        <div dangerouslySetInnerHTML={renderLog()} />
                        {RenderBody}
                    </div>
                </div>
                <div className="flex min-w-[200px] justify-end">
                    {getDateTimeDisplayValue(date, "d MMM yyyy, HH:mm")}
                </div>
            </div>
        </div>
    );
};
export default ActivitiesLog;
