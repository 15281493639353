import MiniMenu from "components/MiniMenu";
import { useTranslation } from "react-i18next";
import * as UserSelectors from "store/userSelectors";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { getDateDisplayValue } from "utils/date";
import { useAppSelector } from "store/hooks";

interface IProps {
    finishedAR: IAbsenceReportExtended;
    getMiniMenuItems: (absenceReport: IAbsenceReportExtended) => {
        text: string;
        onClick: () => void;
    }[];
}
const AbsenceReport = ({ finishedAR, getMiniMenuItems }: IProps) => {
    const medicalOrCustomOrAdminAcces = useAppSelector(UserSelectors.getMedicalOrCustomOrAdminAccess);
    const prefLanguage = useAppSelector(UserSelectors.getUserLanguage);
    const { t } = useTranslation();
    return (
        <div key={finishedAR.id}>
            <div className="flex">
                <p className="my-6 w-1/4">{finishedAR.type === "Sick" ? t("sick") : t("careOfChild")}</p>
                <p className="my-6 w-1/4">{getDateDisplayValue(finishedAR.start, "d MMM yyyy", prefLanguage)}</p>
                <p className="my-6 w-1/4">
                    {getDateDisplayValue(finishedAR.end ?? undefined, "d MMM yyyy", prefLanguage)}
                </p>
                <p className="my-6 w-1/4">{finishedAR.degree}%</p>
                {medicalOrCustomOrAdminAcces && (
                    <MiniMenu
                        buttonValues={getMiniMenuItems(finishedAR)}
                        id={`miniMenu-${finishedAR.id}`}
                        preset="white"
                        buttonRight
                        containerClassName="mt-4 w-1/12 flex justify-end"
                    />
                )}
            </div>
            <div className="border-b border-mhgrey-mediumdisabled" />
        </div>
    );
};
export default AbsenceReport;
