import Card from "@medhelp/ui/Card";

export interface IProps {
    id: string;
    message: string;
}

const NoUserDataMessage = ({ message }: IProps) => (
    <Card className="mb-6 mt-9 xs:mt-14">
        <p>{message}</p>
    </Card>
);

export default NoUserDataMessage;
