import { ReactNode } from "react";
import { useBreakpoints } from "hooks";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { activeCaseTypes } from "../../../utils/case";
import { isManuallyInitiated } from "pages/FollowUp/utils/case";
import { useTranslation } from "react-i18next";
import { EventTag } from "@medhelp/ui/Tag";

interface IProps {
    heading: string;
    children: ReactNode;
    healthCase?: IHealthCase;
    text?: string | ReactNode;
    showTag?: boolean;
}

const CaseContentContainer = (props: IProps) => {
    const { heading, children, healthCase, text, showTag } = props;
    const { t } = useTranslation("followup");
    const breakpoints = useBreakpoints();

    return (
        <div className="min-h-[337px]">
            <div className="px-8">
                <div className="mb-3 flex items-center justify-between">
                    <h3>{heading}</h3>
                    {showTag && healthCase && healthCase.status !== "preliminary" && !breakpoints.isSmallscreen && (
                        <EventTag
                            id="case-details-event"
                            text={isManuallyInitiated(healthCase) ? t("manuallyInitiated") : t("triggeredByTheSystem")}
                        />
                    )}
                </div>
                {text && healthCase && healthCase.status && activeCaseTypes.includes(healthCase.status) && (
                    <div className="mb-8 hidden sm:block">{text}</div>
                )}
            </div>
            {children}
        </div>
    );
};

export default CaseContentContainer;
