import Icon from "components/Icon";
import { MouseEvent } from "react";
import { ServiceCheckbox } from "../ServiceCheckbox";
import { UseFormRegisterReturn } from "react-hook-form";
import SecondaryButton from "components/SecondaryButton";

interface IServiceFieldArray {
    index: number;
    label: string;
    onClick: (event: MouseEvent) => void;
    disabled?: boolean;
}

export const ServiceFieldArray = ({ index, label, onClick, disabled }: IServiceFieldArray) => (
    <div key={index} className="flex h-14 w-full items-center justify-between border-b px-4">
        <div>{label}</div>
        {disabled ? (
            <div className="mr-2">
                <ServiceCheckbox checked={true} disabled />
            </div>
        ) : (
            <button type="button" onClick={onClick} className="p-1">
                <Icon icon="trashCan" size={22} />
            </button>
        )}
    </div>
);

interface IAppendFieldArray {
    buttonText: string;
    onClick: (event: MouseEvent) => void;
    type?: "text" | "number";
    label?: string;
    secondaryLabel?: string;
    disabled?: boolean;
    register: UseFormRegisterReturn<string>;
    secondaryRegister?: UseFormRegisterReturn<string>;
}

export const ServiceFieldArrayAppend = ({
    buttonText,
    type = "text",
    label,
    secondaryLabel,
    register,
    secondaryRegister,
    disabled,
    onClick,
}: IAppendFieldArray) => (
    <div className="flex flex-wrap justify-center gap-8 border-b p-4 xs:justify-between">
        <div className="flex">
            <div>
                {label && <div className="pb-1">{label}</div>}
                <div className="flex items-center">
                    <input
                        min={1}
                        type={type}
                        className={`h-10 border border-grey-500 bg-mhbeige p-2 ${
                            type === "number" ? "w-28 text-center" : "w-72"
                        }`}
                        {...register}
                    />
                    {secondaryRegister && <div className="px-4 text-xl">/</div>}
                </div>
            </div>
            {secondaryRegister && (
                <div>
                    <div className="pb-1">{secondaryLabel && secondaryLabel}</div>
                    <input
                        min={1}
                        type={type}
                        className={`h-10 border border-grey-500 bg-mhbeige p-2 ${
                            type === "number" ? "w-28 text-center" : "w-72"
                        }`}
                        {...secondaryRegister}
                    />
                </div>
            )}
        </div>
        <SecondaryButton
            id="serviceAppend"
            text={buttonText}
            leftIcon={<Icon icon="plus" size={22} />}
            type="button"
            disabled={disabled}
            onClick={onClick}
            className="h-10 self-end"
        />
    </div>
);
