import Icon from "components/Icon";

interface IProps {
    header?: string | null;
    body?: string | string[] | null;
    archived?: string | boolean;
    employeeNumber?: string | null;
}
const ProfileRow = ({ header, body, archived, employeeNumber }: IProps) => {
    return (
        <div className="flex h-full w-full items-start">
            <div className="pr-2">
                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-mhgrey-light lg:h-10 lg:w-10">
                    <Icon icon="person" className="h-3 w-3 lg:h-4 lg:w-4" />
                </div>
            </div>
            <div className="flex flex-col text-left">
                <p className="text-sm font-bold text-mhdarkgreen-original lg:text-base">{header}</p>
                <div className="flex max-w-xs flex-wrap">
                    {body && (
                        <div className="break-all text-sm text-mhgrey-dark opacity-75 lg:text-base">{body[0]}</div>
                    )}
                    {employeeNumber && (
                        <>
                            <div className="px-1 text-sm text-mhgrey-dark opacity-75 lg:text-base">|</div>
                            <div className="flex break-all text-sm text-mhgrey-dark opacity-75 lg:text-base">
                                <div>{employeeNumber}</div>
                            </div>
                        </>
                    )}
                </div>
                {archived && (
                    <div>
                        <div>
                            <p className="inline-block bg-mhred-alert4 px-1 text-xs text-mhred-alert3">{archived}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileRow;
