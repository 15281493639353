import { ITableRowProps } from "../SearchTable";
const ArchiveRow = ({ children, rowProps, key }: ITableRowProps) => (
    <tr
        className={`border-b-solid h-full cursor-pointer border-b-1 border-b-grey-300 bg-background-400/5 text-left`}
        {...rowProps}
        key={key}
    >
        {children}
    </tr>
);
export default ArchiveRow;
