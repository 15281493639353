import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import { ILoginPageData, LoginUsing } from "pages/login/types";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const LoginStart = ({ loginUsing, returnUrl }: ILoginPageData) => {
    const { t } = useTranslation("login");
    const { param } = useParams();
    const navigate = useNavigate();

    const handleClick = (route: string): void => {
        if (returnUrl) navigate(`/login/${route}?returnUrl=${returnUrl}`);
        else navigate(`/login/${route}`);
    };
    return (
        <div data-cy="loginStart" className="mb-14">
            <h1 className="mb-11">{t("login")}</h1>
            {param === "sv" && (
                <div className="flex flex-col">
                    <PrimaryButton
                        id="bankIdButton"
                        text="BankID"
                        className="mt-3 flex h-12 justify-center"
                        onClick={() => handleClick("bankIdOptions")}
                    />
                    <SecondaryButton
                        id="oneTimePassButton"
                        text={t("oneTimePassword")}
                        className="mt-3 h-12 justify-center"
                        onClick={() => handleClick("oneTimePass")}
                    />
                    <SecondaryButton
                        id="moreLoginOptions"
                        text={t("moreLoginOptions")}
                        className="mt-3 h-12 justify-center"
                        onClick={() => handleClick("moreOptions")}
                    />
                </div>
            )}

            {param === "dk" && (
                <div className="flex flex-col">
                    <SecondaryButton
                        id="oneTimePassButton"
                        text={t("oneTimePassword")}
                        className="mt-3 h-12 justify-center"
                        onClick={() => handleClick("oneTimePass")}
                    />
                    <SecondaryButton
                        id="userAndPassButton"
                        text={t("userNameAndPass")}
                        className="mt-3 h-12 justify-center"
                        onClick={() => handleClick("userAndPass")}
                    />
                    <SecondaryButton
                        id="microsoftLogin"
                        text="Microsoft"
                        className="mt-3 h-12 justify-center"
                        onClick={() => loginUsing({ loginUsing: LoginUsing.azure })}
                    />
                    <h3 className="mt-14 flex justify-center">Support</h3>
                    <div className={`mt-4 flex justify-center`}>
                        <button
                            type="button"
                            className="w-max border-b border-linkBorderBottom hover:border-linkBorderBottomHover"
                        >
                            <a href="mailto:kundeservice@medhelpcare.com">kundeservice@medhelpcare.com</a>
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginStart;
