import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import TertiaryButton from "components/TertiaryButton";

export interface ICommonFooterProps {
    tertiaryText?: string;
    tertiaryOnClick?: () => void;
    primaryText?: string;
    primaryOnClick?: () => void;
    primaryDisabled?: boolean;
    primaryRightIcon?: React.ReactElement;
    secondaryText?: string;
    secondaryOnClick?: () => void;
    secondaryDisabled?: boolean;
    isLoading?: boolean;
}
const ModalCommonFooter = ({
    tertiaryText,
    tertiaryOnClick,
    primaryText,
    primaryOnClick,
    primaryDisabled,
    primaryRightIcon,
    secondaryText,
    secondaryOnClick,
    secondaryDisabled,
    isLoading,
}: ICommonFooterProps) => {
    return (
        <div className="flex flex-1 flex-col items-center justify-center gap-2 sm:flex-row sm:gap-10">
            {tertiaryText && (
                <TertiaryButton
                    id="modalTertButton"
                    className="order-2 sm:order-1"
                    text={tertiaryText}
                    onClick={tertiaryOnClick}
                />
            )}
            {primaryText && (
                <PrimaryButton
                    id="modalPrimary"
                    className="order-1 sm:order-2"
                    text={primaryText}
                    onClick={primaryOnClick}
                    disabled={primaryDisabled}
                    isLoading={isLoading}
                    rightIcon={primaryRightIcon}
                />
            )}
            {secondaryText && (
                <SecondaryButton
                    id="modalSecondary"
                    className="order-1- sm:order-1"
                    text={secondaryText}
                    onClick={secondaryOnClick}
                    disabled={secondaryDisabled}
                />
            )}
        </div>
    );
};

export default ModalCommonFooter;
