import Icon from "components/Icon";
import { useTranslation } from "react-i18next";

const OngoingCaseExistsWarning = () => {
    const { t } = useTranslation("followup");
    return (
        <div className="flex gap-2 bg-mhgrey-light p-3">
            <div className="w-6">
                <Icon icon="warningCircle" size={24} className="stroke-black" />
            </div>
            <div>
                <p className="pb-1 font-bold">{t("caseTypeExists")}</p>
                <p>{t("caseTypeExistsInfo")}</p>
            </div>
        </div>
    );
};

export default OngoingCaseExistsWarning;
