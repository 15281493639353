import { twMerge } from "tailwind-merge";
import OngoingCaseExistsWarning from "./OngoingCaseExistsWarning";

interface IManualInitiationRadioButton {
    checked: boolean;
    value: string;
    onChange: () => void;
    disabled: boolean;
    heading: string;
    text: string;
}

const ManualInitiationRadioButton = ({
    checked,
    value,
    onChange,
    disabled,
    heading,
    text,
}: IManualInitiationRadioButton) => (
    <label className={twMerge("flex cursor-pointer gap-2", disabled && "cursor-not-allowed")}>
        <div className="h-11 w-11">
            <input
                data-testid={`manual-initiation-radio-button-${value}`}
                type="radio"
                name="caseType"
                value={value}
                checked={checked}
                onChange={onChange}
                className={twMerge(
                    "h-11 w-11 cursor-pointer accent-selectedRadioButton focus:shadow-none",
                    disabled && "cursor-not-allowed",
                )}
                disabled={disabled}
            />
        </div>
        <div className="flex flex-col gap-4">
            <div className={disabled ? "opacity-50" : ""}>
                <p className="font-bold">{heading}</p>
                <p>{text}</p>
            </div>
            {disabled && <OngoingCaseExistsWarning />}
        </div>
    </label>
);

export default ManualInitiationRadioButton;
