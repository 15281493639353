import Icon from "components/Icon";
import { AdminAccordion } from "pages/Administration/components";
import AdminAccordionChild from "pages/Administration/components/AdminAccordionChild";
import { IDisplayValues, ILoginAccordionSharedProps, ILoginsPageProps } from "pages/Administration/types";
import { initialInputProps } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";

const Logins = ({
    userAccountId,
    userId,
    accordionProps,
    handleButtonClick,
    handleInputOnChange,
}: ILoginsPageProps) => {
    const { t } = useTranslation("administration");

    const getDisplayValuesAsString = (displayValues: IDisplayValues[]): string[] =>
        displayValues.map((v) => `${t(v.identifierTranslation)} ${v.identifierValue ? ": " + v.identifierValue : ""}`);

    return (
        <div id="logins-page-container">
            <h3 className="border-b py-10 pl-10">{t("loginOptions")}</h3>
            <div>
                {Object.values(accordionProps).map((accordion: ILoginAccordionSharedProps, i) => (
                    <AdminAccordion
                        key={accordion.heading + i}
                        heading={t(accordion.heading)}
                        displayValues={getDisplayValuesAsString(accordion.displayValues)}
                        open={accordion.open}
                        setOpen={(v) => handleButtonClick(accordion.accordionName, "open", undefined, v)}
                        primaryButtonClicked={() =>
                            handleButtonClick(
                                accordion.accordionName,
                                "add",
                                initialInputProps(userAccountId, accordion.credentialType, userId),
                            )
                        }
                        primaryButtonText={t("addNew")}
                        primaryButtonIcon={<Icon icon="plusCircle" />}
                        primaryButtonDisabled={accordion.inputProps.some((c) => c.loginCredential.id === 0)}
                        active={accordion.inputProps.length > 0}
                    >
                        {!accordion.loginTypeExistsInCompanyConfig && (
                            <div className="mb-4 flex items-center text-mhred-alert1">
                                <Icon icon="warning" />
                                <p className="ml-2">{t("notSetInConfig")}</p>
                            </div>
                        )}
                        <AdminAccordionChild
                            credentialType={accordion.credentialType}
                            inputControls={accordion.inputProps}
                            activeTab={accordion.activeTab}
                            handleInputOnChange={(input, value) =>
                                handleInputOnChange(accordion.accordionName, input, value)
                            }
                            handleButtonClick={(button, input) =>
                                handleButtonClick(accordion.accordionName, button, input)
                            }
                        />
                    </AdminAccordion>
                ))}
            </div>
        </div>
    );
};

export default Logins;
