import Tag from "../Tag";
import Icon, { Icons } from "components/Icon";

export type TagPresets = "green" | "grey" | "red";

interface IProps {
    id: string;
    text: string;
    preset: TagPresets;
    icon?: Icons;
    className?: string;
}

const iconColorPreset = {
    green: "primary-1100",
    grey: "grey-600",
    red: "mhred-alert3",
};

const tagColorPreset = {
    green: "border-primary-1100 text-primary-1100",
    grey: "border-grey-600 text-grey-600",
    red: "border-mhred-alert3 text-mhred-alert3",
};

const StatusTag = (props: IProps) => {
    const { id, text, preset, icon, className } = props;

    return (
        <Tag id={id} className={`h-7 rounded border ${tagColorPreset[preset]}`}>
            <p className={`whitespace-nowrap ${className}`}>{text}</p>
            {icon && <Icon icon={icon} size={16} stroke={iconColorPreset[preset]} className={"ml-2"} />}
        </Tag>
    );
};

export default StatusTag;
