import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";

interface IErrorMessage {
    heading?: string;
    text: string;
    linkText?: string;
    link?: string;
}

const ErrorMessage = ({ heading, text, linkText, link }: IErrorMessage) => (
    <>
        <div className="flex gap-2 bg-mhred-alert3 p-3 pr-10 text-white">
            <div>
                <Icon icon="warningCircle" size={24} />
            </div>
            <div>
                {heading && <div className="font-bold">{heading}</div>}
                {text}
            </div>
        </div>
        {linkText && link && (
            <div>
                <SecondaryButton
                    id="error-message-link"
                    text={linkText}
                    onClick={() => window.location.replace(link)}
                />
            </div>
        )}
    </>
);

export default ErrorMessage;
