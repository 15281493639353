import { isUndefined } from "lodash/fp";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CardWithExpand, Card, CardAlign, LoadableComponent, Form } from "@medhelp/ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import RadioButton from "components/RadioButton";
import { selectors, thunkActions } from "../../../redux";
import UserProfileForm from "../../../components/UserProfileForm";
import SettingsHeader from "../../../components/SettingsHeader";
import TertiaryButton from "components/TertiaryButton";
import SecondaryButton from "components/SecondaryButton";
import { MiniModal } from "@medhelp/ui/Modals";

const Profile = () => {
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(selectors.getUserEmployment);
    const status = useAppSelector(selectors.getStatus);

    const [userInfromationSettingsExpanded, setUserInfromationSettingsExpanded] = useState(false);
    const [userInformation, setUserInformation] = useState<string | undefined>(undefined);
    const [reason, setReason] = useState<string | undefined>(undefined);
    const [textArea, setTextAreaState] = useState<HTMLTextAreaElement | null>(null);

    const { t } = useTranslation(["translation", "followup"]);
    const Values = useMemo(() => {
        return [
            {
                value: "false",
                description: t("profile.companyInformation", {
                    ns: "followup",
                }),
                isActive: isUndefined(userInformation)
                    ? (!currentUser?.doNotEraseEmployment ?? false)
                    : userInformation === "false",
            },
            {
                value: "true",
                description: t("profile.customerInformation", {
                    ns: "followup",
                }),
                isActive: isUndefined(userInformation)
                    ? (currentUser?.doNotEraseEmployment ?? false)
                    : userInformation === "true",
            },
        ];
    }, [currentUser?.doNotEraseEmployment, t, userInformation]);

    const onCancel = useCallback(() => {
        setUserInformation(undefined);
    }, []);
    const onSave = useCallback(() => {
        if (userInformation) {
            dispatch(
                thunkActions.saveEmploymentEraseSettings({
                    doNotEraseEmployment: Boolean(userInformation === "true"),
                    reason,
                }),
            );
        }
    }, [dispatch, reason, userInformation]);

    const setTextArea = useCallback<<T>(node: T | null) => void>((node) => {
        if (node instanceof HTMLTextAreaElement) {
            setTextAreaState(node);
            return;
        }
    }, []);

    useEffect(() => {
        if (textArea && currentUser?.doNotDeleteMessage) {
            textArea.value = currentUser.doNotDeleteMessage;
        }
    }, [currentUser?.doNotDeleteMessage, textArea]);
    return (
        <Card testId="history-certificates-card" align={CardAlign.start}>
            <div className="flex w-full flex-col">
                <div className="min-h-[110px] w-full border-t-2 border-solid border-mhgrey-border bg-primaryBg">
                    <LoadableComponent status={status.userEmployment} errorMessage="Could not fetch user">
                        <UserProfileForm />
                    </LoadableComponent>
                </div>
                <LoadableComponent status={status.userEmployment} errorMessage="Could not fetch user">
                    <CardWithExpand
                        key="followup-profile-card-settings-thin-information"
                        index={1}
                        headerView={
                            <SettingsHeader
                                isExpanded={userInfromationSettingsExpanded}
                                onCancel={onCancel}
                                onSave={onSave}
                            />
                        }
                        onExpand={(value) => {
                            setUserInfromationSettingsExpanded(value);
                        }}
                    >
                        <div className="px-8 pb-4">
                            <div className="flex">
                                <p className="mr-2 font-bold">
                                    {t("profile.thinInformation", {
                                        ns: "followup",
                                    })}
                                </p>
                                <MiniModal heading={t("followUpAbsence.info.gallraHeader", { ns: "followup" })}>
                                    <p>
                                        {t("followUpAbsence.info.gallraInfo", {
                                            ns: "followup",
                                        })}
                                    </p>
                                </MiniModal>
                            </div>
                            {Values.map((props, index) => (
                                <RadioButton
                                    key={`followup-user-settings-for-employment-${index}`}
                                    id="RadioButton"
                                    values={props}
                                    selected={props.isActive}
                                    clicked={setUserInformation}
                                />
                            ))}
                            {(currentUser?.doNotEraseEmployment || userInformation === "true") && (
                                <div className="px-11 pt-5">
                                    <p>
                                        {t("reasonForHideiInformation", {
                                            ns: "followup",
                                        })}
                                    </p>
                                    <Form
                                        onChange={(d) => {
                                            if (d.target instanceof HTMLTextAreaElement) {
                                                setReason(d.target.value);
                                            }
                                        }}
                                        setRef={setTextArea}
                                        textarea
                                    />
                                </div>
                            )}
                            <div className="flex justify-center gap-4 py-0 sm:hidden">
                                <TertiaryButton id="cool" text={t("abort")} onClick={onCancel} />
                                <SecondaryButton id="cool" text={t("save")} onClick={onSave} />
                            </div>
                        </div>
                    </CardWithExpand>
                </LoadableComponent>
            </div>
        </Card>
    );
};
export default Profile;
