import Checkbox from "@medhelp/ui/Checkbox";
import { ICheckBoxStateProps, INotificationTypeRow } from "pages/Administration/types";
import { getNotificationTypeTranslationFromName } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";

interface INotificationTypeRowPageProps extends INotificationTypeRow {
    disableEditSubscription?: boolean;
    onCheckboxChange: (checkboxProps: ICheckBoxStateProps[]) => void;
}

const NotificationTypeRow = ({
    notificationTypeName,
    eventCode,
    checkboxesProps,
    isRoleSubscription,
    disableEditSubscription,
    onCheckboxChange,
}: INotificationTypeRowPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="flex justify-between border-b md:mb-0 md:px-10">
            <div className="flex w-full items-center py-6">
                <div className="w-1/2">
                    {getNotificationTypeTranslationFromName(notificationTypeName, eventCode, t)}
                </div>
                <div className="flex w-1/2 justify-between">
                    <div className="flex flex-col">
                        {isRoleSubscription === undefined ? (
                            <span />
                        ) : (
                            <p>{isRoleSubscription === true ? t("autoViaRole") : t("manuallyAdded")}</p>
                        )}
                    </div>
                    <div className="flex justify-between">
                        {checkboxesProps.map((checkbox, i) => (
                            <div className="flex w-18 justify-center" key={checkbox.subscriptionTypeId + "-" + i}>
                                <Checkbox
                                    checked={checkbox.checked}
                                    onChange={() => onCheckboxChange([checkbox])}
                                    disabled={disableEditSubscription}
                                />
                            </div>
                        ))}
                        {checkboxesProps.length < 2 && <span className="w-18" />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotificationTypeRow;
