import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { UseFormRegisterReturn } from "react-hook-form";

export interface IServiceDatePicker {
    label: string;
    activationRegister?: UseFormRegisterReturn<string>;
    deactivationRegister?: UseFormRegisterReturn<string>;
    activationDate?: string;
    deactivationDate?: string;
    children?: ReactNode;
}

const ServiceDatePicker = ({
    label,
    activationRegister,
    deactivationRegister,
    activationDate,
    deactivationDate,
    children,
}: IServiceDatePicker) => {
    const { t } = useTranslation("service");
    return (
        <div
            className={`my-1 flex min-h-[56px] w-full flex-wrap items-center justify-between border-1 bg-white px-6 py-2 ${
                activationDate ? "text-mhgrey-darkdisabled" : ""
            }`}
        >
            <div className="w-80">{label}</div>
            <div className="flex text-xs">
                <div className={`pr-2`}>{t("activationDate")}:</div>
                {activationRegister && (
                    <input className="bg-transparent font-bold" type="date" {...activationRegister} />
                )}
                {activationDate && (
                    <input className="bg-transparent font-bold" type="date" value={activationDate} disabled />
                )}
            </div>
            <div className="flex text-xs">
                <div className="pr-2">{t("deactivationDate")}:</div>
                {deactivationRegister && (
                    <input className="bg-transparent font-bold" type="date" {...deactivationRegister} />
                )}
                {deactivationDate && (
                    <input className="bg-transparent font-bold" type="date" value={deactivationDate} disabled />
                )}
            </div>
            {children}
        </div>
    );
};

export default ServiceDatePicker;
