import { twMerge } from "tailwind-merge";

export interface ITabs {
    key: string;
    text: string;
}
export interface TabMenuProps {
    tabs: ITabs[];
    activeTab: string;
    setActiveTab: (value: string) => void;
}

const TabMenu = ({ tabs, activeTab, setActiveTab }: TabMenuProps) => (
    <div className="flex gap-8 border-b px-6 pt-6">
        {tabs.map(({ key, text }) => (
            <button
                onClick={() => setActiveTab(key)}
                key={key}
                className={twMerge(
                    "flex items-center gap-1.5 pb-4 focus:shadow-none",
                    key === activeTab && "-mb-0.5 border-b-2 border-black font-bold",
                )}
            >
                {text}
            </button>
        ))}
    </div>
);

export default TabMenu;
