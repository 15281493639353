import { Card } from "@medhelp/ui";

const HistoryCasesLoading = () => {
    return (
        <Card className="animate-pulse">
            <div className="flex w-full flex-col">
                <div className={`flex w-full justify-between`}>
                    <div className="pl-0 pr-6">
                        <div className="h-2.5 w-26 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        <div className="mt-2 h-2.5 w-60 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default HistoryCasesLoading;
