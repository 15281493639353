export interface IProps {
    label: string;
    value: string;
}
const FormImmutableFormTextInput = ({ label, value }: IProps) => (
    <div className="mb-10 flex flex-col font-bold">
        <label className="mb-1.5">{label}</label>
        <input
            className="w-full border border-mhdarkgreen-original bg-transparent px-4 py-3 text-grey-350"
            disabled
            value={value}
        />
    </div>
);

export default FormImmutableFormTextInput;
