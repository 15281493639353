import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card } from "@medhelp/ui";
import { SpinnerPage } from "components/Spinner";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import SecondaryButton from "components/SecondaryButton";
import { Custom500 } from "pages/500/500";
import Modals from "./components/Modals";
import CurrentCertificate from "./components/CurrentCertificates";
import HistoryCertificates from "./components/HistoryCertificates";
import UploadError from "./components/UploadError";
import LoadMedicalCertificate from "./components/LoadMedicalCertificate";
import { thunkActions, actions, MedicalCertificateModals, selectors, FileStatus } from "./redux";
import PrimaryButton from "components/PrimaryButton";
import { useAppendixAccessRights } from "./hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AbsenceLoadStatus, useAbsenceLoadStatus } from "pages/Absence/pages/absencereporting/hooks";
import { toast } from "react-toastify";

const MedicalCertificate = () => {
    const { t } = useTranslation();
    const { routeEmploymentGuid } = useParams();
    const dispatch = useAppDispatch();
    const addedFile = useAppSelector(selectors.getAddedFile);
    const fileStatus = useAppSelector(selectors.medicalCertificateFileStatus);
    const activeCertificats = useAppSelector(selectors.getCurrentCertificates);
    const canShowAppendix = useAppendixAccessRights();

    const status = useAbsenceLoadStatus();

    useEffect(() => {
        dispatch(thunkActions.getFiles({ employmentGuid: routeEmploymentGuid }));
    }, [dispatch, routeEmploymentGuid]);

    const resetTempFile = useCallback(() => {
        dispatch(
            actions.setSuccess({
                groupKey: null,
                success: null,
            }),
        );
    }, [dispatch]);
    useEffect(() => {
        if (addedFile.success) {
            let groupKey = addedFile.groupKey;

            toast(addedFile.success === "current" ? t("fileUploadedSuccessCurrent") : t("fileUploadedSuccessHistory"), {
                onClose: resetTempFile,
            });

            if (groupKey && status === AbsenceLoadStatus.DONE && fileStatus === FileStatus.ok) {
                setTimeout(() => {
                    if (groupKey) {
                        document.getElementById(groupKey)?.scrollIntoView({ behavior: "smooth" });
                    }
                }, 300);
            }
        }
    }, [addedFile.groupKey, addedFile.success, dispatch, fileStatus, resetTempFile, status, t]);

    if (fileStatus === FileStatus.pending) {
        return <SpinnerPage />;
    }
    if (fileStatus === FileStatus.error) {
        return <UploadError />;
    }
    if (fileStatus === FileStatus.errorLoading) {
        return <Custom500 />;
    }

    return (
        <LoadMedicalCertificate>
            <div className="mt-8 xs:mt-16">
                <CurrentCertificate />
            </div>
            {canShowAppendix && (
                <Card testId="medical-certificate" className="mt-10">
                    {activeCertificats.length < 1 && (
                        <PrimaryButton
                            leftIcon={<PlusIcon />}
                            id="medical-certificate"
                            onClick={() => dispatch(actions.showModal(MedicalCertificateModals.uploadCertificate))}
                            text={t("newMedicalCertificate")}
                        />
                    )}
                    {activeCertificats.length > 0 && (
                        <SecondaryButton
                            leftIcon={<PlusIcon />}
                            id="medical-certificate"
                            onClick={() => dispatch(actions.showModal(MedicalCertificateModals.uploadCertificate))}
                            text={t("newMedicalCertificate")}
                        />
                    )}
                </Card>
            )}
            <HistoryCertificates />
            <Modals />
        </LoadMedicalCertificate>
    );
};

export default MedicalCertificate;
