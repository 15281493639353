import Icon from "components/Icon";

export interface IButtonValues {
    text: string;
    onClick: () => void;
    checked: boolean;
    key: string;
}

interface IProps {
    filterText: string;
    buttonValues: IButtonValues[];
    open: boolean;
    setOpen: () => void;
}

const RadioButtonFilter = (props: IProps) => {
    const { filterText, buttonValues, open, setOpen } = props;

    return (
        <div className="relative">
            <button onClick={setOpen} className="mb-5 flex items-center">
                <p className="mr-3 text-sm text-grey-700">{filterText}</p>
                <p className="mr-2 text-sm">{buttonValues.find((value) => value.checked)?.text}</p>
                <Icon icon={open ? "caretUp" : "caretDown"} size={8} />
            </button>
            <div className={`absolute -mt-4 flex w-64 flex-col bg-white py-2 shadow-lg ${open ? "z-20" : "hidden"}`}>
                {buttonValues.map((c) => (
                    <button
                        type="button"
                        key={c.key}
                        className={"mini-menu-foucs bg-white py-2 pl-4 text-left text-mhdarkgreen-original"}
                        onClick={() => {
                            c.onClick();
                            setOpen();
                        }}
                    >
                        <div className="flex justify-between">
                            {c.text}
                            <Icon
                                icon={c.checked ? "radioActive" : "radio"}
                                size={15}
                                className="mr-3 self-center"
                                stroke={"#192D05"}
                                fill={"#192D05"}
                            />
                        </div>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default RadioButtonFilter;
