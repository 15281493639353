import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as WarningIcon } from "icons/infoWarning.svg";

export interface ICountdownProps {
    message: string;
    time: number;
    setTime: (time: number) => void;
    navigateTo?: string;
    isError?: boolean;
}
const Countdown = ({ message, time, setTime, navigateTo, isError }: ICountdownProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (time > 0) {
            const intervalId = setInterval(() => {
                setTime(time - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [setTime, time]);

    useEffect(() => {
        if (navigateTo && time === 0) navigate(navigateTo);
    }, [navigate, navigateTo, time]);

    return (
        <div className={`flex ${isError ? "text-mhred-alert2" : "text-mhdarkgreen-original"}`}>
            {isError && (
                <div className="mt-1 flex">
                    <WarningIcon className="h-4.5 fill-none" />
                </div>
            )}
            <p className="mb-6 ml-3">{message}</p>
        </div>
    );
};

export default Countdown;
