import { useState } from "react";
import { useAppSelector } from "store/hooks";
import TabMenu, { ITabs } from "./TabMenu";
import UpcomingImport from "./UpcomingImport";
import { useTranslation } from "react-i18next";
import FileImportPanel from "./FileImportPanel";
import { skipToken } from "@reduxjs/toolkit/query";
import LastSuccessfulImport from "./LastSuccessfulImport";
import { getFileImportCustomerId } from "pages/Administration/redux/administrationSelectors";
import { useGetConfigurationCustomersByCustomerIdQuery } from "store/rtk-apis/fileImport/fileImportApi";

const ImportContainer = () => {
    const { t } = useTranslation("fileImport");
    const [activeTab, setActiveTab] = useState("upcoming");
    const customerId = useAppSelector(getFileImportCustomerId);

    const {
        data: configuration,
        error,
        isLoading,
    } = useGetConfigurationCustomersByCustomerIdQuery(customerId ? { customerId } : skipToken);

    if (error) return <></>;
    if (isLoading) return <></>;
    if (!configuration) return <></>;

    const tabs: ITabs[] = [
        { key: "upcoming", text: t("upcoming") },
        { key: "lastSuccessful", text: t("lastSuccessful") },
    ];

    return (
        <div className="flex flex-col">
            <FileImportPanel {...configuration} />

            <div className="bg-primaryBg shadow-md">
                <TabMenu tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className="flex w-full flex-col divide-y-1">
                    {activeTab === "upcoming" && <UpcomingImport {...configuration} />}
                    {activeTab === "lastSuccessful" && <LastSuccessfulImport {...configuration} />}
                </div>
            </div>
        </div>
    );
};

export default ImportContainer;
