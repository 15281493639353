import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { actions } from "../../redux";
import AbsenceConfirmation from "./AbsenceConfirmation";
import { CallbackMessage } from "./Callback/CallbackMessage";
import SecondaryButton from "components/SecondaryButton";
import NotificationConfirmation from "./AbsenceNotification/NotificationConfirmation";

export type PageTypes =
    | "absenceConfirmation"
    | "notificationConfirmation"
    | "medicalAdvisory"
    | "notificationConfirmationMedicalAdvisory";

export default function Index() {
    const [page, setPage] = useState<PageTypes>("absenceConfirmation");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const handleOnClose = () => {
        navigate("/absencereporting/");
        dispatch(actions.resetSelectedAbsenceReport());
    };

    const getNextPage = (page: PageTypes) => {
        switch (page) {
            case "absenceConfirmation":
                return <AbsenceConfirmation setPage={(value) => setPage(value)} />;
            case "notificationConfirmation":
                return (
                    <NotificationConfirmation>
                        <SecondaryButton
                            id="confirmation-close-button"
                            text={t("close")}
                            onClick={handleOnClose}
                            className="m-1 flex w-full justify-center xs:w-auto"
                        />
                    </NotificationConfirmation>
                );
            case "medicalAdvisory":
                return <CallbackMessage />;
            case "notificationConfirmationMedicalAdvisory":
                return (
                    <NotificationConfirmation className="border-b border-mhgrey-medium">
                        <CallbackMessage />
                    </NotificationConfirmation>
                );
            default:
                return null;
        }
    };

    return <div>{getNextPage(page)}</div>;
}
