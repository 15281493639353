import * as React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardAlign } from "@medhelp/ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectors, thunkActions } from "../../redux";
import { getHasBaseProduct } from "store/userSelectors";
import { absenceList } from "../../utils";
import * as FollowUpAbsenceListUtils from "./FollowUpAbsenceList.utils";
import FollowUpAbsenceItem from "./FollowUpAbsenceItem";

const FollowUpAbsenceList = () => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const currentAbsenceReports = useAppSelector(selectors.getAbsenceListSorted);
    const getAbsenceActivityLogs = useAppSelector(selectors.getAbsenceActivityLogsWithAbsenceReportId);
    const getAbsenceActivityLogsStatus = useAppSelector(selectors.getAbsenceActivityLogsStatusWithAbsenceReportId);
    const noAbsenceData = useAppSelector(selectors.getNoAbsenceData);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    const onExpand = React.useCallback(
        (absenceReportId?: string) => (epxanded: boolean) => {
            if (absenceReportId && epxanded) {
                const absenceActivityLogs = getAbsenceActivityLogs(absenceReportId);
                if (!absenceActivityLogs) {
                    dispatch(thunkActions.fetchAbsenceActivityLogs(absenceReportId));
                }
            }
            return epxanded;
        },
        [dispatch, getAbsenceActivityLogs],
    );
    const AbsenceData = React.useMemo(() => {
        if (!currentAbsenceReports) return [];

        const ui = currentAbsenceReports.map((currentAbsenceReport) => {
            if (!currentAbsenceReport) return null;
            const absenceCause = currentAbsenceReport?.properties
                ? absenceList.getProperty(currentAbsenceReport.properties, "AbsenceCause")
                : null;

            t(`followUpAbsence.summaryOfAbsence.${absenceCause?.value || ""}`);
            const AbsenceSicknessOrVab = t(FollowUpAbsenceListUtils.getAbsenceReason(currentAbsenceReport.type));

            // Absence Cause Holder, this will be used for containing translations for the absence cause
            let AbsenceCause = "";
            if (absenceCause?.value) {
                AbsenceCause = t(`followUpAbsence.summaryOfAbsence.${absenceCause.value}`);
            }

            if (absenceCause?.value === undefined) {
                AbsenceCause = "";
            }

            return {
                header: `${AbsenceSicknessOrVab}: ${AbsenceCause}`,
                list: FollowUpAbsenceListUtils.createAbsenceInfo(t, currentAbsenceReport, hasBaseProduct),
                ...currentAbsenceReport,
            };
        });
        return ui;
    }, [currentAbsenceReports, t, hasBaseProduct]);

    if (noAbsenceData) {
        return <Card>{t("noAbsenceData")}</Card>;
    }
    return (
        <Card testId="history-certificates-card" align={CardAlign.start} className="p-0">
            <div className="flex w-full flex-col">
                {AbsenceData.map((absenceData, index) => (
                    <FollowUpAbsenceItem
                        key={`${absenceData?.absenceReportId}-absencelist-${index}`}
                        absenceData={absenceData}
                        index={index}
                        getAbsenceActivityLogsStatus={getAbsenceActivityLogsStatus}
                        getAbsenceActivityLogs={getAbsenceActivityLogs}
                        onExpand={onExpand}
                        absenceReportId={absenceData?.absenceReportId}
                    />
                ))}
            </div>
        </Card>
    );
};

export default FollowUpAbsenceList;
