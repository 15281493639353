import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import axios from "axios";
import { Config } from "config";
import { RootState } from "store";
import {
    InitLoginModel,
    InitModel,
    InitResult,
    InitResult2,
    LoginModel2,
    LoginModel3,
    LoginPasswordModel,
    LoginTokenModel,
    StatusResult,
} from "swagger/authentication";
import { IReturnModelExtended, IGetUserAccounts, UserAccount, IWarningBannerData, IGetStatus } from "../types";
import * as apiEndpoints from "api/endpoints";
import { checkIfNativeApp } from "utils/nativeApp";
import { PasswordResetRequestViewModel, PasswordResetViewModel } from "swagger/usercontextservice";

const createConfig = (token?: string) => ({
    headers: { Authorization: `Bearer ${token ? token : getServices().token}` },
});

// const authService = `${Config.api_servicesBase_url}authenticationservice/api/auth/`;
// const authServiceAccept =
//     "https://accept-services.medhelp.se/authenticationservice/api/auth/";
// const userContextServiceAccept = `https://accept-services.medhelp.se/usercontextservice/api/`;

export const getWarningBannerModel = createAsyncThunk("getWarningBannerModel", async (_, thunkApi) => {
    const { data } = await axios({
        method: "GET",
        url: "https://medhelp-image-assets.s3.eu-west-1.amazonaws.com/status.json",
    });

    if (data) return data as IWarningBannerData;

    return thunkApi.rejectWithValue("rejected");
});

const getUserAccounts = async (model: IGetUserAccounts): Promise<UserAccount[]> => {
    // Todo ändra url
    // const url = `${userContextServiceAccept}user/GetUserAccountsByLoginType?type=${
    //     model.type
    // }&username=${model?.username ?? ""}`;
    const url = `${apiEndpoints.userContextService()}user/GetUserAccountsByLoginType?type=${
        model.type
    }&username=${model?.username ?? ""}`;
    const { data } = await axios.get<UserAccount[]>(url, createConfig(model.token));
    return data;
};

export const loginUsernameAndPass = createAsyncThunk(
    "loginUsernameAndPass",
    async (loginModel: LoginPasswordModel, thunkApi) => {
        const url = `${apiEndpoints.authService()}Login`;

        let loginModelLocal = loginModel;

        /*
         * * Important note: This preferred retailer id is needed for to make the login work
         * in the case of multiple companies on accept.
         */
        if (localStorage.getItem("multipleCompany") === "true") {
            loginModelLocal = {
                ...loginModel,
                preferredRetailerId: 1,
            };
        }

        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(loginModelLocal),
        });

        if (data.statusCode === 302) {
            const response = data as IReturnModelExtended;

            if (response.loginFailed) return data;

            const typeAndUsername: IGetUserAccounts = {
                type: 1,
                username: loginModel.username,
                token: response.accessToken!,
            };

            const userAccounts = await getUserAccounts(typeAndUsername);
            response.userAccounts = userAccounts;
            if (checkIfNativeApp() && userAccounts.length < 2) {
                window.ReactNativeWebView.postMessage(`loginUsernameAndPass$v${data.accessToken}$v${data.statusCode}`);
                return thunkApi.rejectWithValue("rejected");
            }
            return response;
        }
        if (checkIfNativeApp()) {
            window.ReactNativeWebView.postMessage(`loginUsernameAndPass$v${data.statusCode}`);
            return thunkApi.rejectWithValue("rejected");
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const postOneTimePass = createAsyncThunk("postOneTimePass", async (initOtpModel: InitModel, thunkApi) => {
    // const response =
    //     await getServices().clients.authentication.authInitOneTimePassLogin(
    //         initOtpModel,
    //     );
    const url = `${apiEndpoints.authService()}InitOneTimePassLogin`;
    // const url = `${authServiceAccept}InitOneTimePassLogin`;
    const { data, status } = await axios({
        method: "post",
        url: url,
        headers: {
            "Content-Type": "application/json",
        },
        data: JSON.stringify(initOtpModel),
    });

    if (status === 200) return data as InitResult;

    return thunkApi.rejectWithValue("rejected");
});

export const loginWithOneTimePass = createAsyncThunk(
    "loginWithOneTimePass",
    async (loginModel: LoginModel3, thunkApi) => {
        const url = `${apiEndpoints.authService()}LoginWithOneTimePass`;
        // const url = `${authServiceAccept}LoginWithOneTimePass`;

        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(loginModel),
        });

        if (data.statusCode === 200 || data.statusCode === 302) {
            let response = data as IReturnModelExtended;
            if (response.loginFailed) return response;

            const currentState = thunkApi.getState() as RootState;
            const typeAndUsername: IGetUserAccounts = {
                type: 1,
                username: currentState.login.oneTimePassProps.identifier,
                token: response.accessToken!,
            };
            const userAccounts = await getUserAccounts(typeAndUsername);
            response.userAccounts = userAccounts;
            if (checkIfNativeApp() && userAccounts.length < 2) {
                window.ReactNativeWebView.postMessage(
                    `loginWithUserWithOneTimePass$v${response.accessToken}$v${data.statusCode}`,
                );
                return thunkApi.rejectWithValue("rejected");
            }
            return { ...response, returnUrl: loginModel.returnUrl };
        }
        if (checkIfNativeApp()) {
            window.ReactNativeWebView.postMessage(`loginWithUserWithOneTimePass$v${""}$v${data.statusCode}`);
            return thunkApi.rejectWithValue("rejected");
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const loginUsingAzureAd = createAsyncThunk("loginUsingAzureAd", async (returnUrl?: string) => {
    const url = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?";
    const params = {
        client_id: Config.microsoftAppId,
        response_type: "id_token",
        redirect_uri: `${Config.web_portal_url}login/microsoft`,
        scope: "openid email profile",
        response_mode: "fragment",
        state: returnUrl ? returnUrl : `${Config.web_portal_url}login/microsoft`,
        nonce: "123123123",
    };
    const urlWithParams = url + new URLSearchParams(params).toString();
    document.location.href = urlWithParams;
});

export const loginWithMicrosoftToken = createAsyncThunk(
    "loginWithMicrosoftToken",
    async (loginModel: LoginTokenModel, thunkApi) => {
        const url = `${apiEndpoints.authService()}LoginWithMicrosoftToken`;
        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(loginModel),
        });
        if (data.statusCode === 302) {
            let response = data as IReturnModelExtended;
            if (response.loginFailed) return response;

            const typeAndUsername: IGetUserAccounts = {
                type: 5,
                token: response.accessToken!,
                username: response.identifier,
            };
            const userAccounts = await getUserAccounts(typeAndUsername);
            response.userAccounts = userAccounts;
            if (checkIfNativeApp() && userAccounts.length < 2) {
                window.ReactNativeWebView.postMessage(
                    `loginWithMicrosoftToken$v${data.accessToken}$v${data.statusCode}`,
                );
                return thunkApi.rejectWithValue("rejected");
            }
            return { ...response, returnUrl: loginModel.returnUrl };
        }
        if (checkIfNativeApp()) {
            window.ReactNativeWebView.postMessage(`loginWithMicrosoftToken$v${data.statusCode}`);
            return thunkApi.rejectWithValue("rejected");
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const initGrandIdNoGuiLogin = createAsyncThunk(
    "initGrandIdNoGuiLogin",
    async (loginModel: InitLoginModel, thunkApi) => {
        const url = `${apiEndpoints.authService()}InitGrandIdNoGuiLogin`;
        // Todo ändra url
        // const url = `${authServiceAccept}InitGrandIdNoGuiLogin`;

        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(loginModel),
        });

        if (data && !data.hasErrors) return data as InitResult2;

        return thunkApi.rejectWithValue("rejected");
    },
);

export const getGrandIdNoGuiStatus = createAsyncThunk(
    "getGrandIdNoGuiStatus",
    async (getStatusModel: IGetStatus, thunkApi) => {
        // Todo ändra url
        // const url = `${authServiceAccept}GetGrandIdNoGuiStatus${getStatusModel.isQr ? "QR" : ""}?sessionGuid=${sessionGuid}`;
        const url = `${apiEndpoints.authService()}GetGrandIdNoGuiStatus${
            getStatusModel.isQr ? "QR" : ""
        }?sessionGuid=${getStatusModel.sessionGuid}`;

        const { data } = await axios.get<StatusResult>(url);
        if (data && !data.error) return data;

        return thunkApi.rejectWithValue("rejected");
    },
);

export interface ILoginGrandIdNoGui {
    sessionGuid: string;
    returnUrl?: string;
}

export const loginGrandIdNoGui = createAsyncThunk(
    "loginGrandIdNoGui",
    async (loginNoGrandIdNoGui: ILoginGrandIdNoGui, thunkApi) => {
        const loginModel: LoginModel2 = {
            sessionGuid: loginNoGrandIdNoGui.sessionGuid,
        };
        // Todo ändra url
        // const url = `${authServiceAccept}LoginWithGrandIdNoGui`;
        const url = `${apiEndpoints.authService()}LoginWithGrandIdNoGui`;
        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(loginModel),
        });

        if (data && data.statusCode === 302) {
            let response = data as IReturnModelExtended;
            if (response.loginFailed) return response;
            const typeAndUsername: IGetUserAccounts = {
                type: 2,
                token: response.accessToken!,
                username: response.identifier,
            };
            const userAccounts = await getUserAccounts(typeAndUsername);
            response.userAccounts = userAccounts;
            if (checkIfNativeApp() && userAccounts.length < 2) {
                window.ReactNativeWebView.postMessage(`loginGrandIdNoGui$v${data.accessToken}$v${data.statusCode}`);
                return thunkApi.rejectWithValue("rejected");
            }
            return { ...response, returnUrl: loginNoGrandIdNoGui.returnUrl };
        }
        if (checkIfNativeApp()) {
            window.ReactNativeWebView.postMessage(`loginGrandIdNoGui$v${data.statusCode}`);
            return thunkApi.rejectWithValue("rejected");
        }

        return thunkApi.rejectWithValue("rejected");
    },
);

export const createPasswordReset = createAsyncThunk(
    "createPasswordReset",
    async (passwordResetRequestModel: PasswordResetRequestViewModel, thunkApi) => {
        const url = `${apiEndpoints.userContextService()}PasswordReset/CreateResetRequest`;
        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(passwordResetRequestModel),
        });

        if (data) return data;

        return thunkApi.rejectWithValue("rejected");
    },
);
export const getResetRequest = createAsyncThunk("getResetRequest", async (id: string, thunkApi) => {
    const url = `${apiEndpoints.userContextService()}PasswordReset/GetResetRequest?id=${id}`;
    // await getServices().clients.passwordReset.getResetRequest();

    const { data } = await axios.get<PasswordResetViewModel>(url);

    if (data) return data;

    return thunkApi.rejectWithValue("rejected");
});
export const passwordReset = createAsyncThunk(
    "passwordReset",
    async (passwordResetRequestModel: PasswordResetViewModel, thunkApi) => {
        const url = `${apiEndpoints.userContextService()}PasswordReset/Reset`;
        // await getServices().clients.passwordReset.reset();

        const { data } = await axios({
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: JSON.stringify(passwordResetRequestModel),
        });

        if (data) return data;

        return thunkApi.rejectWithValue("rejected");
    },
);
