import { useTranslation } from "react-i18next";
import WizardHeader from "components/WizardHeader";

interface AbsenceConfirmationProps {
    companySpecificConfirmation: string | null;
    showBorder: boolean;
    children: React.ReactNode;
}
const AbsenceConfirmation = ({ companySpecificConfirmation, showBorder, children }: AbsenceConfirmationProps) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="flex flex-col pt-10">
                <WizardHeader isMandatory={false} heading={t("absenceRegisteredConfirmation")} />
                <div className={`flex flex-col justify-center pt-10 ${showBorder && "border-b border-mhgrey-medium"}`}>
                    {companySpecificConfirmation ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: companySpecificConfirmation,
                            }}
                            className="break-words pb-12"
                            key="companySpecificText"
                        ></div>
                    ) : (
                        <div className="break-words pb-12" key="absenceConfirmationText">
                            <p>
                                {t("absenceConfirmationText")}
                                <br />
                                <br />
                                {t("getWell")}
                            </p>
                        </div>
                    )}
                </div>
                {children}
            </div>
        </div>
    );
};

export default AbsenceConfirmation;
