import React, { useRef, useState, useCallback } from "react";
import { TailSpin } from "react-loader-spinner";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useDetectOutsideClickModal } from "../../hooks";
import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
interface ISearchModalProps {
    id: string;
    className?: string;
    preset: ISearchBarSizePreset;
    placeholder: string;
    searchResultItemComponent: (result: any, index: number, hideModal?: () => void) => JSX.Element;
    searchHistory: any;
    searchResult: any;
    onInputChange: (query: string) => void;
    isLoading: boolean;
    clearSearch?: boolean;
    setClearSearch?: (value: boolean) => void;
    searchResultTitle?: string;
    modalOverlay?: boolean;
}

const searchBarSizePreset = {
    medium: "max-w-2xl",
    large: "w-full",
};

export type ISearchBarSizePreset = keyof typeof searchBarSizePreset;

const SearchModal = ({
    children,
    id,
    className,
    preset,
    placeholder,
    onInputChange,
    isLoading,
    searchHistory = [],
    searchResult = [],
    searchResultItemComponent,
    clearSearch = false,
    setClearSearch = undefined,
    searchResultTitle,
    modalOverlay = true,
}: ISearchModalProps & { children?: React.ReactNode }) => {
    const ref = useRef(null);
    const [searchNode, setSearchNode] = React.useState<HTMLInputElement | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const { t } = useTranslation();

    const setSearchNodeRef = React.useCallback((node: HTMLInputElement) => {
        setSearchNode(node);
    }, []);
    React.useEffect(() => {
        if (clearSearch && searchNode && setClearSearch) {
            searchNode.value = "";
            setClearSearch(false);
        }
    }, [clearSearch, searchNode, setClearSearch]);
    const onChange = useCallback(
        (value: string) => {
            if (value && value.length > 1) {
                const search = debounce(onInputChange, 300);
                search(value);
            }
            setSearchWord(value);
        },
        [onInputChange],
    );
    const hideModal = React.useCallback(() => {
        setOpen(false);
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceOnChange = React.useCallback(debounce(onChange, 300), []);
    useDetectOutsideClickModal(ref, open, setOpen, false);
    return (
        <>
            <div id={id} ref={ref} className={`xxs:relative ${open && "xxs:z-20"}`}>
                <div
                    id="searchbar"
                    className={twMerge(
                        "flex items-center border border-grey-500 p-3 pl-6",
                        open ? "absolute left-0 top-0 w-full bg-pageBg xxs:relative xxs:z-20" : "",
                        searchBarSizePreset[preset],
                        className,
                    )}
                >
                    <button type="button" className="flex" onClick={() => setOpen(!open)}>
                        <Icon icon={open ? "back" : "magnifier"} className="h-4 w-4 fill-none" />
                    </button>
                    <input
                        ref={setSearchNodeRef}
                        className="search-bar ml-4 w-full border-none bg-pageBg"
                        type="text"
                        data-cy="searchbarinput"
                        defaultValue={searchWord}
                        onChange={(e) => {
                            if (e.target.value.length > 1) {
                                debounceOnChange(e.target.value);
                            }
                        }}
                        onFocus={() => setOpen(true)}
                        placeholder={placeholder}
                    />
                    {isLoading && <TailSpin color="#3b3b36" height={20} width={20} />}
                </div>
                <div
                    data-cy="modal-content"
                    className={`z-20 h-full w-full bg-pageBg shadow-custom xxs:h-auto lg:min-w-xl ${
                        modalOverlay ? "border border-grey-500" : ""
                    } ${open ? "absolute left-0 top-0 mt-12 flex xxs:top-auto xxs:mt-0" : "hidden"} ${searchBarSizePreset[preset]} `}
                >
                    <div id="historyAndResultContainer" className="h-full w-full overflow-auto xxs:max-h-sm">
                        <div>
                            {searchResult?.length > 0 ? (
                                <div
                                    data-cy="endpointSearchResult"
                                    data-testid="endpointSearchResult"
                                    className="flex h-full flex-col"
                                    onClick={() => setOpen(false)}
                                >
                                    <p className="px-6 pb-2 pt-4 text-mhgrey-dark">{searchResultTitle}</p>
                                    {searchResult?.map((u: any, i: number) => {
                                        return searchResultItemComponent(u, i, hideModal);
                                    })}
                                    <div className="border-b" />
                                </div>
                            ) : (
                                searchWord &&
                                !isLoading && (
                                    <div className="list-hover flex w-full items-center border-b border-grey-200 p-6">
                                        <p className="flex text-lg text-mhred-alert2 underline">{t("noResult")}</p>
                                    </div>
                                )
                            )}
                        </div>
                        {searchHistory?.length > 0 && (
                            <div>
                                <p className="p-6 text-mhgrey-dark">{t("latestSearches")} </p>
                                {searchHistory?.map((u: any, i: number) => {
                                    return searchResultItemComponent(u, i, hideModal);
                                })}
                            </div>
                        )}
                    </div>
                    {children}
                </div>
            </div>
            {modalOverlay && (
                <div
                    id="modal-overlay"
                    className={`search-overlay-height absolute left-0 top-0 w-full bg-mhgrey-dark opacity-50 ${
                        open ? "z-10 hidden xxs:flex" : "hidden"
                    }`}
                />
            )}
        </>
    );
};

export default SearchModal;
