import React, { Children, cloneElement, useMemo } from "react";
import { useElementClicked } from "hooks";
import ReactModal from "react-modal";
import { setOverflow } from "utils/setOverflow";
import { Retailers } from "globalTypes";

export interface IProps {
    open: boolean;
    setOpen: (open: boolean, afterClose?: boolean) => void;
    children: React.ReactElement;
    theme?: Retailers;
    id?: string;
    background?: string;
    hideOnClickOutside?: boolean;
}

const Modal = ({ id, open, setOpen, theme, background, children, hideOnClickOutside = false }: IProps) => {
    const setNode = useElementClicked((value) => {
        if (!value && hideOnClickOutside) {
            setOpen(false);
        }
    });
    const Element = useMemo(
        () =>
            Children.map(children, (child) =>
                cloneElement(child, {
                    ref: setNode,
                }),
            ),
        [children, setNode],
    );
    return (
        <ReactModal
            id={id}
            testId={id}
            isOpen={open}
            className={`${theme} h-full w-full overflow-hidden backdrop-blur-sm focus:shadow-none`}
            style={{
                overlay: {
                    backgroundColor: "rgba(94, 95, 86, 0.5)",
                    zIndex: 10,
                },
                content: {
                    background: background,
                },
            }}
            shouldCloseOnEsc
            onRequestClose={() => setOpen(false)}
            onAfterOpen={() => setOverflow("hidden")}
            onAfterClose={() => {
                setOpen(false, true);
                setOverflow("auto");
            }}
        >
            {Element}
        </ReactModal>
    );
};

export default Modal;
