import { useTranslation } from "react-i18next";
import StatusTag, { FileImportStatus } from "./StatusTag";

interface ImportQueueInfoProps {
    status: FileImportStatus;
    importStart: string;
    importEnd?: string;
}

const ImportQueueInfo = ({ status, importStart, importEnd }: ImportQueueInfoProps) => {
    const { t } = useTranslation("fileImport");
    return (
        <div className="flex flex-col gap-6 md:flex-row md:justify-between md:gap-0">
            <div className="flex flex-col gap-4 text-sm md:flex-row md:gap-20 md:pr-8">
                <div>
                    <div>{t("importStart")}:</div>
                    <div className="font-bold">{importStart}</div>
                </div>
                {importEnd && (
                    <div>
                        <div>{t("importEnd")}:</div>
                        <div className="font-bold">{importEnd}</div>
                    </div>
                )}
            </div>
            <div>{status && <StatusTag status={status} />}</div>
        </div>
    );
};

export default ImportQueueInfo;
