import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { CostCalculationBasisViewModel } from "swagger/employeeattendance";
import { saveCostCalculation } from "pages/FollowUp/redux/followUpAsyncActions";
import Icon from "components/Icon";

const compensationLevels: (keyof CostCalculationBasisViewModel)[] = [
    "compensationLevelDayOne",
    "compensationLevelTwoToFourteen",
    "compensationLevel15To90",
    "compensationLevel91To180",
    "compensationLevel181To365",
];

const weekDays: (keyof CostCalculationBasisViewModel)[] = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
];

interface IAbsenceCostCalculationForm {
    costCalculation: CostCalculationBasisViewModel;
    year: number;
    setIsOpen: (open: boolean) => void;
}

const AbsenceCostCalculationForm = ({ costCalculation, year, setIsOpen }: IAbsenceCostCalculationForm) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        reset,
        register,
        formState: { isDirty, isValid },
    } = useForm({
        mode: "onChange",
        defaultValues: costCalculation,
    });

    const renderInput = (
        label: string,
        keyName: keyof CostCalculationBasisViewModel,
        unit?: string,
        onlyInteger?: boolean,
    ) => (
        <div className="flex">
            <label className="w-7/12">{label}:</label>
            <input
                className="w-3/12 border p-1"
                {...register(keyName, {
                    validate: (value) => {
                        return (
                            (onlyInteger ? /^[0-9]*$/ : /^[0-9]+(\.[0-9]{0,2})?$/).test(String(value)) ||
                            "Invalid number"
                        );
                    },
                })}
            />
            <div className="w-2/12 pl-2">{unit}</div>
        </div>
    );

    const onSubmit = (data: CostCalculationBasisViewModel) => {
        setLoading(true);
        dispatch(saveCostCalculation({ ...data, year }))
            .then(unwrapResult)
            .then(() => {
                setLoading(false);
                reset(data);
                toast(t("saved"), { type: "success" });
            })
            .catch(() => {
                setLoading(false);
                reset();
                toast(t("failed"), { type: "error" });
            });
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-y-2 bg-primaryBg p-4 shadow-lg md:w-48/100"
        >
            <div className="flex justify-between">
                <PrimaryButton id="costCalculationReset" disabled={!isDirty} type="button" onClick={() => reset()}>
                    {t("reset")}
                </PrimaryButton>
                <Icon
                    icon="info"
                    height="26"
                    width="26"
                    className="cursor-pointer text-mhgrey-medium"
                    onClick={() => setIsOpen(true)}
                />
            </div>
            <h3>
                {costCalculation.employmentGroupName
                    ? t(costCalculation.employmentGroupName)
                    : t(costCalculation.employmentGroupIdentifier || "")}
            </h3>
            <div className="flex">
                <label className="w-7/12">{t("year")}:</label>
                <input className="w-3/12 border p-1" value={year} disabled />
            </div>
            {renderInput(t("monthlySalaryAverage"), "monthlySalaryAverage", "kr", true)}
            {renderInput(t("holidayCompensation"), "holidayCompensation", "%")}
            {renderInput(t("socialSecurityContribution"), "socialSecurityContribution", "%")}
            {renderInput(t("hoursDayAverage"), "hoursDayAverage", "")}
            {renderInput(t("overheadCost"), "overheadCost", "")}
            <div>
                <h5>{t("compensationLevel")} (%)</h5>
                <div className="flex flex-wrap justify-between border-y">
                    {compensationLevels.map((item) => (
                        <div key={item} className="flex flex-col py-2">
                            <div className="text-sm">{t(item)}</div>
                            <input
                                className="w-10 border p-1"
                                {...register(item, {
                                    valueAsNumber: true,
                                    validate: (value) => /^[0-9]*$/.test(String(value)) || "Invalid number",
                                })}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h5>{t("workDays")}</h5>
                <div className="flex flex-wrap justify-between border-y">
                    {weekDays.map((day) => (
                        <div key={day} className="flex flex-col items-center py-2">
                            <label htmlFor={day} className="text-sm lg:hidden">
                                {t(day).slice(0, 3)}
                            </label>
                            <label htmlFor={day} className="hidden text-sm lg:flex">
                                {t(day)}
                            </label>
                            <input
                                id={day}
                                className="h-3 w-3 cursor-pointer accent-mhdarkgreen-original"
                                type="checkbox"
                                {...register(day)}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <PrimaryButton
                    id="costCalculationSubmit"
                    disabled={!isDirty || !isValid}
                    type="submit"
                    isLoading={loading}
                >
                    {t("save")}
                </PrimaryButton>
            </div>
        </form>
    );
};

export default AbsenceCostCalculationForm;
