import Icon from "components/Icon";
import { IRoleSubscriptionGroups } from "pages/Administration/types";
import { getChannelText, getNotificationTypeTranslation } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";
import { AddRoleSubscriptionConfigration } from "swagger/messenger";

export interface IRoleSubscriptionSavedProps {
    roleSubscriptionTypes: IRoleSubscriptionGroups[];
    deleteClicked: (subscription?: AddRoleSubscriptionConfigration) => void;
}
const RoleSubscriptionSavedRow = ({ roleSubscriptionTypes, deleteClicked }: IRoleSubscriptionSavedProps) => {
    const { t } = useTranslation("administration");
    return (
        <>
            <div className="hidden h-14 w-full items-center border-b xxs:flex">
                <p className="w-full font-bold xxs:w-2/5">{t("notificationType")}</p>
                <p className="w-full font-bold xxs:w-1/5">{t("channel")}</p>
                <p className="w-full font-bold xxs:w-2/5">{t("recipient")}</p>
                <span className="w-10" />
            </div>

            {roleSubscriptionTypes.map((type) => (
                <div key={type.groupName} className="flex flex-col">
                    <p className="border-b py-5 font-bold">{t(type.groupName)}</p>
                    {type.selectedRoleSubscriptions.map((sub, i) => (
                        <div
                            key={`${sub.notificationType}-${sub.eventCode}-index:${i}`}
                            className="flex w-full items-center justify-between border-b px-5 py-3"
                        >
                            <div className="flex w-full flex-col xxs:flex-row">
                                <p className="w-full xxs:w-2/5">
                                    {getNotificationTypeTranslation(
                                        Number(sub.notificationType),
                                        sub.eventCode ?? "",
                                        t,
                                    )}
                                </p>
                                <p className="w-full xxs:w-1/5">{t(getChannelText(sub.channel))}</p>
                                <p className="w-full xxs:w-2/5">{t(sub.role ?? "")}</p>
                            </div>
                            <button
                                className="flex h-10 w-10 items-center justify-center"
                                onClick={() => deleteClicked(sub)}
                            >
                                <Icon icon="trashCan" size={22} />
                            </button>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default RoleSubscriptionSavedRow;
