import Icon from "components/Icon";

const InfoBox = ({ text }: { text: string }) => (
    <div className="flex gap-4 rounded-lg bg-grey-100 px-4 py-8">
        <div>
            <Icon icon="lightBulb" />
        </div>
        <div className="max-w-xl">{text}</div>
    </div>
);

export default InfoBox;
