import Icon from "components/Icon";
import useHover from "hooks/useHover";
import Tooltip from "../Tooltip";
import { useTranslation } from "react-i18next";
import { getDateDisplayValue } from "utils/date";

interface IForecastChart {
    current?: number;
    forecast?: number;
    date?: string;
}

const ForecastChart = ({ current, forecast, date }: IForecastChart) => {
    const { t } = useTranslation("dashboard");

    const currentMonth = {
        month: getDateDisplayValue(date, "MMMM"),
    };

    const ForecastChange = () => {
        let text;
        let icon: "arrowUp" | "arrowDown" | "arrowRight";
        if ((forecast || 0) > (current || 0)) {
            text = t("absenceExpectedToIncrease", currentMonth);
            icon = "arrowUp";
        } else if ((forecast || 0) < (current || 0)) {
            text = t("absenceExpectedToDecrease", currentMonth);
            icon = "arrowDown";
        } else {
            text = t("absenceExpectedToRemain", currentMonth);
            icon = "arrowRight";
        }

        return (
            <div className="flex justify-between text-secondaryText">
                <div className="w-72 text-sm">{text}</div>
                <Icon icon={icon} size={26} />
            </div>
        );
    };

    return (
        <div className="mx-6 flex h-full flex-col justify-between pb-5 pt-10 text-tertiaryText">
            <div>
                <div className="border-b border-secondaryBorder" />
                <AbsenceForecastRow text={t("absenceSoFarThisMonth", currentMonth)} percentage={current || 0} />
                <AbsenceForecastRow
                    text={t("forecastForMonthAsAWhole", currentMonth)}
                    percentage={forecast || 0}
                    tooltip={true}
                />
            </div>
            {ForecastChange()}
        </div>
    );
};

interface IAbsenceForecastRow {
    text: string;
    percentage: number;
    tooltip?: boolean;
}

const AbsenceForecastRow = ({ text, percentage, tooltip }: IAbsenceForecastRow) => {
    const [hovered, eventHandlers] = useHover();
    const { t } = useTranslation("dashboard");
    return (
        <div className="flex h-10 items-center justify-between border-b border-secondaryBorder">
            <div
                // @ts-ignore
                {...eventHandlers}
                className={`text-sm ${tooltip ? "cursor-pointer" : ""}`}
            >
                {text}
                {tooltip && hovered && (
                    <Tooltip heading={t("forecast")} text={t("absenceForecastTooltip")} className="ml-12" />
                )}
            </div>
            <div className="text-xl font-bold">{percentage ? (percentage * 100).toFixed(2) : 0}%</div>
        </div>
    );
};

export default ForecastChart;
