import PrimaryButton from "components/PrimaryButton";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import UserAccordion from "../UserAccordion/UserAccordion";
import { useEffect, useState } from "react";
import { ModalFrame } from "@medhelp/ui";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { setOverflow } from "utils/setOverflow";
import { IUserSettingsProps, UserSettingsComponents } from "pages/UserSettings/types";

const DeleteHistoricalAbsenceCauses = ({ handleUpdateComponent, status, isLoading }: IUserSettingsProps) => {
    const { t } = useTranslation("userSettings");
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        if (status === "reqHasBeenSent" && !isLoading) setOpen(false);
    }, [isLoading, status]);

    return (
        <>
            <UserAccordion heading={t("deleteHistoricalAbsenceCauses")} disableButton={false} hideSave>
                <div className="flex flex-col">
                    <div className="mt-6 flex w-full flex-col items-center xl:flex-row">
                        <p className="w-full font-bold">{t("deleteExistingSendReq")}</p>
                        <div className="hidden w-full justify-end xl:flex">
                            <PrimaryButton
                                id="deleteHistoricalAbsenceCauses"
                                text={t("sendReq")}
                                disabled={false}
                                onClick={() => setOpen(true)}
                                leftIcon={<Icon icon="trashCan" />}
                            />
                        </div>
                    </div>
                    <p className="mt-3.5 max-w-screen-msd">{t("toDeleteYourExistingAbsenceCauses")}</p>
                    <div className="mt-12 flex w-full xl:hidden">
                        <PrimaryButton
                            id="deleteHistoricalAbsenceCauses"
                            text={t("sendReq")}
                            disabled={false}
                            onClick={() => setOpen(true)}
                            leftIcon={<Icon icon="trashCan" />}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </UserAccordion>
            <ModalFrame
                open={open}
                setOpen={setOpen}
                header={<h2>{t("sendReqForRemoval")}</h2>}
                content={<p>{t("toDeleteYourExistingAbsenceCauses")}</p>}
                footer={
                    <ModalCommonFooter
                        tertiaryText={t("abort")}
                        tertiaryOnClick={() => {
                            setOpen(false);
                            setOverflow("auto");
                        }}
                        primaryText={t("yesSendRequest")}
                        primaryOnClick={() => {
                            handleUpdateComponent({
                                componentToUpdate: UserSettingsComponents.DeleteHistoricalAbsenceCauses,
                            });
                        }}
                        isLoading={isLoading}
                    />
                }
            />
        </>
    );
};

export default DeleteHistoricalAbsenceCauses;
