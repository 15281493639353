import * as React from "react";

const PendingProgressBar = () => {
    return (
        <div
            data-cy="pending-progress-bar"
            className="relative h-2 w-full overflow-hidden rounded bg-grey-200 text-left"
        >
            <div className="animate-progress-bar h-full w-0 bg-primaryButtonPrimary"></div>
        </div>
    );
};

export default PendingProgressBar;
