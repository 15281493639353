import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import { IProps, EvenType } from "./ActivitesLog.utils";
import EditAbsenceValue from "../EditAbsenceValue";
import { getDateTimeDisplayValue } from "utils/date";

interface IMessageMobile extends IProps {
    showMessage: boolean;
    setShowMessage: (value: boolean) => void;
}
const Message = ({ text, showMessage, setShowMessage }: IMessageMobile) => {
    const { t } = useTranslation("followup");
    if (!text) return null;
    return (
        <div className="flex items-center gap-2" role="button" onClick={() => setShowMessage(!showMessage)}>
            <Icon icon={`${showMessage ? "caretFullDown" : "caretFullRight"}`} size={8} />
            <p>{showMessage ? t("hideMessage") : t("showMessage")}</p>
        </div>
    );
};

const MobileActivitesLog = (props: IProps) => {
    const { date, eventType, performedBy, reportedVia, text, contacts, editValues } = props;
    const [showMessage, setShowMessage] = useState(false);
    const { t } = useTranslation("followup");
    const RenderBody = useMemo(() => {
        if (editValues) {
            return <EditAbsenceValue data={editValues} />;
        }
        return <Message showMessage={showMessage} setShowMessage={setShowMessage} {...props} />;
    }, [editValues, props, showMessage]);
    const EventTypeName = useMemo(() => editValues?.[0].propertyName ?? eventType, [editValues, eventType]);
    return (
        <div className="border-b-solid border-b-1 border-background-300">
            <div className="flex flex-col justify-between py-5">
                <div className="flex gap-5">
                    <div className="flex">
                        <div className="relative flex h-12 w-12 rounded-full bg-grey-300 p-3">
                            <EvenType event={eventType} />
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t(`followUpAbsence.eventType.${EventTypeName || ""}`, {
                                    name: t(performedBy) || t("followUpAbsence.unknown"),
                                    app: t(`followUpAbsence.performedVia.${reportedVia || "0"}`),
                                }),
                            }}
                        />
                        <div>{getDateTimeDisplayValue(date)}</div>
                        {RenderBody}
                    </div>
                </div>

                {showMessage && text && (
                    <div className="mt-10 flex flex-col gap-4 text-mhdarkgreen-original">
                        <div className="text-base font-bold">{t("message")}</div>
                        <div
                            className=""
                            dangerouslySetInnerHTML={{
                                __html: text.replace(/(<\/?h[0-9]?\/?>)/g, ""),
                            }}
                        />
                        <div className="mt-6 text-base font-bold">{t("receiver")}</div>
                        <div className="flex flex-col">
                            {contacts.map(({ name, contactKey }) => (
                                <div className="flex flex-1 flex-col flex-wrap justify-between gap-4 align-baseline sm:flex-row sm:gap-0">
                                    <div className="w-[250px] truncate">{name}</div>
                                    <div className="flex-1">{contactKey}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default MobileActivitesLog;
