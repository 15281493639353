import { Trans, useTranslation } from "react-i18next";
import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";

interface IProps {
    modalIsOpen: boolean;
    handleStartNewCaseButtonClick: () => void;
    handleOnCancel: () => void;
}

const ConfirmStartCaseModal = (props: IProps) => {
    const { modalIsOpen, handleStartNewCaseButtonClick, handleOnCancel } = props;
    const { t } = useTranslation("followup");

    return (
        <ModalFrame
            id="start-case-confirmation-modal"
            header={<h1>{t("startNewCase")}</h1>}
            open={modalIsOpen}
            setOpen={handleOnCancel}
            content={<p className="px-3 pb-2 pt-8 sm:px-0">{<Trans i18nKey={t("infoStartCaseConfirmation")} />}</p>}
            footer={
                <ModalCommonFooter
                    tertiaryText={t("noCancel")}
                    tertiaryOnClick={handleOnCancel}
                    primaryText={t("startNew")}
                    primaryOnClick={handleStartNewCaseButtonClick}
                />
            }
        />
    );
};

export default ConfirmStartCaseModal;
