import * as React from "react";
import { ReactComponent as AccordionArrowDown } from "icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "icons/accordionArrowUp.svg";

export interface IProps {
    headerView: JSX.Element;
    children?: React.ReactNode;
    testId?: string;
    getExpanded?: (value: boolean) => void;
    onMount?: boolean;
    lock?: boolean;
}
const ExpandableComponent = ({
    headerView,
    children,
    getExpanded,
    onMount = false,
    testId = "expande-button-expandable-component",
    lock = false,
}: IProps) => {
    const [expanded, setExpanded] = React.useState(onMount);
    const onClick = React.useCallback(() => {
        const newState = !expanded;
        setExpanded(newState);
        if (getExpanded) {
            getExpanded(newState);
        }
    }, [expanded, getExpanded]);

    return (
        <div className="flex w-full flex-col">
            <div
                data-testid={testId}
                role="button"
                onClick={onClick}
                className="flex w-full cursor-pointer justify-between px-8 py-6"
            >
                {headerView}
                {!lock && (
                    <div className="flex h-8 w-8 items-center justify-center">
                        {expanded ? <AccordionArrowUp /> : <AccordionArrowDown />}
                    </div>
                )}
            </div>
            {expanded && !lock && children}
        </div>
    );
};

export default ExpandableComponent;
