import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

const Box = ({ children, className }: { children: ReactNode; className?: string }) => (
    <div className={twMerge("py-8 md:px-8", className)}>
        <div className="rounded-lg bg-white p-4 md:p-8">{children}</div>
    </div>
);

export default Box;
