import { Trans, useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { TemporarySubscription } from "swagger/messenger";
import SearchModal from "components/SearchModal";
import SearchResult from "components/SearchResult";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";

interface IProps {
    userManagerName: string;
    searchResult: TemporarySubscription[] | null;
    selectedColleagues: TemporarySubscription[];
    clearSearch: boolean;
    setClearSearch: Dispatch<SetStateAction<boolean>>;
    handleOnSearchInputChange: (query: string) => void;
    renderSearchResults: (searchItem: TemporarySubscription) => JSX.Element;
    removeColleagueToNotify: (colleague: TemporarySubscription) => void;
    handleSendNotification: () => void;
    handleOnClick: () => void;
}

const AbsenceNotificationWizardView = (props: IProps) => {
    const {
        userManagerName,
        searchResult,
        selectedColleagues,
        clearSearch,
        setClearSearch,
        handleOnSearchInputChange,
        renderSearchResults,
        removeColleagueToNotify,
        handleSendNotification,
        handleOnClick,
    } = props;
    const { t } = useTranslation();

    return (
        <div
            id="absence-notification-wizard-content"
            className="wizard-container-notify-colleagues relative flex flex-grow flex-col xs:w-full md:w-10/12"
        >
            <div className="flex flex-col">
                <div className="mb-16 border border-mhgrey-medium px-8 pb-6 pt-8">
                    <div className="flex">
                        <h2>{t("notifyColleagueHeading.createReport")}</h2>
                    </div>
                    <div className="flex flex-col gap-12 pt-4 xxl:flex-row">
                        <p className="w-full">
                            <Trans
                                i18nKey="notifyColleagueText.createReport"
                                values={{
                                    closestManager: userManagerName,
                                }}
                            />
                        </p>
                        <div className="w-full">
                            <SearchModal
                                id="searchAndResultSearchModal"
                                preset="medium"
                                searchHistory={[]}
                                searchResult={searchResult}
                                isLoading={false}
                                onInputChange={handleOnSearchInputChange}
                                placeholder={t("addColleagueToNotify")}
                                searchResultItemComponent={renderSearchResults}
                                searchResultTitle={t("suggestedColleagues")}
                                modalOverlay={false}
                                clearSearch={clearSearch}
                                setClearSearch={setClearSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="border-b border-grey-200 py-2 text-grey-600" key="selectedColleagues">
                    <span className="font-bold">{selectedColleagues.length} </span>
                    <span>{selectedColleagues.length === 1 ? t("colleagueSelected") : t("colleaguesSelected")}</span>
                </div>
                {selectedColleagues.map((selectedSearchItem: any) => (
                    <div
                        key={`${selectedSearchItem.id}-search-result-colleague`}
                        className="z-60 flex w-full flex-col justify-between border-b border-grey-200 px-6 py-5 xs:flex-row xs:items-center"
                    >
                        <SearchResult
                            id={selectedSearchItem.id ?? ""}
                            icon="person"
                            header={selectedSearchItem.name ?? ""}
                            searchResultItems={[
                                selectedSearchItem.name ?? "",
                                `${t("department")}: ${selectedSearchItem.name}`,
                            ]}
                        />
                        <SecondaryButton
                            id="add-remove-colleague"
                            text={t("delete")}
                            className="mx-12 w-23 justify-center px-3 xs:mx-0"
                            onClick={() => removeColleagueToNotify(selectedSearchItem)}
                        />
                    </div>
                ))}
            </div>
            <div>
                <div className="flex flex-col pt-15 xs:flex-row xs:pt-20">
                    <SecondaryButton
                        id="searchSecondaryButton"
                        text={t("close")}
                        onClick={handleOnClick}
                        className="m-1 flex w-full justify-center xs:w-auto"
                    />
                    <PrimaryButton
                        id="searchPrimaryButton"
                        text={t("sendNotification")}
                        disabled={selectedColleagues?.length === 0}
                        onClick={() => {
                            handleSendNotification();
                        }}
                        className="m-1 flex w-full justify-center xs:w-auto"
                    />
                </div>
            </div>
        </div>
    );
};

export default AbsenceNotificationWizardView;
