import Icon from "components/Icon/Icon";

const NewsWidgetLoader = () => {
    return (
        <div className="widget flex animate-pulse flex-col bg-primaryBg text-primaryText shadow-lg">
            <div className="widget-container-header flex flex-row items-center justify-between border-b border-mhgrey-light pl-6 pr-3">
                <div>
                    <p className="font-bold">Medhelp News</p>
                </div>
            </div>
            <div className="height-276">
                <NewsWidgetLoaderAccordion />
                <NewsWidgetLoaderAccordion />
            </div>
        </div>
    );
};

export default NewsWidgetLoader;

const NewsWidgetLoaderAccordion = () => (
    <>
        <div className="flex h-30 w-full items-center border-b border-mhgrey-light bg-pageBg px-8">
            <div className="h-full w-full pt-6">
                <div className="my-3 h-2.5 w-10/12 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="my-3 h-2.5 w-3/4 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="my-3 h-1.5 w-1/4 rounded-full bg-gray-100 dark:bg-gray-700"></div>
            </div>
            <div className="flex items-center justify-center">
                <Icon icon="caretDown" />
            </div>
        </div>
    </>
);
