import AdminInput from "../../AdminInput";
import { useTranslation } from "react-i18next";
import { IEmploymentInfoCompProps } from "pages/Administration/types";
import { Heading, Select } from "@medhelp/ui";

const EmploymentInfo = ({
    employedOn,
    employmentNumber,
    onChange,
    disableAll,
    employmentGroupValues,
}: IEmploymentInfoCompProps) => {
    const { t } = useTranslation("administration");
    return (
        <div className="border-b">
            <div className="p-10">
                <h3 className="mb-6">{t("employmentInfo")}</h3>
                <div className="mb-4 flex">
                    <AdminInput
                        heading={t("company")}
                        className="mr-4"
                        savedValue={employedOn}
                        onChange={() => {}}
                        disabled
                    />
                </div>
                <div className="flex">
                    <AdminInput
                        heading={t("EmploymentNumber")}
                        className="mr-4"
                        savedValue={employmentNumber}
                        onChange={(v) => onChange("employmentNumber", v)}
                        disabled={disableAll}
                    />
                    <div className="flex w-full flex-col">
                        <Heading heading={t("employmentGroup")} />
                        <Select
                            {...employmentGroupValues}
                            onChange={(v) => onChange("employmentGroups", v.value)}
                            containerClassName="w-full xxs:max-w-[308px]"
                            disabled={disableAll}
                            translationKey="administration"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmploymentInfo;
