import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
import { getDateDisplayValue } from "utils/date";

interface IDisplayValue {
    open: boolean;
    setOpen?: (open: boolean) => void;
    selectedLocale: string;
    dataTestId?: string;
    disabled?: boolean;
    selectedDate?: string;
    placeholder?: string;
    clearDate?: () => void;
    onClick?: () => void;
    heading?: string;
    isMandatory?: boolean;
    className?: string;
    isSmallscreen?: boolean;
}

const DisplayValue = ({
    open,
    setOpen,
    isSmallscreen,
    selectedLocale,
    dataTestId,
    disabled,
    placeholder,
    selectedDate,
    onClick,
    heading,
    isMandatory,
    className = "",
}: IDisplayValue) => (
    <div className="flex flex-col" onClick={() => isSmallscreen && setOpen && setOpen(true)}>
        {heading && (
            <div className="flex">
                <p className="font-bold xs:mb-4">{heading}</p>
                {isMandatory && <div className={"ml-1 text-2xl leading-6 text-mhred-alert2"}>*</div>}
            </div>
        )}
        <div className="flex flex-col" data-testid={dataTestId} tabIndex={0} onClick={onClick}>
            <div
                className={twMerge(
                    "flex h-12 w-full items-center pl-4",
                    className,
                    open ? "bg-inputDatepicker" : "border border-mhdarkgreen-original",
                    disabled ? "border-grey-500 hover:cursor-not-allowed" : "hover:cursor-pointer",
                )}
            >
                <Icon
                    icon="calendar"
                    size={18}
                    className={disabled ? "text-grey-500" : open ? "text-white" : "text-mhdarkgreen-original"}
                    strokeWidth={2}
                />
                <p
                    className={`ml-4 ${disabled ? "text-grey-500" : open ? "text-white" : "text-mhdarkgreen-original"}`}
                    data-testid={`${dataTestId}-display-value`}
                >
                    {selectedDate ? getDateDisplayValue(selectedDate, "P", selectedLocale) : placeholder}
                </p>
            </div>
        </div>
    </div>
);

export default DisplayValue;
