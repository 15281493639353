import { Tab, subscriptionHeadings } from "pages/Administration/types";
import { useTranslation } from "react-i18next";

export interface ISubscriptionHeadingsProps {
    subscriptions: number;
    user: string;
    activeTab: Tab;
}
const SubscriptionHeadings = ({ subscriptions, user, activeTab }: ISubscriptionHeadingsProps) => {
    const { t } = useTranslation("administration");

    if (subscriptions < 1)
        return (
            <p className="p-10 pb-0">
                {activeTab === "userSubsOn"
                    ? t("hasNoSubscriptions", { user: user })
                    : t("hasNoSubscribers", { user: user })}
            </p>
        );
    return (
        <div className="hidden w-full border-b bg-grey-50 py-1 font-bold xxs:flex xs:px-10">
            <div className="w-1/2">{t("name")}</div>
            <div className="flex w-1/2">
                {subscriptionHeadings?.map((heading) => (
                    <div key={heading} className="flex w-full">
                        {t(heading)}
                    </div>
                ))}
            </div>
            <span className="px-16" />
        </div>
    );
};

export default SubscriptionHeadings;
