import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import SearchModal from "components/SearchModal";
import SearchResultUser from "components/SearchResultUser";
import { Guid } from "utils/guid";
import * as UserSelectors from "store/userSelectors";
import { selectors as AbsenceReportsSelectors, actions as absenceReportsActions, thunkActions } from "../../redux";

const AbsenceReportingSearchPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { routeEmploymentGuid } = useParams();
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const [searchIsVisible, setSearchIsVisible] = useState<boolean>(false);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const recentEmploymentSearches = useAppSelector(AbsenceReportsSelectors.parsedRecentEmploymentSearches);
    const employmentSearch = useAppSelector(AbsenceReportsSelectors.getEmploymentSearch);

    useEffect(() => {
        dispatch(absenceReportsActions.reset());
        return () => {
            dispatch(absenceReportsActions.reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (userContext.userAccount?.id) {
            if (userContext.HasMedicalAdvisory || userContext.HasCustomerSupport) {
                setSearchIsVisible(true);
                dispatch(thunkActions.getAbsenceReportRecentEmploymentSearches("userSearch"));
            } else {
                navigate("/absencereporting");
            }
        }
    }, [dispatch, userContext, navigate]);

    useEffect(() => {
        setSearchIsLoading(false);
    }, [employmentSearch]);

    const handleOnSearchInputChange = (query: string) => {
        setSearchIsLoading(true);
        dispatch(thunkActions.getEmploymentsBySSN(query));
    };

    const handleSearchSelectedEmployment = (employmentSearchResult: IEmploymentSearchResult) => {
        if (employmentSearchResultIsValid(employmentSearchResult)) {
            dispatch(dispatch(absenceReportsActions.reset()));
            navigate(`/absencereporting/${employmentSearchResult.referenceKey}`);
        }
    };

    const employmentSearchResultIsValid = (employmentSearchResult: IEmploymentSearchResult): boolean => {
        return employmentSearchResult &&
            employmentSearchResult.referenceKey &&
            Guid.isValid(employmentSearchResult.referenceKey) &&
            employmentSearchResult.referenceKey !== routeEmploymentGuid
            ? true
            : false;
    };

    const renderUserSearchResults = (searchItem: IEmploymentSearchResult, index: number) => {
        return (
            <SearchResultUser
                key={index}
                searchResult={searchItem}
                onClick={() => {
                    handleSearchSelectedEmployment(searchItem);
                }}
            />
        );
    };

    return (
        <div id="absenceListPage" className="mt-2 w-full">
            {searchIsVisible && (
                <div className="w-full max-w-screen-xxxxl pb-6 sm:pt-6">
                    <SearchModal
                        id="SearchModalAbsenceReport"
                        preset="large"
                        searchHistory={recentEmploymentSearches}
                        searchResult={employmentSearch}
                        isLoading={searchIsLoading}
                        onInputChange={handleOnSearchInputChange}
                        placeholder={t("searchCoWorker")}
                        searchResultItemComponent={renderUserSearchResults}
                        searchResultTitle={t("suggestedSearches")}
                    />
                </div>
            )}
        </div>
    );
};

export default AbsenceReportingSearchPage;
