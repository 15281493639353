import ReactModal from "react-modal";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { ReactComponent as CloseIcon } from "icons/login/close.svg";
import { useTranslation } from "react-i18next";
import { getRetailerBg } from "pages/login/utils";

export interface ILoginModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    children?: React.ReactNode;
}
const LoginModal = ({ open, setOpen, children }: ILoginModalProps) => {
    const activeRetailer = useAppSelector(UserSelectors.getUserRetailer);
    const { t } = useTranslation("login");

    return (
        <div data-cy="login-modal">
            <ReactModal
                id="loginModal"
                isOpen={open}
                className="relative flex h-screen w-screen justify-center overflow-auto px-5 py-16 lg:max-h-4/5 lg:min-h-xl lg:max-w-4/5"
                style={{
                    overlay: {
                        zIndex: 10,
                        backgroundColor: "rgba(94, 95, 86, 0.5)",
                    },
                    content: { background: getRetailerBg(activeRetailer) },
                }}
                onRequestClose={() => setOpen(false)}
                shouldCloseOnEsc
            >
                <div className="fixed right-0 top-0 lg:absolute">
                    <button type="button" className="p-5 xl:p-9" onClick={() => setOpen(false)}>
                        <CloseIcon className="h-4" />
                    </button>
                </div>
                <div id="loginContent" className="w-full max-w-screen-msd">
                    {!children && (
                        <>
                            <h1 className="mb-12">{t("questionsAndAnswersLogin")}</h1>
                            <h3 className="mb-3">{t("whyNotAbleLogin")}</h3>
                            <p className="mb-9 whitespace-pre-line">{t("reasonUnableToLogin")}</p>
                            <h3 className="mb-3">{t("noResetLink")}</h3>
                            <p className="mb-9 whitespace-pre-line">{t("noResetLinkAnswer")}</p>
                            <h3 className="mb-3">{t("whichBrowserToUse")}</h3>
                            <p className="mb-9 whitespace-pre-line">{t("whichBrowserToUseAnswer")}</p>
                            <h3 className="mb-3">{t("saveShortcut")}</h3>
                            <p className="mb-9 whitespace-pre-line">{t("saveShortcutAnswer")}</p>
                        </>
                    )}
                    {children}
                </div>
            </ReactModal>
        </div>
    );
};

export default LoginModal;
