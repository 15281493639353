const OngoingAbsenceWidgetLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="flex w-full justify-between px-6 pb-4 pt-5 text-sm">
                <div className="flex w-1/3 items-center justify-center pr-2">
                    <div className="h-2.5 w-20 rounded-full bg-gray-600 dark:bg-gray-700"></div>
                </div>
                <div className="flex w-1/3 items-center justify-center pr-2">
                    <div className="h-2.5 w-20 rounded-full bg-gray-600 dark:bg-gray-700"></div>
                </div>
                <div className="flex w-1/3 items-center justify-center pr-2">
                    <div className="h-2.5 w-20 rounded-full bg-gray-600 dark:bg-gray-700"></div>
                </div>
            </div>
            <div className="flex h-full flex-col items-center">
                <div className="flex h-32 w-32 items-center justify-center">
                    <div className="h-20 w-20 rounded-full bg-gray-600 dark:bg-gray-700"></div>
                </div>
                <div className="mt-4 px-8 text-center leading-5">
                    <div className="h-2.5 w-40 rounded-full bg-gray-600 dark:bg-gray-700"></div>
                </div>
            </div>
        </div>
    );
};

export default OngoingAbsenceWidgetLoader;
