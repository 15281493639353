import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateForwardOrBack, getEachIntervalFormatted } from "utils/date";
import { actions, selectors } from "../../redux";
import YearsDropdown from "./YearsDropdown";

export default function Index() {
    const dispatch = useAppDispatch();
    const month = useAppSelector(selectors.getSearchMonthById("month"));
    const { t } = useTranslation("followup");
    const [open, setOpenState] = useState(false);

    const years = useMemo(
        () => getEachIntervalFormatted("years", getDateForwardOrBack(5, "years", "backwards"), new Date(), "yyyy"),
        [],
    );

    const onClickOutside = useCallback(
        (value: boolean) => {
            if (!value && open) {
                setOpenState(false);
            }
        },
        [open],
    );

    const setOpen = useCallback(() => {
        setOpenState(!open);
    }, [open]);
    const Heading = useMemo(() => <p>{month?.year || ""}</p>, [month?.year]);
    const Items = useMemo(
        () => (
            <>
                {years.map((year, index) => (
                    <div
                        onClick={() => {
                            dispatch(
                                actions.updateSearchFilter({
                                    id: "month",
                                    year: year,
                                    displayMonth: month.displayMonth,
                                    monthIndex: month.monthIndex,
                                    referenceKey: "month",
                                }),
                            );
                            setOpenState(false);
                        }}
                        className="flex w-full items-end hover:bg-mhgrey-light"
                        key={`years-dropdown-${year}-${index}`}
                    >
                        <div className="px-2 py-2">
                            <p>{year}</p>
                        </div>
                    </div>
                ))}
            </>
        ),
        [dispatch, month.displayMonth, month.monthIndex, years],
    );
    return (
        <YearsDropdown
            open={open}
            setOpen={setOpen}
            items={Items}
            heading={Heading}
            label={t("year")}
            onClickOutside={onClickOutside}
        />
    );
}
