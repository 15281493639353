import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions, actions, selectors } from "../../../redux";
import { TemporarySubscription } from "swagger/messenger";
import { PageTypes } from "..";
import * as UserSelectors from "store/userSelectors";
import SearchResult from "components/SearchResult";
import SecondaryButton from "components/SecondaryButton";
import AbsenceNotificationWizardView from "./AbsenceNotificationWizardView";
import AbsenceNotificationModalView from "./AbsenceNotificationModalView";

interface IProps {
    absenceReportId: string;
    typeOfRegistration: string;
    modalIsOpen?: boolean;
    setModalIsOpen?: () => void;
    setPage?: (page: PageTypes) => void;
}

export default function Index(props: IProps) {
    const { absenceReportId, typeOfRegistration, modalIsOpen, setModalIsOpen, setPage } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [clearSearch, setClearSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedColleagues, setSelectedColleagues] = useState<TemporarySubscription[]>([]);
    const [notificationSent, setNotificationSent] = useState(false);
    const companyId = useAppSelector(UserSelectors.getUserAccountCompanyId);
    const temporarySubscriptions = useAppSelector(selectors.getSearchedTemporarySubscriptions);
    const isMedicalAdvisory = useAppSelector(selectors.isMedicalAdvisory);
    const isError = useAppSelector(selectors.getSendAbsenceNotificationIsError);
    const userManagerName = useAppSelector(selectors.getUserManagerName);

    useEffect(() => {
        if (companyId) {
            dispatch(thunkActions.getTemporarySubscriptions(companyId));
        }
    }, [dispatch, companyId]);

    const searchResult = useMemo(() => {
        if (temporarySubscriptions) {
            return temporarySubscriptions(searchQuery);
        }
        return [];
    }, [searchQuery, temporarySubscriptions]);

    const handleOnSearchInputChange = useCallback((query: string) => {
        setSearchQuery(query);
    }, []);

    const addColleagueToNotify = useCallback(
        (colleague: TemporarySubscription) => {
            setSelectedColleagues([...selectedColleagues, colleague]);
        },
        [selectedColleagues],
    );

    const removeColleagueToNotify = useCallback(
        (colleague: TemporarySubscription) => {
            setSelectedColleagues(selectedColleagues.filter((c) => c.id !== colleague.id));
        },
        [selectedColleagues],
    );

    const handleSendNotification = useCallback(() => {
        dispatch(
            thunkActions.sendTemporarySubscriptions({
                absenceReportId,
                temporaryIds: selectedColleagues.map((colleague) => colleague.id ?? ""),
            }),
        );
        setNotificationSent(true);
        if (isMedicalAdvisory) {
            setPage && setPage("notificationConfirmationMedicalAdvisory");
        } else {
            setPage && setPage("notificationConfirmation");
        }
    }, [absenceReportId, dispatch, isMedicalAdvisory, selectedColleagues, setPage]);

    const handleOnClick = () => {
        if (typeOfRegistration === "createReport") {
            if (isMedicalAdvisory) {
                setPage && setPage("medicalAdvisory");
            } else {
                navigate("/absencereporting/");
                dispatch(actions.resetSelectedAbsenceReport());
            }
        } else {
            setModalIsOpen && setModalIsOpen();
            dispatch(actions.resetSelectedAbsenceReport());
        }
    };

    const renderSearchResults = useCallback(
        (searchItem: TemporarySubscription) => {
            const colleagueIsSelected = selectedColleagues.some(({ id }) => id === searchItem.id);
            return (
                <div
                    key={`${searchItem.id}-search-result-colleague`}
                    className="relative flex w-full flex-col justify-between border-b border-grey-200 px-6 py-5 xs:flex-row xs:items-center"
                >
                    <SearchResult
                        id={searchItem.id ?? ""}
                        icon="person"
                        header={searchItem.name ?? ""}
                        searchResultItems={[
                            searchItem.receiver ?? "",
                            `${t("department")}: ${searchItem.departmentName ?? ""}`,
                        ]}
                    />
                    <SecondaryButton
                        id="add-remove-colleague"
                        text={colleagueIsSelected ? t("delete") : t("add")}
                        className="mx-12 w-32 justify-center px-3 xs:mx-0"
                        onClick={() => {
                            setClearSearch(true);
                            colleagueIsSelected
                                ? removeColleagueToNotify(searchItem)
                                : addColleagueToNotify(searchItem);
                        }}
                    />
                </div>
            );
        },
        [addColleagueToNotify, removeColleagueToNotify, selectedColleagues, t],
    );

    return typeOfRegistration === "createReport" ? (
        <AbsenceNotificationWizardView
            userManagerName={userManagerName}
            searchResult={searchResult}
            selectedColleagues={selectedColleagues}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            handleOnSearchInputChange={handleOnSearchInputChange}
            renderSearchResults={renderSearchResults}
            removeColleagueToNotify={removeColleagueToNotify}
            handleSendNotification={handleSendNotification}
            handleOnClick={handleOnClick}
        />
    ) : (
        <AbsenceNotificationModalView
            isError={isError}
            userManagerName={userManagerName}
            absenceNotificationModalIsOpen={modalIsOpen ?? false}
            typeOfRegistration={typeOfRegistration}
            notificationSent={notificationSent}
            searchResult={searchResult}
            selectedColleagues={selectedColleagues}
            clearSearch={clearSearch}
            setClearSearch={setClearSearch}
            handleOnSearchInputChange={handleOnSearchInputChange}
            renderSearchResults={renderSearchResults}
            removeColleagueToNotify={removeColleagueToNotify}
            handleSendNotification={handleSendNotification}
            handleOnClick={handleOnClick}
        />
    );
}
