import DefaultTableRow, { ITableRowProps, IRow } from "./DefaultTableRow";

export type Column = JSX.Element[];

export interface IDefaultTableRow extends IRow {
    children: React.ReactNode;
}
export interface IProps {
    data: {
        column?: Column;
        row: IRow[];
    };
    RowConatiner?: React.FC<ITableRowProps>;
    footer?: JSX.Element;
    placeholder?: JSX.Element;
    loading?: boolean;
    firstElementWidth?: string;
}
interface IViewProps extends IProps {}

const SearchTable = ({
    data: { row, column },
    footer,
    loading = false,
    placeholder,
    firstElementWidth,
    RowConatiner = DefaultTableRow,
}: IViewProps) => (
    <table className={`${loading ? "h-[500px] w-full table-auto" : "h-full w-full table-auto"}`}>
        {loading && placeholder ? (
            <thead>
                <tr>
                    <th data-testid="placeholder-table">{placeholder}</th>
                </tr>
            </thead>
        ) : (
            <>
                <thead>
                    {column ? (
                        <tr className="border-b-solid border-b-1 px-6 text-left">
                            {column.map((col, index) => {
                                const firstElement = index === 0;
                                const lastElement = index === column.length - 1;
                                return (
                                    <th
                                        className={`${
                                            firstElement && `pl-6 ${firstElementWidth && `w-${firstElementWidth}`}`
                                        } ${lastElement && "pr-6"}`}
                                        key={`${col.key}-${index}-searchtable`}
                                    >
                                        {col}
                                    </th>
                                );
                            })}
                        </tr>
                    ) : (
                        <tr>
                            <th />
                        </tr>
                    )}
                </thead>
                <tbody className="h-full">
                    {row.map((row, index) => (
                        <RowConatiner {...row} key={`row-container-${row.id}-${index}`}>
                            {row?.cells?.map((cell, index) => (
                                <td key={`${index}-${cell.id}`} className="h-full align-top">
                                    <div className="flex flex-col">{cell.data.map((value) => value)}</div>
                                </td>
                            ))}
                        </RowConatiner>
                    ))}
                </tbody>
                {footer && (
                    <tfoot>
                        <tr>{footer}</tr>
                    </tfoot>
                )}
            </>
        )}
    </table>
);
export default SearchTable;
