import UsernameOrPwdInput from "pages/login/components/UsernameOrPwdInput";
import { IUserSettingsProps, UserSettingsComponents } from "pages/UserSettings/types";
import { getPwdMissing } from "pages/UserSettings/utils";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserAccordion from "../UserAccordion/UserAccordion";

const SetPassword = ({ handleUpdateComponent, status, isLoading }: IUserSettingsProps) => {
    const { t } = useTranslation("userSettings");
    const [currentPass, setCurrentPass] = useState<string>("");
    const [newPass, setNewPass] = useState<string>("");
    const [confirmNewPass, setConfirmNewPass] = useState<string>("");
    const [pwdMissing, setPwdMissing] = useState<string[]>([]);

    const renderPasswordStrength = (): ReactNode => {
        if (pwdMissing.length > 2) {
            return (
                <>
                    <div className="absolute h-1.5 w-1/3 bg-mhred-alert3" />
                    <p className="text-right text-sm">{t("weakPwd")}</p>
                </>
            );
        } else if (pwdMissing.length === 0) {
            return (
                <>
                    <div className="w-3/3 absolute h-1.5 bg-primary-700" />
                    <p className="text-right text-sm">{t("strongPwd")}</p>
                </>
            );
        } else {
            return (
                <>
                    <div className="absolute h-1.5 w-2/3 bg-mhblue" />
                    <p className="text-right text-sm">{t("averagePwd")}</p>
                </>
            );
        }
    };

    const newPassError = (!!currentPass && !newPass) || (pwdMissing.length !== 0 && pwdMissing.length !== 5);
    const newPassErrorMessage =
        pwdMissing.length !== 0 && pwdMissing.length !== 5
            ? `${t("pwdIsMissingA")} ${pwdMissing.join(", ")}`
            : t("mustStateNewPass");
    const confirmNewPassError = !!confirmNewPass && confirmNewPass !== newPass;

    const formIsInvalid = !currentPass || newPassError || !confirmNewPass || confirmNewPassError;

    const handleClick = () => {
        if (!formIsInvalid) {
            handleUpdateComponent({
                componentToUpdate: UserSettingsComponents.ChangePassword,
                pwdCompValues: {
                    currentPassword: currentPass,
                    newPassword: newPass,
                    newPasswordMatch: confirmNewPass,
                },
            });
        }
    };

    useEffect(() => {
        setPwdMissing(getPwdMissing(newPass, t));
    }, [newPass, t]);

    useEffect(() => {
        if (!isLoading && status === "newPassSaved") {
            setCurrentPass("");
            setNewPass("");
            setConfirmNewPass("");
        }
    }, [status, isLoading]);

    return (
        <UserAccordion
            heading={t("changePassword")}
            saveClicked={handleClick}
            disableButton={formIsInvalid}
            isLoading={isLoading}
        >
            <div className="flex w-full flex-col md:flex-row">
                <form onSubmit={handleClick} className="flex flex-col">
                    <input autoComplete="username" className="hidden" />
                    <UsernameOrPwdInput
                        heading={t("currentPass")}
                        inputValue={currentPass}
                        inputChanged={(v) => setCurrentPass(v)}
                        containerClassName="mb-8 xs:max-w-xs"
                        className="h-12"
                        type="password"
                        name="currentPwd-password"
                        submitOnEnter={handleClick}
                        isMandatory
                        errorMessage={status === "authFailed" ? t("currentPwdIncorrect") : undefined}
                        disableKeyCode={32}
                        autoComplete="current-password"
                    />
                    <UsernameOrPwdInput
                        heading={t("newPass")}
                        inputValue={newPass}
                        inputChanged={(v) => setNewPass(v)}
                        containerClassName={`xs:max-w-xs ${newPass ? "" : "mb-8"}`}
                        className="h-12"
                        type="password"
                        name="newPwd-password"
                        submitOnEnter={handleClick}
                        isMandatory
                        errorMessage={newPassError ? newPassErrorMessage : ""}
                        disableKeyCode={32}
                        autoComplete="new-password"
                    />
                    {newPass && (
                        <div className="relative mt-1 flex w-full max-w-xs flex-col">
                            <div className="h-1.5 w-full bg-grey-200" />
                            {renderPasswordStrength()}
                        </div>
                    )}
                    <UsernameOrPwdInput
                        heading={t("confirmNewPass")}
                        inputValue={confirmNewPass}
                        inputChanged={(v) => setConfirmNewPass(v)}
                        containerClassName="mb-8 xs:max-w-xs"
                        className="h-12"
                        type="password"
                        name="confirmPwd-password"
                        submitOnEnter={handleClick}
                        isMandatory
                        errorMessage={confirmNewPassError ? t("passNotMatching") : ""}
                        disableKeyCode={32}
                        autoComplete="new-password"
                    />
                </form>
                <div className="mt-8 flex h-max w-full flex-col border border-mhdarkgreen-original p-6 xs:max-w-sm md:ml-3 xl:ml-20">
                    <p className="font-bold">{t("newPwdMustContain")}</p>
                    <ul className="list-disc pl-6">
                        <li>{t("atleastSmallLetter")}</li>
                        <li>{t("atleastCapitalLetter")}</li>
                        <li>{t("atleastOneNumber")}</li>
                        <li>{t("altlestSpecialChar")}</li>
                        <li>{t("atleastNineChars")}</li>
                    </ul>
                </div>
            </div>
        </UserAccordion>
    );
};

export default SetPassword;
