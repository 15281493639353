import { debounce } from "lodash";
import { useMemo } from "react";

export interface IUsernameOrPwdInputProps {
    containerClassName?: string;
    className?: string;
    heading?: string;
    inputValue?: string;
    inputChanged: (inputValue: string) => void;
    errorMessage?: string;
    errorClassName?: string;
    type: string;
    name: string;
    placeholder?: string;
    submitOnEnter?: () => void;
    onlyDigits?: boolean;
    isMandatory?: boolean;
    disableKeyCode?: number;
    autoComplete?: string;
    debounceInputChange?: (value: string) => void;
    disabled?: boolean;
}
const UsernameOrPwdInput = ({
    containerClassName,
    className,
    heading,
    inputValue,
    inputChanged,
    errorMessage,
    errorClassName,
    type,
    name,
    placeholder,
    submitOnEnter,
    onlyDigits,
    isMandatory,
    disableKeyCode,
    autoComplete,
    debounceInputChange,
    disabled,
}: IUsernameOrPwdInputProps) => {
    const handleKeyDown = (event: { key: string; keyCode: number }) => {
        if (onlyDigits) {
            const digits = new RegExp(/[0-9]|-/);
            if (
                !digits.test(event.key) &&
                event.key !== "Backspace" &&
                event.key !== "ArrowLeft" &&
                event.key !== "ArrowRight"
            ) {
                window.event?.preventDefault();
            }
        }
        if (submitOnEnter && event.key === "Enter") submitOnEnter();
        if (disableKeyCode && event.keyCode === disableKeyCode) window.event?.preventDefault();
    };

    const debounceOnChange = useMemo(() => {
        if (debounceInputChange) {
            return debounce(debounceInputChange, 500);
        } else return debounce(() => {}, 500);
    }, [debounceInputChange]);

    return (
        <div className={`w-full xs:min-w-xs ${containerClassName}`}>
            {heading && (
                <p className="mb-2 font-bold">
                    {heading} {isMandatory ? "*" : ""}
                </p>
            )}
            <input
                type={type}
                name={name}
                className={`w-full whitespace-pre-wrap border bg-pageBg pl-2 text-black ${
                    errorMessage ? "border-mhred-alert1" : "border-mhdarkgreen-original"
                } ${className}`}
                value={inputValue}
                onChange={(e) => {
                    inputChanged(e.target.value);
                    debounceOnChange(e.target.value);
                }}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                autoComplete={autoComplete ? autoComplete : "on"}
                disabled={disabled}
            />
            <p className={`mt-0.5 text-mhred-alert1 ${errorClassName}`}>{errorMessage}</p>
        </div>
    );
};

export default UsernameOrPwdInput;
