import React from "react";
import { TailSpin } from "react-loader-spinner";
import { twMerge } from "tailwind-merge";
export interface IButtonProps
    extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    id: string;
    text?: string;
    rightIcon?: React.ReactElement;
    leftIcon?: React.ReactElement;
    isLoading?: boolean;
    children?: React.ReactNode;
    styleText?: string;
}

const PrimaryButton = (props: IButtonProps) => {
    const {
        text,
        id,
        disabled = false,
        className,
        rightIcon,
        leftIcon,
        onClick,
        isLoading,
        children,
        styleText,
        ...rest
    } = props;
    return (
        <button
            type="button"
            id={id}
            data-testid={id}
            className={twMerge(
                "h-12 items-center whitespace-nowrap px-8 font-bold focus:outline-none focus:ring-1 focus:ring-primaryButtonFocusFrame active:bg-primaryButtonSecondary",
                disabled || isLoading
                    ? "cursor-not-allowed bg-primaryButtonDisabled text-primaryButtonDisabledText"
                    : "bg-primaryButtonPrimary text-primaryButtonText hover:bg-primaryButtonSecondary",
                className,
            )}
            onClick={(e) => (onClick ? onClick(e) : () => {})}
            onDoubleClick={() => console.log("not implemented")}
            onDoubleClickCapture={() => console.log("not implemented")}
            {...rest}
            disabled={disabled || isLoading}
        >
            {children ? (
                children
            ) : (
                <div className={twMerge("relative flex items-center", styleText)}>
                    {leftIcon && <div className={`mr-2.5 flex items-center`}>{leftIcon}</div>}
                    <div>{text}</div>
                    {!isLoading && rightIcon && <div className={`ml-2.5 flex h-10 items-center`}>{rightIcon}</div>}
                    {isLoading && (
                        <div className="ml-4 flex">
                            <div className="absolute top-0">
                                <TailSpin color="#3b3b36" height={20} width={20} />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </button>
    );
};
export default PrimaryButton;
