import Icon from "components/Icon";

const ErrorWidget = () => {
    return (
        <div className="flex h-full flex-col items-center justify-center pb-10 pt-3">
            <div>
                <Icon icon="close" size={100} />
            </div>
            <p>Ops... Something went wrong</p>
        </div>
    );
};

export default ErrorWidget;
