import React, { SyntheticEvent } from "react";
import { TailSpin } from "react-loader-spinner";
import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { twMerge } from "tailwind-merge";

export interface IProps {
    header: React.ReactElement;
    items: React.ReactElement;
    setOpen: (event: SyntheticEvent) => void;
    open: boolean;
    overflowText?: string | JSX.Element;
    isOverflowing?: boolean;
    onClickOutside?: (value: boolean) => void;
    keepClose?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

const Secondary = ({
    header,
    setOpen,
    open,
    items,
    overflowText,
    isOverflowing,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClickOutside = (value: boolean) => {},
    keepClose = false,
    loading = false,
    disabled,
}: IProps) => {
    const ref = useElementClicked(onClickOutside);
    return (
        <div ref={ref}>
            <div
                className={twMerge(
                    "flex h-[50px] w-full flex-1 items-center justify-between border-1 p-2",
                    disabled ? "cursor-not-allowed border-grey-500" : "border-black",
                )}
            >
                {header}
                <div className="flex">
                    {overflowText &&
                        isOverflowing &&
                        (React.isValidElement(overflowText) ? (
                            overflowText
                        ) : (
                            <p className="w-7 self-center text-sm font-bold">{overflowText}</p>
                        ))}

                    {loading ? (
                        <div className="ml-4 flex">
                            <TailSpin color="#3b3b36" height={20} width={20} />
                        </div>
                    ) : (
                        <button
                            onClick={(event) => {
                                if (keepClose) return;
                                setOpen(event);
                            }}
                            className={twMerge(
                                "m-1 p-1",
                                keepClose ? "opacity-50" : "",
                                disabled ? "cursor-not-allowed" : "cursor-pointer",
                            )}
                            data-testid="secondary-dropdown-icon-button"
                        >
                            <Icon icon={open ? "triangleUp" : "triangleDown"} size={12} />
                        </button>
                    )}
                </div>
            </div>
            <div className="relative z-10">
                {open && (
                    <div className="absolute z-10 max-h-sm w-full cursor-pointer overflow-y-scroll border-b-1 border-l-1 border-r-1 border-black bg-pageBg pt-1">
                        {items}
                    </div>
                )}
            </div>
        </div>
    );
};
export default Secondary;
