import { Heading, PhonePrefix } from "@medhelp/ui";
import { getCustomCountryList } from "@medhelp/ui/PhonePrefix/PhonePrefix.utils";
import { debounce } from "lodash";
import { useState, useMemo, useCallback, KeyboardEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { getCountryPrefix, getPhoneNumber, validateMobileNumber } from "utils/phone";

export interface IPhoneNumberComponentProps {
    value: string;
    onChange: (value: string) => void;
    useCustomCountryList?: boolean;
    validateFields?: boolean;
    className?: string;
    countryHeader: string;
    phoneNumberHeader: string;
    disabled?: boolean;
    isValid?: (valid: boolean) => void;
    placeholder?: string;
    showMandatory?: boolean;
    allowEmptyNumber?: boolean;
    children?: ReactNode;
}
const PhoneNumberComponent: React.FunctionComponent<IPhoneNumberComponentProps> = ({
    value,
    onChange,
    useCustomCountryList,
    validateFields,
    className,
    countryHeader,
    phoneNumberHeader,
    disabled,
    isValid,
    placeholder,
    children,
    showMandatory,
    allowEmptyNumber,
}) => {
    const { t } = useTranslation("login");

    const [prefixErrorMessage, setPrefixErrorMessage] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(
        getPhoneNumber(value) === "initialValue" ? "" : getPhoneNumber(value),
    );

    const customCountryList = getCustomCountryList(t);

    const debounceOnChange = useMemo(() => {
        return debounce(onChange, 300);
    }, [onChange]);

    const handleChange = (newValue: string, isPhoneNumber = false) => {
        const phoneNumberWithPrefix = newValue.includes("+")
            ? `${newValue}-${getPhoneNumber(value)}`
            : `${getCountryPrefix(value)}-${newValue}`;
        isPhoneNumber ? debounceOnChange(phoneNumberWithPrefix) : onChange(phoneNumberWithPrefix);
    };
    const disablePrefix = useMemo(
        () =>
            !useCustomCountryList ||
            (useCustomCountryList && getCountryPrefix(value) === "+45") ||
            (useCustomCountryList && getCountryPrefix(value) === "+46"),
        [useCustomCountryList, value],
    );

    const handlePhonePrefixValue = (countryCode: string): string => {
        if (!useCustomCountryList) return countryCode;
        return countryCode === "+46" || countryCode === "+45" ? countryCode : "+";
    };

    const handlePhoneOnKeyDown = useCallback((e: KeyboardEvent) => {
        //Only accept numbers and backspace
        if (e.key !== "Backspace" && isNaN(Number(e.key))) e.preventDefault();
    }, []);

    useMemo(() => {
        if (validateFields && !value.includes("initialValue")) {
            const errorModel = validateMobileNumber(value, t, allowEmptyNumber);
            if (errorModel.isPrefixError) {
                setPrefixErrorMessage(errorModel.errorMessage);
                setPhoneErrorMessage("");
            } else {
                setPhoneErrorMessage(errorModel.errorMessage);
                setPrefixErrorMessage("");
            }
        }
    }, [allowEmptyNumber, t, validateFields, value]);

    useMemo(() => {
        if (isValid) isValid(!prefixErrorMessage && !phoneErrorMessage);
    }, [isValid, phoneErrorMessage, prefixErrorMessage]);

    return (
        <div className={twMerge("flex w-full flex-wrap", disabled ? "text-grey-500" : "", className)}>
            <div className="mb-6 mr-4 flex w-[308px] flex-col">
                <Heading heading={countryHeader} mandatory={showMandatory} />
                <PhonePrefix
                    value={handlePhonePrefixValue(getCountryPrefix(value))}
                    setValue={handleChange}
                    containerClassName="max-w-[308px]"
                    className="w-full"
                    customCountryCodes={useCustomCountryList ? customCountryList : undefined}
                    disabled={disabled}
                    closeOnSelect
                />
            </div>
            <div className="flex">
                <div className="relative flex max-w-[100px] flex-col">
                    <p className="mb-2 truncate pr-1 font-bold">{t("countryCode")}</p>
                    <input
                        value={getCountryPrefix(value)}
                        onChange={(e) => handleChange(e.target.value)}
                        className={twMerge(
                            "w-25 relative h-12 border-r-0 bg-transparent pl-3",
                            prefixErrorMessage
                                ? "border-3 border-mhred-alert1 outline-none"
                                : "border border-mhdarkgreen-original",
                            disabled || disablePrefix ? "border-grey-500 text-grey-500 hover:cursor-not-allowed" : "",
                        )}
                        disabled={disabled || disablePrefix}
                    />
                    <p className={`absolute top-20 z-10 bg-mhbeige px-2 text-mhred-alert1 shadow-md`}>
                        {prefixErrorMessage}
                    </p>
                </div>
                <div className="relative flex flex-col">
                    <Heading heading={phoneNumberHeader} mandatory={showMandatory} />
                    <input
                        value={phoneNumber}
                        onKeyDown={handlePhoneOnKeyDown}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value);
                            handleChange(e.target.value, true);
                        }}
                        className={twMerge(
                            "relative h-12 w-52 bg-transparent pl-3",
                            phoneErrorMessage
                                ? "border-3 border-mhred-alert1 outline-none"
                                : "border border-mhdarkgreen-original",
                            disabled ? "border-grey-500 text-grey-500 hover:cursor-not-allowed" : "",
                        )}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                    <p className="absolute top-20 z-10 max-w-[208px] bg-mhbeige px-2 text-mhred-alert1 shadow-md">
                        {phoneErrorMessage}
                    </p>
                </div>
                {children}
            </div>
        </div>
    );
};

export default PhoneNumberComponent;
