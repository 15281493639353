import { RootState } from "store";
import { createSelector } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import {
    filter,
    some,
    flow,
    sortBy,
    map,
    lowerFirst,
    find,
    get,
    isEqual,
    reduce,
    orderBy,
    isUndefined,
} from "lodash/fp";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import _, { isEmpty } from "lodash";
import { isRiskAbsenceCause, isRiskBackAtWorkDate } from "../utils/dynamicMedicalAdvisory";
import * as userSelectors from "store/userSelectors";
import { getUnix, isAfterDate, isSameOrBeforeToday, isSameOrAfterToday, isSameOrBeforeDate } from "utils/date";
import { getAbsenceReportFieldDisplayValue } from "../utils/absenceReports";

export const getAbsenceReportsSlice = (state: RootState) => state.absenceReports;

export const getAbsenceReports = (state: RootState) => state.absenceReports.AbsenceReports;

export const getFirstDayMedicalCertificate = (state: RootState) => state.absenceReports.FirstDayMedicalCertificate;

export const getAbsenceTemplates = (state: RootState) => state.absenceReports.Templates;

export const getConfigurationFields = (state: RootState) => state.absenceReports.ConfigurationFields;

export const getRecentEmploymentSearches = (state: RootState) => state.absenceReports.RecentEmploymentSearches;

export const getEmploymentSearch = (state: RootState) => state.absenceReports.SearchResult;

export const getSelectedAbsenceReport = (state: RootState) => state.absenceReports.SelectedAbsenceReport;

export const getCompanySpecificAbsenceConfirmation = (state: RootState) =>
    state.absenceReports.CompanySpecificAbsenceConfirmation;

export const getIsOK = (state: RootState) => state.absenceReports.IsOK;

export const getSelectedEmployee = createSelector(getAbsenceReportsSlice, (absenceReports) => {
    if (!absenceReports.SelectedEmployee) return { referenceKey: null, customerId: null, companyId: null };
    return absenceReports.SelectedEmployee;
});

export const getSelectedEmployeeInfo = createSelector(
    getAbsenceReportsSlice,
    (absenceReports) => absenceReports.SelectedEmployee,
);

export const getUserManagerName = createSelector(getAbsenceReportsSlice, ({ SelectedEmployee }) =>
    SelectedEmployee?.mainManagerFirstName && SelectedEmployee?.mainManagerLastName
        ? `${SelectedEmployee.mainManagerFirstName} ${SelectedEmployee.mainManagerLastName}`
        : "",
);

export const getOngoingReports = createSelector(
    getAbsenceReports,
    filter((ar: IAbsenceReportExtended) => ar?.status?.toLowerCase() !== "finished"),
);

export const getAbsenceReportById = (absenceReportId: string) => (state: RootState) => {
    return state.absenceReports.AbsenceReports?.find((ar: IAbsenceReportExtended) => ar.id === absenceReportId);
};

export const hasAbsenceReport = createSelector(getOngoingReports, (ongoingReports) => ongoingReports.length > 0);

export const getAbsenceReportDeletable = (absenceReport: IAbsenceReportExtended, isManage: boolean) =>
    createSelector(getAbsenceReportById(absenceReport.id!), (ar) => {
        if (isManage) return true;
        if (ar?.isPreliminary && ar?.status?.toLowerCase() === "ongoing") return false;
        return true;
    });

export const getAbsencesBasedOnPreliminary = (isPreliminary = true) =>
    createSelector(
        getOngoingReports,
        flow(
            filter((oa: IAbsenceReportExtended) => oa.isPreliminary === isPreliminary),
            sortBy((b: IAbsenceReportExtended) => getUnix(b.updated)),
        ),
    );

export const getAbsenceReportsTotalDegree = createSelector(
    getOngoingReports,
    flow(
        filter((oa: IAbsenceReportExtended) => oa.isPreliminary === false && oa.end === null),
        map((a) => a.degree),
        reduce((sum: number, a: number) => sum + a, 0),
    ),
);

export const getIsEditing = createSelector(
    getAbsenceReports,
    some((v) => Boolean(v?.isEdit)),
);

export const getFinishedReports = createSelector(
    getAbsenceReports,
    filter((ar: IAbsenceReportExtended) => ar?.status?.toLowerCase() === "finished"),
);

export const isFirstDayMedicalCertificateVisible = createSelector(
    getFirstDayMedicalCertificate,
    some((c) => isSameOrBeforeDate(c.startDate ?? new Date()) && isAfterDate(c.endDate ?? new Date())),
);

/**
 * Sick -> first
 * CareOfChild: -> after
 * Default (unknown) -> last
 */
enum SortAbsenceTemplates {
    Sick,
    CareOfChild,
}
export const getAbsenceTemplatesDescription = (t: TFunction) =>
    createSelector(getAbsenceTemplates, (item) =>
        map(
            (item) => ({
                value: item.absenceReportType || "",
                description: t(lowerFirst(item.absenceReportType!)),
            }),
            item,
        ).sort((a, b) => {
            const getSortValueA = SortAbsenceTemplates[a?.value as keyof typeof SortAbsenceTemplates];
            const getSortValueB = SortAbsenceTemplates[b.value as keyof typeof SortAbsenceTemplates];
            const aValue = isUndefined(getSortValueA) ? 1337 : getSortValueA;
            const bValue = isUndefined(getSortValueB) ? 1337 : getSortValueB;
            return aValue - bValue;
        }),
    );

export const getConfigurationByName = (configName: string) =>
    createSelector(
        getConfigurationFields,
        flow(
            find((conf) => conf?.name === configName),
            get("value"),
            isEqual("true"),
        ),
    );

export const parsedRecentEmploymentSearches = createSelector(
    getRecentEmploymentSearches,
    map((employment) => {
        if (!employment.data)
            return {
                id: 0,
                referenceKey: null,
                name: "",
                companyName: null,
                socialSecurityNumber: null,
                employmentNumber: null,
                departmentNames: null,
                startDate: null,
                countryCode: null,
                companyId: null,
                departments: null,
                endDate: null,
                mobileNumber: null,
                companyCustomerKey: null,
                newReporting: true,
                customerId: 0,
            };

        const employmentRecentData = JSON.parse(employment.data);
        return {
            id: employmentRecentData.userAccountId,
            referenceKey: employment.employmentId || employmentRecentData.referenceKey,
            name: employmentRecentData.name,
            companyName: employmentRecentData.company,
            socialSecurityNumber: employmentRecentData.ssn,
            employmentNumber: employmentRecentData.employmentNumber,
            departmentNames: employmentRecentData.department,
            startDate: employmentRecentData.created,
            countryCode: null,
            companyId: null,
            departments: null,
            endDate: null,
            mobileNumber: null,
            companyCustomerKey: null,
            newReporting: true,
            customerId: 0,
        };
    }),
);

export enum FinishedReportsSortEnum {
    "type",
    "start",
    "end",
    "degree",
}
export const getSortedFinishedAbsenceReports = (sorting: { type: FinishedReportsSortEnum; order: "asc" | "desc" }) =>
    createSelector(getFinishedReports, (finishedAbsenceReports) =>
        orderBy(FinishedReportsSortEnum[sorting.type], sorting.order, finishedAbsenceReports),
    );

export const getTemporarySubscriptions = (state: RootState) => state.absenceReports.TemporarySubscriptions;

export const getSearchedTemporarySubscriptions = createSelector(getTemporarySubscriptions, (temporarySubscriptions) => {
    if (!isEmpty(temporarySubscriptions)) {
        return (query: string) =>
            temporarySubscriptions.filter((colleague) => colleague?.name?.toLowerCase().includes(query.toLowerCase()));
    } else {
        return null;
    }
});

export const getSendAbsenceNotificationIsError = (state: RootState) =>
    state.absenceReports.SendAbsenceNotificationIsError;

export const getAbsenceReportType = createSelector(
    getSelectedAbsenceReport,
    (selectedAbsenceReport) => selectedAbsenceReport?.type ?? null,
);

export const isAllMandatoryFieldsSelected = (page: number) =>
    createSelector(
        getTemplateFieldsByPages,
        getSelectedAbsenceReport,
        getShowAbsenceCauseToEmployer,
        (fields, selectedAbsenceReport, showAbsenceCauseToEmployer) => {
            const mandatoryFields = fields[page - 3]?.filter(
                (field) => field.mandatory || field.name === "RequestedMedicalAdvisory",
            );
            const isMandatoryFieldsSelected = mandatoryFields?.every((mandatoryField) =>
                selectedAbsenceReport?.fields?.some((selectedFields) => {
                    return (
                        selectedFields.name === mandatoryField.name &&
                        selectedFields?.value &&
                        selectedFields.value?.length > 0
                    );
                }),
            );

            const showAbsenceCauseField = fields[page - 3].find(
                (field) => field.name === "AbsenceCause" && !field.widget && !field.confidential,
            );

            if (showAbsenceCauseField) {
                return isMandatoryFieldsSelected && showAbsenceCauseToEmployer !== null;
            } else {
                return isMandatoryFieldsSelected;
            }
        },
    );

export const getAbsenceReportPageNumber = (state: RootState) => state.absenceReports.absenceReportPageNumber;

export const getSelectedTemplate = createSelector(
    getAbsenceReportType,
    getAbsenceTemplates,
    (absenceType, absenceTemplates) => absenceTemplates?.find((value) => value.absenceReportType === absenceType),
);

export const getTemplateFields = createSelector(getSelectedTemplate, (template) => {
    if (!template?.fields || template?.fields.length === 0) return [];
    return template.fields.filter((field) => field.name !== "RiskMedicalAdvisoryRequest");
});

export const getTemplateFieldsWithoutRequestedMedicalAdvisory = createSelector(getTemplateFields, (templateFields) =>
    templateFields.filter((field) => field.name !== "RequestedMedicalAdvisory"),
);

export const isRisk = createSelector(getAbsenceReportsSlice, (absenceReportsState) => absenceReportsState.isRisk);

export const isRiskDynamicMedicalAdvisory = createSelector(
    getSelectedAbsenceReport,
    getFirstDayMedicalCertificate,
    isRisk,
    (selectedAbsenceReport, firstDayMedicalCertificate, isRisk) => {
        if (selectedAbsenceReport) {
            const filteredCertificates = (firstDayMedicalCertificate || []).filter((certificate) => {
                if (certificate.startDate && certificate.endDate) {
                    return isSameOrBeforeToday(certificate.startDate) && isSameOrAfterToday(certificate.endDate);
                }
                return false;
            });
            return (
                isRiskAbsenceCause(selectedAbsenceReport.fields ?? []) ||
                isRiskBackAtWorkDate(selectedAbsenceReport) ||
                !isEmpty(filteredCertificates) ||
                isRisk
            );
        }
    },
);

export const isMedicalAdvisory = createSelector(
    getConfigurationByName("mandatoryCallback"),
    getSelectedAbsenceReport,
    (mandatoryCallback, selectedAbsenceReport) => {
        return (
            (mandatoryCallback ||
                selectedAbsenceReport?.fields?.some(
                    (field) =>
                        (field.name === "RequestedMedicalAdvisory" || field.name === "RiskMedicalAdvisoryRequest") &&
                        field.value === "true",
                )) ??
            false
        );
    },
);

export const getTemplateFieldsByPages = createSelector(
    getTemplateFields,
    getAbsenceReportType,
    getConfigurationByName("dynamicMedicalAdvisory"),
    isRiskDynamicMedicalAdvisory,
    (templateFields, absenceType, dynamicMedicalAdvisory, isRisk) => {
        const templateFieldsByPages = Object.values(_.groupBy(templateFields, "page"));
        if (dynamicMedicalAdvisory && absenceType === "Sick") {
            if (isRisk) {
                return templateFieldsByPages.filter((page) =>
                    page.some((field) => field.name !== "RequestedMedicalAdvisory"),
                );
            } else {
                return templateFieldsByPages.concat(
                    templateFieldsByPages.splice(
                        templateFieldsByPages.findIndex((field) =>
                            field.find((x) => x.name === "RequestedMedicalAdvisory"),
                        ),
                        1,
                    ),
                );
            }
        } else {
            return templateFieldsByPages;
        }
    },
);

export const getCurrentFields = createSelector(
    getTemplateFieldsByPages,
    getAbsenceReportPageNumber,
    (templateFields, currentPage) => {
        if (templateFields) {
            return templateFields[currentPage - 3];
        } else {
            return null;
        }
    },
);

export const getShowAbsenceCauseToEmployer = (state: RootState) => state.absenceReports.showAbsenceCauseToEmployer;

export const getIsOwnReport = createSelector(
    getSelectedEmployee,
    userSelectors.getUserContext,
    (selectedEmployee, userContext) => userContext?.EmploymentGuid === selectedEmployee?.referenceKey,
);

export const getSendNotification = createSelector(
    getSelectedAbsenceReport,
    (selectedAbsenceReport) => !selectedAbsenceReport?.suppressNotification,
);

export const showAbsenceNotification = createSelector(
    getConfigurationByName("temporarySubscriber"),
    getIsOwnReport,
    userSelectors.getIsAbsenceReportAdmin,
    (isAbsenceNotification, isOwnReport, isManage) => (!isManage || isOwnReport) && isAbsenceNotification,
);

export const getEmploymentGuid = createSelector(
    getSelectedEmployee,
    userSelectors.getUserContext,
    (selectedEmployee, userContext) => {
        return selectedEmployee?.referenceKey ? selectedEmployee?.referenceKey : userContext.EmploymentGuid;
    },
);

export const getInitialStartDate = createSelector(getSelectedAbsenceReport, (selectedAbsenceReport) => {
    return selectedAbsenceReport?.start;
});

export const getInitialEndDate = createSelector(getSelectedAbsenceReport, (selectedAbsenceReport) => {
    return selectedAbsenceReport &&
        selectedAbsenceReport.end &&
        selectedAbsenceReport.status?.toLowerCase() === "finished"
        ? selectedAbsenceReport.end
        : (selectedAbsenceReport?.backAtWork ?? undefined);
});

export const getInitialDegree = createSelector(getSelectedAbsenceReport, (selectedAbsenceReport) => {
    return selectedAbsenceReport && selectedAbsenceReport.degree ? selectedAbsenceReport.degree.toString() : null;
});

export const getIsChanged = (absenceReport: IAbsenceReportExtended) =>
    createSelector(getSelectedAbsenceReport, (selectedAbsenceReport) => {
        return (
            absenceReport.start !== selectedAbsenceReport?.start ||
            absenceReport.end !== selectedAbsenceReport?.end ||
            absenceReport.backAtWork !== selectedAbsenceReport?.backAtWork ||
            absenceReport.degree !== selectedAbsenceReport?.degree ||
            !isEqual(absenceReport.degrees, selectedAbsenceReport?.degrees)
        );
    });

export const getAbsenceTemplateByType = (type: string | null | undefined) =>
    createSelector(getAbsenceTemplates, (absenceTemplates) =>
        absenceTemplates?.find((template) => template.absenceReportType === type),
    );

export const getAbsenceReportCardDetails = (absenceReport: IAbsenceReportExtended) =>
    createSelector(getAbsenceTemplateByType(absenceReport.type), (template) => {
        const filteredTemplateFields = template?.fields?.filter(
            (field) => field.name !== "RiskMedicalAdvisoryRequest" && field.name !== "RequestedMedicalAdvisory",
        );

        const fieldsWithValue = filteredTemplateFields?.map((templateField) => {
            const field = absenceReport?.fields?.find((field) => field.name === templateField.name);
            return { ...templateField, value: field?.value, confidential: field?.confidential };
        });

        return fieldsWithValue?.map((field) => ({
            ...field,
            value: getAbsenceReportFieldDisplayValue(field),
        }));
    });
