import { useState } from "react";
import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
import { Employee } from "swagger/fortnox";
import { Combobox } from "@headlessui/react";
import PrimaryButton from "components/PrimaryButton";
import ErrorMessage from "./ErrorMessage";

interface IEmployeeSelector {
    employees: Employee[];
    handleSubmit: (employee: string) => void;
    selectedEmployee: Employee | null;
    setSelectedEmployee: (employee: Employee | null) => void;
}

const EmployeeSelector = ({ employees, handleSubmit, selectedEmployee, setSelectedEmployee }: IEmployeeSelector) => {
    const [query, setQuery] = useState("");

    const filteredEmployees = employees
        .filter((employee) => employee?.email?.toLowerCase().includes(query?.toLowerCase()))
        .slice(0, 5);

    return (
        <>
            <p className="text-lg">
                Ange e-post för den i ert företag som kommer vara admin för ert kundkonto. E-posten måste finnas i den
                personallista som ni har i Fortnox plattform. Vi skickar ett mail till den angivna e-posten när
                kundkontot har satts upp och admin kan logga in med BankID.
            </p>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-3">
                    <p className="font-bold">Ange e-post (arbete)</p>
                    <Combobox value={selectedEmployee} onChange={setSelectedEmployee}>
                        <div className="relative">
                            <div className="flex h-12 w-full items-center border border-mhdarkgreen-original px-3">
                                <Icon icon="search" />
                                <Combobox.Input
                                    placeholder={selectedEmployee?.email || "E-post.."}
                                    onChange={(e) => setQuery(e.target.value)}
                                    className={twMerge(
                                        "w-full bg-transparent p-3 focus:shadow-none",
                                        selectedEmployee && "placeholder-mhdarkgreen-original",
                                    )}
                                />
                                <div
                                    data-testid="clear-button"
                                    className="hover:cursor-pointer"
                                    onClick={() => setSelectedEmployee(null)}
                                >
                                    <Icon icon="xCircleFull" />
                                </div>
                            </div>
                            <Combobox.Options className="absolute mt-1.5 flex w-full flex-col divide-y rounded-md bg-white shadow-md">
                                {selectedEmployee && !selectedEmployee.personalIdentityNumber && (
                                    <ErrorMessage
                                        heading="Personnummer saknas"
                                        text="Du behöver lägg till personnummer i Fortnox plattform för att kunna skapa ett adminkonto kopplat till vald e-post. Personnummer behövs för att du admin kunna logga in med BankID."
                                    />
                                )}
                                {filteredEmployees.length === 0 && query.length > 0 && (
                                    <div className="p-4 text-mhred-alert3">
                                        Vi hittar ingen med den e-posten i er personallista i Fortnox plattform
                                    </div>
                                )}
                                {filteredEmployees.map((employee) => (
                                    <Combobox.Option
                                        className="h-13 w-full cursor-pointer p-4 hover:bg-gray-100"
                                        key={employee.employeeId}
                                        value={employee}
                                    >
                                        <>{employee.email}</>
                                    </Combobox.Option>
                                ))}
                            </Combobox.Options>
                        </div>
                    </Combobox>
                </div>
                {selectedEmployee && (
                    <div className="flex flex-col gap-8 text-mhgrey-medium">
                        <div className="flex flex-col gap-3">
                            <div className="font-bold">Namn</div>
                            <div className="h-12 w-full border border-mhdarkgreen-original p-3">
                                {selectedEmployee.fullName}
                            </div>
                        </div>
                        <div className="flex flex-col gap-3">
                            <div className="font-bold">Personnummer</div>
                            <div className="h-12 w-full border border-mhdarkgreen-original p-3">
                                {selectedEmployee.personalIdentityNumber}
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <PrimaryButton
                                onClick={() =>
                                    selectedEmployee?.employeeId && handleSubmit(selectedEmployee.employeeId)
                                }
                                id="fortnox-submit"
                                text="Aktivera Konto"
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default EmployeeSelector;
