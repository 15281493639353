import { useTranslation } from "react-i18next";
import UserAccordion, { IUserAccordionDisplayProps } from "../UserAccordion/UserAccordion";
import { IContactInfoProps } from "pages/UserSettings/types";
import PhoneNumberComponent from "components/PhoneNumberComponent";
import { getNumberWithoutDash, validateMobileNumber } from "utils/phone";
import Input from "components/Input";

const SetContactInfo = ({
    contactInfoInputValues,
    setWorkNumber,
    setWorkEmail,
    setPrivateMobile,
    setPrivatePhone,
    handleSaveClicked,
    isLoading,
}: IContactInfoProps) => {
    const { t } = useTranslation("userSettings");

    const setContactInfoProps: IUserAccordionDisplayProps[] = [
        {
            description: t("mobileNrPrivate"),
            value: getNumberWithoutDash(contactInfoInputValues.privateMobile) ?? "",
        },
        {
            description: t("workEmail"),
            value: contactInfoInputValues.workEmail ?? "",
        },
        {
            description: t("mobileNrWork"),
            value: getNumberWithoutDash(contactInfoInputValues.workNumber) ?? "",
        },
        {
            description: t("mobileNrHome"),
            value: getNumberWithoutDash(contactInfoInputValues.privatePhone) ?? "",
        },
    ];

    return (
        <div>
            <UserAccordion
                heading={t("contactInfo")}
                displayProps={setContactInfoProps}
                saveClicked={handleSaveClicked}
                disableButton={!!validateMobileNumber(contactInfoInputValues.privateMobile, t).errorMessage}
                isLoading={isLoading}
            >
                <>
                    <div className="flex flex-col border-b pb-10">
                        <p className={`mb-6 font-bold`}>{t("work")}</p>

                        <div className="flex w-full flex-col">
                            <PhoneNumberComponent
                                countryHeader={t("selectCountryForNumber")}
                                phoneNumberHeader={t("mobileNrWork")}
                                value={contactInfoInputValues.workNumber}
                                onChange={setWorkNumber}
                                useCustomCountryList
                                className="mb-6"
                                disabled
                            />
                            <Input
                                heading={t("workEmail")}
                                className="h-12 xs:max-w-xs"
                                inputValue={contactInfoInputValues.workEmail}
                                inputChanged={setWorkEmail}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="mt-10 flex flex-col">
                        <p className={`mb-6 font-bold`}>{t("private")}</p>

                        <div className="flex w-full flex-col">
                            <PhoneNumberComponent
                                countryHeader={t("selectCountryForNumber")}
                                phoneNumberHeader={`${t("mobileNrPrivate")} *`}
                                value={contactInfoInputValues.privateMobile}
                                onChange={setPrivateMobile}
                                useCustomCountryList
                                className="mb-6"
                                validateFields
                                showMandatory
                            />
                            <PhoneNumberComponent
                                countryHeader={t("selectCountryForNumber")}
                                phoneNumberHeader={t("mobileNrHome")}
                                value={contactInfoInputValues.privatePhone}
                                onChange={setPrivatePhone}
                                useCustomCountryList
                                className="mb-6"
                            />
                        </div>
                    </div>
                </>
            </UserAccordion>
        </div>
    );
};

export default SetContactInfo;
