import * as React from "react";
import Card, { CardAlign } from "../Card";

export interface IProps {
    title: string | React.ReactElement;
    children: React.ReactNode;
    testId?: string;
    className?: string;
}
const CardWithTitle = ({ testId, title, children, className }: IProps) => {
    return (
        <Card testId={testId} align={CardAlign.start} className="p-0">
            <div className="w-full">
                <div
                    className={`flex h-19 w-full items-center border-b-1 border-solid border-grey-200 px-8 ${className}`}
                >
                    {React.isValidElement(title) ? title : <p className="font-bold">{title}</p>}
                </div>
                {children}
            </div>
        </Card>
    );
};

export default CardWithTitle;
