const TopLocation = ({ header }: { header: string }) => {
    return (
        <div className="flex flex-1 cursor-pointer" role="button">
            <div className="flex w-full max-w-screen-xxxxl flex-col border border-b-grey-500 border-t-grey-500">
                <div className="flex flex-1 cursor-pointer select-none justify-center gap-2 p-3">
                    <div>{header}</div>
                </div>
            </div>
        </div>
    );
};

export default TopLocation;
