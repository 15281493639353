import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
import * as utils from "./AbsenceReportCallNurse.utils";
import { useAppSelector } from "store/hooks";
import { selectors as AbsenceReportsSelectors } from "../../redux";
const AbsenceReportCallNurse = () => {
    const { t } = useTranslation();
    const configurationFields = useAppSelector(AbsenceReportsSelectors.getConfigurationFields);
    const getNursePhoneNumber = React.useCallback(
        (getCountryPrefix?: boolean) => utils.getNursePhoneNumber(configurationFields, getCountryPrefix),
        [configurationFields],
    );
    return (
        <div className="mb-10 flex flex-col items-center bg-mhgrey-light bg-opacity-25 p-10">
            <h2 className="mb-4">{t("reportYourAbsence")}</h2>
            <p className="mb-6 text-center">{t("absenceByCallNurse")}</p>
            <a className="items-center xs:flex" href={`tel:${getNursePhoneNumber(true)}`}>
                <PhoneIcon className="hidden h-6 w-6 text-black xs:flex" />
                <p className="ml-2 hidden border-b border-transparent text-3xl font-bold hover:border-mhdarkgreen-original xs:flex">
                    {getNursePhoneNumber()}
                </p>
                <div className="ml-2 h-10 items-center border-b border-transparent bg-primaryButtonPrimary px-8 hover:border-mhdarkgreen-original hover:bg-primaryButtonSecondary xs:hidden">
                    <p className="flex pt-2 font-bold text-primaryButtonText">{t("callNurse")}</p>
                </div>
            </a>
        </div>
    );
};
export default AbsenceReportCallNurse;
