import Icon from "components/Icon";
import { useDetectOutsideClick } from "hooks";
import { useState, ChangeEvent } from "react";

interface ISelectWithFilter {
    placeholder: string;
    handleClick: (id: number) => void;
    options: { id: number; name: string }[];
    displayValue: string;
}

const ServiceSelectWithFilter = ({ options, placeholder, handleClick, displayValue }: ISelectWithFilter) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [inputValue, setInputValue] = useState("");

    const {
        isOutside: dropdownOpen,
        setIsOutside: setDropdownOpen,
        setContainer,
    } = useDetectOutsideClick(false, false);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        const inputValue = e.target.value;
        const filtered = options.filter((option) => option.name?.toLowerCase().includes(inputValue.toLowerCase()));
        setFilteredOptions(filtered);
    };

    return (
        <div ref={setContainer} className="relativ z-10 flex w-full flex-col hover:cursor-pointer md:w-86">
            <label className="font-bold">{placeholder} *</label>
            <div className="relative" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <div className="mt-2 flex h-12 w-full items-center border border-mhdarkgreen-original bg-mhbeige px-2">
                    {displayValue ? displayValue : placeholder}
                </div>
                <div className="absolute right-3 top-6.5">
                    <Icon className="mb-1" icon="caretDown" size={12} stroke="2" />
                </div>
            </div>
            {dropdownOpen && (
                <div className="absolute mt-20 bg-pageBg">
                    <div className="flex h-12 w-full items-center border-x p-2">
                        <Icon icon="search" />
                        <input
                            onChange={handleInputChange}
                            className="search-bar w-full bg-pageBg pl-2"
                            type="text"
                            value={inputValue}
                        />
                    </div>
                    <div className="max-h-64 w-full overflow-auto border md:w-86">
                        {filteredOptions.map(({ id, name }) => (
                            <div
                                key={id}
                                className="flex items-center p-2 hover:cursor-pointer hover:bg-grey-100"
                                onClick={() => {
                                    setDropdownOpen(false);
                                    handleClick(id);
                                }}
                            >
                                {name + " - " + id}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceSelectWithFilter;
