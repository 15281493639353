import { isEmpty, isNumber } from "lodash/fp";
import { useMemo, useState } from "react";
import Icon from "components/Icon";
import Checkbox from "../../Checkbox";
import { IDynamicData } from "./types";

interface IProps {
    onChange: (referenceKey: string) => void;
    checked?: boolean;
    /** Label  */
    label: string;
    referenceKey: string | number;
    children?: IDynamicData[];
    hasMore?: boolean;
    selectable?: boolean;
    className?: string;
    hasChildren?: boolean;
    top?: boolean;
    alt?: string;
    showChildren?: boolean;
    keyProp?: string;
}

const Item = ({
    onChange,
    checked,
    selectable = false,
    label,
    referenceKey,
    children,
    top = true,
    hasChildren = false,
    className,
    alt,
    showChildren,
    keyProp,
    ...rest
}: IProps) => {
    const [show, setShow] = useState(showChildren ?? false);

    const Children = useMemo(() => (isEmpty(children) ? undefined : children), [children]);
    return (
        <div className={`${className}`} key={keyProp}>
            <div className="flex py-[10px] hover:bg-mhgrey-light md:py-1">
                {Children ? (
                    <button
                        type="button"
                        className="ml-1 px-1 pr-1"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            setShow(!show);
                        }}
                    >
                        <div className="w-3">
                            <Icon icon={show ? "triangleDown" : "triangleRight"} size={12} />
                        </div>
                    </button>
                ) : top ? (
                    <div className="min-w-[4px]" />
                ) : (
                    <div className={`w-[28px] ${hasChildren ? "min-w-[24px]" : "min-w-[21px]"}`} />
                )}
                <div className="ml-1 flex items-center justify-center">
                    <Checkbox
                        disabled={!selectable}
                        checked={checked}
                        onChange={(event) => {
                            if (!selectable) return;
                            onChange(event.target.name);
                        }}
                        name={isNumber(referenceKey) ? referenceKey.toString() : referenceKey}
                        alt={alt}
                        {...rest}
                    />
                </div>
                <div
                    className={`pl-2 ${!selectable && "text-mhgrey-medium"} w-full hover:bg-mhgrey-light`}
                    data-testid={`checkbox-filter-click-${label}`}
                    onClick={() => {
                        if (!selectable) return;
                        onChange(isNumber(referenceKey) ? referenceKey.toString() : referenceKey);
                    }}
                >
                    {label}
                </div>
            </div>
            {Children && show ? (
                <div className="pl-5">
                    {Children.map((item) => (
                        <Item
                            {...item}
                            onChange={onChange}
                            className="py-0 pt-1"
                            top={false}
                            key={`${item.referenceKey}-${item.label}`}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default Item;
