import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useBreakpoints } from "hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "pages/FollowUp/redux";
import { IHealthCase, IHealthCaseActivity } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateDisplayValue, getDateTimeDisplayValue } from "utils/date";
import { activeCaseTypes } from "../../../utils/case";
import { CaseActivityModal } from "..";
import { Status } from "@medhelp/ui/LoadableComponent";
import { toast } from "react-toastify";
import { isBeforeToday } from "utils/date";
import Icon, { Icons } from "components/Icon";
import CaseActivitiesLoading from "pages/FollowUp/pages/Employee/Case/CaseActivities/CaseActivitiesLoading";
import MiniMenu from "components/MiniMenu";
import { useAppDispatch } from "store/hooks";

interface IActivityActionValues {
    text: string;
    onClick: () => void;
    icon: Icons;
    key: string;
    id: string;
}

interface IProps {
    activity: IHealthCaseActivity;
    healthCase: IHealthCase;
}

const CaseActivityItem = (props: IProps) => {
    const { activity, healthCase } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const [open, setOpen] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(activity.performed || false);
    const handleIconClick = () => {
        if (healthCase.status && activeCaseTypes.includes(healthCase.status)) {
            if (healthCase.id && activity.id)
                dispatch(
                    thunkActions.updateCaseActivityPerformed({
                        healthCaseId: healthCase.id,
                        activityId: activity.id,
                    }),
                );
            setIsChecked(!isChecked);
        }
    };

    const getPerformedText = () => {
        if (activity.performed && !activity.performedBy) {
            return t("doneAutomatically");
        } else if (activity.performed) {
            return t("done");
        } else {
            return t("notDone");
        }
    };

    const getActivityActionValues = () => {
        const actionValues: IActivityActionValues[] = [
            {
                text: activity.performed ? t("markAsNotPerformed") : t("markAsPerformed"),
                onClick: () => {
                    activity.performed
                        ? dispatch(
                              thunkActions.updateCaseActivityUndoPerformed({
                                  healthCaseId: healthCase.id ?? "",
                                  activityId: activity.id ?? "",
                              }),
                          )
                        : dispatch(
                              thunkActions.updateCaseActivityPerformed({
                                  healthCaseId: healthCase.id ?? "",
                                  activityId: activity.id ?? "",
                              }),
                          );
                    setIsChecked(!isChecked);
                },
                icon: activity.performed ? "circle" : "checkCircle",
                key: `case-activity-item-performed-${activity?.id}`,
                id: "performed",
            },
            {
                text: t("editActivity"),
                onClick: () => setModalIsOpen(true),
                icon: "pencil",
                key: `case-activity-item-edit-${activity?.id}`,
                id: "edit",
            },
            {
                text: t("deleteActivity"),
                onClick: () => {
                    dispatch(
                        thunkActions.deleteCaseActivity({
                            healthCaseId: healthCase.id ?? "",
                            activityId: activity.id ?? "",
                        }),
                    )
                        .then(unwrapResult)
                        .then(() => toast(t("activityRemoved")))
                        .catch((e) =>
                            toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("activityNotDeleted")}`, {
                                type: "error",
                            }),
                        );
                },
                icon: "trashCan",
                key: `case-activity-item-delete-${activity?.id}`,
                id: "delete",
            },
        ];

        if (activity.source === "user") {
            return actionValues;
        } else if (activity.performed && !activity.performedBy) {
            return [];
        } else {
            return actionValues.filter((action) => action.id === "performed");
        }
    };

    const getActivityInfo = () => {
        if (breakpoints.isSmallscreen) {
            if (activity.source === "chain") {
                return `${getDateDisplayValue(activity.date, "d MMM yyyy")} - ${t("day")} ${activity.day ?? 0}`;
            } else {
                return `${getDateDisplayValue(activity.date, "d MMM yyyy")} - ${
                    activity.updated ? t("editedBy") : t("addedBy")
                } ${activity.createdByUserName || t("createdByUnknown").toLowerCase()}`;
            }
        } else {
            if (activity.source === "chain") {
                return `${t("day")} ${activity.day ?? 0}`;
            } else {
                return `${activity.updated ? t("editedBy") : t("addedBy")} ${
                    activity.createdByUserName || t("createdByUnknown").toLowerCase()
                } ${getDateTimeDisplayValue(
                    activity.updated ? activity.updated : activity.created,
                    "d MMM yyyy, HH:mm",
                )}`;
            }
        }
    };

    if (activity.status === Status.PENDING) {
        <CaseActivitiesLoading />;
    }

    const flaggedActivity = !activity.performed && isBeforeToday(activity.date);
    const multiLineActivity = activity.description?.split("\n");

    return (
        <div className="flex items-start justify-between border-t border-grey-300 px-5 py-3 sm:px-8">
            <div className="flex">
                <div className="mr-2 mt-6 flex w-4 sm:mt-5">
                    <Icon icon="warning" className={`text-red-500 ${flaggedActivity ? "" : "hidden"}`} />
                </div>
                <div className="mr-8 hidden w-16 sm:block">
                    <p className="text-2xl font-bold">{getDateDisplayValue(activity.date, "dd")}</p>
                    <p className="w-16 text-sm">{getDateDisplayValue(activity.date, "MMM yyyy")}</p>
                </div>
                <div>
                    <p className="mt-1 text-xs">{getActivityInfo()}</p>
                    <div className="flex">
                        {activity.description && (
                            <button className="mr-1 self-center" onClick={() => setOpen(!open)}>
                                <Icon icon={open ? "caretFullDown" : "caretFullRight"} size={12} />
                            </button>
                        )}
                        <p className="font-bold">{activity.title}</p>
                    </div>
                    {open &&
                        multiLineActivity?.map((line, index) => {
                            if (!line) {
                                return <br key={index} />;
                            }

                            return (
                                <p key={index} className="ml-4">
                                    {line}
                                </p>
                            );
                        })}
                </div>
            </div>
            <div className="flex h-14 items-center">
                <p className="ml-2 hidden text-sm font-bold sm:flex">{getPerformedText()}</p>
                <div
                    className={`ml-2 self-center sm:mr-5 ${
                        !isChecked && healthCase.status && activeCaseTypes.includes(healthCase.status)
                            ? "hover:cursor-pointer"
                            : "hover:default"
                    }`}
                >
                    <Icon
                        icon={isChecked ? "checkCircle" : "circle"}
                        className={isChecked ? "text-primary-1000" : ""}
                        onClick={!isChecked ? handleIconClick : undefined}
                    />
                </div>
                {healthCase.status && activeCaseTypes.includes(healthCase.status) && (
                    <MiniMenu
                        id="activity-actions"
                        preset="white"
                        buttonValues={getActivityActionValues()}
                        buttonRight
                        containerClassName="flex justify-end"
                    />
                )}
            </div>
            {modalIsOpen && (
                <CaseActivityModal
                    isEdit
                    healthCase={healthCase}
                    activity={activity}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={() => setModalIsOpen(!modalIsOpen)}
                />
            )}
        </div>
    );
};

export default CaseActivityItem;
