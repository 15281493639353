export interface BasicInfo {
    title: string;
    value?: string | number | null;
}

interface IBasicInfo {
    name?: string | null;
    basicInfo: BasicInfo[];
}

const BasicInfo = ({ name, basicInfo }: IBasicInfo) => (
    <div className="py-10">
        <h2 className="px-2 md:px-0">{name}</h2>
        <div className="mt-6 flex flex-col flex-wrap border-b border-t px-2 py-6 text-mhgrey-dark md:flex-row md:px-0">
            {basicInfo?.map(({ title, value }, index) => (
                <div key={title} className="flex">
                    {title}: {value}
                    {index < basicInfo.length - 1 && <span className="hidden px-3 md:flex">|</span>}
                </div>
            ))}
        </div>
    </div>
);

export default BasicInfo;
