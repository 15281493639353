import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate } from "react-router-dom";
import { selectors, actions } from "../../../redux";
import { PageTypes } from "..";
import AbsenceConfirmation from "./AbsenceConfirmation";
import AbsenceNotification from "../AbsenceNotification";
import { CallbackMessage } from "../Callback/CallbackMessage";
import SecondaryButton from "components/SecondaryButton";

type ConfirmationPageTypes = "confirmation" | "confirmationWithNotification" | "confirmationWithMedicalAdvisory" | null;

interface IProps {
    setPage: (page: PageTypes) => void;
}

export default function Index(props: IProps) {
    const { setPage } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [confirmationPage, setConfirmationPage] = useState<ConfirmationPageTypes>(null);
    const selectedAbsenceReport = useAppSelector(selectors.getSelectedAbsenceReport);
    const companySpecificConfirmation = useAppSelector(selectors.getCompanySpecificAbsenceConfirmation);
    const isMedicalAdvisory = useAppSelector(selectors.isMedicalAdvisory);
    const isAbsenceNotification = useAppSelector(selectors.getConfigurationByName("temporarySubscriber"));

    const handleOnClose = () => {
        navigate("/absencereporting/");
        dispatch(actions.resetSelectedAbsenceReport());
    };

    useEffect(() => {
        if (isAbsenceNotification) {
            setConfirmationPage("confirmationWithNotification");
        } else if (isMedicalAdvisory) {
            setConfirmationPage("confirmationWithMedicalAdvisory");
        } else {
            setConfirmationPage("confirmation");
        }
    }, [isAbsenceNotification, isMedicalAdvisory]);

    const getNextPage = (page: ConfirmationPageTypes) => {
        switch (page) {
            case "confirmation":
                return (
                    <div className="flex flex-col xs:flex-row">
                        <SecondaryButton
                            id="confirmation-close-button"
                            text={t("close")}
                            onClick={handleOnClose}
                            className="m-1 flex w-full justify-center xs:w-auto"
                        />
                    </div>
                );
            case "confirmationWithNotification":
                return (
                    <AbsenceNotification
                        absenceReportId={selectedAbsenceReport?.id ?? ""}
                        typeOfRegistration="createReport"
                        setPage={(page) => setPage(page)}
                    />
                );
            case "confirmationWithMedicalAdvisory":
                return <CallbackMessage />;
            default:
                return null;
        }
    };

    return (
        <AbsenceConfirmation
            companySpecificConfirmation={companySpecificConfirmation}
            showBorder={confirmationPage === "confirmationWithMedicalAdvisory"}
        >
            {getNextPage(confirmationPage)}
        </AbsenceConfirmation>
    );
}
