import Icon from "components/Icon/Icon";

interface IProps {
    text: string;
}

const Error = (props: IProps) => {
    const { text } = props;

    return (
        <div className="flex h-full flex-col items-center justify-center pb-10 pt-3">
            <div>
                <Icon icon="close" size={100} />
            </div>
            <p>{text}</p>
        </div>
    );
};

export default Error;
