const CaseHealthContactLoading = () => {
    return (
        <div className="mb-8 hidden animate-pulse sm:block">
            <div className="mb-2 mr-8 h-3 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
            <div className="mb-2 mr-8 h-3 w-3/4 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        </div>
    );
};

export default CaseHealthContactLoading;
