import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getRegion } from "store/userSelectors";
import { Accordion } from "@medhelp/ui";
import RehabRoutines from "pages/FollowUp/components/RehabRoutines";
import RehabTemplates from "pages/FollowUp/components/RehabTemplates";

interface ITranslatedObject {
    heading: string;
    text: string;
    list: string[];
}

const Resources = () => {
    const { t } = useTranslation("followup");
    const region = useAppSelector(getRegion);

    const chainInformation: ITranslatedObject[] = t("resources.rehabChain.chainInformation", {
        returnObjects: true,
    });

    return (
        <div className="border-t">
            {region === "Sweden" && (
                <>
                    <Accordion heading={<h3>{t("resources.rehabRoutines.heading")}</h3>} iconSize={16}>
                        <RehabRoutines />
                    </Accordion>
                    <Accordion heading={<h3>{t("resources.rehabChain.heading")}</h3>} iconSize={16}>
                        <div className="ml-7">
                            {chainInformation.map((item) => (
                                <div key={item.heading} className="mb-9">
                                    <p className="mb-1 font-bold">{item.heading}</p>
                                    <p className="mb-2">
                                        <Trans
                                            t={t}
                                            i18nKey={item.text}
                                            components={{
                                                linkTag: (
                                                    <a
                                                        href="https://www.forsakringskassan.se/"
                                                        style={{
                                                            textDecoration: "underline",
                                                        }}
                                                    />
                                                ),
                                            }}
                                            values={{
                                                linkTag: "www.forsakringskassan.se",
                                            }}
                                        />
                                    </p>
                                    <ul className="list-inside list-disc text-base">
                                        {item.list.map((listItem) => (
                                            <li>{listItem}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </Accordion>
                </>
            )}
            <Accordion heading={<h3>{t("resources.templates.heading")}</h3>} iconSize={16}>
                <RehabTemplates />
            </Accordion>
        </div>
    );
};

export default Resources;
