import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { clearCookiesAndStorage } from "store/userSlice";
import { checkIfNativeApp } from "utils/nativeApp";
import {
    initialIBankIdProps,
    IBankIdProps,
    UserAccount,
    IWarningBannerData,
    initialOtpProps,
    IReturnModelExtended,
    IOtpLoginSliceProps,
    initialResetPassProps,
    IResetPassSliceProps,
    IUsernameAndPassSliceProps,
    initialUsernameAndPassProps,
} from "../types";
import { getCookieOptions } from "../utils";
import { templateBuilder } from "./builders";
import {
    getGrandIdNoGuiStatus,
    getWarningBannerModel,
    initGrandIdNoGuiLogin,
    createPasswordReset,
    loginGrandIdNoGui,
    loginUsernameAndPass,
    loginWithMicrosoftToken,
    loginWithOneTimePass,
    postOneTimePass,
    getResetRequest,
    passwordReset,
} from "./loginAsyncActions";

export interface ILoginSlice {
    isAuthenticated?: boolean | null;
    warningBannerData: IWarningBannerData | null;
    selectedMarket: string | null;
    userAccounts: UserAccount[] | null;
    isLoading: boolean;
    countdownTimer: number;
    failedAt: string;
    bankIdProps: IBankIdProps;
    oneTimePassProps: IOtpLoginSliceProps;
    usernameAndPassProps: IUsernameAndPassSliceProps;
    resetPassProps: IResetPassSliceProps;
}

const initialloginState: ILoginSlice = {
    isAuthenticated: null,
    warningBannerData: null,
    selectedMarket: null,
    userAccounts: null,
    isLoading: false,
    countdownTimer: -1,
    failedAt: "",
    bankIdProps: initialIBankIdProps,
    oneTimePassProps: initialOtpProps,
    usernameAndPassProps: initialUsernameAndPassProps,
    resetPassProps: initialResetPassProps,
};

export interface ISetOtpPropAction {
    newValue: string;
    otpProp: keyof IOtpLoginSliceProps;
}

const handleUserAccounts = (
    state: ILoginSlice,
    userAccounts: UserAccount[],
    token: string,
    returnUrl?: string,
    allowCrossSessionToken?: boolean,
): void => {
    sessionStorage.setItem("mhToken", token);

    if (allowCrossSessionToken) localStorage.setItem("mhToken", token);

    if (userAccounts.length < 2) {
        Cookies.set("mhToken", token!, getCookieOptions());
        Cookies.set("migrated", "true", getCookieOptions());
        if (returnUrl && returnUrl !== "") {
            const redirectUrl = returnUrl.startsWith("http") ? returnUrl : `https://${returnUrl}`;
            location.href = redirectUrl;
        } else {
            state.isAuthenticated = true;
            state.isLoading = false;
        }
    } else state.userAccounts = userAccounts;
};

const loginSlice = createSlice({
    name: "login",
    initialState: initialloginState,

    reducers: {
        setMarket(state, action: PayloadAction<string>) {
            state.selectedMarket = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        resetBankIdData(state) {
            state.bankIdProps = initialIBankIdProps;
        },
        setCountdownTimer(state, action: PayloadAction<number>) {
            state.countdownTimer = action.payload;
        },
        setOtpPropValue(state, action: PayloadAction<ISetOtpPropAction>) {
            const { newValue, otpProp: prop } = action.payload;
            state.oneTimePassProps[prop] = newValue;
        },
        setUsernameAndPassPropValue(
            state,
            action: PayloadAction<{
                newValue: string;
                prop: keyof IUsernameAndPassSliceProps;
            }>,
        ) {
            const { newValue, prop } = action.payload;
            state.usernameAndPassProps[prop] = newValue;
        },
        setResetPassPropValue(
            state,
            action: PayloadAction<{
                newValue: string | string[];
                prop: keyof IResetPassSliceProps;
            }>,
        ) {
            const { newValue, prop } = action.payload;
            if (Array.isArray(newValue)) state.resetPassProps.pwdMissing = newValue;
            else if (typeof newValue === "string" && prop !== "pwdMissing") {
                state.resetPassProps[prop] = newValue;
            }
        },
    },
    extraReducers: (builder) => {
        templateBuilder(builder);
        builder
            .addCase(getWarningBannerModel.fulfilled, (state, action: PayloadAction<IWarningBannerData>) => {
                state.isLoading = false;
                state.warningBannerData = action.payload;
            })
            .addCase(getWarningBannerModel.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(loginUsernameAndPass.fulfilled, (state, action: PayloadAction<IReturnModelExtended>) => {
                clearCookiesAndStorage();
                const { userAccounts, allowCrossSessionToken, accessToken, returnUrl } = action.payload;
                handleUserAccounts(state, userAccounts!, accessToken!, returnUrl, allowCrossSessionToken);
                state.isLoading = false;
            })
            .addCase(loginUsernameAndPass.rejected, (state) => {
                if (checkIfNativeApp()) {
                    state.isLoading = true;
                    clearCookiesAndStorage();
                    return;
                }
                state.failedAt = "loginUsernameAndPass";
                state.isAuthenticated = false;
                state.isLoading = false;
                clearCookiesAndStorage();
            })
            .addCase(postOneTimePass.fulfilled, (state, action) => {
                const { success, cacheIdentifier } = action.payload;
                if (success) {
                    state.oneTimePassProps.cacheIdentifier = cacheIdentifier!;
                    state.oneTimePassProps.currentStep = "submitPass";
                }
                state.isLoading = false;
            })
            .addCase(postOneTimePass.rejected, (state) => {
                state.isLoading = false;
                state.countdownTimer = 60;
                state.failedAt = "postOneTimePass";
            })
            .addCase(loginWithOneTimePass.fulfilled, (state, action) => {
                const { accessToken, loginFailed, lockout, userAccounts, returnUrl } = action.payload;
                if (loginFailed) {
                    state.failedAt = "loginWithOneTimePass, login failed";

                    state.countdownTimer = lockout;
                } else {
                    clearCookiesAndStorage();
                    handleUserAccounts(state, userAccounts!, accessToken!, returnUrl);
                    state.isAuthenticated = true;
                }
                state.isLoading = false;
            })
            .addCase(loginWithOneTimePass.rejected, (state) => {
                state.isLoading = false;
                state.failedAt = "loginWithOneTimePass, rejected";
            })
            .addCase(loginWithMicrosoftToken.fulfilled, (state, action) => {
                const { loginFailed, accessToken, userAccounts, identifier, allowCrossSessionToken, returnUrl } =
                    action.payload;
                if (loginFailed) {
                    state.countdownTimer = 5;
                    state.failedAt = "loginWithMicrosoftToken, response fullfilled login failed";
                    state.isLoading = false;
                } else {
                    clearCookiesAndStorage();
                    sessionStorage.setItem("login", "microsoft");
                    if (identifier) localStorage.setItem("identifier", identifier);
                    handleUserAccounts(state, userAccounts!, accessToken!, returnUrl, allowCrossSessionToken);
                }
            })
            .addCase(loginWithMicrosoftToken.rejected, (state) => {
                state.isLoading = false;
                state.countdownTimer = 5;
                state.failedAt = "loginWithMicrosoftToken, response rejected";
            })
            .addCase(initGrandIdNoGuiLogin.fulfilled, (state, action) => {
                const data = action.payload;
                state.bankIdProps.statusResult.sessionGuid = data.sessionGuid ?? "";
                state.bankIdProps.autoStartToken = data.autoStartToken ?? "";
                state.bankIdProps.statusResult.hasErrors = data.hasErrors;
                state.bankIdProps.statusResult.error = data.error;
                state.bankIdProps.statusResult.qrCode = data.qrCode;
            })
            .addCase(initGrandIdNoGuiLogin.rejected, (state) => {
                state.failedAt = "initGrandIdNoGuiLogin";
                state.bankIdProps.bankIdRejected = true;
                state.isLoading = false;
            })
            .addCase(getGrandIdNoGuiStatus.fulfilled, (state, action) => {
                const data = action.payload;

                state.bankIdProps.statusResult = data;
                if (data.qrCode) state.isLoading = false;

                if (data.status === "failed") state.isLoading = false;
                if (data.status === "success") {
                    state.bankIdProps.bankIdAuthenticated = true;
                    state.bankIdProps.loginGrandIdGuid = data.sessionGuid!;
                }
            })
            .addCase(getGrandIdNoGuiStatus.rejected, (state) => {
                state.failedAt = "getGrandIdNoGuiStatus";
                state.isLoading = false;
                state.bankIdProps.bankIdRejected = true;
            })
            .addCase(loginGrandIdNoGui.fulfilled, (state, action) => {
                clearCookiesAndStorage();
                const { userAccounts, allowCrossSessionToken, accessToken, returnUrl } = action.payload;
                handleUserAccounts(state, userAccounts!, accessToken!, returnUrl, allowCrossSessionToken);
            })
            .addCase(loginGrandIdNoGui.rejected, (state) => {
                const username = state.bankIdProps.username;
                if (!username) {
                    state.failedAt = "loginGrandIdNoGui";
                    state.isLoading = false;
                    state.bankIdProps.bankIdRejected = true;
                }
            })
            .addCase(createPasswordReset.fulfilled, (state) => {
                state.isLoading = false;
                state.resetPassProps.currentStep = "resetLinkSent";
            })
            .addCase(createPasswordReset.rejected, (state) => {
                state.failedAt = "loadPasswordResetRequest, response rejected";
                state.isLoading = false;
            })
            .addCase(getResetRequest.fulfilled, (state, action) => {
                state.resetPassProps.currentStep = "setNewPass";
                state.resetPassProps.passResetViewModel = { ...action.payload };
                state.isLoading = false;
            })
            .addCase(getResetRequest.rejected, (state) => {
                state.failedAt = "getResetRequest, response rejected";
                state.isLoading = false;
            })
            .addCase(passwordReset.fulfilled, (state, action) => {
                const { authFailed, successful } = action.payload;
                if (successful) {
                    state.resetPassProps.currentStep = "resetSuccesful";
                } else {
                    state.failedAt = `passwordReset, ${authFailed ? "auth failed" : "change failed"}`;
                }
                state.isLoading = false;
            })
            .addCase(passwordReset.rejected, (state) => {
                state.failedAt = "passwordReset, response rejected";
                state.isLoading = false;
            });
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
