import { ColumnText } from "@medhelp/ui";
import { StatusTag } from "@medhelp/ui/Tag";
import Icon from "components/Icon";
import { isArray } from "lodash/fp";
import { ITable, SearchTableResult } from "pages/FollowUp/types";
import { getTagProps } from "pages/FollowUp/utils/case";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { DegreePeriodType, SearchTypes } from "../../utils";
import { getDegreePeriodString } from "utils/degrees";
import { orderBy } from "lodash";

interface IProfile {
    body: string;
    header: string;
    text?: string;
}

interface IItems {
    employee: IProfile;
    degrees?: DegreePeriodType;
    type?: { header?: string | null; body?: string | null }[];
    reimbursableType?: { header?: string | null; body?: string | null }[];
    employmentId: {
        id: string;
        type: string;
    };
    activity?: { header?: string | null }[];
    caseType?: { body?: string | null }[];
    caseStatus?: {
        header?: string | null;
        status?: string | null;
    }[];
    start?: { header?: string | null }[];
}

const ColumnTextRow = ({ body }: { body?: string | string[] | null }) => {
    return useMemo(() => {
        if (!body) return null;
        if (isArray(body)) {
            return (
                <div className="flex">
                    {body.map((x, index) => (
                        <>
                            <p className="text-sm text-mhgrey-dark">{x}</p>
                            {index < body.length - 1 && <div className="px-1 text-sm text-mhgrey-dark">|</div>}
                        </>
                    ))}
                </div>
            );
        }
        return <p className="text-sm text-mhgrey-dark">{body}</p>;
    }, [body]);
};
interface IPropsView {
    items: IItems[];
}
const Row = ({ items }: IPropsView) => {
    const { t } = useTranslation("followup");
    return (
        <div className="flex h-full w-full flex-col px-3 py-4">
            {items.map(
                (
                    { employee, degrees, type, employmentId, reimbursableType, activity, caseType, start, caseStatus },
                    index,
                ) => {
                    const sortedDegrees = orderBy(degrees?.degrees, ["timeStamp"], "desc");
                    return (
                        <div key={`${employee.header}-${index}`}>
                            <div className="flex items-start justify-between">
                                <div className="flex w-full items-start gap-2 text-base">
                                    <div className="pr-2">
                                        <div className="flex h-11 w-11 items-center justify-center rounded-full bg-mhgrey-light">
                                            <Icon icon="person" className="h-4 w-4" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col text-left">
                                        <p className="font-bold text-mhdarkgreen-original">{employee.header}</p>
                                        <ColumnTextRow body={employee.body} />
                                        {employee?.text && (
                                            <div>
                                                <p className="inline-block bg-mhred-alert4 px-1 text-xs text-mhred-alert3">
                                                    {employee.text}
                                                </p>
                                            </div>
                                        )}
                                        {type?.map(({ header, body }) => (
                                            <ColumnText className="mt-4 px-0">
                                                <div className="flex gap-2">
                                                    <p className="text-sm font-bold">{header}</p>
                                                </div>
                                                <p className="text-sm">
                                                    <div className="relative">{body}</div>
                                                </p>
                                            </ColumnText>
                                        ))}
                                        {reimbursableType?.map(({ header, body }) => (
                                            <ColumnText className="mt-2 px-0">
                                                <div className="flex gap-2">
                                                    <p className="text-sm font-bold">{header}</p>
                                                </div>
                                                <p className="text-sm">
                                                    <div className="relative">{body}</div>
                                                </p>
                                            </ColumnText>
                                        ))}
                                        {sortedDegrees.map((x, i) => {
                                            if (x.timeStamp) {
                                                return (
                                                    <div className="mt-2 whitespace-pre-wrap text-sm">
                                                        {`${getDegreePeriodString(
                                                            x.timeStamp,
                                                            sortedDegrees,
                                                            i,
                                                            t,
                                                            x.endDate,
                                                        )} | ${x.degree}%`}
                                                    </div>
                                                );
                                            }
                                        })}
                                        {employee.text && (
                                            <div className="mt-2">
                                                <div>
                                                    <p className="inline-block bg-mhred-alert4 px-1 text-xs text-mhred-alert3">
                                                        {employee.text}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        {activity?.map(({ header }) => (
                                            <ColumnText
                                                className="mt-4 break-all px-0"
                                                header={header ?? ""}
                                                boldHeader
                                            />
                                        ))}
                                        {caseType?.map(({ body }) => (
                                            <ColumnText className="mt-4 px-0" header={body ?? ""} boldHeader />
                                        ))}
                                        {start?.map(({ header }) => (
                                            <ColumnText className="px-0" header={header ?? ""} />
                                        ))}
                                        {caseStatus?.map(({ header, status }) => (
                                            <div className="mt-4 flex">
                                                <StatusTag
                                                    id={`mobile-row-case-status-${header}`}
                                                    text={header ?? ""}
                                                    preset={getTagProps(status)?.color ?? "grey"}
                                                    icon={getTagProps(status)?.icon}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <Link to={`/followup/employee/${employmentId.id}/absence`}>
                                    <Icon icon="caretRight" size={10} />
                                </Link>
                            </div>
                        </div>
                    );
                },
            )}
        </div>
    );
};

const useRows = (tableProps: ITable, type: SearchTypes) => {
    const navigate = useNavigate();
    const getElement = useCallback((cells: SearchTableResult[]) => {
        const mobileCells = cells.reduce<IItems>((prev, curr) => {
            if (curr.id === "degrees" && isArray(curr.data)) {
                return {
                    ...prev,
                    [curr.id]: curr.data[0]?.degrees,
                };
            }
            if (curr.id) {
                return {
                    ...prev,
                    [curr.id]: curr.data,
                };
            }
            return prev;
        }, {} as IItems);
        return [
            {
                data: [<Row items={[mobileCells]} />],
                id: "mobile-table-row",
            },
        ];
    }, []);
    const onRowClick = useCallback(
        (id?: string | null) => {
            if (id) {
                const isCaseType = type === "cases" || type === "activities";
                navigate(`/followup/employee/${id}/${isCaseType ? "case" : "absence"}`);
            }
        },
        [navigate, type],
    );
    const rows = useMemo(
        () =>
            tableProps?.data.map((props) => ({
                cells: getElement(props.cells),
                rowProps: {
                    onClick: props.id ? () => onRowClick(props.id) : undefined,
                    theme: props.theme,
                },
            })),
        [getElement, onRowClick, tableProps?.data],
    );
    return rows;
};

export default useRows;
