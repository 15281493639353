import { useTranslation } from "react-i18next";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";

interface IProps {
    healthCase: IHealthCase;
}

const ChainProgressBar = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");

    const chainProgressWidth = healthCase?.chainInformation?.day
        ? `${Math.round((healthCase.chainInformation.day / 550) * 100)}%`
        : "0%";

    return (
        <div className={`mx-8 mb-6 bg-mhbeige px-8 pb-10 ${healthCase.status === "paused" ? "opacity-50" : ""}`}>
            <p className="pb-6 pt-5 text-sm font-bold">{t("rehabChain")}</p>
            <div className="relative flex h-8 bg-mhgrey-medium">
                <div
                    className={"flex h-8 items-center border-r border-dashed border-white bg-progressBar"}
                    style={{ width: chainProgressWidth }}
                >
                    <div className="h-1 w-full rounded bg-white"></div>
                </div>
                <div className="absolute left-[16%] h-8 w-[2px] bg-white"></div>
                <div className="absolute left-[33%] h-8 w-[2px] bg-white"></div>
                <div className="absolute left-[66%] h-8 w-[2px] bg-white"></div>
            </div>
            <div className="relative mt-1 flex text-sm text-grey-600">
                <div className="absolute left-[0%]">0</div>
                <div className="absolute left-[14%]">{`90 ${t("days")}`}</div>
                <div className="absolute left-[31%]">{`180 ${t("days")}`}</div>
                <div className="absolute left-[64%]">{`365 ${t("days")}`}</div>
                <div className="absolute left-[98%]">550..</div>
            </div>
        </div>
    );
};

export default ChainProgressBar;
