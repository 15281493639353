import { IFileData } from "@medhelp/medical-certificate/redux";
import { IImage } from "../Appendix/Appendix";
import Appendix from "../Appendix";
import CertificatesMetadata from "../CertificatesMetadata";

export interface IProps {
    images: IImage[];
    fileData: IFileData[];
    text: string;
    showApendix?: boolean;
}

const MedicalCertificateInfo = ({ images, fileData, text, showApendix = true }: IProps) => (
    <div className="flex w-full flex-col gap-10">
        <h3 className="flex w-full self-center">{text}</h3>
        <CertificatesMetadata metaData={fileData} />
        {showApendix && <Appendix header="Bilagor" images={images} />}
    </div>
);
export default MedicalCertificateInfo;
