import { ModalFrame } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getUserContext } from "store/userSelectors";
import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as Tick } from "icons/tick.svg";
import EmployeeSummaryLoader from "./EmployeeSummaryLoader";
import EmployeeSummaryTable from "../Tables/EmployeeSummaryTable";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { ReactComponent as ThreeProfiles } from "icons/threeProfiles.svg";
import { fetchAbsenceSummary } from "pages/dashboard/redux/dashboardActions";
import { ReactComponent as ProfileWithLine } from "icons/profileWithLine.svg";
import { filterCompaniesByAccessRights } from "pages/dashboard/utils/dashboardAccessRights";
import { getAbsenceSummary, getCompanyGroupForCompany } from "pages/dashboard/redux/dashboardSelectors";

const EmployeeSummary = () => {
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("dashboard");
    const userContext = useAppSelector(getUserContext);
    const [currentKey, setCurrentKey] = useState("");
    const { data, status } = useAppSelector(getAbsenceSummary);
    const companies = useAppSelector(getCompanyGroupForCompany);

    useEffect(() => {
        const filteredCompaniesByAccessRight = filterCompaniesByAccessRights(
            userContext.userAccount?.accessRights || [],
            companies ?? [],
            "absenceWidget",
        );
        dispatch(fetchAbsenceSummary(filteredCompaniesByAccessRight.map((x) => x.referenceKey as number)));
    }, [dispatch, companies, userContext]);

    const handleClick = (keyName: string) => {
        setCurrentKey(keyName);
        setOpen(true);
    };

    if (status === Status.PENDING) {
        return <EmployeeSummaryLoader />;
    }

    if (status === Status.ERROR) {
        return null;
    }

    return (
        <>
            <div className="dashboard-width h-88 bg-primaryBg shadow-lg lg:h-44">
                <div className="flex h-17 items-center justify-center border-b font-bold xs:justify-start xs:pl-6">
                    {t("summaryOfEmployees")}
                </div>
                {data && (
                    <div className="summary-column flex flex-col justify-between px-8 pt-5 text-sm">
                        <SummaryBox
                            icon={<ThreeProfiles />}
                            heading={t("yourEmployees")}
                            topValue={data.totalEmployees ?? 0}
                            topText={t("totally")}
                            bottomValue={data.ongoing ?? 0}
                            bottomText={t("absent")}
                            handleBottomClick={() => handleClick("ongoing")}
                        />
                        <SummaryBox
                            icon={<ProfileWithLine />}
                            heading={t("startedAbsence")}
                            topValue={data.startToday ?? 0}
                            topText={t("today")}
                            handleTopClick={() => handleClick("startToday")}
                            bottomValue={data.startYday ?? 0}
                            bottomText={t("yesterday")}
                            handleBottomClick={() => handleClick("startYday")}
                        />
                        <SummaryBox
                            icon={<Tick />}
                            heading={t("backAtWork")}
                            topValue={data.backToday ?? 0}
                            topText={t("today")}
                            handleTopClick={() => handleClick("backToday")}
                            bottomValue={data.backYday ?? 0}
                            bottomText={t("yesterday")}
                            handleBottomClick={() => handleClick("backYday")}
                        />
                    </div>
                )}
            </div>
            <ModalFrame
                id="widgetEmployeeModal"
                open={open}
                setOpen={setOpen}
                header={<h3>{t(`summaryModalHeading.${currentKey}`)}</h3>}
                content={<EmployeeSummaryTable keyName={currentKey} />}
                preset="full"
            />
        </>
    );
};

export default EmployeeSummary;

interface ISummaryBox {
    icon: ReactNode;
    heading: string;
    topValue: number;
    topText: string;
    handleTopClick?: () => void;
    bottomValue: number;
    bottomText: string;
    handleBottomClick: () => void;
}

const SummaryBox = ({
    icon,
    heading,
    topValue,
    topText,
    handleTopClick,
    bottomValue,
    bottomText,
    handleBottomClick,
}: ISummaryBox) => (
    <div className="flex pb-4">
        <div className="w-10">{icon}</div>
        <div className="pl-9 font-bold">
            <div>{heading}</div>
            {handleTopClick ? (
                topValue > 0 ? (
                    <div className="cursor-pointer" onClick={handleTopClick}>
                        <span className="underline">{topValue}</span> {topText}
                    </div>
                ) : (
                    <div>
                        {topValue} {topText}
                    </div>
                )
            ) : (
                <div className="font-bold">
                    {topValue} {topText}
                </div>
            )}
            {bottomValue > 0 ? (
                <div className="cursor-pointer" onClick={handleBottomClick}>
                    <span className="underline">{bottomValue}</span> {bottomText}
                </div>
            ) : (
                <div>
                    {bottomValue} {bottomText}
                </div>
            )}
        </div>
    </div>
);
