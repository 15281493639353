export interface IProps {
    text: string;
}
const SearchTableHeader = ({ text }: IProps) => {
    return (
        <div className="flex items-center gap-1 px-2 pb-5 pt-8">
            <p className="text-sm font-bold lg:text-base">{text}</p>
        </div>
    );
};
export default SearchTableHeader;
