import * as React from "react";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as Warning } from "icons/warning.svg";

type Status = "info" | "error";
interface IProps {
    children: React.ReactNode;
    status?: Status;
}

const Icon = React.memo(({ status }: Pick<IProps, "status">) => {
    switch (status) {
        case "info": {
            return <InfoIcon width="24" height="24" fill="transparent" data-testid="info-status-infobox" />;
        }
        case "error": {
            return <Warning width="24" height="24" fill="transparent" data-testid="error-status-infobox" />;
        }
        default: {
            return <InfoIcon width="24" height="24" fill="transparent" data-testid="info-status-infobox" />;
        }
    }
});
const Infobox = ({ children, status = "info" }: IProps) => (
    <div className="flex border-2 border-solid border-background-300">
        <div className="flex basis-24 items-center justify-center border-r-2 bg-info">
            <Icon status={status} />
        </div>
        <div className="flex flex-1 items-center p-8">{children}</div>
    </div>
);

export default Infobox;
