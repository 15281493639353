import Icon from "components/Icon";
import { isEmpty } from "lodash/fp";
import { ChangeEvent, useCallback } from "react";

interface IProps {
    value?: string;
    onChange: (number: string) => void;
    placeholder?: string;
    label?: string;
}
const NumberField = ({ value, onChange: onChangeProp, placeholder = "", label, ...rest }: IProps) => {
    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event?.target.value;
            if (value && value.match(/[1-9]/)) {
                return onChangeProp(value);
            }
            return onChangeProp("");
        },
        [onChangeProp],
    );
    const increment = useCallback(() => {
        // @ts-expect-error
        if (isEmpty(value) || isNaN(value)) {
            return onChangeProp("1");
        }
        if (!isEmpty(value) && value) {
            let currentValue = parseInt(value);
            return onChangeProp((currentValue += 1).toString());
        }
        return onChangeProp("1");
    }, [onChangeProp, value]);
    const decrement = useCallback(() => {
        if (!value) {
            return onChangeProp("");
        }
        let currentValue = parseInt(value);
        if (currentValue === 1) {
            return onChangeProp("");
        }
        return onChangeProp((currentValue -= 1).toString());
    }, [onChangeProp, value]);
    return (
        <div className="flex w-full justify-center">
            <div className={`mb-3 w-full`}>
                {label && <label className="form-label mb-2 inline-block text-gray-700">{label}</label>}
                <div className="relative">
                    <input
                        onChange={onChange}
                        value={value}
                        type="number"
                        min="1"
                        className="number-field form-control m-0 block h-11 w-full border border-solid border-mhdarkgreen-original bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-700 transition ease-in-out focus:bg-white focus:text-gray-700 focus:outline-none"
                        placeholder={placeholder}
                        {...rest}
                    />
                    <div className="absolute right-2 top-1 flex translate-y-1/4 flex-col gap-1">
                        <Icon icon="caretUp" size={10} className="cursor-pointer" onClick={increment} />
                        <Icon icon="caretDown" size={10} className="cursor-pointer" onClick={decrement} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NumberField;
