import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { debounce } from "lodash";
import { TailSpin } from "react-loader-spinner";
import { IPowerbarSearch } from "interfaces/ISearch";
import PowerbarSearchItems from "components/PowerbarSearchItems";
import Icon from "components/Icon/Icon";
export interface IPowerbarSearchProps {
    isLoading: boolean;
    handleOpenSearch: () => void;
    powerbarSearchResult: IPowerbarSearch[];
    open: boolean;
    setOpen: (open: boolean) => void;
    onChange: (value: string) => void;
    activeSearchName: string | null;
    onClose: (value: string) => void;
    noResult: boolean;
    inputText: string;
}

const PowerbarSearch = ({
    isLoading,
    handleOpenSearch,
    powerbarSearchResult,
    open,
    setOpen,
    onChange,
    activeSearchName,
    onClose,
    noResult,
    inputText,
}: IPowerbarSearchProps) => {
    const { t } = useTranslation();
    const [os, setOs] = useState<string | null>(null);
    let [inputSearchRef, setInputRef] = useState<HTMLInputElement | undefined>(undefined);
    const debounceOnChange = useMemo(() => {
        return debounce(onChange, 300);
    }, [onChange]);

    const onSearchChange = useCallback((node: HTMLInputElement) => {
        setInputRef(node);
    }, []);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e && e.key === "s" && e.metaKey) {
                const openSearch = os === "Mac" ? e.shiftKey : e.altKey;
                if (openSearch) handleOpenSearch();
            }
        };
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [handleOpenSearch, os]);

    useEffect(() => {
        if (os === null) {
            const os = ["Win", "Mac"];
            const currentOs = os.find((v) => navigator.userAgent.indexOf(v) >= 0);
            setOs(currentOs ?? "");
        }
    }, [os]);

    return (
        <div className="h-full w-full max-w-lg">
            {!open && (
                <div className="group relative flex h-full w-full items-center justify-center">
                    <div
                        className={`flex h-full w-full items-center border-r border-grey-300 bg-menuNavInputBg text-black max-xl:hidden xl:pl-3 ${
                            activeSearchName ? "bg-grey-50" : "menuNavInputBg hover:bg-grey-100"
                        }`}
                    >
                        <button
                            className="flex h-full w-full cursor-pointer items-center rounded-none"
                            onClick={handleOpenSearch}
                        >
                            <div>
                                <Icon icon="search" size={20} />
                            </div>

                            <p className="pl-2 text-left">{activeSearchName ?? t("searchCompanyOrEmp")}</p>
                        </button>
                        {activeSearchName && (
                            <button
                                tabIndex={-1}
                                aria-hidden="true"
                                className="flex h-9 w-10 items-center justify-center"
                                onClick={() => onClose("")}
                            >
                                <Icon icon="close" />
                            </button>
                        )}
                    </div>

                    <div
                        className="flex cursor-pointer items-center justify-center pr-5 text-menuNavColorPrimary xl:hidden"
                        onClick={handleOpenSearch}
                    >
                        <button>
                            <Icon icon="search" size={28} />
                        </button>
                    </div>

                    <div className="absolute top-11 z-20 hidden flex-col items-center text-white xl:group-hover:flex">
                        <div className="absolute h-2.5 w-2.5 rotate-45 transform bg-menuNavBg" />
                        <div className="mt-1.5 flex flex-col items-center rounded bg-menuNavBg p-2 text-menuNavColorPrimary">
                            <p>{t("openSearchWith")}</p>
                            <div className="mt-1 flex text-menuNavColorPrimary">
                                {os === "Mac" && (
                                    <>
                                        <Icon icon="macLogo" className="rounded border px-1" size={27} />

                                        <Icon icon="shift" className="mx-2 rounded border px-1" size={27} />
                                    </>
                                )}
                                {os === "Win" && (
                                    <>
                                        <Icon icon="winLogo" className="rounded border px-1" size={26} />
                                        <p className="mx-2 rounded border px-1">Alt</p>
                                    </>
                                )}
                                <p className="rounded border px-1">S</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ReactModal
                id="powerbar-search-modal"
                isOpen={open}
                className="absolute top-1 w-full max-w-3xl rounded-md xl:ml-65"
                style={{
                    overlay: {
                        zIndex: 10,
                        backgroundColor: "rgba(94, 95, 86, 0.5)",
                    },
                    content: { background: "#FFFFFF" },
                }}
                onRequestClose={() => setOpen(false)}
            >
                <div id="search-open" className="flex flex-col">
                    <div className="flex h-15 items-center gap-2 border-b border-grey-350 px-4 text-menuNavColorPrimary">
                        <div>
                            <Icon icon="search" size={20} />
                        </div>
                        <input
                            ref={onSearchChange}
                            className="hide-outline w-full"
                            type="text"
                            placeholder={t("openSearchPlaceholder")}
                            defaultValue={activeSearchName || ""}
                            onChange={(e) => debounceOnChange(e.target.value)}
                            autoFocus
                        />
                        {isLoading && <TailSpin color="#3b3b36" height={20} width={20} />}
                        <button
                            className="flex items-center justify-center"
                            onClick={() => {
                                if (inputSearchRef) {
                                    inputSearchRef.value = "";
                                }
                                onChange("");
                            }}
                        >
                            <Icon icon="close" size={24} />
                        </button>
                    </div>

                    <div className="max-h-md overflow-auto rounded-md">
                        <div className="border-b border-grey-200">
                            {noResult && (
                                <p className="my-3 ml-4 flex text-lg text-mhred-alert2 underline">{t("noResult")}</p>
                            )}
                            {!noResult && (
                                <p className="my-3 ml-4 text-grey-600">
                                    {powerbarSearchResult.length > 0 ? t("suggestedSearches") : t("latestChosen")}
                                </p>
                            )}
                        </div>
                        {!noResult && <PowerbarSearchItems setOpen={setOpen} inputText={inputText} />}
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};

export default PowerbarSearch;
