import React from "react";

import { getAdminComponentProps } from "pages/Administration/redux/administrationSelectors";
import ProductSettingsInfoBox from "../ProductSettingsInfoBox/ProductSettingsInfoBox";
import { CardWithExpand } from "@medhelp/ui";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";

function ProductSetting() {
    const adminProps = useAppSelector(getAdminComponentProps);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [open, setOpen] = React.useState(false);
    const { companyName } = adminProps;
    const { t } = useTranslation("administration");
    return (
        <div>
            <div className="mt-10">
                <ProductSettingsInfoBox />
            </div>
            <div className="my-16">
                {companyName && (
                    <div className="hidden lg:flex">
                        <div className="mr-8 flex flex-col">
                            <p className="text-gray-500">{t("company")}</p>
                            <h3 className="hover:text-gray-500">{companyName}</h3>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-gray-500">{t("organization")}</p>
                            <h3 className="hover:text-gray-500">{t("all")}</h3>
                        </div>
                    </div>
                )}
            </div>
            <div className="m-6 bg-widgetBeige lg:mx-0">
                <CardWithExpand
                    index={0}
                    headerView={
                        <div className="w-full lg:w-3/5">
                            <h1 className="text-2xl">{t("caseActivityTemplate")}</h1>
                            <p className="pt-4 text-base">{t("caseActivityTemplateInfo")}</p>
                        </div>
                    }
                    onExpand={setOpen}
                />
            </div>
        </div>
    );
}

export default ProductSetting;
