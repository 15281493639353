import Icon from "components/Icon";
import { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    name?: string;
    alt?: string;
    size?: number;
    label?: React.ReactNode;
}

const Checkbox = ({ checked, onChange, disabled = false, name, alt, size = 18, label, ...rest }: IProps) => (
    <label
        className={twMerge(
            "flex items-center rounded-md hover:bg-mhgrey-light",
            disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
    >
        <input
            className="hidden"
            name={name}
            checked={checked}
            onChange={(e) => {
                onChange(e);
            }}
            disabled={disabled}
            alt={alt}
            {...rest}
            type="checkbox"
        />
        {checked ? (
            <Icon icon="checkBoxChecked" fill={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        ) : (
            <Icon icon="checkBoxUncheched" stroke={disabled ? "#BBBDAC" : "#192D05"} size={size} />
        )}
        {label}
    </label>
);
export default Checkbox;
