import { useCallback, useState } from "react";
import { useAppDispatch } from "store/hooks";
import { TailSpin } from "react-loader-spinner";
import { isEmpty } from "lodash/fp";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ReactComponent as PictureIcon } from "icons/picture.svg";
import { thunkActions } from "../../redux";

export interface IImage {
    fileName: string;
    url: string;
    id?: string;
}
interface IProps {
    images: IImage[];
    header?: string;
    onClick?: (name: string) => void;
}
const Appendix = ({ images, header, onClick }: IProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const linkButton = useCallback(
        (fileName: string, id?: string, url?: string) => (
            <div
                onClick={() => {
                    if (!id) {
                        if (url) {
                            window.open(url);
                            return;
                        }
                        return;
                    }
                    setLoading(true);
                    const newWindow = window.open();
                    dispatch(thunkActions.getSpecificFile({ id }))
                        .then((data) => {
                            setLoading(false);
                            let fileURL = URL.createObjectURL(data.payload);
                            if (newWindow) newWindow.location.href = fileURL;
                        })
                        .catch((e) => {
                            if (newWindow) newWindow.document.body.innerHTML = e.message;
                        });
                }}
                className="w-full cursor-pointer"
                key={id}
            >
                <div className="relative flex h-12 gap-6 p-2 sm:h-24 sm:w-24 sm:flex-col sm:gap-0 sm:p-0">
                    <div className="flex self-center sm:flex-1 sm:items-end sm:justify-self-center">
                        <PictureIcon />
                    </div>
                    <div className="flex overflow-hidden overflow-ellipsis sm:flex-1 sm:pl-2 sm:pr-2">
                        <p className="self-center overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
                            {fileName}
                        </p>
                    </div>
                </div>
            </div>
        ),
        [dispatch],
    );

    if (isEmpty(images)) return null;

    if (loading) {
        return (
            <div className="flex flex-col gap-3">
                {header && <p className="hidden text-sm text-grey-600 sm:block">{header}</p>}
                <div className="flex h-[96px] w-[96px] items-center justify-center">
                    <TailSpin color="#3b3b36" height={20} width={20} />
                </div>
            </div>
        );
    }
    return (
        <div className="flex flex-col gap-3">
            {header && <p className="hidden text-sm text-grey-600 sm:block">{header}</p>}
            <div className="flex flex-col gap-2 sm:flex-row sm:flex-wrap sm:gap-6">
                {images.map(({ fileName, url, id }) => (
                    <div
                        key={url}
                        className="relative flex h-12 gap-6 bg-mhgrey-light sm:h-24 sm:w-24 sm:flex-col sm:gap-0 sm:p-0"
                    >
                        {linkButton(fileName, id, url)}
                        {onClick && (
                            <div
                                data-testid="appendix-remove-file-click"
                                onClick={() => onClick(fileName)}
                                className="absolute right-0 top-0 flex h-4 w-4 -translate-y-2 translate-x-1 cursor-pointer items-center justify-center rounded-full bg-mhgrey-medium"
                            >
                                <CloseIcon width="8" height="8" fill="white" className="text-white" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Appendix;
