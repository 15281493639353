import {
    getCompanies,
    getDepartments,
    getCheckedItems,
    getChosenTimeInterval,
    getChosenAbsenceCause,
} from "pages/dashboard/redux/dashboardSelectors";
import { useState } from "react";
import Icon from "components/Icon";
import { widgetStyles } from "../Widget";
import { useAppSelector } from "store/hooks";
import HCMTodo from "../WidgetTypes/HCMTodo";
import { useTranslation } from "react-i18next";
import { widgetMap } from "../Widget/widgetMap";
import { getUserLanguage } from "store/userSelectors";
import WidgetFilterContainer from "./WidgetFilterContainer";
import HCMStatusOverview from "../WidgetTypes/HCMStatusOverview";
import OngoingRehabWidget from "../WidgetTypes/OngoingRehabWidget";
import { getTimeSpanLabel } from "pages/dashboard/utils/widgetUtils";
import OngoingAbsenceWidget from "../WidgetTypes/OngoingAbsenceWidget";
import AbsenceCauseProportion from "../WidgetTypes/AbsenceCauseProportion";
import { IWidget, WidgetType } from "pages/dashboard/utils/dashboardTypes";
import LastActivatedRehabWidget from "../WidgetTypes/LastActivatedRehabWidget";
import { flattenDepartments, hasSelectedRequiredFilters } from "pages/dashboard/utils/dashboardSliceUtils";
import WorkRelatedAbsenceWidget from "../WidgetTypes/WorkRelatedAbsenceWidget";
import LastReportedAbsenceWidget from "../WidgetTypes/LastReportedAbsenceWidget";
import AbsenceForecastCurrentMonth from "../WidgetTypes/AbsenceForecastCurrentMonth";
import AbsenceTrendWidget from "../WidgetTypes/AbsenceTrendWidget/AbsenceTrendWidget";
import EstimatedUpcomingAbsenceWidget from "../WidgetTypes/EstimatedUpcomingAbsenceWidget";
import RecurringSickLeaveWidget from "../WidgetTypes/RecurringSickLeaveWidget/RecurringSickLeaveWidget";
import TotalAbsenceLastMonthsWidget from "../WidgetTypes/TotalAbsenceLastMonthsWidget/TotalAbsenceLastMonthsWidget";

const WidgetContainer = (widget: IWidget) => {
    const { id, type, customHeading } = widget;
    const { t } = useTranslation("dashboard");
    const currentWidget = widgetMap.get(type);
    const [showFilter, setShowFilter] = useState(false);
    const userLanguage = useAppSelector(getUserLanguage) ?? "sv";
    const companies = useAppSelector(getCompanies(id));
    const departments = useAppSelector(getDepartments(id));
    const timeInterval = useAppSelector(getChosenTimeInterval(id));
    const absenceCause = useAppSelector(getChosenAbsenceCause(id));
    const { checkedFilterItems, numberOfFilters } = useAppSelector(getCheckedItems(id));
    const checkedDepartments = flattenDepartments(departments || [])?.filter(({ checked }) => checked);
    const checkedCompanies = companies?.filter(({ checked }) => checked);

    const renderDashboardWidget = (type: WidgetType) => {
        switch (type) {
            case "ongoing-absence":
                return <OngoingAbsenceWidget id={id} type={type} />;
            case "last-reported-absence":
                return <LastReportedAbsenceWidget id={id} type={type} />;
            case "ongoing-rehab":
                return <OngoingRehabWidget id={id} type={type} />;
            case "work-related-absence":
                return <WorkRelatedAbsenceWidget id={id} type={type} />;
            case "last-activated-rehab":
                return <LastActivatedRehabWidget id={id} type={type} />;
            case "estimated-upcoming-absence":
                return <EstimatedUpcomingAbsenceWidget id={id} type={type} />;
            case "recurring-absence-last-12-months":
                return <RecurringSickLeaveWidget id={id} type={type} />;
            case "absence-percent-last-6-months":
                return <TotalAbsenceLastMonthsWidget id={id} type={type} />;
            case "absence-percent-per-month":
                return <AbsenceTrendWidget id={id} type={type} />;
            case "absence-forecast-current-month":
                return <AbsenceForecastCurrentMonth id={id} type={type} />;
            case "absence-cause-proportion":
                return <AbsenceCauseProportion id={id} type={type} />;
            case "hcm-status-overview":
                return <HCMStatusOverview id={id} type={type} />;
            case "hcm-todo":
                return <HCMTodo id={id} type={type} />;
            default:
                return <></>;
        }
    };

    return currentWidget ? (
        <div
            data-testid={id}
            className={`widget flex flex-col shadow-lg ${
                currentWidget?.style === "primary"
                    ? "bg-primaryBg text-primaryText"
                    : "bg-secondaryBg text-secondaryText"
            }`}
        >
            <div
                className={`widget-container-header relative flex flex-row items-center justify-between border-b pl-6 pr-3 ${
                    currentWidget?.style === "primary" ? "border-primaryBorder" : "border-secondaryBorder"
                }`}
            >
                <div>
                    <p className={`font-bold ${currentWidget?.style === "primary" ? "" : "text-secondaryText"}`}>
                        {customHeading || t(`widgetHeading.${currentWidget?.heading}`)}
                    </p>
                    {numberOfFilters > 0 && (
                        <button type="button" className="flex items-center" onClick={() => setShowFilter(!showFilter)}>
                            <p className="mr-1 text-xs">
                                {numberOfFilters} {numberOfFilters > 1 ? t("filtersApplied") : t("filterApplied")}
                            </p>
                            {currentWidget?.style && (
                                <Icon
                                    icon={showFilter ? "caretUp" : "caretDown"}
                                    stroke={widgetStyles[currentWidget?.style].icon}
                                    size={12}
                                />
                            )}
                        </button>
                    )}
                </div>
                {currentWidget?.timeSpan && (
                    <div
                        className={`time-span-label rounded-full border-1 px-3 ${
                            currentWidget?.style === "primary"
                                ? "border-primaryBorder bg-primaryBg text-grey-700"
                                : "border-secondaryBorder bg-secondaryBg text-mhbeige"
                        }`}
                    >
                        <div className="flex flex-row">
                            <div className={`self-center ${currentWidget?.style === "primary" ? "" : "opacity-75"}`}>
                                <Icon
                                    icon="calendar"
                                    size={12}
                                    stroke={`${currentWidget?.style === "primary" ? "#5F6152" : "#FFFAF5"}`}
                                    strokeWidth={2}
                                />
                            </div>
                            <p className={`pl-1 text-xs ${currentWidget?.style === "primary" ? "" : "opacity-75"}`}>
                                {getTimeSpanLabel(currentWidget, t, userLanguage, timeInterval)}
                            </p>
                        </div>
                        {absenceCause ? (
                            <div className="text-center">
                                <p className="text-xs">{t(absenceCause)}</p>
                            </div>
                        ) : null}
                    </div>
                )}
                <WidgetFilterContainer {...widget} />
            </div>
            {showFilter ? (
                <div className="height-276 overflow-auto">
                    <div className="pl-6 pr-3 pt-4">
                        {checkedFilterItems.map(
                            (item) =>
                                item.data &&
                                item.data?.length > 0 && (
                                    <div key={item.heading}>
                                        <p className="font-bold">
                                            {t("filterBy")} {item.heading}
                                        </p>
                                        <div className="flex flex-wrap pb-4">
                                            {item.data?.map((type) => t(type.label)).join(", ")}
                                        </div>
                                    </div>
                                ),
                        )}
                    </div>
                </div>
            ) : (
                <div className="height-276">
                    {companies &&
                    companies?.length > 1 &&
                    checkedCompanies?.length === 0 &&
                    checkedDepartments?.length === 0 ? (
                        <div className="flex h-full items-center justify-center p-20 text-center">
                            {t("selectFilter", { filter: t("company") })}
                        </div>
                    ) : !hasSelectedRequiredFilters(widget, currentWidget) ? (
                        <div className="flex h-full items-center justify-center p-20 text-center">
                            {t("selectFilter", {
                                filter: t("absenceCause"),
                            })}
                        </div>
                    ) : (
                        renderDashboardWidget(type)
                    )}
                </div>
            )}
        </div>
    ) : null;
};

export default WidgetContainer;
