import { twMerge } from "tailwind-merge";

interface ButtonProps {
    text: string;
    onClick: () => void;
    preset?: "primary" | "secondary";
    disabled?: boolean;
}

const PRESETS = {
    primary: "bg-mhdarkgreen-original text-white",
    secondary: "border border-mhdarkgreen-original",
};

const Button = ({ text, onClick, preset = "primary", disabled }: ButtonProps) => (
    <button
        className={twMerge("h-11 w-full rounded text-center md:w-content md:min-w-[120px]", PRESETS[preset])}
        onClick={onClick}
        type="button"
        disabled={disabled}
    >
        {text}
    </button>
);

export default Button;
