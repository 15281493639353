import {
    getCustomerData,
    getActiveBillingPosts,
    getSelectedCompanyCurrency,
    getDeactivatedBillingPosts,
    getBillableErpProductsByRetailId,
} from "pages/service/redux/serviceSelectors";
import {
    fetchBillableErpProductsByRetailId,
    saveAndFetchBillingPostConfigurations,
} from "pages/service/redux/serviceAsyncActions";
import Icon from "components/Icon";
import { isEmpty } from "lodash/fp";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import ServiceDatePicker from "../ServiceDatePicker";
import { InvoicePost } from "swagger/businessupport";
import { useFieldArray, useForm } from "react-hook-form";
import ClickablePlaceholder from "../ClickablePlaceholder";
import { AccordionBooleanContent, ServiceAccordion } from "../ServiceAccordion";

const BillingPostConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const { retailerId } = useAppSelector(getCustomerData);
    const currency = useAppSelector(getSelectedCompanyCurrency);
    const billingPostConfiguration = useAppSelector(getActiveBillingPosts);
    const deactivatedBillingPosts = useAppSelector(getDeactivatedBillingPosts);
    const billingPostOptions = useAppSelector(getBillableErpProductsByRetailId);
    const [selectedValue, setSelectedValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (retailerId) dispatch(fetchBillableErpProductsByRetailId(retailerId));
    }, [dispatch, retailerId]);

    const {
        control,
        register,
        handleSubmit,
        reset,
        resetField,
        watch,
        formState: { isDirty, isValid, dirtyFields },
    } = useForm({ defaultValues: { billingPostConfiguration } });

    useEffect(() => {
        reset({ billingPostConfiguration });
    }, [billingPostConfiguration, reset]);

    const filteredBillingPostOptions = billingPostOptions.filter(
        (erpProduct) => !watch("billingPostConfiguration").some((post) => post.erpNumber === erpProduct.erpNumber),
    );

    const onSubmit = (data: { billingPostConfiguration: InvoicePost[] }) => {
        setIsLoading(true);

        const dirtyFieldsArray: InvoicePost[] = [];

        data?.billingPostConfiguration.filter((x, index) => {
            if (!isEmpty(dirtyFields.billingPostConfiguration && dirtyFields.billingPostConfiguration[index]))
                dirtyFieldsArray.push(x);
        });

        dispatch(saveAndFetchBillingPostConfigurations(dirtyFieldsArray))
            .then(unwrapResult)
            .then(() => {
                setIsLoading(false);
                toast(t("saved"));
            })
            .catch((e) => {
                toast(t("error") + ": " + e.message, { type: "error" });
                reset({ billingPostConfiguration });
                setIsLoading(false);
            });
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "billingPostConfiguration",
        // necessary because useFieldArray overwrites the id property
        keyName: "key",
    });

    const handleSelectBillingPost = (erpNumber: string) => {
        setSelectedValue("");
        const selectedBillingPost = billingPostOptions.find((billingPost) => billingPost.erpNumber === erpNumber);
        if (selectedBillingPost)
            append({
                erpNumber: selectedBillingPost.erpNumber,
                billingPostType: selectedBillingPost.billingPostType,
                activationDate: "",
                deactivationDate: "",
            });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("billingPosts")}
                reset={() => reset({ billingPostConfiguration })}
                disabled={!isDirty || !isValid}
                isLoading={isLoading}
                content={
                    <>
                        {billingPostConfiguration.map((invoicePost) => (
                            <AccordionBooleanContent
                                key={invoicePost.id}
                                label={t(`erpNumberKey.${invoicePost.erpNumber}`)}
                            />
                        ))}
                    </>
                }
            >
                <div className="my-8">
                    {fields?.map(({ erpNumber, id, key }, index) => (
                        <ServiceDatePicker
                            key={key}
                            label={t(`erpNumberKey.${erpNumber}`)}
                            activationRegister={register(`billingPostConfiguration.${index}.activationDate`, {
                                required: true,
                            })}
                            deactivationRegister={register(`billingPostConfiguration.${index}.deactivationDate`)}
                        >
                            <div className="flex flex-wrap items-center gap-2">
                                <ClickablePlaceholder
                                    label={
                                        <p className="text-xs">
                                            {t("price")}:{" "}
                                            <b>
                                                {watch(`billingPostConfiguration.${index}.customerPrice`)
                                                    ? watch(`billingPostConfiguration.${index}.customerPrice`) +
                                                      " " +
                                                      (currency || "")
                                                    : t("notSet")}
                                            </b>
                                        </p>
                                    }
                                    reset={() => resetField(`billingPostConfiguration.${index}.customerPrice`)}
                                >
                                    <input
                                        autoFocus={true}
                                        className="h-8 w-40 px-2"
                                        type="number"
                                        {...register(`billingPostConfiguration.${index}.customerPrice`, {
                                            valueAsNumber: true,
                                        })}
                                    />
                                </ClickablePlaceholder>
                                <div className="flex w-36 items-center justify-between text-xs text-grey-600">
                                    {!id && (
                                        <button type="button" onClick={() => remove(index)} className="p-1">
                                            <Icon icon="trashCan" size={14} />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </ServiceDatePicker>
                    ))}
                    <div className="mt-10">
                        <select
                            className="w-50 mt-2 h-12 overflow-ellipsis border border-mhdarkgreen-original bg-mhbeige px-2 hover:cursor-pointer disabled:cursor-not-allowed"
                            onChange={(e) => handleSelectBillingPost(e.target.value)}
                            value={selectedValue}
                            disabled={filteredBillingPostOptions.length === 0}
                        >
                            <option disabled value="">
                                {t("chooseBillingPost")}
                            </option>
                            {filteredBillingPostOptions?.map(({ erpNumber, name }) => (
                                <option key={erpNumber} value={erpNumber || ""}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {deactivatedBillingPosts && (
                        <div className="mt-10">
                            {deactivatedBillingPosts?.map(
                                ({ id, erpNumber, activationDate, deactivationDate, customerPrice }) => (
                                    <ServiceDatePicker
                                        key={id}
                                        label={t(`erpNumberKey.${erpNumber}`)}
                                        activationDate={activationDate}
                                        deactivationDate={deactivationDate}
                                    >
                                        <div className="flex flex-wrap items-center gap-2">
                                            <ClickablePlaceholder
                                                label={
                                                    <p className="text-xs">
                                                        {t("price")}:{" "}
                                                        <b>
                                                            {customerPrice
                                                                ? customerPrice + " " + (currency || "")
                                                                : t("notSet")}
                                                        </b>
                                                    </p>
                                                }
                                                disabled={true}
                                            >
                                                <input
                                                    className="h-8 w-40 px-2"
                                                    type="number"
                                                    value={customerPrice || ""}
                                                />
                                            </ClickablePlaceholder>
                                        </div>
                                    </ServiceDatePicker>
                                ),
                            )}
                        </div>
                    )}
                </div>
            </ServiceAccordion>
        </form>
    );
};

export default BillingPostConfiguration;
