import { isUndefined } from "lodash/fp";
import { SyntheticEvent } from "react";

export interface IProps {
    label?: string;
    required?: boolean;
    prefix?: React.ReactElement;
    textarea?: boolean;
    error?: string;
    className?: string;
    onChange: (v: SyntheticEvent) => void;
    setRef?: <T>(node: T) => void;
}

const Form = ({ label, prefix, required, textarea, error, className, onChange, setRef, ...rest }: IProps) => {
    return (
        <div className="mb-10 flex flex-col font-bold">
            {label && (
                <label className="mb-1.5">
                    {label} {required ? "*" : ""}
                </label>
            )}
            {error && <p className="pb-4 text-mhred-alert1">{error}</p>}
            <div className="flex w-full">
                {prefix && prefix}
                {textarea ? (
                    <textarea
                        {...(!isUndefined(setRef)
                            ? {
                                  ref: setRef,
                              }
                            : {})}
                        onChange={onChange}
                        className={`h-44 w-full resize-none border border-mhdarkgreen-original bg-transparent px-4 py-3`}
                        {...rest}
                    />
                ) : (
                    <input
                        {...(!isUndefined(setRef)
                            ? {
                                  ref: setRef,
                              }
                            : {})}
                        onChange={onChange}
                        className={`w-full border border-mhdarkgreen-original bg-transparent px-4 py-3 ${
                            error ? "border border-mhred-alert1" : ""
                        } ${className}`}
                        {...rest}
                    />
                )}
            </div>
        </div>
    );
};

export default Form;
