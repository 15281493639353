import Tag from "../Tag";
import Icon from "components/Icon";

interface IProps {
    id: string;
    text: string;
    disabled: boolean;
    onClick: () => void;
}

const ClickableTag = (props: IProps) => {
    const { id, text, disabled, onClick } = props;

    return (
        <Tag id={id} className="bg-mhgrey-light py-1">
            <p className={"max-w-[200px] truncate text-xs font-bold lg:max-w-[125px]"}>{text}</p>
            {!disabled && onClick && (
                <button onClick={onClick} className="ml-3" disabled={disabled}>
                    <Icon icon="close" size={12} />
                </button>
            )}
        </Tag>
    );
};

export default ClickableTag;
