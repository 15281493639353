import { useTranslation } from "react-i18next";
import { upperCase } from "lodash/fp";

const ArchiveTag = () => {
    const { t } = useTranslation("followup");

    return (
        <div className="-mt-1">
            <p className="inline-block bg-mhred-alert4 px-1 text-xs font-bold text-mhred-alert3">
                {upperCase(t("followUpArchived"))}
            </p>
        </div>
    );
};

export default ArchiveTag;
