import { useCallback } from "react";
import { isEmpty } from "lodash/fp";
import { useTranslation } from "react-i18next";
import { Card, CardAlign } from "@medhelp/ui";
import { selectors } from "../../redux";
import { certificates as utilsC } from "../../utils";
import HistoryCertificateItem from "./HistoryCertificateItem";
import HistoryCertificateManage from "./HistoryCertificateManage";
import CertificateContainer from "../CertificateContainer";
import { useAppSelector } from "store/hooks";

interface IProps {
    manage?: boolean;
}
const HistoryCertificates = ({ manage = false }: IProps) => {
    const { t } = useTranslation();
    const certificates = useAppSelector(selectors.getSortedCertificates("history", utilsC.MetadataKeys.to));
    const addedFile = useAppSelector(selectors.getAddedFile);
    const IsHighlighted = useCallback(
        (groupKey: string) => Boolean(groupKey === addedFile.groupKey),
        [addedFile.groupKey],
    );
    if (isEmpty(certificates))
        return (
            <div className="mt-20">
                <h2 className="">Historik</h2>

                <Card testId="no-current-certificate-test" className="mt-3 xs:mt-6">
                    <p>{t("noCurrentCertificates")}</p>
                </Card>
            </div>
        );
    return (
        <div className="mt-20">
            <h2 className="">Historik</h2>
            <Card
                testId="history-certificates-card-medical-certificate-page"
                align={CardAlign.start}
                className="mt-3 p-0 xs:mt-6"
            >
                <div className="flex w-full flex-col">
                    {certificates.map((certificate, index) => (
                        <CertificateContainer
                            key={`history-certificate-item-${certificate.groupKey}`}
                            id={certificate.groupKey || ""}
                            highlight={IsHighlighted(certificate.groupKey || "no-highlight")}
                        >
                            {manage ? (
                                <HistoryCertificateManage
                                    certificate={certificate}
                                    index={index}
                                    expanded={IsHighlighted(certificate.groupKey || "no-highlight")}
                                />
                            ) : (
                                <HistoryCertificateItem
                                    certificate={certificate}
                                    index={index}
                                    expanded={IsHighlighted(certificate.groupKey || "no-highlight")}
                                />
                            )}
                        </CertificateContainer>
                    ))}
                </div>
            </Card>
        </div>
    );
};

export default HistoryCertificates;
