import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { SubscriptionLookup } from "swagger/employeeattendance";
import { twMerge } from "tailwind-merge";
import { getIconByLookupType } from "pages/Administration/utils";
import { UpdateProps } from "../CreateNewSubscription/CreateNewSubscription";
import { CompanyUser } from "swagger/customercompany";

export interface INewSubscriptionSearchStateProps {
    currentSearchQuery: string;
    searchIsLoading: boolean;
    searchOpen: boolean;
    searchResults: SubscriptionLookup[] | null;
    selectedSearchCompanyUser: CompanyUser | null;
}
export interface INewSubscriptionSearchCompProps extends INewSubscriptionSearchStateProps {
    isExternalSearch?: boolean;
    newSubscriptionOnChange: (propName: UpdateProps, value: string | number | SubscriptionLookup) => void;
}
const NewSubscriptionSearch = ({
    currentSearchQuery,
    searchIsLoading,
    searchOpen,
    searchResults,
    newSubscriptionOnChange,
    isExternalSearch,
}: INewSubscriptionSearchCompProps) => {
    const { t } = useTranslation("administration");
    const [open, setOpen] = useState(searchOpen);
    const ref = useElementClicked(setOpen);
    const [searchQuery, setSearchQuery] = useState(currentSearchQuery);

    const debounceOnChange = useMemo(() => {
        return debounce(newSubscriptionOnChange, 500);
    }, [newSubscriptionOnChange]);

    const handleSearchItemClicked = (result: SubscriptionLookup) => {
        isExternalSearch
            ? newSubscriptionOnChange("externalUserContactKey", result.text ?? "")
            : newSubscriptionOnChange("selectedSearchResult", result);
        setSearchQuery(result.name ? result.name : (result.text ?? ""));
        setOpen(false);
    };

    return (
        <div ref={ref} className="relative flex max-w-[632px] flex-col">
            <input
                className="h-12 w-full cursor-text whitespace-pre-wrap border border-black bg-mhbeige pl-10 text-black"
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value);
                    debounceOnChange("newSubSearchQuery", e.target.value);
                }}
                onFocus={() => setOpen(true)}
            />
            <div className="absolute left-3.5 top-3.5">
                <Icon icon="magnifier" className="fill-none" />
            </div>
            {searchIsLoading && (
                <div className="absolute right-0 mr-3.5">
                    <div className="mt-3.5">
                        <TailSpin color="#3b3b36" height={20} width={20} />
                    </div>
                </div>
            )}
            {open && searchResults && (
                <div className="max-h-100 cursor-pointer overflow-auto border bg-white shadow-md">
                    {searchResults?.length === 0 ? (
                        <p className="p-4">{t("noHits")}</p>
                    ) : (
                        searchResults?.map((result, i) => (
                            <div
                                key={`${result.id}-${i}`}
                                className="relative flex items-center border-b p-4"
                                onClick={() => {
                                    handleSearchItemClicked(result);
                                }}
                            >
                                <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-mhgrey-light">
                                    <Icon icon={getIconByLookupType(result.lookupType)} />
                                </div>
                                <div className="max-w-lg font-bold text-mhdarkgreen-original">
                                    {`${result.name} ${result.company?.name ? `- ${result.company?.name}` : ""}`}
                                    {result.text && (
                                        <div
                                            className={twMerge(
                                                "flex flex-wrap",
                                                result.lookupType === 4 ? "font-bold" : "text-xs font-normal",
                                            )}
                                        >
                                            {`${result.text}${result.tags ? " | " + result.tags.join(", ") : ""}`}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default NewSubscriptionSearch;
