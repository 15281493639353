import { useTranslation } from "react-i18next";

const CookiePolicy = () => {
    const { t } = useTranslation("about");

    return (
        <>
            <div className="about border-b pb-24">
                <h1 className="mb-16">{t("cookiePolicyInfo.heading")}</h1>
                <div className="mb-3 mt-8 flex-col lg:flex">
                    <h2 className="mb-4 lg:pr-10">{t("cookiePolicyInfo.title1")}</h2>
                    <p>{t("cookiePolicyInfo.paragraph1")}</p>
                </div>
                <div className="mb-3 mt-8 flex-col lg:flex">
                    <h2 className="mb-4 lg:pr-10">{t("cookiePolicyInfo.title2")}</h2>
                    <ul className="list-disc pl-6">
                        <li>{t("cookiePolicyInfo.paragraph2")}</li>
                        <br />
                        <li>{t("cookiePolicyInfo.paragraph2-1")}</li>
                    </ul>
                </div>
                <div className="mb-3 mt-8 flex-col lg:flex">
                    <h2 className="mb-4 lg:pr-10">{t("cookiePolicyInfo.title3")}</h2>
                    <p>{t("cookiePolicyInfo.paragraph3")}</p>
                </div>
            </div>
            <p className="mt-6">{t("lastUpdated")}: 2022-03-09</p>
        </>
    );
};

export default CookiePolicy;
