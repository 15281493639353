import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions } from "../../../redux";
import { getUpdateCaseStatusDisabled } from "pages/FollowUp/redux/followUpSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { ModalCommonFooter, ModalFrame, Select } from "@medhelp/ui";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
}

const ChangeCaseStatusModal = (props: IProps) => {
    const { healthCase, modalIsOpen, setModalIsOpen } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const changeStatusDisabled = useAppSelector(getUpdateCaseStatusDisabled(healthCase.id ?? ""));
    const [status, setStatus] = useState({
        value: "",
        description: "",
    });
    const [reason, setReason] = useState({
        value: "",
        description: "",
    });
    const statusDisabledText: string[] = t("statusDisabledText.text", {
        returnObjects: true,
    });

    const saveDisabled = !status.value || !reason.value;

    const selectStatusValues = [
        {
            value: "ongoing",
            description: t("ongoing"),
            disabled: healthCase.status === "ongoing",
        },
        {
            value: "ended",
            description: t("ended"),
            disabled:
                healthCase.status === "ended" ||
                healthCase.status === "paused" ||
                healthCase.status === "declined" ||
                healthCase.status === "expired",
        },
    ];

    const selectReasonValuesOngoing = [
        {
            value: t("reasonsForCaseStatusChange.mistake"),
            description: t("reasonsForCaseStatusChange.mistake"),
        },
        {
            value: t("reasonsForCaseStatusChange.newAssessment"),
            description: t("reasonsForCaseStatusChange.newAssessment"),
        },
        {
            value: t("reasonsForCaseStatusChange.otherReason"),
            description: t("reasonsForCaseStatusChange.otherReason"),
        },
    ];

    const selectReasonValuesEnded = [
        {
            value: t("reasonsForCaseStatusChange.measuresThatHaveBeenDocumented"),
            description: t("reasonsForCaseStatusChange.measuresThatHaveBeenDocumented"),
        },
        {
            value: t("reasonsForCaseStatusChange.measuresThatHaveNotBeenDocumented"),
            description: t("reasonsForCaseStatusChange.measuresThatHaveNotBeenDocumented"),
        },
        {
            value: t("reasonsForCaseStatusChange.documentInOtherCase"),
            description: t("reasonsForCaseStatusChange.documentInOtherCase"),
        },
        {
            value: t("reasonsForCaseStatusChange.startedOnFalseGrounds"),
            description: t("reasonsForCaseStatusChange.startedOnFalseGrounds"),
        },
        {
            value: t("reasonsForCaseStatusChange.otherReason"),
            description: t("reasonsForCaseStatusChange.otherReason"),
        },
    ];

    const handleSaveStatus = () => {
        if (healthCase.id) {
            const statusModel = {
                status: status.value,
                reason: reason.value,
            };
            dispatch(
                thunkActions.updateCaseStatus({
                    healthCaseId: healthCase.id,
                    statusModel,
                }),
            )
                .then(unwrapResult)
                .then(() => toast(t("statusUpdated")))
                .catch((e) =>
                    toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("statusNotUpdated")}`, {
                        type: "error",
                    }),
                );
            setModalIsOpen();
            navigate(`/followup/employee/${healthCase.employmentId}/case`);
        }
    };

    return (
        <ModalFrame
            id="change-case-status-modal"
            header={<h1>{t("changeStatus")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={
                changeStatusDisabled ? (
                    <div className="px-3 sm:px-0">
                        <h4>{t("statusDisabledText.heading")}</h4>
                        <ul className="list-disc p-10">
                            {statusDisabledText.map((text) => (
                                <li key={text}>{text}</li>
                            ))}
                        </ul>
                    </div>
                ) : (
                    <div className="px-3 sm:px-0">
                        <p className="border-b border-mhgrey-light pb-10">{t("changeStatusText")}</p>
                        <div className="my-8 flex">
                            <div className="w-full">
                                <h5 className="mb-2 font-bold">{t("currentStatus")}</h5>
                                <p>{t(healthCase.status ?? "")}</p>
                            </div>
                            <div className="w-full">
                                <h5 className="mb-2 font-bold">{t("newStatusMandatory")}</h5>
                                <Select
                                    placeholder={t("selectStatus")}
                                    selectedValue={status}
                                    onChange={(v) => setStatus(v ?? "")}
                                    options={selectStatusValues}
                                />
                            </div>
                        </div>
                        {status.value && (
                            <>
                                <h5 className="pb-2 font-bold">{t("reasonMandatory")}</h5>
                                <Select
                                    className="h-content min-h-[48px] p-2"
                                    placeholder={t("selectReason")}
                                    selectedValue={reason}
                                    onChange={(v) => setReason(v ?? "")}
                                    options={
                                        status.value === "ongoing" ? selectReasonValuesOngoing : selectReasonValuesEnded
                                    }
                                />
                            </>
                        )}
                    </div>
                )
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("save")}
                    primaryOnClick={handleSaveStatus}
                    primaryDisabled={saveDisabled}
                />
            }
        />
    );
};

export default ChangeCaseStatusModal;
