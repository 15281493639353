import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { Children, useMemo, useState } from "react";

const LAYOUT = {
    childHeading: "childHeading",
    normal: "normal",
    noBackdrop: "no-backdrop",
};
export interface IProps {
    // eslint-disable-next-line @typescript-eslint/ban-types
    children: React.ReactElement<{} & { subMenu?: boolean }>[];
    heading: string;
    onDropdownClick: (index: number) => void;
    activeChild?: number;
    layout?: "childHeading" | "normal" | "no-backdrop";
    subMenu?: boolean;
}
const Primary = ({ children, onDropdownClick, heading, activeChild, subMenu, layout = "childHeading" }: IProps) => {
    const [show, setShow] = useState(false);
    const container = useElementClicked((v) => {
        if (show && !v) {
            setShow(v);
        }
    });
    const RenderElement = useMemo(
        () =>
            Children.map(children, (child, index) => {
                if (child.props.subMenu)
                    return (
                        <div
                            key={child.key}
                            className={`cursor-pointer hover:bg-grey-100 ${
                                index === activeChild ? "bg-grey-100" : "bg-pageBg"
                            }`}
                        >
                            {child}
                        </div>
                    );
                if (layout === LAYOUT.normal) {
                    return (
                        <div
                            key={child.key}
                            className={`cursor-pointer px-8 py-3 hover:bg-grey-100 ${
                                index === activeChild ? "bg-grey-100" : "bg-pageBg"
                            }`}
                            onClick={() => {
                                onDropdownClick(index);
                                setShow(false);
                            }}
                            role="button"
                        >
                            {child}
                        </div>
                    );
                }
                return (
                    index !== activeChild && (
                        <div
                            key={child.key}
                            className={`cursor-pointer p-3 hover:bg-grey-100 ${
                                index === activeChild ? "bg-grey-100" : "bg-pageBg"
                            }`}
                            onClick={(event) => {
                                event.stopPropagation();
                                onDropdownClick(index);
                                setShow(false);
                            }}
                        >
                            {child}
                        </div>
                    )
                );
            }),
        [activeChild, children, layout, onDropdownClick],
    );
    const border = useMemo(() => {
        return LAYOUT.noBackdrop !== layout ? "border-t-grey-500 border-b-grey-500 border" : "";
    }, [layout]);
    return (
        <div
            ref={container}
            className={`"flex flex-1" ${subMenu ? "px-8 py-3" : ""}`}
            onClick={(event) => {
                event.stopPropagation();
                setShow(!show);
            }}
            role="button"
        >
            <div className={`w-full max-w-screen-xxxxl ${border} flex flex-col`}>
                <div
                    className={`flex flex-1 ${
                        LAYOUT.noBackdrop !== layout ? "justify-center p-3" : "items-center justify-start"
                    } cursor-pointer select-none gap-2`}
                >
                    <div>{heading}</div>
                    <div className="flex self-center">
                        {show ? (
                            <Icon icon="caretUp" className="w-3 cursor-pointer fill-none text-center text-black" />
                        ) : (
                            <Icon icon="caretDown" className="w-3 cursor-pointer fill-none text-center text-black" />
                        )}
                    </div>
                </div>
                <div className="relative z-10">
                    <div className={`absolute z-20 w-full ${LAYOUT.noBackdrop !== layout ? "" : "min-w-xs shadow-md"}`}>
                        {show && <>{RenderElement}</>}
                    </div>
                    {show && LAYOUT.noBackdrop !== layout && (
                        <div className="absolute flex h-screen w-full bg-mhgrey-dark bg-opacity-50" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Primary;
