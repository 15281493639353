import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Filter from "../Filter";
import { CheckboxList } from "../List";
import { IDynamicData } from "../List/CheckboxList/types";
import { selectCheckedItemsLabel } from "../List/CheckboxList/utils";
import Checkbox from "../Checkbox";
import Search from "../Search";
import { debounce, isString } from "lodash/fp";
import { isAllSelected } from "domain/ogranization/dynamicData";

export type IViewElements = {
    selectAll?: string;
    search?: boolean;
    checkboxList?: boolean;
};

interface IProps {
    setData: (value: string) => void;
    // Dynamic change based on
    data: IDynamicData[];
    overflowText?: string | JSX.Element;
    numberOfHiddenElements?: (numberOfHiddenElements: number) => void;
    viewElements?: IViewElements;
    hideOnClickOutside?: boolean;
    keepClose?: boolean;
    loading?: boolean;
    disabled?: boolean;
}

const CheckboxFilter = ({
    setData,
    data,
    overflowText,
    numberOfHiddenElements,
    hideOnClickOutside = false,
    viewElements = { selectAll: undefined, search: true, checkboxList: true },
    keepClose = false,
    loading,
    disabled,
}: IProps) => {
    const [open, setOpenState] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [searchParams, setSearchParams] = useState("");

    const setOpen = useCallback(
        (value: boolean) => {
            if (keepClose) return;
            setOpenState(value);
        },
        [keepClose],
    );
    const ViewElements = useMemo(
        () => ({
            selectAll: viewElements.selectAll,
            search: viewElements.search ?? true,
            checkboxList: viewElements.checkboxList ?? true,
        }),
        [viewElements],
    );

    const onClickOutside = useCallback(
        (value: boolean) => {
            if (!hideOnClickOutside) return;
            if (!value && open) {
                setOpen(false);
            }
        },
        [hideOnClickOutside, open, setOpen],
    );

    const removeTag = useCallback(
        (referenceKey: string | number) => {
            setData(referenceKey.toString());
        },
        [setData],
    );

    const selectAllFilter = useCallback(() => {
        const currentSelected = !allSelected;
        setAllSelected(currentSelected);
        setData(currentSelected ? "select" : "deselect");
    }, [allSelected, setData]);

    const onSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchParams(event.target.value);
    }, []);

    const DebounceSearch = useMemo(() => {
        const debounceSearchQuery = debounce(100, onSearch);
        return debounceSearchQuery;
    }, [onSearch]);

    const SelectAll = useMemo(
        () => (
            <div
                className="ml-2 flex py-[10px] md:py-1"
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    selectAllFilter();
                }}
            >
                <div className="flex items-center justify-center">
                    <Checkbox checked={allSelected} onChange={() => {}} name="selectall" alt="selectAll" />
                </div>
                <div className="pl-2 font-bold">{ViewElements.selectAll || ""}</div>
            </div>
        ),
        [ViewElements.selectAll, allSelected, selectAllFilter],
    );

    const SearchContainer = useMemo(() => <Search onChange={DebounceSearch} placeholder="" />, [DebounceSearch]);

    const Items = useMemo(
        () => (
            <>
                {ViewElements.search && SearchContainer}
                {isString(ViewElements.selectAll) && SelectAll}
                {ViewElements.checkboxList && (
                    <CheckboxList data={data} setData={setData} searchParams={searchParams} />
                )}
            </>
        ),
        [
            ViewElements.search,
            ViewElements.selectAll,
            ViewElements.checkboxList,
            SearchContainer,
            SelectAll,
            data,
            setData,
            searchParams,
        ],
    );

    const Tags = useMemo(() => selectCheckedItemsLabel(data, []), [data]);

    useEffect(() => {
        setAllSelected(isAllSelected(data));
    }, [data]);

    return (
        <Filter
            open={open}
            setOpen={() => setOpen(!open)}
            items={Items}
            tags={Tags}
            removeTag={removeTag}
            overflowText={overflowText}
            numberOfHiddenElements={numberOfHiddenElements}
            onClickOutside={onClickOutside}
            keepClose={keepClose}
            loading={loading}
            disabled={disabled}
        />
    );
};
export default CheckboxFilter;
