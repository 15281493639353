import { ReactElement } from "react";

interface ITooltip {
    heading?: string;
    text?: string;
    className?: string;
    children?: ReactElement;
}

const Tooltip = ({ heading, text, className = "w-72", children }: ITooltip) => (
    <div className={`absolute z-20 bg-white p-4 text-sm text-primaryText shadow-md ${className}`}>
        {heading && <div className="mb-4 font-bold">{heading}</div>}
        {text && <div>{text}</div>}
        {children}
    </div>
);

export default Tooltip;
