import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { thunkActions as absenceThunkActions } from "pages/Absence/pages/absencereporting/redux";
import { thunkActions as medicalThunkActions } from "@medhelp/medical-certificate/redux";
import { thunkActions as followUpThunkActions, actions } from "../../redux";
import {
    getCurrentUser,
    getHasHealthCaseManagement,
    getHasAccessToEmployeeRehab,
    getUserEmploymentId,
    getIsLoadingRehabAccess,
    getUserCompanyId,
} from "../../redux/followUpSelectors";
import { getHasBaseProduct, getIsRehabProcessManagement, getUserContext } from "store/userSelectors";
import EmployeeCards from "../../components/EmployeeCards";
import EmployeeMenu from "../../components/EmployeeMenu";
import FollowUpNewsModal from "../../components/FollowUpNewsModal";
import EmployeeHeader from "../../components/EmployeeHeader";
import { SpinnerPage } from "components/Spinner";

const Employee = () => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const { routeEmploymentGuid } = useParams();
    const userContext = useAppSelector(getUserContext);
    const currentUser = useAppSelector(getCurrentUser);
    const selectedEmploymentId = useAppSelector(getUserEmploymentId);
    const selectedCompanyId = useAppSelector(getUserCompanyId);
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const isRehabProcessManagement = useAppSelector(getIsRehabProcessManagement);
    const hasAccessToEmployeeCases = useAppSelector(getHasAccessToEmployeeRehab);
    const isLoading = useAppSelector(getIsLoadingRehabAccess);
    const hasBaseProduct = useAppSelector(getHasBaseProduct);

    useEffect(() => {
        if (routeEmploymentGuid && routeEmploymentGuid !== currentUser) {
            dispatch(actions.resetEmploymentCard());
            dispatch(actions.updateCurrentUser(routeEmploymentGuid));
            dispatch(absenceThunkActions.getAbsenceReportEmploymentByGuid(routeEmploymentGuid));
            dispatch(
                medicalThunkActions.getFiles({
                    employmentGuid: routeEmploymentGuid,
                }),
            );
            dispatch(
                followUpThunkActions.fetchAbsenceOverview({
                    employmentGuid: routeEmploymentGuid,
                }),
            );
            dispatch(followUpThunkActions.fetchEmployment(routeEmploymentGuid));

            if (!hasBaseProduct) {
                dispatch(
                    followUpThunkActions.fetchFirstDayMedicalCertificate({
                        employmentGuid: routeEmploymentGuid,
                    }),
                );
            }
        }
        if (selectedEmploymentId && hasAccessToEmployeeCases) {
            dispatch(followUpThunkActions.fetchRehab(selectedEmploymentId));
        }
        if (hasAccessToEmployeeCases && routeEmploymentGuid) {
            dispatch(
                followUpThunkActions.fetchHealthCaseList({
                    employmentId: routeEmploymentGuid,
                    language: userContext.user?.userPreferences?.language,
                }),
            );
        }
    }, [
        dispatch,
        routeEmploymentGuid,
        userContext,
        currentUser,
        hasAccessToEmployeeCases,
        selectedEmploymentId,
        hasBaseProduct,
    ]);

    useEffect(() => {
        if (selectedCompanyId && isRehabProcessManagement) {
            dispatch(
                followUpThunkActions.fetchOrganizaionTreeView({
                    companyId: selectedCompanyId,
                    typeOfSearch: "rehab",
                }),
            );
        }
    }, [selectedCompanyId, dispatch, isRehabProcessManagement]);

    useEffect(
        () => () => {
            dispatch(actions.resetEmploymentCard());
        },
        [dispatch],
    );

    if (isLoading) {
        return <SpinnerPage />;
    }

    return (
        <div className="flex flex-col gap-3">
            <div className="mt-3 flex flex-col px-4 xl:px-0">
                <EmployeeHeader />
                <EmployeeCards />
            </div>
            <div className="mt-16">
                <EmployeeMenu />
            </div>
            {hasHealthCaseManagement && isRehabProcessManagement && (
                <FollowUpNewsModal
                    header={t("news.header")}
                    body={t("news.body")}
                    buttonText={t("news.buttonText")}
                    storage="hide-followup-employee-news"
                />
            )}
        </div>
    );
};

export default Employee;
