import { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoints } from "hooks";
import { Status, thunkActions } from "../../redux";
import {
    getHasRehabServiceContract,
    getHasHealthCaseManagement,
    getCaseTemplatesStatus,
    getRehabServiceContractStatus,
    getUserCompanyId,
} from "../../redux/followUpSelectors";
import { getUserLanguage, getCompanyId, getCustomerId } from "store/userSelectors";
import { getIsRehabProcessManagement } from "store/userSelectors";
import { Shadow } from "@medhelp/ui";
import { useNavigate } from "react-router-dom";
import TabMenu from "components/TabMenu";
import DropdownMenu from "components/DropdownMenu";
import { isEmpty } from "lodash/fp";
import {
    AllFollowUpAccess,
    CompanyAdministrationAccessRights,
    RehabProcessManagementAccessRights,
} from "../../accessRights";
import { hasFollowUpSearch } from "routes/hooks";
import { SpinnerPage } from "components/Spinner";
import { useAppDispatch, useAppSelector } from "store/hooks";

const FollowUpTopLinks = () => {
    const { t } = useTranslation("followup");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isXLargeScreen } = useBreakpoints();
    const userLanguage = useAppSelector(getUserLanguage);
    const companyId = useAppSelector(getCompanyId);
    const customerId = useAppSelector(getCustomerId);
    const userCompanyId = useAppSelector(getUserCompanyId);
    const hasRehabServiceContract = useAppSelector(getHasRehabServiceContract);
    const hasHealthCaseManagement = useAppSelector(getHasHealthCaseManagement);
    const isRehabProcessManagement = useAppSelector(getIsRehabProcessManagement);
    const rehabContractStatus = useAppSelector(getRehabServiceContractStatus);
    const caseTemplatesStatus = useAppSelector(getCaseTemplatesStatus);

    useEffect(() => {
        if (companyId) {
            dispatch(thunkActions.fetchRehabServiceContract(userCompanyId ?? companyId));
        }
        if (customerId && isRehabProcessManagement) {
            dispatch(
                thunkActions.fetchCaseTemplateList({
                    customerId: customerId,
                    companyId: userCompanyId ?? undefined,
                    language: userLanguage,
                }),
            );
        }
    }, [companyId, customerId, dispatch, isRehabProcessManagement, userCompanyId, userLanguage]);

    const Routes = useMemo(
        () => [
            {
                route: `followup/search`,
                name: "test",
                indexRoute: "reports",
                whitelist: [...AllFollowUpAccess],
                children: [
                    {
                        route: "reports",
                        name: t("followUpPage.toplinks.searchAbsence"),
                        whitelist: [...AllFollowUpAccess],
                        children: [],
                    },
                    {
                        route: "employee",
                        name: t("followUpPage.toplinks.searchEmployees"),
                        whitelist: [...AllFollowUpAccess],
                        children: [],
                    },
                    {
                        route: "absenceCostCalculation",
                        name: t("followUpPage.toplinks.costCalculation"),
                        whitelist: [...CompanyAdministrationAccessRights],
                        children: [],
                    },
                    {
                        route: "resources",
                        name: t("followUpPage.toplinks.resources"),
                        whitelist: [...RehabProcessManagementAccessRights],
                        children: [],
                        hidden: !hasRehabServiceContract || !hasHealthCaseManagement,
                    },
                ],
            },
        ],
        [hasHealthCaseManagement, hasRehabServiceContract, t],
    );
    const Render = useMemo(
        () => (
            <div>
                <Shadow />
                {isXLargeScreen ? (
                    <DropdownMenu routes={Routes} subMenuTo={`followup/search`} header={t("followup")} />
                ) : (
                    <TabMenu
                        routes={Routes}
                        subMenuTo={`followup/search`}
                        externalRoute={hasFollowUpSearch() ? undefined : "none"}
                    />
                )}
            </div>
        ),
        [isXLargeScreen, Routes, t],
    );

    if (isEmpty(Routes)) {
        navigate("/followup");
        return null;
    }

    if (
        rehabContractStatus === Status.PENDING ||
        (caseTemplatesStatus === Status.PENDING && isRehabProcessManagement)
    ) {
        return <SpinnerPage />;
    }

    return Render;
};
export default FollowUpTopLinks;
