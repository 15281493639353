interface IInputProps {
    id?: string;
    containerClassName?: string;
    className?: string;
    heading?: string;
    inputValue?: string;
    inputChanged: (inputValue: string) => void;
    errorMessage?: string;
    errorClassName?: string;
    placeholder?: string;
    disabled?: boolean;
}

const Input = (props: IInputProps) => {
    const {
        id,
        containerClassName,
        className,
        heading,
        inputValue,
        inputChanged,
        errorMessage,
        errorClassName,
        placeholder,
        disabled,
    } = props;

    return (
        <div id={id} className={`w-full ${disabled ? "text-grey-500" : ""} ${containerClassName}`}>
            {heading && <p className="mb-2 font-bold">{heading}</p>}
            <input
                data-cy={`input-${id}`}
                type="text"
                className={`w-full whitespace-pre-wrap border pl-2 ${
                    errorMessage ? "border-mhred-alert1" : "border-mhdarkgreen-original"
                } ${disabled ? "border-grey-500 text-grey-500" : "bg-pageBg text-black"} ${className}`}
                value={inputValue}
                onChange={(e) => inputChanged(e.target.value)}
                placeholder={placeholder}
                disabled={disabled}
            />
            <p className={`mt-0.5 text-mhred-alert1 ${errorClassName}`}>{errorMessage}</p>
        </div>
    );
};

export default Input;
