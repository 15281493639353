import React from "react";
import { useAppSelector } from "store/hooks";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import * as AbsenceReportsSelectors from "../../redux/absenceReportsSelectors";

interface IProps {
    id: string;
    preset: IAbsenceReportFirstDayMedicalCertificate;
    className?: string;
    heading: string;
    content: string;
    children?: React.ReactNode;
}

const absenceReportFirstDayMedicalCertificatePresets = {
    background: "bg-pageBg",
    white: "bg-white",
};

export type IAbsenceReportFirstDayMedicalCertificate = keyof typeof absenceReportFirstDayMedicalCertificatePresets;

const AbsenceReportFirstDayMedicalCertificate = ({ children, id, preset, className, heading, content }: IProps) => {
    const firstDayMedicalCertificateVisible = useAppSelector(
        AbsenceReportsSelectors.isFirstDayMedicalCertificateVisible,
    );
    if (!firstDayMedicalCertificateVisible) return null;
    return (
        <div className="my-10 xxs:mt-0">
            <div
                id={id}
                className={`flex border border-background-300 ${absenceReportFirstDayMedicalCertificatePresets[preset]} ${className}`}
            >
                <div className="h-auto bg-mhred-alert2 bg-opacity-25 px-3 pt-9 xxs:px-8">
                    <WarningIcon className="w-6 text-black" />
                </div>
                <div className="p-8 pl-6">
                    <h2 className="mb-3">{heading}</h2>
                    <p>{content}</p>
                </div>
                {children}
            </div>
        </div>
    );
};
export default AbsenceReportFirstDayMedicalCertificate;
