import { Card, CardAlign } from "@medhelp/ui";

const OngoingCasesLoading = () => {
    const rows = () => {
        const rows = [];
        for (let index = 0; index < 5; index++) {
            rows.push(
                <div className="flex h-12 w-56 flex-col justify-center px-8" key={index}>
                    <div className="flex gap-2">
                        <div className="h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </div>,
            );
        }
        return rows;
    };

    return (
        <Card testId="hcm-loading" align={CardAlign.start} className="mb-8 animate-pulse p-0">
            <div className="w-full">
                <div className={`flex h-19 w-full items-center border-b-1 border-solid border-grey-200 px-8`}>
                    <div className="h-2.5 w-56 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="ml-5 h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div className={"flex flex-wrap items-start pb-8 pt-6"}>{rows()}</div>
            </div>
        </Card>
    );
};

export default OngoingCasesLoading;
