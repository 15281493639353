const AbsenceTrendWidgetLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="chart-selector flex flex-wrap px-6 py-4 text-sm">
                <div className="flex w-1/3 items-center">
                    <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div className="flex w-1/3 items-center">
                    <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div className="flex w-1/3 items-center">
                    <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
                <div className="flex w-1/3 items-center">
                    <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
            </div>
            <div className="chart-container">
                <div className="chart-selector flex flex-wrap px-6 py-4 text-sm">
                    <div className="flex w-1/4 items-end">
                        <div className="h-20 w-14 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/4 items-end">
                        <div className="h-36 w-14 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/4 items-end">
                        <div className="h-15 w-14 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/4 items-end">
                        <div className="h-2.5 w-14 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AbsenceTrendWidgetLoader;
