import { ReactNode } from "react";
import IconModal from "components/IconModal";

interface AbsenceReportFieldWrapperProps {
    heading: string;
    isMandatory: boolean;
    modalHeading?: string;
    modalTopContent?: string | ReactNode;
    modalBottomContent?: string;
}

const AbsenceReportFieldWrapper = ({
    children,
    heading,
    isMandatory,
    modalHeading,
    modalTopContent,
    modalBottomContent,
}: AbsenceReportFieldWrapperProps & { children?: ReactNode }) => (
    <div className="w-full pt-10 xs:w-auto">
        <div className="flex pb-5">
            <p className="font-bold">{heading}</p>
            <IconModal
                id={`${heading}IconModal`}
                heading={modalHeading || heading}
                topContent={modalTopContent!}
                bottomContent={modalBottomContent}
                isMandatory={isMandatory}
            />
        </div>
        {children}
    </div>
);

export default AbsenceReportFieldWrapper;
