import { useState } from "react";
import { ReactComponent as SweFlagIcon } from "icons/login/sweFlag.svg";
import { ReactComponent as DenFlagIcon } from "icons/login/denFlag.svg";
import Select from "@medhelp/ui/Select";
import PrimaryButton from "components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import LoginContainer from "pages/login/components/LoginContainer";
import { useAppDispatch } from "store/hooks";
import { loginActions } from "pages/login/redux/loginSlice";
import { useSearchParams } from "react-router-dom";
import { ISelectValue } from "@medhelp/ui/Select/Select";

const GlobalMarket = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const [selectedValue, setSelectedValue] = useState<ISelectValue>();

    const returnUrl = searchParams.get("returnUrl") ? searchParams.get("returnUrl") : undefined;

    const marketOptions: ISelectValue[] = [
        {
            description: "Sweden",
            value: "sv",
            icon: <SweFlagIcon className="h-4 w-4" />,
        },
        {
            description: "Denmark",
            value: "dk",
            icon: <DenFlagIcon className="h-4" />,
        },
    ];

    const handleContinueClick = (): void => {
        dispatch(loginActions.setMarket(selectedValue?.value!));
        if (returnUrl) navigate(`./${selectedValue?.value}?returnUrl=${returnUrl}`);
        else navigate(`./${selectedValue?.value}`);
    };

    return (
        <LoginContainer id="globalMarketPage" selectedMarket="global" selectedLanguage="en">
            <h1 className="mb-10 mt-40">Select country to log in</h1>
            <Select
                options={marketOptions}
                selectedValue={selectedValue!}
                placeholder="Select country"
                onChange={(v) => setSelectedValue(v)}
                containerClassName="mb-8"
            />

            <PrimaryButton
                id="global-market-login"
                text="Continue"
                className="flex h-12 justify-center"
                disabled={!selectedValue}
                onClick={handleContinueClick}
            />
        </LoginContainer>
    );
};

export default GlobalMarket;
