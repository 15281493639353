import Cookies from "js-cookie";
import { createServicesUsingToken, getServices } from "api/services";
import { getCookieOptions } from "pages/login/utils";
import { UserContext } from "swagger/usercontextservice";

interface userActionsNewTokenUtilChangeAccountProps {
    userAccountId: number;
    thunkApi: any;
    token: string | null;
}

interface userActionsNewTokenUtilChangeAccountPropsResponse {
    requestStatusIfNewToken: number | null;
    userDataIfNewToken: UserContext | null;
    tokenIfNewToken: string | null;
}

const userActionsNewTokenUtilChangeAccount = async ({
    userAccountId,
    thunkApi,
    token,
}: userActionsNewTokenUtilChangeAccountProps): Promise<userActionsNewTokenUtilChangeAccountPropsResponse> => {
    if (localStorage.getItem("newJwtToken") === null) {
        localStorage.setItem("newJwtToken", "false");
        localStorage.setItem("multipleCompany", "false");
    }

    if (localStorage && localStorage.getItem("newJwtToken") === "true" && token) {
        const getServicesExist = getServices && getServices();
        if (!getServicesExist) createServicesUsingToken(token.toString());

        // @ts-expect-error: Let's ignore a compile error like this unreachable code
        const { status: statusNewToken, data: newToken } =
            userAccountId && (await getServices().clients.jwt.jwtUserAccountIdGet(userAccountId));
        if (statusNewToken !== 200) return thunkApi.rejectWithValue("rejected newToken");

        if (newToken) {
            // Some test users might not have correct data like roles on them
            try {
                getServices().setServicesJwtToken(newToken);
                Cookies.set("mhToken", newToken, getCookieOptions());
                sessionStorage.setItem("mhToken", newToken);
                Cookies.set("newToken", newToken, getCookieOptions());
                const { status: statusGetUserFromJwtToken, data: userContextFromGetUserFromJwtToken } =
                    await getServices().clients.user.getUserFromJwtToken();
                if (statusGetUserFromJwtToken !== 200)
                    return thunkApi.rejectWithValue("rejected getUserFromJwtToken with newToken");
                return Promise.resolve({
                    requestStatusIfNewToken: statusGetUserFromJwtToken,
                    userDataIfNewToken: userContextFromGetUserFromJwtToken,
                    tokenIfNewToken: newToken,
                });
            } catch (error) {
                if (typeof error === "string") {
                    return thunkApi.rejectWithValue("rejected getUserFromJwtToken with newToken", error.toUpperCase());
                } else if (error instanceof Error) {
                    return thunkApi.rejectWithValue("rejected getUserFromJwtToken with newToken", error.message);
                }
            }
        }
    }
    return Promise.resolve({
        requestStatusIfNewToken: null,
        userDataIfNewToken: null,
        tokenIfNewToken: null,
    });
};

export default userActionsNewTokenUtilChangeAccount;
