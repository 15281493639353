import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { selectors } from "pages/FollowUp/redux";
import TertiaryButton from "components/TertiaryButton";
import SecondaryButton from "components/SecondaryButton";
import { ReactComponent as AccordionArrowDown } from "icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "icons/accordionArrowUp.svg";

interface IProps {
    expanded: boolean;
    setExpanded: (value: boolean) => void;
    // We dont want to show the userProfile form for now, waiting if the feature should be toggle on or not
    isActiveFeature: boolean;
}
const UserProfileFormHeader = ({ expanded, setExpanded, isActiveFeature }: IProps) => {
    const { t } = useTranslation(["followup", "translation"]);
    const userEmployment = useAppSelector(selectors.getUserEmployment);
    return (
        <>
            <div className="flex flex-col">
                <h2 className="mb-7">{t("profilePersonalAndContact")}</h2>
                <div className="flex flex-col gap-2">
                    <p>
                        {t("name")}: <b>{userEmployment?.name}</b>
                    </p>
                    <p>
                        {t("personalNumber")}: <b>{userEmployment?.socialSecurityNumber}</b>
                    </p>
                    <p>
                        {t("employmentNumber")}: <b>{userEmployment?.employmentNumber}</b>
                    </p>
                    {!expanded && (
                        <>
                            <p>
                                {t("email")}: <b>{userEmployment?.contactInformation?.email || ""}</b>
                            </p>
                            <p>
                                {t("mobileNumberPrivate")}: <b>{userEmployment?.contactInformation?.mobile || ""}</b>
                            </p>
                            <p>
                                {t("mobileNumberWork")}:{" "}
                                <b>{userEmployment?.contactInformation?.secondaryMobile || ""}</b>
                            </p>
                            <p>
                                {t("address")}: <b>{userEmployment?.contactInformation?.homeAddress || ""}</b>
                            </p>
                        </>
                    )}
                </div>
            </div>
            {isActiveFeature && (
                <div className="flex">
                    <div
                        className={`flex-col gap-2 px-0 xs:flex-row xs:gap-0 xs:px-8 ${
                            expanded ? "hidden sm:flex" : "hidden"
                        }`}
                    >
                        <TertiaryButton
                            id="on-profile-form-followup-cancel"
                            text={t("abort", { ns: "translation" })}
                            onClick={() => {
                                setExpanded(false);
                            }}
                            className="mr-2"
                        />
                        <SecondaryButton
                            id="on-profile-form-followup-save"
                            type="submit"
                            text={t("save", { ns: "translation" })}
                            onClick={() => {
                                setExpanded(false);
                            }}
                        />
                    </div>

                    <div className="flex h-8 w-8 items-center justify-center">
                        {expanded ? (
                            <AccordionArrowUp
                                onClick={() => {
                                    setExpanded(false);
                                }}
                            />
                        ) : (
                            <AccordionArrowDown />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
export default UserProfileFormHeader;
