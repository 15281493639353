import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import { isEmpty } from "lodash/fp";
import { ConfirmStartCaseModal, StartCaseModal } from "..";
import { Card } from "@medhelp/ui";
import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import {
    getHasManualInitiationByType,
    getHasOngoingCaseByType,
    getOngoingCases,
    getStartNewCaseIsDisabled,
} from "pages/FollowUp/redux/followUpSelectors";

const NewCase = () => {
    const { t } = useTranslation("followup");
    const ongoingCases = useAppSelector(getOngoingCases);
    const startNewCaseIsDisabled = useAppSelector(getStartNewCaseIsDisabled);
    const [startCaseModalIsOpen, setStartCaseModalIsOpen] = useState(false);
    const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
    const hasManualRehabCaseInitiation = useAppSelector(getHasManualInitiationByType("rehab"));
    const hasOngoingRehabCase = useAppSelector(getHasOngoingCaseByType("rehab"));

    const handleOnNewCase = () => {
        if (hasOngoingRehabCase && !hasManualRehabCaseInitiation) {
            setConfirmationModalIsOpen(true);
        } else {
            setStartCaseModalIsOpen(true);
        }
    };

    return (
        <Card>
            {isEmpty(ongoingCases) ? (
                <PrimaryButton
                    id="new-health-case-button"
                    text={t("newCase")}
                    onClick={() => handleOnNewCase()}
                    className="flex w-full justify-center sm:w-65"
                />
            ) : (
                <SecondaryButton
                    id="new-health-case-button"
                    text={t("newCase")}
                    onClick={() => handleOnNewCase()}
                    disabled={startNewCaseIsDisabled}
                    className="flex w-full justify-center sm:w-65"
                />
            )}
            {confirmationModalIsOpen && (
                <ConfirmStartCaseModal
                    modalIsOpen={confirmationModalIsOpen}
                    handleStartNewCaseButtonClick={() => {
                        setConfirmationModalIsOpen(false);
                        setStartCaseModalIsOpen(true);
                    }}
                    handleOnCancel={() => setConfirmationModalIsOpen(false)}
                />
            )}
            {startCaseModalIsOpen && (
                <StartCaseModal modalIsOpen={startCaseModalIsOpen} setModalIsOpen={setStartCaseModalIsOpen} />
            )}
        </Card>
    );
};

export default NewCase;
