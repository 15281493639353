const CaseActivitiesLoading = () => {
    return (
        <div className="flex h-20 animate-pulse items-start justify-between border-t border-grey-300 px-8 py-5">
            <div className="flex">
                <div className="mr-8 h-10 w-14 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div>
                    <div className="h-2.5 w-56 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="mt-2 h-4 w-44 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
            </div>
            <div className="flex h-14 py-4">
                <div className="mr-8 h-2.5 w-26 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="h-2.5 w-8 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </div>
        </div>
    );
};

export default CaseActivitiesLoading;
