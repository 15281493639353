import * as React from "react";
import { useTranslation } from "react-i18next";

interface TableCell {
    subscribe: JSX.Element;
    careOfChild: JSX.Element;
    sick: JSX.Element;
    deviant: JSX.Element;
    rehab: JSX.Element;
}

interface IProps {
    subscribe: string;
    data: TableCell[];
}
const SubscribeTale = ({ data, subscribe }: IProps) => {
    const { t } = useTranslation();
    return (
        <table className="table-auto">
            <thead>
                <tr className="border-b-solid border-b-1 text-left">
                    <th className="max-w-[100px] py-6 text-xs sm:min-w-[100px] sm:max-w-none sm:text-sm">
                        {subscribe}
                    </th>
                    <th className="text-center text-xs sm:text-sm">{t("sick")}</th>
                    <th className="text-center text-xs sm:text-sm">{t("onlyVabText")}</th>
                    <th className="text-center text-xs sm:text-sm">{t("deviant")}</th>
                    <th className="text-center text-xs sm:text-sm">{t("rehab")}</th>
                </tr>
            </thead>
            <tbody>
                {data.map(({ subscribe, sick, careOfChild, deviant, rehab }) => (
                    <tr className="border-b-solid border-b-1 text-left">
                        <td className="max-w-[100px] py-6 sm:min-w-[100px] sm:max-w-none">{subscribe}</td>
                        <td>{sick}</td>
                        <td>{careOfChild}</td>
                        <td>{deviant}</td>
                        <td>{rehab}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
export default SubscribeTale;
