import { useTranslation } from "react-i18next";

const OngoingRehabWidgetLoader = () => {
    const { t } = useTranslation("dashboard");
    return (
        <div className="flex h-full animate-pulse justify-center pb-10 pt-3">
            <ul className="flex w-full flex-col px-6 text-sm">
                <li className="flex h-10 items-center justify-between pb-2 font-bold">
                    <div>{t("reasonsForRehab")}</div>
                    <div>{t("noOfProcesses")}</div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-26 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div>
                        <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-56 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div>
                        <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-42 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div>
                        <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div>
                        <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default OngoingRehabWidgetLoader;
