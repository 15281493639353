import { ChartTypeRegistry } from "chart.js";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as MySelection } from "icons/widget/checkboxMySelection.svg";
import { ModalFrame } from "@medhelp/ui";
import RecurringAbsencesTable from "../Tables/RecurringAbsencesTable";
import { IEmployeeDetails } from "pages/dashboard/utils/dashboardTypes";

interface IBarChartData {
    name: string;
    value: string | number;
    modalHeading?: string;
    tooltipHeading?: string;
    tooltipText?: string;
    employeeDetails?: IEmployeeDetails[];
}
interface IBarChart {
    id: string;
    mySelection: string;
    secondaryColor: string;
    data: IBarChartData[];
    xAxisLabel?: string;
    yAxisLabel?: string;
    selectedNumberOfMonths: number;
}
interface IChartConfig {
    type: keyof ChartTypeRegistry;
    data: any;
    options: any;
}

const StaticBarChart = ({
    mySelection,
    secondaryColor,
    data,
    xAxisLabel,
    yAxisLabel,
    selectedNumberOfMonths,
}: IBarChart) => {
    const chartContainer = useRef(null);
    const { t } = useTranslation("dashboard");
    const [openModal, setOpenModal] = useState(false);
    const [employees, setEmployees] = useState<IEmployeeDetails[] | null>(null);
    const [heading, setHeading] = useState("");
    let newChartInstance: any;

    const showData = (event: any) => {
        const activePoints = newChartInstance?.getElementsAtEventForMode(event, "point", newChartInstance?.options);
        if (activePoints.length > 0) {
            const label = newChartInstance?.data?.xAxisLabels[activePoints[0]?.index];
            const barData = data.filter((x: any) => t(x.name) === label)[0];
            setEmployees(barData?.employeeDetails!);
            setHeading(barData?.modalHeading || "");
            setOpenModal(true);
        }
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const barThickness = () => {
        if (data.map((x) => x.value).length < 4) return 64;
        if (data.map((x) => x.value).length === 4) return 48;
        if (data.map((x) => x.value).length > 5) return 32;
        return 38;
    };

    const chartConfig: IChartConfig = {
        type: "bar",
        data: {
            labels: data.map((x) => x.tooltipHeading),
            xAxisLabels: data.map((x) => t(x.name)),
            datasets: [
                {
                    backgroundColor: secondaryColor,
                    data: data.map((x) => x.value),
                    barThickness,
                },
            ],
        },
        options: {
            interaction: { mode: "index" },
            onHover: function (e: any) {
                const points = this.getElementsAtEventForMode(e, "index", { axis: "x", intersect: true }, false);
                if (points.length) e.native.target.style.cursor = "pointer";
                else e.native.target.style.cursor = "default";
            },
            animation: {
                duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                tooltip: {
                    displayColors: false,
                    caretSize: 0,
                    mode: "x",
                    intersect: false,
                    titleMarginBottom: 0,
                    titleColor: "#192D05",
                    bodyColor: "#192D05",
                    backgroundColor: "#FFFFFF",
                    cornerRadius: 0,
                    padding: 18,
                    bodyFont: {
                        size: 12,
                    },
                    titleFont: {
                        size: 12,
                    },
                    callbacks: {
                        label: (i: any) => {
                            const tooltipText = data.map((x) => x.tooltipText || "");
                            if (tooltipText) {
                                return tooltipText[i.dataIndex].split(/((?:.*?\s){7}.*?)\s/g);
                            }
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        callback: (i: any) => data.map((x) => t(x.name))[i],
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        precision: 0,
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        lineWidth: 0.5,
                        drawBorder: false,
                        color: "#8F957F",
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            newChartInstance = new Chart(chartContainer.current, chartConfig);
            return () => newChartInstance.destroy();
        }
    }, [chartContainer, data, openModal, showData]);

    return (
        <div>
            <div className={`flex items-center px-6 pt-3 ${xAxisLabel || yAxisLabel ? "h-11" : "h-19"}`}>
                <div className="w-4">
                    <MySelection fill={secondaryColor} />
                </div>
                <p className="p-2 text-xs">{mySelection}</p>
            </div>
            <div>
                <div className="chart-container">
                    {yAxisLabel && <p className="chart-axis-label px-2 pb-2 font-bold">{yAxisLabel}</p>}
                    <canvas onClick={showData} ref={chartContainer} />
                    {xAxisLabel && <p className="chart-axis-label px-6 text-center font-bold">{xAxisLabel}</p>}
                </div>
            </div>
            {data?.length > 0 && (
                <ModalFrame
                    id="widgetEmployeeModal"
                    open={openModal}
                    setOpen={closeModal}
                    header={<h3>{heading}</h3>}
                    preset="full"
                    content={
                        <RecurringAbsencesTable
                            data={employees || []}
                            selectedNumberOfMonths={selectedNumberOfMonths}
                        />
                    }
                />
            )}
        </div>
    );
};

export default StaticBarChart;
