import { useTranslation } from "react-i18next";
import { useState, ChangeEvent } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useDetectOutsideClick } from "hooks";
import { thunkActions } from "../../../redux";
import { getHasCustomerSupport, getUserRetailer } from "store/userSelectors";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { activeCaseTypes, getTagProps } from "../../../utils/case";
import { PageTypes } from "../OngoingCaseItem/OngoingCaseItem";
import { StatusTag } from "@medhelp/ui/Tag";
import { toast } from "react-toastify";
import {
    CaseActivityModal,
    CaseNoteModal,
    CaseTemplateModal,
    CaseResponsibleModal,
    RemoveCaseModal,
    ExportCaseModal,
    ChangeCaseStatusModal,
} from "..";
import ExcelUploadedModal from "../../ExcelUploaded";
import Icon, { Icons } from "components/Icon";
import PrimaryButton from "components/PrimaryButton";

interface IActionButtonValues {
    text: string;
    icon: Icons;
    iconColor: string;
    key: string;
    visible: boolean;
    onClick?: () => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface IProps {
    healthCase: IHealthCase;
    expanded: boolean;
    onClick?: () => void;
    setPage?: (page: PageTypes) => void;
    isPreliminary?: boolean;
}

const CaseTitle = (props: IProps) => {
    const { healthCase, expanded, onClick, setPage, isPreliminary } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const { isOutside: open, setIsOutside: setOpen, setContainer } = useDetectOutsideClick(false, false);
    const hasCustomerSupport = useAppSelector(getHasCustomerSupport);
    const userRetailer = useAppSelector(getUserRetailer);
    const isActiveCase = activeCaseTypes.includes(healthCase.status ?? "");
    const [activityModalIsOpen, setActivityModalIsOpen] = useState(false);
    const [noteModalIsOpen, setNoteModalIsOpen] = useState(false);
    const [templateModalIsOpen, setTemplateModalIsOpen] = useState(false);
    const [responsibleModalIsOpen, setResponsibleModalIsOpen] = useState(false);
    const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);
    const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
    const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
    const [exportStarted, setExportStarted] = useState(false);

    const isCorrectFileType = (type: string) =>
        type === "application/pdf" ||
        type === " application/msword" ||
        type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    const actionButtonValues: IActionButtonValues[] = [
        {
            text: t("addActivity"),
            icon: "calendar",
            iconColor: "#192D05",
            key: `case-title-activity-action-${healthCase?.id}`,
            visible: isActiveCase,
            onClick: () => setActivityModalIsOpen(true),
        },
        {
            text: t("addNote"),
            icon: "notes",
            iconColor: "#192D05",
            key: `case-title-notes-action-${healthCase?.id}`,
            visible: isActiveCase,
            onClick: () => setNoteModalIsOpen(true),
        },
        {
            text: t("uploadDocument"),
            icon: "document",
            iconColor: "#192D05",
            key: `case-title-document-action-${healthCase?.id}`,
            visible: isActiveCase,
            onChange: (event) => {
                if (event.target.files && healthCase.id) {
                    if (isCorrectFileType(event.target.files[0].type)) {
                        setPage && setPage("caseDocuments");
                        dispatch(
                            thunkActions.uploadFileDocument({
                                healthCaseId: healthCase.id,
                                file: event.target.files[0],
                                type: "document",
                            }),
                        )
                            .then(unwrapResult)
                            .then(() => toast(t("documentUploaded")))
                            .catch((e) =>
                                toast(
                                    `${
                                        e?.response?.status ? `${e.response.status} - ` : ""
                                    }${t("documentNotUploaded")}`,
                                    {
                                        type: "error",
                                    },
                                ),
                            );
                    } else {
                        toast(
                            <span className="flex flex-col text-white">
                                <span className="font-bold">{t("wrongFileFormat")}</span>
                                <span>{t("onlyPossibleToUpload")}</span>
                            </span>,
                            {
                                type: "error",
                            },
                        );
                    }
                }
            },
        },
        {
            text: t("selectTemplate"),
            icon: "template",
            iconColor: "#192D05",
            key: `case-title-template-action-${healthCase?.id}`,
            visible: isActiveCase,
            onClick: () => setTemplateModalIsOpen(true),
        },
        {
            text: t("changeCaseManager"),
            icon: "personOutlined",
            iconColor: "#192D05",
            key: `case-title-change-manager-action-${healthCase?.id}`,
            visible: isActiveCase,
            onClick: () => setResponsibleModalIsOpen(true),
        },
        {
            text: t("changeCaseStatus"),
            icon: "refresh",
            iconColor: "#192D05",
            key: `case-title-change-status-action-${healthCase?.id}`,
            visible: true,
            onClick: () => setStatusModalIsOpen(true),
        },
        {
            text: t("removeCase"),
            icon: "trashCan",
            iconColor: "#FF5637",
            key: `case-title-remove-action-${healthCase?.id}`,
            visible: hasCustomerSupport,
            onClick: () => setRemoveModalIsOpen(true),
        },
        {
            text: t("exportCase"),
            icon: "upload",
            iconColor: "#FF5637",
            key: `case-title-export-action-${healthCase?.id}`,
            visible: true,
            onClick: () => setExportModalIsOpen(true),
        },
    ];

    return isPreliminary ? (
        <div className="flex w-full justify-between">
            <div className="flex flex-col items-start sm:flex-row">
                <h3 className="mr-6">{t(healthCase.type ?? "")}</h3>
                <StatusTag
                    id={`case-status-${healthCase.id}`}
                    text={t(`caseStatus.${healthCase.status}`)}
                    preset={getTagProps(healthCase?.status)?.color ?? "grey"}
                    icon={getTagProps(healthCase?.status)?.icon}
                />
            </div>
            <div className="flex h-8 w-8 items-center justify-center opacity-50">
                <Icon icon={"caretUp"} size={16} />
            </div>
        </div>
    ) : (
        <div className="flex w-full flex-col focus:shadow-none sm:flex-row sm:items-center sm:justify-between">
            <div className="mb-10 flex flex-col items-start sm:mb-0 sm:flex-row sm:items-center">
                <div className="flex w-full justify-between">
                    <h3 className="mr-6">{t(healthCase.type ?? "")}</h3>
                    {isActiveCase && (
                        <button className="flex h-8 w-8 items-center justify-center sm:hidden" onClick={onClick}>
                            <Icon icon={expanded ? "caretUp" : "caretDown"} size={16} />
                        </button>
                    )}
                </div>
                <StatusTag
                    id={`case-status-${healthCase.id}`}
                    text={t(`caseStatus.${healthCase.status}`)}
                    preset={getTagProps(healthCase?.status)?.color ?? "grey"}
                    icon={getTagProps(healthCase?.status)?.icon}
                />
            </div>
            <div className="flex items-center">
                <div ref={setContainer} className="relative flex w-full justify-end">
                    <PrimaryButton
                        id="actions-button"
                        text={t("actions")}
                        rightIcon={<Icon icon={open ? "caretFullUp" : "caretFullDown"} size={7} />}
                        onClick={() => {
                            setOpen(!open);
                        }}
                        className="flex w-full justify-center"
                    />
                    <div
                        className={`absolute mt-11 flex w-64 flex-col bg-white py-2 shadow-lg ${
                            open ? "z-10" : "hidden"
                        }`}
                    >
                        {actionButtonValues.map(
                            (c) =>
                                c.visible &&
                                (c.onChange ? (
                                    <label
                                        key={c.key}
                                        htmlFor={c.key}
                                        className="mini-menu-foucs cursor-pointer bg-white py-2 pl-4 text-left hover:bg-grey-100"
                                    >
                                        <input
                                            id={c.key}
                                            accept="application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            type="file"
                                            onChange={(e) => {
                                                c.onChange && c.onChange(e);
                                            }}
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                            className="hidden"
                                        />
                                        <div className="flex">
                                            <Icon
                                                icon={c.icon}
                                                size={15}
                                                className="mr-3 self-center"
                                                stroke={c.iconColor}
                                                strokeWidth={1.5}
                                            />

                                            {c.text}
                                        </div>
                                    </label>
                                ) : (
                                    <button
                                        key={c.key}
                                        className={"mini-menu-foucs bg-white py-2 pl-4 text-left hover:bg-grey-100"}
                                        onClick={c.onClick}
                                    >
                                        <div className="flex">
                                            <Icon
                                                icon={c.icon}
                                                size={15}
                                                className="mr-3 self-center"
                                                stroke={c.iconColor}
                                                strokeWidth={1.5}
                                            />

                                            {c.text}
                                        </div>
                                    </button>
                                )),
                        )}
                    </div>
                </div>
                {isActiveCase && (
                    <button className="ml-8 hidden h-8 w-8 items-center justify-center sm:flex" onClick={onClick}>
                        <Icon icon={expanded ? "caretUp" : "caretDown"} size={16} />
                    </button>
                )}
            </div>
            {activityModalIsOpen && (
                <CaseActivityModal
                    isEdit={false}
                    healthCase={healthCase}
                    modalIsOpen={activityModalIsOpen}
                    setModalIsOpen={() => setActivityModalIsOpen(!activityModalIsOpen)}
                    setPage={setPage}
                />
            )}
            {noteModalIsOpen && (
                <CaseNoteModal
                    isEdit={false}
                    healthCase={healthCase}
                    modalIsOpen={noteModalIsOpen}
                    setModalIsOpen={() => setNoteModalIsOpen(!noteModalIsOpen)}
                    setPage={setPage}
                />
            )}
            {templateModalIsOpen && (
                <CaseTemplateModal
                    healthCase={healthCase}
                    modalIsOpen={templateModalIsOpen}
                    setModalIsOpen={() => setTemplateModalIsOpen(!templateModalIsOpen)}
                    setPage={setPage}
                />
            )}
            {responsibleModalIsOpen && (
                <CaseResponsibleModal
                    healthCase={healthCase}
                    modalIsOpen={responsibleModalIsOpen}
                    setModalIsOpen={() => setResponsibleModalIsOpen(!responsibleModalIsOpen)}
                />
            )}
            {statusModalIsOpen && (
                <ChangeCaseStatusModal
                    healthCase={healthCase}
                    modalIsOpen={statusModalIsOpen}
                    setModalIsOpen={() => setStatusModalIsOpen(!statusModalIsOpen)}
                />
            )}
            {removeModalIsOpen && (
                <RemoveCaseModal
                    healthCase={healthCase}
                    modalIsOpen={removeModalIsOpen}
                    setModalIsOpen={() => setRemoveModalIsOpen(!removeModalIsOpen)}
                />
            )}
            {exportModalIsOpen && (
                <ExportCaseModal
                    healthCase={healthCase}
                    modalIsOpen={exportModalIsOpen}
                    setModalIsOpen={() => setExportModalIsOpen(!exportModalIsOpen)}
                    setExportStarted={() => setExportStarted(!exportStarted)}
                />
            )}
            {exportStarted && (
                <ExcelUploadedModal
                    isOpen={exportStarted}
                    setOpen={() => setExportStarted(!exportStarted)}
                    text={t("excelUploadedOkay")}
                    header={t("excelExportHeader")}
                    context={userRetailer}
                />
            )}
        </div>
    );
};

export default CaseTitle;
