import { useState, ReactNode } from "react";
import { Accordion } from "@medhelp/ui";
import RadioButton from "components/RadioButton";
import FilterHeading from "../FilterHeading";
import { IFilterProps } from "pages/FollowUp/types";

export interface IProps extends IFilterProps {
    children?: ReactNode;
}

interface IPropsView {
    items: {
        label: string;
        onChange: (value: string) => void;
        checked: boolean;
        name: string;
    }[];
    header: string;
    body: string;
    children?: ReactNode;
}
const RadioButtonFilter = ({ header, body, items, children }: IPropsView) => {
    const [open, setOpen] = useState(false);
    return (
        <Accordion
            heading={<FilterHeading header={header} body={body} open={open} />}
            icon="caretFull"
            setOpen={setOpen}
            open={open}
        >
            <div className="my-6 flex flex-col gap-6 pl-4 pr-3">
                {items.map(({ label, onChange, checked, name }) => (
                    <div
                        className="flex cursor-pointer select-none justify-between"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            onChange(name);
                        }}
                    >
                        <p className={`pl-2 text-sm`}>{label}</p>
                        <div className="ml-1 flex items-center justify-center">
                            <RadioButton
                                id="radiobutton-filter-search-followup"
                                selected={checked}
                                value={name}
                                clicked={() => {}}
                                variant="small"
                            />
                        </div>
                    </div>
                ))}
                {children}
            </div>
        </Accordion>
    );
};
export default RadioButtonFilter;
