import Icon from "components/Icon";
import { Employee } from "swagger/fortnox";
import { useEffect, useState } from "react";
import Stock from "assets/FortnoxLanding.png";
import ErrorMessage from "./components/ErrorMessage";
import { SpinnerWithOverlay } from "components/Spinner";
import SuccessMessage from "./components/SuccessMessage";
import { useLocation, useNavigate } from "react-router-dom";
import EmployeeSelector from "./components/EmployeeSelector";
import { useCreateFortnoxIntegration } from "./useCreateFortnoxIntegration";

export const errorMessages = {
    requestFailed: {
        heading: "Något gick fel",
        text: "Detta kan bero på att det redan finns ett aktiverat konto för ert företag, eller att vi inte kunde hämta nödvändig företagsinformation från Fortnox. Vänligen kontrollera om ni redan har ett aktiverat konto genom att logga in med BankID på inloggningssidan.",
        link: "https://www.fortnox.se/",
        linkText: "Tillbaka till Fortnox",
    },
    submitError: {
        heading: "Något gick fel",
        text: "Något gick fel när vi försökte aktivera adminkonto och skapa upp ert kundkonto. Försök igen eller testa vid ett senare tillfälle.",
        linkText: "",
        link: "",
    },
} as const;

export type ErrorMessage = keyof typeof errorMessages;

const Fortnox = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
    const { data, isLoading, errorKey, handleSubmit, success } = useCreateFortnoxIntegration(code);

    useEffect(() => {
        if (!code) navigate("/login");
    });

    if (isLoading) return <SpinnerWithOverlay />;

    return (
        <div className="relative flex w-full justify-center lg:justify-start">
            <img src={Stock} alt="stock" className="hidden lg:flex" />
            <div className="absolute flex h-full justify-center bg-mhbeige px-5 py-15 sm:w-[720px] sm:px-0 lg:right-0">
                <div className="flex flex-col gap-22 sm:w-[460px]">
                    <Icon icon="logo" width={130} />
                    <div className="flex flex-col gap-8">
                        <h1 className="text-[40px]">Aktivera Adminkonto</h1>
                        {success ? (
                            <SuccessMessage />
                        ) : errorKey ? (
                            <ErrorMessage
                                heading={errorMessages[errorKey].heading}
                                text={errorMessages[errorKey].text}
                                link={errorMessages[errorKey].link}
                                linkText={errorMessages[errorKey].linkText}
                            />
                        ) : (
                            <EmployeeSelector
                                employees={data.employees || []}
                                handleSubmit={handleSubmit}
                                selectedEmployee={selectedEmployee}
                                setSelectedEmployee={setSelectedEmployee}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Fortnox;
