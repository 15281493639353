import TopLinks from "components/TopLinks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

const Support = () => {
    const { t } = useTranslation("support");
    const Routes = useMemo(
        () => [
            {
                route: "helpGuide",
                name: t("helpGuide"),
                children: [],
            },
            {
                route: "contactSupport",
                name: t("contactSupport"),
                children: [],
            },
        ],
        [t],
    );
    return (
        <>
            <TopLinks routes={Routes} headRoute="support" header={t("support")} />
            <div className="w-full justify-center pb-20 pt-12 xl:flex xl:px-0">
                <div className="w-full max-w-6xl">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default Support;
