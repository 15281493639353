import * as React from "react";
import { isFunction, isEmpty } from "lodash/fp";
import { Link } from "react-router-dom";
import { checkWhitelistRoute, filterRoutesByHidden, IRoute } from "routes/hooks";
import { getExternalLink } from "routes";
import { useAppSelector } from "store/hooks";
import { getUserAccessRights } from "store/userSelectors";

interface IChildrenExtraProps {
    active: boolean;
    route: IRoute;
}
type NavChild = React.ReactNode | (({ active, route }: IChildrenExtraProps) => React.ReactNode);
export interface IExternalProps {
    active: string;
    routes: IRoute[];
    children: NavChild;
    subMenuTo?: string;
    params?: string;
    setScrollRefState: (node: HTMLDivElement | null) => void;
    updateButtonsState: (currentTarget: EventTarget & HTMLDivElement) => void;
}
interface IProps {
    createNavigationLink: (route: IRoute, index: number, children: NavChild) => JSX.Element;
    routes: IRoute[];
    children: NavChild;
    setScrollRefState: (node: HTMLDivElement | null) => void;
    updateButtonsState: (currentTarget: EventTarget & HTMLDivElement) => void;
}
export const useNavigation = (navigationRoutes: IRoute[], active: string, subMenuTo?: string, params?: string) => {
    const accessRights = useAppSelector(getUserAccessRights);
    const visableNavigationRoutes = filterRoutesByHidden(navigationRoutes);
    const routes = React.useMemo(
        () => (accessRights ? checkWhitelistRoute(visableNavigationRoutes, accessRights) : visableNavigationRoutes),
        [accessRights, visableNavigationRoutes],
    );
    const parentRoute = React.useMemo(() => (subMenuTo ? `/${subMenuTo}` : ""), [subMenuTo]);
    const Params = React.useMemo(() => (params ? `/${params}` : ""), [params]);
    const getNavigationRoutes = React.useMemo(
        () => (subMenuTo ? routes.find((route) => route.route === subMenuTo)?.children || routes : routes),
        [routes, subMenuTo],
    );
    const createNavigationLink = React.useCallback(
        (route: IRoute, index: number, children: NavChild) => {
            const currentRoute = isEmpty(route.route) ? "" : `/${route.route}`;
            const fullRoute = `${parentRoute}${currentRoute}${Params}`;
            const externalLink = getExternalLink(currentRoute);
            const routeTo = externalLink === "" ? fullRoute : externalLink;
            const key = `${routeTo}-${index}`;
            if (route.subMenu) {
                return (
                    <div key={route.name}>
                        {isFunction(children)
                            ? children({
                                  active: route.route === active,
                                  route,
                              })
                            : children}
                    </div>
                );
            }
            if (externalLink === "") {
                return (
                    <Link to={routeTo} className="focus:shadow-none" key={key}>
                        {isFunction(children)
                            ? children({
                                  active: route.route === active,
                                  route,
                              })
                            : children}
                    </Link>
                );
            }
            return (
                <a href={externalLink} key={key}>
                    {isFunction(children)
                        ? children({
                              active: route.route === active,
                              route,
                          })
                        : children}
                </a>
            );
        },
        [Params, active, parentRoute],
    );
    return {
        getNavigationRoutes,
        createNavigationLink,
    };
};
const Navigation = ({ routes, createNavigationLink, children, setScrollRefState, updateButtonsState }: IProps) => (
    <div
        className="scrollbar-hide flex w-full items-center gap-8 overflow-x-scroll scroll-smooth pb-4 sm:overflow-visible"
        key="Navigation"
        onScroll={(event) => updateButtonsState(event.currentTarget)}
        ref={setScrollRefState}
    >
        {routes.map((route, index) => createNavigationLink(route, index, children))}
    </div>
);

export default Navigation;
