import { IPowerbarSearch } from "interfaces/ISearch";
import { KeyboardEvent } from "react";
import PowerbarSearchItem from "./PowerbarSearchItem";

export interface IProps {
    setOpen: (open: boolean) => void;
    inputText: string;
}
interface ISearchResultItemProps {
    searchResults: IPowerbarSearch[];
    searchItemClicked: (searchItem: IPowerbarSearch) => void;
    assumeUserClicked: (userAccountId: number) => void;
    handleKeyDown: (event: KeyboardEvent, length: number) => void;
    inputText: string;
}

const PowerbarSearchItems = ({
    searchResults,
    searchItemClicked,
    assumeUserClicked,
    handleKeyDown,
    inputText,
}: ISearchResultItemProps) => (
    <>
        {searchResults.map((searchItem, i) =>
            searchItem ? (
                <div
                    key={`${searchItem.fullName}-${i.toString()}`}
                    id={"selectedSearches-" + i}
                    className="w-full cursor-pointer bg-white hover:bg-grey-100 focus:border focus:border-mhdarkgreen-focus focus:shadow-none"
                    onClick={() => searchItemClicked(searchItem)}
                    onKeyDown={(e) => handleKeyDown(e, searchResults.length)}
                >
                    <PowerbarSearchItem inputText={inputText} {...searchItem} assumeUserClicked={assumeUserClicked} />
                </div>
            ) : null,
        )}
    </>
);
export default PowerbarSearchItems;
