const EmployeeSummaryLoader = () => {
    return (
        <div className="dashboard-width animate-pulse bg-primaryBg shadow-lg">
            <div className="flex h-17 items-center justify-center border-b font-bold xs:justify-start xs:pl-6">
                <div className="h-2.5 w-56 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </div>
            <div className="summary-column flex flex-col justify-between px-8 pt-5 text-sm">
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="pl-9 font-bold">
                        <div className="mb-3">
                            <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 w-40 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                    </div>
                </div>
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="pl-9 font-bold">
                        <div className="mb-3">
                            <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 w-40 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                    </div>
                </div>
                <div className="flex pb-4">
                    <div className="w-10">
                        <div className="h-10 w-10 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="pl-9 font-bold">
                        <div className="mb-3">
                            <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>

                        <div className="mb-3">
                            <div className="h-2.5 w-20 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                        <div className="mb-3">
                            <div className="h-2.5 w-40 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeSummaryLoader;
