import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RecursiveComponent from "./RecursiveComponent";
import { ReactComponent as Close } from "icons/widget/close.svg";
import { ReactComponent as Search } from "icons/widget/search.svg";
import { ReactComponent as CheckboxChecked } from "icons/widget/checkboxChecked.svg";
import { ReactComponent as CheckboxUnchecked } from "icons/widget/checkboxUnchecked.svg";
import { useDetectOutsideClick } from "hooks";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Icon from "components/Icon";
import { IDynamicData, WidgetDropdownTypeKeys } from "pages/dashboard/utils/dashboardTypes";
import { fetchCheckedDropdownItems } from "pages/dashboard/redux/dashboardActions";
import { getCompanies, getDepartments, getEmploymentGroups } from "pages/dashboard/redux/dashboardSelectors";
import { actions } from "pages/dashboard/redux";
import { IWidgetMap } from "../Widget/widgetMap";

interface IWidgetDropdown {
    dynamicData: IDynamicData[];
    id: WidgetDropdownTypeKeys;
    currentWidget?: IWidgetMap;
    heading?: string;
    dataTestid?: string;
    translate?: boolean;
    hasChildren?: boolean;
    absolutePositioning?: true;
    isGuide?: boolean;
    widgetId?: string;
    useRadioButtons?: boolean;
    required?: boolean;
}

const WidgetDropdown = ({
    dynamicData,
    id,
    heading,
    dataTestid,
    translate,
    hasChildren,
    absolutePositioning,
    currentWidget,
    isGuide,
    widgetId,
    useRadioButtons,
    required,
}: IWidgetDropdown) => {
    const {
        isOutside: showDropdown,
        setIsOutside: setShowDropdown,
        setContainer,
    } = useDetectOutsideClick(false, false);

    const [userInput, setUserInput] = useState("");
    const [hiddenButtons, setHiddenButtons] = useState(0);
    const [allSelected, setAllSelected] = useState(false);
    const dropdownRef = useRef<null | HTMLDivElement>(null);
    const scrollRef = useRef<null | HTMLDivElement>(null);
    const { t } = useTranslation("dashboard");
    const dispatch = useAppDispatch();
    const companies = useAppSelector(getCompanies(widgetId || ""));
    const departments = useAppSelector(getDepartments(widgetId || ""));
    const employmentGroups = useAppSelector(getEmploymentGroups(widgetId || ""));

    const handleClick = (id: WidgetDropdownTypeKeys, referenceKey: string) => {
        if (isGuide) {
            if (Number(referenceKey)) dispatch(actions.updateCompanyGroupForCompanySelection(Number(referenceKey)));
        } else {
            dispatch(
                fetchCheckedDropdownItems({
                    id,
                    referenceKey,
                    accessRight: currentWidget?.accessRight,
                    widgetId: widgetId || "",
                }),
            );
        }
    };

    const handleRadioButtonClick = (id: WidgetDropdownTypeKeys, referenceKey: string) => {
        dispatch(
            actions.updateRadioSelectedDropdownItem({
                id,
                referenceKey,
                widgetId: widgetId || "",
            }),
        );
    };

    const toggleAll = () => {
        setAllSelected(!allSelected);

        if (isGuide) {
            dispatch(actions.updateAllDropdownCompanies());
        } else {
            dispatch(
                actions.updateAllCheckedDropdownItems({
                    id,
                    allSelected,
                    widgetId: widgetId || "",
                }),
            );
        }
    };

    useEffect(() => {
        let hiddenElementCounter = 0;
        const collection = dropdownRef?.current?.children!;
        const dropdownPosition = dropdownRef?.current?.getBoundingClientRect().y;

        for (let i = 0; i < collection.length; i += 1) {
            if (collection[i]?.getBoundingClientRect().y > dropdownPosition!) hiddenElementCounter += 1;
        }

        setHiddenButtons(hiddenElementCounter);
    }, [companies, departments, employmentGroups]);

    const checkIfAllSelected = () => {
        const values: any[] = [];
        dynamicData
            ?.filter((x) => x.selectable)
            .map((i) => {
                values.push(i.checked);
                const childSelect = (child: IDynamicData[]) => {
                    child
                        .filter((x) => x.selectable)
                        .forEach((k: IDynamicData) => {
                            values.push(k.checked);
                            if (k.children) childSelect(k.children);
                        });
                };
                if (i.children) childSelect(i.children);
            });
        return values.every((x) => x);
    };

    const filteredData = !userInput
        ? dynamicData
        : dynamicData.filter((department) => department.label.toLowerCase().includes(userInput.toLowerCase()));

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);

        if (!showDropdown)
            setTimeout(() => {
                if (
                    typeof scrollRef.current === "object" &&
                    scrollRef &&
                    scrollRef.current &&
                    "scrollIntoView" in scrollRef.current
                ) {
                    scrollRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }
            }, 100);
    };

    const flattenDepartments = (dep: IDynamicData[]) => {
        let result: any[] = [];
        dep?.forEach((element) => {
            if (element.checked) result.push(element);
            if (element.children) result = result.concat(flattenDepartments(element.children));
        });
        return result;
    };

    return (
        <div ref={setContainer} className="mb-6">
            <div className="flex flex-col">
                <div className="mb-2" ref={scrollRef}>
                    {heading && (
                        <p>
                            {t("filterBy")} {heading} {required && "*"}
                        </p>
                    )}
                </div>

                <div>
                    <div className="flex h-15 w-full items-center justify-between border border-mhdarkgreen-original">
                        <div
                            className="flex h-10 w-3/5 flex-wrap overflow-hidden"
                            ref={dropdownRef}
                            data-testid={`checked-items-${dataTestid}`}
                        >
                            {flattenDepartments(dynamicData).map(
                                (data) =>
                                    data.selectable &&
                                    data.checked && (
                                        <div key={data.referenceKey}>
                                            <button
                                                type="button"
                                                className="flex-no-wrap my-1 ml-3 flex h-8 items-center bg-mhgrey-light px-3 py-1"
                                                onClick={() => handleClick(id, data.referenceKey)}
                                            >
                                                <p className="whitespace-no-wrap truncate text-sm font-bold">
                                                    {translate ? t(data.label) : data.label}
                                                </p>
                                                <div className="ml-3">
                                                    <Close />
                                                </div>
                                            </button>
                                        </div>
                                    ),
                            )}
                        </div>
                        <div className="flex items-center">
                            {dynamicData && hiddenButtons > 0 && (
                                <div className="text-sm xs:text-base">{`+${hiddenButtons} ${t("more")}...`}</div>
                            )}
                            <button
                                type="button"
                                className="m-2 p-3"
                                onClick={() => toggleDropdown()}
                                data-testid="triangleButton"
                            >
                                {showDropdown ? (
                                    <Icon icon={"triangleUp"} size={12} />
                                ) : (
                                    <Icon icon={"triangleDown"} size={12} />
                                )}
                            </button>
                        </div>
                    </div>

                    {showDropdown && (
                        <div className="relative z-20">
                            <div
                                className={`w-full border-2 border-mhgrey-medium bg-pageBg ${
                                    absolutePositioning ? "absolute" : ""
                                }`}
                            >
                                <div>
                                    {dynamicData?.length > 15 && (
                                        <div className="borderOpacity flex h-14 items-center border-b border-mhgrey-medium pl-4">
                                            <div>
                                                <Search />
                                            </div>

                                            <input
                                                onChange={(e) => setUserInput(e.target.value)}
                                                className="search-bar ml-4 w-full border-none bg-transparent"
                                                type="text"
                                            />
                                        </div>
                                    )}
                                    <div className="pt-4">
                                        {!useRadioButtons && (
                                            <div>
                                                <label
                                                    htmlFor="SelectAll"
                                                    className="flex cursor-pointer items-center py-1 pl-3 hover:bg-mhgrey-light"
                                                >
                                                    <input
                                                        id="SelectAll"
                                                        className="hidden"
                                                        type="checkbox"
                                                        checked={allSelected}
                                                        onChange={() => toggleAll()}
                                                    />

                                                    {checkIfAllSelected() ? (
                                                        <CheckboxChecked fill="#192D05" />
                                                    ) : (
                                                        <CheckboxUnchecked stroke="#192D05" />
                                                    )}
                                                    <p className="pl-2 font-bold">{t("selectAll")}</p>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <ul className="max-height-400 overflow-auto pb-4">
                                    {hasChildren && currentWidget
                                        ? filteredData?.map((data) => (
                                              <RecursiveComponent
                                                  id={id}
                                                  data={data}
                                                  key={data.id}
                                                  handleClick={handleClick}
                                                  translate={translate!}
                                                  currentWidget={currentWidget}
                                              />
                                          ))
                                        : filteredData?.map((data) => {
                                              return (
                                                  <li key={data.referenceKey}>
                                                      <label
                                                          htmlFor={data.referenceKey as string}
                                                          className="flex cursor-pointer items-center py-1 pl-3 hover:bg-mhgrey-light"
                                                      >
                                                          {useRadioButtons ? (
                                                              <>
                                                                  <input
                                                                      id={data.referenceKey as string}
                                                                      className="hidden"
                                                                      type="radio"
                                                                      checked={data.checked}
                                                                      onChange={() =>
                                                                          handleRadioButtonClick(
                                                                              id,
                                                                              data.referenceKey as string,
                                                                          )
                                                                      }
                                                                      disabled={!data.selectable}
                                                                  />
                                                                  {data.checked ? (
                                                                      <Icon
                                                                          icon="radioActive"
                                                                          fill="#192D05"
                                                                          stroke={"#192D05"}
                                                                      />
                                                                  ) : (
                                                                      <Icon icon="radio" stroke={"#192D05"} />
                                                                  )}
                                                                  <p className="pl-2">
                                                                      {translate ? t(data.label) : data.label}
                                                                  </p>
                                                              </>
                                                          ) : (
                                                              <>
                                                                  <input
                                                                      id={data.referenceKey as string}
                                                                      className="hidden"
                                                                      type="checkbox"
                                                                      checked={data.checked}
                                                                      onChange={() =>
                                                                          handleClick(id, data.referenceKey as string)
                                                                      }
                                                                      disabled={!data.selectable}
                                                                  />
                                                                  {data.selectable && data.checked ? (
                                                                      <CheckboxChecked fill="#192D05" />
                                                                  ) : (
                                                                      <CheckboxUnchecked
                                                                          stroke={
                                                                              !data.selectable ? "#BBBDAC" : "#192D05"
                                                                          }
                                                                      />
                                                                  )}
                                                                  <p
                                                                      className={`pl-2 ${
                                                                          !data.selectable && "text-mhgrey-medium"
                                                                      }`}
                                                                  >
                                                                      {translate ? t(data.label) : data.label}
                                                                  </p>
                                                              </>
                                                          )}
                                                      </label>
                                                  </li>
                                              );
                                          })}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default WidgetDropdown;
