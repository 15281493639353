import "react-day-picker/dist/style.css";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { getDateStringForwardOrBack, getStringDateRangeAndConvertToDateRange } from "utils/date";

import * as UserSelectors from "store/userSelectors";
import { useAppSelector } from "../../store/hooks";
import { StringDateRange } from "@medhelp/ui/Datepicker/types";
import DateTabs from "./DateTabs";
import DatePickerModal from "./DatePickerModal";
import { useBreakpoints, useElementClicked } from "hooks";
import DateRangePickerComponent from "./DateRangePickerComponent";
import DateRangeHandleSelected from "./DateRangeHandleSelected";

interface IDateRangePickerProps {
    heading?: string;
    headingTo?: string;
    disableDatesBefore?: string;
    disableDatesAfter?: string;
    range?: StringDateRange | undefined;
    setRange: (range: StringDateRange | undefined) => void;
    setOpen?: (open: boolean) => void;
    isMandatory?: boolean;
    disabled?: boolean | undefined;
    edit?: boolean;
}

export const DateRangePicker = ({
    heading,
    headingTo,
    disableDatesBefore = getDateStringForwardOrBack(20, "years", "backwards"),
    disableDatesAfter = getDateStringForwardOrBack(1, "years", "forward"),
    isMandatory = false,
    setRange,
    range,
    disabled,
    edit,
}: IDateRangePickerProps) => {
    const [selected, setSelected] = useState<DateRange | undefined>(
        range?.from && range?.to ? getStringDateRangeAndConvertToDateRange(range) : undefined,
    );
    const [defaultMonth, setDefaultMonth] = useState(selected?.from || undefined);
    const [open, setOpen] = useState(false);
    const [tabSelected, setTabSelected] = useState("false");
    const [firstClick, setFirstClick] = useState(false);
    const [hoveredDate, setHoveredDate] = useState<Date | undefined>();
    const language = useAppSelector(UserSelectors.getUserLanguage);

    const handleDayMouseEnter = (day: Date) => {
        if (!selected || selected.to) {
            return;
        }
        setHoveredDate(day);
    };

    const isDateInRange = (day: Date) => {
        if (!selected?.from || !hoveredDate) {
            return false;
        }
        return selected.from < day && day < hoveredDate;
    };

    const handleSelect = DateRangeHandleSelected({
        selected,
        firstClick,
        tabSelected,
        setFirstClick,
        setSelected,
        setOpen,
        setTabSelected,
        setRange,
    });

    const ref = useElementClicked((open) => !isSmallscreen && setOpen(!disabled && open));
    const { isSmallscreen } = useBreakpoints();
    return (
        <>
            <div ref={ref} id="range-datepicker-container" className="relative flex max-w-[620px] flex-col pb-6">
                <DateTabs
                    isMandatory={isMandatory}
                    heading={heading}
                    headingTo={headingTo}
                    setOpen={setOpen}
                    open={open}
                    setTabSelected={setTabSelected}
                    tabSelected={tabSelected}
                    selected={selected}
                    setDefaultMonth={setDefaultMonth}
                    className="flex max-w-[620px] flex-col"
                    setFirstClick={setFirstClick}
                    edit={edit}
                />
                <div className={`z-10 max-w-[620px] bg-white shadow-lg ${open ? "flex" : "hidden"}`}>
                    <DateRangePickerComponent
                        defaultMonth={defaultMonth}
                        setDefaultMonth={setDefaultMonth}
                        selected={selected}
                        handleSelect={handleSelect}
                        handleDayMouseEnter={handleDayMouseEnter}
                        isDateInRange={isDateInRange}
                        disableDatesBefore={disableDatesBefore}
                        disableDatesAfter={disableDatesAfter}
                        language={language}
                        nrOfMonths={2}
                        className="m-0 inline-block w-full max-w-[620px] justify-center bg-[#FFFAF5] p-4 text-sm shadow-md max-sm:hidden"
                    />
                </div>
            </div>
            {open && (
                <>
                    <DatePickerModal open={isSmallscreen && open} setOpen={setOpen}>
                        <div>
                            <DateTabs
                                isMandatory={isMandatory}
                                heading={heading}
                                headingTo={headingTo}
                                setOpen={setOpen}
                                open={open}
                                setTabSelected={setTabSelected}
                                tabSelected={tabSelected}
                                selected={selected}
                                setDefaultMonth={setDefaultMonth}
                                className="px-4"
                                setFirstClick={setFirstClick}
                            />

                            <DateRangePickerComponent
                                defaultMonth={defaultMonth}
                                setDefaultMonth={setDefaultMonth}
                                selected={selected}
                                handleSelect={handleSelect}
                                handleDayMouseEnter={handleDayMouseEnter}
                                isDateInRange={isDateInRange}
                                disableDatesBefore={disableDatesBefore}
                                disableDatesAfter={disableDatesAfter}
                                language={language}
                                nrOfMonths={1}
                                className="z-30 m-0 inline-block h-[100dvh] w-full max-w-[620px] justify-center bg-[#FFFAF5] pt-6 text-sm shadow-md md:relative md:hidden md:h-full md:w-auto"
                            />
                        </div>
                    </DatePickerModal>
                </>
            )}
        </>
    );
};
