import Icon, { Icons } from "components/Icon";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

interface MenuNavDropdownMenuProps {
    label: string;
    icon: Icons;
    initialValue?: string;
    items: {
        value: string;
        label: string;
    }[];
    handleClick: (value: string) => void;
}

const MenuNavDropdownMenu = ({ label, icon, initialValue, items, handleClick }: MenuNavDropdownMenuProps) => (
    <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild className="flex">
            <div>
                <button className="flex items-center gap-1.5 text-menuNavColorSecondary focus:border max-xl:hidden">
                    <Icon icon={icon} size={16} />
                    <span className="text-sm text-menuNavColorSecondary max-xl:hidden">{label}</span>
                </button>
                <button className="border-none text-menuNavColorPrimary xl:hidden">
                    <Icon icon={icon} size={28} />
                </button>
            </div>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
            <DropdownMenu.Content
                className="z-11 min-w-[100vw] rounded-md bg-white p-[5px] shadow-topNavMenuDropdown will-change-[opacity,transform] data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade data-[side=right]:animate-slideLeftAndFade data-[side=top]:animate-slideDownAndFade data-[state=open]:p-0 data-[state=open]:shadow-topNavMenuDropdown xs:min-w-[220px]"
                sideOffset={5}
            >
                <DropdownMenu.RadioGroup value={initialValue} onValueChange={handleClick}>
                    {items.map(({ value, label }) => (
                        <DropdownMenu.RadioItem
                            key={value}
                            className="DropdownMenuRadioItem relative flex h-12 select-none items-center rounded-[3px] px-[5px] pl-[25px] leading-none hover:bg-grey-100 focus:bg-grey-100 data-[disabled]:pointer-events-none data-[state=checked]:bg-grey-100"
                            value={value}
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
                                <Icon icon="checkMark" />
                            </DropdownMenu.ItemIndicator>
                            <span>{label}</span>
                        </DropdownMenu.RadioItem>
                    ))}
                </DropdownMenu.RadioGroup>
                <DropdownMenu.Arrow className="fill-white" />
            </DropdownMenu.Content>
        </DropdownMenu.Portal>
    </DropdownMenu.Root>
);

export default MenuNavDropdownMenu;
