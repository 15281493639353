import Tooltip from "../Tooltip";
import { ModalFrame } from "@medhelp/ui";
import { useState, ReactNode } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, thunkActions } from "../../redux";
import { isWidgetHCM } from "pages/dashboard/utils/dashboardTypes";
import HCMCaseTableContainer from "../Tables/HCMCaseTableContainer";
import { getWidgetFilter } from "pages/dashboard/redux/dashboardSelectors";
import { getWidgetHCMRequestModel } from "pages/dashboard/utils/dashboardActionsUtils";
import { getHCMTemplates, getCompanies } from "pages/dashboard/redux/dashboardSelectors";
import { getDateStringForwardOrBack } from "utils/date";

export interface IData {
    type: string;
    status: string;
    count: number;
    modalHeading: string;
}

export interface ITableData {
    id: string;
    heading: ReactNode;
    data: IData[];
    tooltip: string;
}

interface ITableChart {
    keyHeading: string;
    dataHeadings: string[];
    tableData: ITableData[];
    widgetId: string;
}

const TableChart = ({ keyHeading, dataHeadings, tableData, widgetId }: ITableChart) => {
    const dispatch = useAppDispatch();
    const companies = useAppSelector(getCompanies(widgetId));
    const widgetFilter = useAppSelector(getWidgetFilter(false, widgetId));
    const templates = useAppSelector(getHCMTemplates);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState<IData | null>(null);

    const showData = (data: IData) => {
        if (isWidgetHCM(widgetFilter, "hcm-status-overview") && templates) {
            const widgetHCMRequestModel = getWidgetHCMRequestModel(
                { ...widgetFilter, hcmTypes: [data?.type ?? ""] },
                templates,
                Number(companies[0].id) as number,
            );

            const searchCasesRequestModel = {
                ...widgetHCMRequestModel,
                status: [data?.status ?? ""],
                descending: false,
                groupBy: "responsible",
                page: 1,
                from: getDateStringForwardOrBack(30, "days", "backwards"),
            };

            dispatch(thunkActions.fetchHealthCases(searchCasesRequestModel));
        }
        setData(data);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        dispatch(actions.resetCaseSummary());
        setOpenModal(false);
    };

    return (
        <div className="pt-3">
            <ul className="flex flex-col px-6">
                <li className="flex h-10 items-end justify-between pb-2">
                    <p className="text-xs">{keyHeading}</p>
                    <div className="flex">
                        {dataHeadings.map((dataHeading) => (
                            <p key={dataHeading} className="mr-2 flex w-18 justify-center whitespace-nowrap text-xs">
                                {dataHeading}
                            </p>
                        ))}
                    </div>
                </li>
                {tableData.map((data, i) => (
                    <li
                        key={data.id}
                        className={`flex h-13 items-center justify-between border-b py-4 ${i === 0 ? "border-t" : ""}`}
                    >
                        <div className="group cursor-pointer">
                            {data.heading}
                            <div className="hidden group-hover:flex">
                                <Tooltip text={data.tooltip} className="w-60" />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            {data.data?.map((data) => {
                                const { status, type, count } = data;
                                const isExpiredOrDeclinedRehab =
                                    type === "rehab" && (status === "expired" || status === "declined");
                                const displayCount = count > 999 ? "999+" : count;
                                const isClickable = count !== 0 && !isExpiredOrDeclinedRehab;

                                return (
                                    <div key={`${type}-${status}`} className="mr-2 w-18 cursor-default text-center">
                                        {isClickable ? (
                                            <button
                                                type="button"
                                                onClick={() => showData(data)}
                                                className="mr-2 w-18 underline"
                                            >
                                                {displayCount}
                                            </button>
                                        ) : isExpiredOrDeclinedRehab ? (
                                            "-"
                                        ) : (
                                            displayCount
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </li>
                ))}
            </ul>
            <ModalFrame
                id="widget-status-overview-modal"
                open={openModal}
                setOpen={handleCloseModal}
                header={<h3>{data?.modalHeading}</h3>}
                preset="full"
                content={<HCMCaseTableContainer data={data} />}
            />
        </div>
    );
};
export default TableChart;
