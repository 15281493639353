import { Trans, useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { actions } from "pages/FollowUp/redux";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateDisplayValue } from "utils/date";
import { CardWithTitle, ColumnText } from "@medhelp/ui";
import { thunkActions } from "../../../redux";
import { getCaseActiveActivitesCount, getShowRetailerHealthContact } from "pages/FollowUp/redux/followUpSelectors";
import { CaseActivities, CaseDetails, CaseDocuments, CaseLog, CaseNotes } from "pages/FollowUp/pages";
import { CaseRecommendation, CaseTitle } from "..";
import NewsMessage from "components/NewsMessage";
import Icon from "components/Icon";
import { useAppSelector } from "store/hooks";
import { getUserRetailer } from "store/userSelectors";
import CaseHealthContact from "pages/FollowUp/pages/Employee/Case/CaseHealthContact";

export type PageTypes =
    | "caseDetails"
    | "caseActivities"
    | "caseNotes"
    | "caseDocuments"
    | "caseLog"
    | "caseHealthContact";

interface IPageTabs {
    name: PageTypes;
    label: string;
    active: boolean;
    key: string;
}

interface IProps {
    healthCase: IHealthCase;
}

const OngoingCaseItem = (props: IProps) => {
    const { healthCase } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [page, setPage] = useState<PageTypes>("caseDetails");
    const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(true);
    const userRetailer = useAppSelector(getUserRetailer);
    const showRetailerHealthContact = useAppSelector(getShowRetailerHealthContact);
    const activeCaseActivitiesCount = useAppSelector(getCaseActiveActivitesCount(healthCase.id));

    useEffect(() => {
        if (healthCase?.id) {
            dispatch(thunkActions.fetchCaseActivityList(healthCase.id));
        }
    }, [dispatch, healthCase?.id]);

    const getCaseDetails = () => {
        const caseItems = [
            {
                header: t("reason"),
                body: (
                    <div className="flex flex-col">
                        {healthCase?.healthCaseCauses?.map((cause, index) => (
                            <div key={`${healthCase.id}-${cause.cause}-${index}`}>{cause.cause}</div>
                        ))}
                    </div>
                ),
                key: "current-case-reason",
            },
            {
                header: t("caseInitiated"),
                body: healthCase.created ? getDateDisplayValue(healthCase.created, "d MMM yyyy") : "-",
                key: "current-case-initiated",
            },
            {
                header: t("caseStart"),
                body: healthCase.start ? getDateDisplayValue(healthCase.start, "d MMM yyyy") : "-",
                key: "current-case-caseStart",
            },
            {
                header: t("caseEnd"),
                body: t("ongoing"),
                key: "current-case-caseEnd",
            },
            {
                header: t("numberOfDays"),
                body: healthCase.chainInformation?.day ? `${t("day")} ${healthCase.chainInformation.day}` : "-",
                key: "current-case-numberOfDays",
            },
            {
                header: t("caseManager"),
                body: healthCase.responsible ? healthCase.responsible : "-",
                key: "current-case-caseManager",
            },
        ];

        return caseItems;
    };

    const getPageTabs = () => {
        const tabItems: IPageTabs[] = [
            {
                name: "caseDetails",
                label: t("details"),
                active: page === "caseDetails",
                key: "case-details-page-button",
            },
            {
                name: "caseActivities",
                label: t("activities"),
                active: page === "caseActivities",
                key: "case-activities-page-button",
            },
            {
                name: "caseNotes",
                label: t("notes"),
                active: page === "caseNotes",
                key: "case-notes-page-button",
            },
            {
                name: "caseDocuments",
                label: t("documentsTemplates"),
                active: page === "caseDocuments",
                key: "case-documents-page-button",
            },
            {
                name: "caseLog",
                label: t("caseLog"),
                active: page === "caseLog",
                key: "case-log-page-button",
            },
            {
                name: "caseHealthContact",
                label: `${t("contact")} ${userRetailer}`,
                active: page === "caseHealthContact",
                key: "case-health-contact-page-button",
            },
        ];
        if (!showRetailerHealthContact) {
            return tabItems.filter((tab) => tab.key !== "case-health-contact-page-button");
        }
        return tabItems;
    };

    const getPageContent = () => {
        switch (page) {
            case "caseDetails":
                return <CaseDetails healthCase={healthCase} />;
            case "caseActivities":
                return <CaseActivities healthCase={healthCase} />;
            case "caseNotes":
                return <CaseNotes healthCase={healthCase} />;
            case "caseDocuments":
                return <CaseDocuments healthCase={healthCase} />;
            case "caseLog":
                return <CaseLog healthCase={healthCase} />;
            case "caseHealthContact":
                return <CaseHealthContact healthCase={healthCase} />;
            default:
                return <CaseDetails healthCase={healthCase} />;
        }
    };

    const scroll = (direction: "left" | "right") => {
        if (scrollRef) {
            const scrollDistance = scrollRef.scrollWidth / 4;
            scrollRef.scrollLeft += direction === "right" ? scrollDistance : -scrollDistance;
        }
    };

    const setScrollRefState = (node: HTMLDivElement | null) => {
        if (node) setScrollRef(node);
    };

    const updateButtonsState = (currentTarget: EventTarget & HTMLDivElement) => {
        const { scrollLeft, scrollWidth, offsetWidth } = currentTarget;
        const endOfScroll = scrollWidth - scrollLeft - 30 <= offsetWidth;
        if (scrollLeft !== 0 && !showLeftButton) setShowLeftButton(true);
        if (scrollLeft === 0 && showLeftButton) setShowLeftButton(false);
        if (endOfScroll && showRightButton) setShowRightButton(false);
        if (!endOfScroll && !showRightButton) setShowRightButton(true);
    };

    return healthCase ? (
        <div key={`current-case-${healthCase.id}`} className="mb-8">
            <CardWithTitle
                title={
                    <CaseTitle
                        healthCase={healthCase}
                        expanded={healthCase.expanded}
                        onClick={() => dispatch(actions.updateExpandedCase(healthCase.id ?? ""))}
                        setPage={(v) => {
                            if (healthCase.expanded === false) {
                                dispatch(actions.updateExpandedCase(healthCase?.id ?? ""));
                            }
                            setPage(v);
                        }}
                        isPreliminary={healthCase.status === "preliminary"}
                    />
                }
                className="h-fit border-none px-6 py-8 sm:h-19 sm:border-b sm:border-solid sm:border-grey-200 sm:px-8 sm:py-0"
            >
                <div className="sm:mt-8">
                    {healthCase.status === "paused" && (
                        <div className="mx-8 mb-8">
                            <NewsMessage heading={t("rehabCasePaused")} icon={"warning"} leftBg={"red"}>
                                <Trans
                                    t={t}
                                    i18nKey="infoMessagePaused"
                                    components={{
                                        linkTag: (
                                            <a
                                                href="https://www.forsakringskassan.se/"
                                                style={{
                                                    textDecoration: "underline",
                                                }}
                                            />
                                        ),
                                    }}
                                    values={{
                                        linkTag: "www.forsakringskassan.se",
                                    }}
                                />
                            </NewsMessage>
                        </div>
                    )}
                    {healthCase.status === "preliminary" && <CaseRecommendation healthCase={healthCase} />}
                </div>
                {healthCase.expanded ? (
                    <div>
                        {healthCase.status !== "preliminary" && (
                            <div className="mb-9 flex h-12 justify-between border-b border-grey-300 sm:h-10">
                                {showLeftButton && (
                                    <button
                                        onClick={() => scroll("left")}
                                        className={
                                            "flex w-9 items-center justify-center border-r-1 border-grey-300 sm:hidden"
                                        }
                                    >
                                        <Icon icon="caretLeft" size={12} />
                                    </button>
                                )}

                                <div
                                    onScroll={(event) => updateButtonsState(event.currentTarget)}
                                    ref={setScrollRefState}
                                    className="scrollbar-hide flex w-full gap-8 overflow-x-scroll scroll-smooth px-6 leading-none sm:px-8 sm:pb-1.5"
                                >
                                    {getPageTabs().map((tab) => (
                                        <button
                                            className="whitespace-nowrap focus:shadow-none"
                                            key={tab.key}
                                            onClick={() => {
                                                if (healthCase.expanded === false) {
                                                    dispatch(actions.updateExpandedCase(healthCase?.id ?? ""));
                                                }
                                                setPage(tab.name);
                                            }}
                                        >
                                            <div
                                                className={`${
                                                    tab.active
                                                        ? "underline decoration-2 underline-offset-[17px]"
                                                        : "underline-none"
                                                }`}
                                            >
                                                <p
                                                    className={` ${
                                                        tab.active
                                                            ? `text-xs font-bold sm:text-sm`
                                                            : `text-xs font-normal text-gray-600 sm:text-sm`
                                                    }`}
                                                >
                                                    {activeCaseActivitiesCount &&
                                                    tab.key === "case-activities-page-button"
                                                        ? `${tab.label} (${activeCaseActivitiesCount})`
                                                        : tab.label}
                                                </p>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                {showRightButton && (
                                    <button
                                        onClick={() => scroll("right")}
                                        className="flex w-9 items-center justify-center border-l border-grey-300 sm:hidden"
                                    >
                                        <Icon icon="caretRight" size={12} />
                                    </button>
                                )}
                            </div>
                        )}
                        {getPageContent()}
                    </div>
                ) : (
                    <div className={"flex flex-wrap items-start pb-8"}>
                        {getCaseDetails().map((item, index) => (
                            <ColumnText {...item} className="w-56" key={`${healthCase.id}-${item.key}-${index}`} />
                        ))}
                    </div>
                )}
            </CardWithTitle>
        </div>
    ) : null;
};

export default OngoingCaseItem;
