import { useTranslation } from "react-i18next";
import { IProperties } from "../../utils";

interface IProps {
    properties?: IProperties;
}
const PropertiesCell = ({ properties }: IProps) => {
    const { t } = useTranslation("followup");
    return (
        <div className="flex items-start">
            <div className="flex max-h-20 flex-col overflow-hidden">
                <div className="overflow-y-scroll">
                    {properties?.map((x) => (
                        <div className="flex flex-col">
                            <p className="text-sm text-mhdarkgreen-original lg:text-base">
                                {x.name === "EmploymentGroup"
                                    ? t(`reimbursableTypes.${x.value}`)
                                    : t(`reimbursableTypes.${x.name}`)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PropertiesCell;
