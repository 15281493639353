import Icon from "components/Icon";
import { twMerge } from "tailwind-merge";
import { ChangeEvent, ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface IServiceCheckbox {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
    disabled?: boolean;
    register?: UseFormRegisterReturn<string>;
    children?: ReactNode;
}

export const ServiceCheckbox = ({ onChange, checked, disabled, register }: IServiceCheckbox) => (
    <input
        className="h-5 w-5 cursor-pointer accent-[#839500] focus:shadow-none disabled:cursor-default"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        {...register}
    />
);

export const ServiceCheckboxRow = ({
    label,
    onChange,
    checked,
    disabled,
    register,
    children,
}: IServiceCheckbox & { label: string }) => (
    <div className="flex items-center justify-between border-b border-grey-300 py-4">
        <div className="w-full pl-4">
            <div>{label}</div>
        </div>

        {children ? (
            <div className="flex gap-20">{children}</div>
        ) : (
            <ServiceCheckbox onChange={onChange} checked={checked} disabled={disabled} register={register} />
        )}
        <div className="pr-6" />
    </div>
);

interface IServiceCheckboxContainer {
    heading?: string;
    className?: string;
    children: ReactNode;
    checkboxHeading?: string;
    secondaryCheckBoxHeading?: string;
    warning?: string;
    info?: string;
    toggle?: ReactNode;
}

export const ServiceCheckboxContainer = ({
    heading,
    className,
    children,
    checkboxHeading,
    secondaryCheckBoxHeading,
    warning,
    info,
    toggle,
}: IServiceCheckboxContainer) => (
    <div className={twMerge("py-10", className)}>
        <div className="flex items-center justify-between border-b border-grey-300 py-4">
            <div className="flex gap-2">
                {heading && <p className="pl-4 font-bold">{heading}</p>}
                {info && (
                    <div title={info}>
                        <Icon icon="info" />
                    </div>
                )}
                {warning && (
                    <div title={warning} className="text-bold text-orange-500 hover:cursor-default">
                        {"\u26A0"}
                    </div>
                )}
            </div>
            <div className="flex">
                {checkboxHeading && <p className="pr-3 font-bold">{checkboxHeading}</p>}
                {secondaryCheckBoxHeading && <p className="pl-5 font-bold">{secondaryCheckBoxHeading}</p>}
                {toggle && toggle}
            </div>
        </div>
        {children}
    </div>
);
