import { Trans, useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { fetchRehabRoutineDocuments, downloadRehabRoutineDocument } from "../../redux/followUpAsyncActions";
import { getRehabRoutineDocuments } from "../../redux/followUpSelectors";
import { getCompanyId } from "store/userSelectors";
import { Status } from "pages/FollowUp/redux";
import { Spinner } from "components/Spinner";
import Icon from "components/Icon";

const RehabRoutines = () => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const companyId = useAppSelector(getCompanyId);
    const rehabRoutineDocuments = useAppSelector(getRehabRoutineDocuments);

    const rehabRoutines: string[] = t("resources.rehabRoutines.rehabRoutines", {
        returnObjects: true,
    });

    useEffect(() => {
        if (companyId) {
            dispatch(fetchRehabRoutineDocuments(companyId));
        }
    }, [companyId, dispatch]);

    const handleDownloadDocument = (assetKey: string | undefined) => {
        if (companyId && assetKey) {
            dispatch(downloadRehabRoutineDocument({ assetKey, companyId }));
        }
    };

    return rehabRoutineDocuments.status === Status.PENDING ? (
        <Spinner />
    ) : (
        <ul className="mb-5 ml-7 list-outside list-disc text-base">
            <li>
                <Trans
                    t={t}
                    i18nKey={rehabRoutines[0]}
                    components={{
                        linkTag: (
                            <a
                                href="https://www.forsakringskassan.se/"
                                style={{
                                    textDecoration: "underline",
                                }}
                            />
                        ),
                    }}
                    values={{
                        linkTag: "www.forsakringskassan.se",
                    }}
                />
            </li>
            <li>
                <Trans
                    t={t}
                    i18nKey={rehabRoutines[1]}
                    components={{
                        linkTag: (
                            <a
                                href="http://www.av.se"
                                style={{
                                    textDecoration: "underline",
                                }}
                            />
                        ),
                    }}
                    values={{
                        linkTag: "http://www.av.see",
                    }}
                />
            </li>
            {rehabRoutineDocuments.data.map((document) =>
                document?.link ? (
                    <li>
                        <a href={document.link} className="cursor-pointer underline">
                            {document.description ?? document.link}
                        </a>
                    </li>
                ) : (
                    <li className="cursor-pointer" onClick={() => handleDownloadDocument(document.assetKey)}>
                        <div className="flex items-center">
                            <Icon icon="document" />
                            {document.description}
                        </div>
                    </li>
                ),
            )}
        </ul>
    );
};

export default RehabRoutines;
