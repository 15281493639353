import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IFileState } from "../../redux";
import CertificatesMetadata from "../CertificatesMetadata";

interface IProps {
    certificate: IFileState;
    remove?: () => void;
}
const HistoryCertificateHeader = React.memo<IProps>(({ certificate, remove }: IProps) => {
    const { t } = useTranslation();
    if (!certificate.metaData) return null;
    return (
        <div className="flex flex-1 justify-between">
            <div className="flex flex-col gap-2">
                <h3>{t("medicalCertificate")}</h3>
                <CertificatesMetadata metaData={certificate.metaData} />
            </div>
            {remove && (
                <div className="sm: mr-5 hidden sm:block">
                    <SecondaryButton
                        className="min-w-[150px]"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            remove();
                        }}
                        id="delete-medical-certificate-history"
                    >
                        <div className="just-center flex items-center gap-2">
                            <Icon icon="trashCan" />
                            {t("delete")}
                        </div>
                    </SecondaryButton>
                </div>
            )}
        </div>
    );
});
export default HistoryCertificateHeader;
