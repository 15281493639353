import { IAccessRightTypeEnum } from "interfaces/IAccountAccessRights";
import { AccountAccessRightViewModel } from "swagger/usercontextservice";

export const hasAbsenceReporting = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceReporting,
    );
};

export const hasAbsenceFollowup = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceFollowup,
    );
};

export const hasAbsenceAdministration = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceAdministration,
    );
};

export const hasCustomerCompanyAdministration = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.customerCompanyAdministration,
    );
};

export const hasCustomerCompanyAdministrationForCompany = (
    CompanyId: number,
    accessRights: AccountAccessRightViewModel[],
) => {
    return CompanyId
        ? accessRights.some(
              (accessRight) =>
                  accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.customerCompanyAdministration &&
                  accessRight.companyId === CompanyId,
          )
        : false;
};

export const hasCustomerSupport = (accessRights: AccountAccessRightViewModel[] | undefined) => {
    return Boolean(
        accessRights?.some(
            (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.customerSupport,
        ),
    );
};

export const hasFileImportAdministration = (accessRights: AccountAccessRightViewModel[] | undefined) => {
    return Boolean(
        accessRights?.some(
            (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.fileImportAdministration,
        ),
    );
};

export const hasAbsenceArchive = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceArchive,
    );
};

export const hasAbsenceView = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceView,
    );
};

export const hasDepartmenAdministrator = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.departmentAdministration,
    );
};

export const hasRehabDepartmentManagement = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.rehabDepartmentManagement,
    );
};

export const hasAbsenceStatistics = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceStatistics,
    );
};

export const hasTopLevelAbsenceStatistics = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) =>
            accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceStatistics &&
            accessRight.name?.includes("**TopDepartment**"),
    );
};

export const hasAbsenceBoard = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.absenceBoard,
    );
};

export const hasMedicalAdvisory = (accessRights: AccountAccessRightViewModel[]) => {
    return (
        accessRights?.some(
            (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.medicalAdvice,
        ) ||
        accessRights?.some(
            (accessRight) => accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.medicalAdvising,
        )
    );
};

export const hasCustomerOrganizationAdministration = (accessRights: AccountAccessRightViewModel[]) => {
    return accessRights?.some(
        (accessRight) =>
            accessRight.accessRightType?.valueOf() === IAccessRightTypeEnum.customerOrganizationAdministration,
    );
};
