import { Card } from "@medhelp/ui";

interface IProps {
    header: string;
    body: string;
}
const Preview = ({ header, body }: IProps) => (
    <Card>
        <div className="flex max-w-[480px] flex-col items-center justify-center gap-5">
            <p className="text-center font-serif text-xl">{header}</p>
            <div>
                <div className="text-center">{body}</div>
            </div>
        </div>
    </Card>
);

export default Preview;
