import Icon from "components/Icon";
import RowDetails from "./RowDetails";
import DownloadCsv from "./DownloadCsv";
import { useTranslation } from "react-i18next";
import { useGetFileQuery } from "store/rtk-apis/fileImport/fileImportApi";

interface FilesProps {
    customerKey: number;
    importId: string;
}

const Files = ({ customerKey, importId }: FilesProps) => {
    const { data: files, error } = useGetFileQuery({ importId });

    const { t } = useTranslation("fileImport");
    if (!files || error) return <></>;

    return (
        <div className="flex flex-col gap-6 py-8 md:px-8">
            <h2 className="px-4 md:px-0">{t("uploadedFiles")}</h2>
            {files?.map((item) => (
                <div key={item.key} className="flex flex-col gap-8 rounded-lg bg-white p-4 md:p-8">
                    <div className="text-lg font-bold">{item.type ? t(item.type) : "unkown type"}</div>
                    <div className="flex items-center justify-between gap-2 rounded-lg border p-3 md:p-6">
                        <div className="flex items-center gap-2">
                            <div>
                                <Icon icon="document" size={32} />
                            </div>
                            <div className="break-all text-xs md:text-sm">{item.key ?? "???"}</div>
                        </div>

                        {item.id && <DownloadCsv id={item.id} filename={item.key || item.type || item.id} />}
                    </div>
                    <div>
                        <div className="pb-1.5 font-bold">{t("rowDetails")}</div>
                        <div className="text-grey-700">
                            {item.totalRows} {t("rows")}
                        </div>
                    </div>
                    <RowDetails customerKey={customerKey} importId={importId} fileId={item.id} />
                </div>
            ))}
        </div>
    );
};

export default Files;
