import { useEffect, useRef } from "react";
//@ts-ignore
import WebViewer from "@pdftron/pdfjs-express";
import { SpinnerWithOverlay } from "components/Spinner";

interface IProps {
    pdfUrl: string;
    pdfFileName: string;
    isSaving?: boolean;
    className?: string;
    annotation?: string;
    saveButtonText?: string;
    saveAnnotation?: (annotation: string) => void;
    readonly?: boolean;
}
const PdfViewer = ({
    pdfUrl,
    pdfFileName,
    className,
    saveButtonText,
    isSaving,
    annotation,
    saveAnnotation,
    readonly,
}: IProps) => {
    const viewer = useRef(null);
    const formKeyValue = new Map();

    useEffect(() => {
        WebViewer(
            {
                licenseKey: "0yL0h7vSsgj0kidrSAuF",
                path: "/webviewer/lib",
                // disableFlattenedAnnotations: true,
            },
            viewer.current,
        ).then((instance: any) => {
            const { documentViewer, annotationManager, Annotations } = instance.Core;

            // Load pdf document
            instance.UI.loadDocument(pdfUrl, { filename: pdfFileName });

            const getCustomStyles = (widget: any, print: boolean) => {
                if (widget instanceof Annotations.TextWidgetAnnotation) {
                    if (widget.fieldName === "f1-1" && !print) {
                        return {
                            "background-color": "#81943d",
                        };
                    }
                    if (widget.fieldName !== "f1-1" && (print || readonly)) {
                        return {
                            "background-color": "transparent",
                            color: "black",
                        };
                    }
                    return {
                        "background-color": "rgb(66 153 225 / 0.5)",
                        color: "black",
                    };
                }

                if (widget instanceof Annotations.PushButtonWidgetAnnotation) {
                    return {
                        "background-color": "black",
                        color: "white",
                    };
                }

                if (widget instanceof Annotations.CheckButtonWidgetAnnotation) {
                    if (print || readonly) {
                        return {
                            "background-color": "white",
                            opacity: 0.8,
                        };
                    }
                    return {
                        "background-color": "lightgray",
                        opacity: 0.8,
                    };
                }
            };

            // Delete standard menu header, not used
            instance.UI.setHeaderItems(function (header: any) {
                // get the tools overlay
                const toolsOverlay = header.getHeader("toolbarGroup-Annotate").get("toolsOverlay");
                header.getHeader("toolbarGroup-Annotate").delete("toolsOverlay");
                // add the line tool to the top header

                header.push(toolsOverlay);
            });

            // hide ribbons and header items
            instance.UI.disableElements(["ribbons"]);
            instance.UI.disableElements(["toolsHeader"]);
            instance.UI.disableElements(["printButton"]);
            instance.UI.disableElements(["selectToolButton"]);
            instance.UI.disableElements(["toggleNotesButton"]);

            documentViewer.setMargin(20);

            // Styling for form -> editable fields in PDF
            Annotations.WidgetAnnotation.getCustomStyles = (widget: any) => getCustomStyles(widget, false);

            if (!saveAnnotation) return;

            // Populate PDF fields when document loaded
            documentViewer.addEventListener("documentLoaded", () => {
                documentViewer.getAnnotationsLoadedPromise().then(() => {
                    if (annotation && annotation !== "") {
                        formKeyValue.clear();
                        const annotationObject = JSON.parse(annotation);
                        const fieldManager = annotationManager.getFieldManager();
                        for (const key in annotationObject) {
                            const field = fieldManager.getField(key);
                            if (field) {
                                field.setValue(annotationObject[key]);
                                formKeyValue.set(key, annotationObject[key]);
                            }
                        }
                    }
                });
            });

            // Set print quality to high
            instance.UI.setPrintQuality(2);

            // Create print button in main menus
            instance.UI.setHeaderItems((header: any) => {
                header.getHeader("default").push({
                    img: "icon-header-print-line",
                    type: "actionButton",
                    element: "printButton",
                    onClick: () => {
                        instance.UI.print();
                    },
                });
            });

            if (!saveAnnotation || readonly) return;

            annotationManager.addEventListener("fieldChanged", (field: any, value: any) => {
                formKeyValue.set(field.name, value);
            });

            // Styling for form -> remove blue fields for print
            instance.iframeWindow.addEventListener("visibilityChanged", (event: any) => {
                if (event.detail.element !== "printModal") return;

                // Get page count
                const pageCount = documentViewer.getPageCount();

                if (event.detail.isVisible)
                    Annotations.WidgetAnnotation.getCustomStyles = (widget: any) => getCustomStyles(widget, true);

                if (!event.detail.isVisible)
                    Annotations.WidgetAnnotation.getCustomStyles = (widget: any) => getCustomStyles(widget, false);

                for (let i = 0; i < pageCount; i++) {
                    annotationManager.drawAnnotations(i + 1, null, true);
                }
            });

            // Custom save button
            instance.UI.setHeaderItems((header: any) => {
                header.push({
                    render: () => {
                        return (
                            <button
                                id="actions-button"
                                onClick={async () => {
                                    const obj = Object.fromEntries(formKeyValue);
                                    const annotation = JSON.stringify(obj);
                                    saveAnnotation(annotation);
                                }}
                                style={{
                                    fontWeight: "700",
                                    color: "#192d05",
                                    backgroundColor: "#e1f550",
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                    height: "2.5rem",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    fontFamily: "inherit",
                                    fontSize: "100%",
                                    lineHeight: "inherit",
                                    boxSizing: "border-box",
                                    borderWidth: 0,
                                    borderStyle: "solid",
                                    borderColor: "#e5e7eb",
                                    margin: 0,
                                }}
                            >
                                <div>{saveButtonText}</div>
                            </button>
                        );
                    },
                    type: "customElement",
                });
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfUrl]);

    return (
        <div>
            <div className={"webviewer absolute h-full w-full " + className} ref={viewer}>
                {isSaving && <SpinnerWithOverlay />}
            </div>
        </div>
    );
};

export default PdfViewer;
