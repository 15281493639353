import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
//import { RootStateOrAny } from "react-redux";
import {
    MedHelpAbsenceReportingDomainTemplate,
    MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport,
} from "swagger/absencereportapi";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";
import PrimaryButton from "components/PrimaryButton";
import Select, { ISelectValue } from "components/Select";
import SecondaryButton from "components/SecondaryButton";
import { SpinnerWithOverlay } from "components/Spinner";
import * as UserSelectors from "store/userSelectors";
import { thunkActions, IAbsenceReportSlice } from "../../redux";
import { SingleDatepicker } from "@medhelp/ui";
import { useSingleDatepickerProps } from "hooks";
import { getDateStringForwardOrBack } from "utils/date";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store";

interface IAbsenceReportCreateFinishedCard {
    id: string;
    preset: IRegisterHistoricalArCardPresets;
    className?: string;
    absenceTypes: ISelectValue[];
    templates: MedHelpAbsenceReportingDomainTemplate[];
    employeeId: string;
    cancelOnClick: () => void;
    saveOnClick: () => void;
}

interface FinishedAbsenceReportFieldsMap {
    [dynamicFieldKey: string]: {
        value: string;
        name: string;
        isMandatory: boolean;
    };
}

const registerHistoricalArCardPresets = {
    background: "bg-pageBg",
    red: "bg-red-500",
};

export type IRegisterHistoricalArCardPresets = keyof typeof registerHistoricalArCardPresets;

const AbsenceReportCreateFinishedCard = (props: IAbsenceReportCreateFinishedCard & { children?: React.ReactNode }) => {
    const { children, id, preset, className, absenceTypes, templates, employeeId, cancelOnClick, saveOnClick } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [finishedAbsenceReportFields, setFinishedAbsenceReportFields] = useState<FinishedAbsenceReportFieldsMap>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [absenceCauses, setAbsenceCauses] = useState<ISelectValue[] | null>(null);
    const [displayFK, setDisplayFK] = useState(false);

    const userContext = useAppSelector(UserSelectors.getUserContext);

    const { CreateFinishedAbsenceReportOK } = useAppSelector(
        (state: RootState) => state.absenceReports as IAbsenceReportSlice,
    );

    const setFieldValue = (fieldName: string, newValue: string) => {
        finishedAbsenceReportFields[fieldName].value = newValue;
        setFinishedAbsenceReportFields({ ...finishedAbsenceReportFields });
    };
    const isInValid = Object.keys(finishedAbsenceReportFields)
        .map((historicArFieldName) => {
            const arField = finishedAbsenceReportFields[historicArFieldName];
            if (arField.isMandatory) {
                if (arField.value) {
                    return true;
                }
                return false;
            }
            return true;
        })
        .some((a) => a === false);

    useEffect(() => {
        const fieldMap: FinishedAbsenceReportFieldsMap = {};
        fieldMap.absenceType = {
            name: "absenceType",
            value: selectedTemplate ? selectedTemplate : "",
            isMandatory: true,
        };
        fieldMap.startDate = {
            name: "startDate",
            value: "",
            isMandatory: true,
        };
        fieldMap.endDate = {
            name: "endDate",
            value: "",
            isMandatory: true,
        };
        fieldMap.backAtWorkDate = {
            name: "backAtWorkDate",
            value: "",
            isMandatory: true,
        };
        fieldMap.degree = {
            name: "degree",
            value: "",
            isMandatory: true,
        };
        fieldMap.absenceCause = {
            name: "absenceCause",
            value: "",
            isMandatory: false,
        };
        fieldMap.sendToSocialInsurance = {
            name: "sendToSocialInsurance",
            value: "",
            isMandatory:
                userContext.user?.countryCode?.toLowerCase() === "se" && selectedTemplate === "Sick" ? true : false,
        };
        setFinishedAbsenceReportFields({ ...fieldMap });
    }, [selectedTemplate, userContext]);

    useEffect(() => {
        const absenceCauseField = templates
            ?.find((c: MedHelpAbsenceReportingDomainTemplate) => c.absenceReportType === selectedTemplate!)
            ?.fields?.find((field) => field.name === "AbsenceCause");
        const causes: ISelectValue[] = absenceCauseField?.options?.map((option) => {
            return {
                description: option.description!,
                value: option.value!,
            };
        })!;

        if (selectedTemplate?.toLocaleLowerCase() === "sick") {
            if (!absenceCauses) setAbsenceCauses(causes);
            setDisplayFK(true);
        } else {
            setDisplayFK(false);
            setAbsenceCauses(null);
        }
    }, [selectedTemplate, templates, absenceCauses]);

    useEffect(() => {
        if (
            finishedAbsenceReportFields?.absenceCause &&
            finishedAbsenceReportFields?.absenceCause.isMandatory &&
            absenceCauses?.length === 0
        ) {
            finishedAbsenceReportFields.absenceCause.isMandatory = false;
            setFinishedAbsenceReportFields({ ...finishedAbsenceReportFields });
        }
    }, [absenceCauses, finishedAbsenceReportFields]);

    useEffect(() => {
        if (CreateFinishedAbsenceReportOK) {
            saveOnClick();
        }
    }, [CreateFinishedAbsenceReportOK, saveOnClick]);

    const handleSaveOnClick = () => {
        const absenceReportFinished: MedHelpAbsenceReportingWebApiModelsCreateHistoricalAbsenceReport = {
            type: finishedAbsenceReportFields.absenceType.value,
            employmentId: employeeId,
            start: finishedAbsenceReportFields.startDate.value,
            end: finishedAbsenceReportFields.endDate.value,
            backAtWork: finishedAbsenceReportFields.backAtWorkDate.value,
            degree: Number(finishedAbsenceReportFields.degree.value),
            sendToSocialInsurance: finishedAbsenceReportFields.sendToSocialInsurance.value === "true",
            absenceCause: finishedAbsenceReportFields.absenceCause.value ?? "",
        };
        dispatch(thunkActions.finishedAbsenceReport(absenceReportFinished));
        setIsLoading(true);
    };

    const startDateDpProps = useSingleDatepickerProps({
        onChangeDate: (v) => {
            finishedAbsenceReportFields.startDate.value = v ?? "";
            setFinishedAbsenceReportFields({
                ...finishedAbsenceReportFields,
            });
        },
        givenSelectedDate: finishedAbsenceReportFields.startDate?.value,
        placeholder: t("addDate"),
    });
    const endDateDpProps = useSingleDatepickerProps({
        onChangeDate: (v) => {
            finishedAbsenceReportFields.endDate.value = v ?? "";
            (finishedAbsenceReportFields.backAtWorkDate.value = v
                ? getDateStringForwardOrBack(1, "days", "forward", v)
                : ""),
                setFinishedAbsenceReportFields({
                    ...finishedAbsenceReportFields,
                });
        },
        givenSelectedDate: finishedAbsenceReportFields.endDate?.value,
        placeholder: t("addDate"),
    });

    const backAtWorkDateProps = useSingleDatepickerProps({
        onChangeDate: (v) => {
            finishedAbsenceReportFields.backAtWorkDate.value = v ?? "";
            setFinishedAbsenceReportFields({
                ...finishedAbsenceReportFields,
            });
        },
        givenSelectedDate: finishedAbsenceReportFields.backAtWorkDate?.value ?? "",
        placeholder: t("addDate"),
    });

    const maxMonthsToRegisterHistoricalAbsence = userContext.HasCustomerSupport
        ? 36
        : userContext.HasAbsenceAdministration
          ? 12
          : 1;

    const disableDatesBefore = getDateStringForwardOrBack(maxMonthsToRegisterHistoricalAbsence, "months", "backwards");

    const oneDayBack = useMemo((): string => getDateStringForwardOrBack(1, "days", "backwards"), []);

    return (
        <div
            id={id}
            className={`relative p-6 shadow-custom xxs:p-8 xxs:px-10 ${registerHistoricalArCardPresets[preset]} ${className}`}
        >
            {isLoading && <SpinnerWithOverlay />}
            <div>
                <h4>{t("registerCompletedAbsence")}</h4>
            </div>
            <p className="mt-4 text-grey-600 lg:mt-6 lg:w-3/5">
                {t("registerHistoricalAbsenceMaxMonths", {
                    months: maxMonthsToRegisterHistoricalAbsence,
                })}
            </p>
            <div className="flex flex-wrap gap-4">
                <AbsenceReportFieldWrapper heading={`${t("kindOfAbsence")}`} isMandatory>
                    <Select
                        id="absenceTypeGroup"
                        placeholder={t("choseAbsenceType")}
                        hidePlaceHolderInList
                        values={absenceTypes}
                        onChange={(v) => {
                            setFieldValue("absenceType", v!);
                            setSelectedTemplate(v!);
                        }}
                        selectedValue={finishedAbsenceReportFields.absenceType?.value}
                        className="xs:w-60"
                    />
                </AbsenceReportFieldWrapper>

                {selectedTemplate && (
                    <>
                        <AbsenceReportFieldWrapper heading={t("startDate")} isMandatory>
                            <SingleDatepicker
                                {...startDateDpProps}
                                disableDatesBefore={disableDatesBefore}
                                disableDatesAfter={finishedAbsenceReportFields.endDate?.value || oneDayBack}
                                heading={t("startDate")}
                                isMandatory
                            />
                        </AbsenceReportFieldWrapper>

                        <AbsenceReportFieldWrapper heading={t("endDate")} isMandatory>
                            <SingleDatepicker
                                {...endDateDpProps}
                                disableDatesBefore={finishedAbsenceReportFields.startDate?.value || oneDayBack}
                                disableDatesAfter={oneDayBack}
                                className="xs:w-56"
                                heading={t("endDate")}
                                isMandatory
                            />
                        </AbsenceReportFieldWrapper>

                        <AbsenceReportFieldWrapper heading={t("backAtWork")} isMandatory>
                            <SingleDatepicker
                                {...backAtWorkDateProps}
                                disableDatesBefore={finishedAbsenceReportFields.endDate?.value}
                                className="xs:w-60"
                                disabled={!finishedAbsenceReportFields.endDate?.value}
                                heading={t("backAtWork")}
                                isMandatory
                            />
                        </AbsenceReportFieldWrapper>

                        <AbsenceReportFieldWrapper
                            heading={t("absenceDegree")}
                            isMandatory
                            modalHeading={t("absenceDegreeInfoHistoricalModalHeader")}
                            modalTopContent={t("absenceDegreeInfoHistoricalModalContent")}
                        >
                            <Select
                                id="absenceDegreeSelect"
                                placeholder={t("choseDegree")}
                                hidePlaceHolderInList
                                values={[
                                    { value: "100", description: "100%" },
                                    { value: "75", description: "75%" },
                                    { value: "50", description: "50%" },
                                    { value: "25", description: "25%" },
                                ]}
                                onChange={(v) => setFieldValue("degree", v!)}
                                selectedValue={finishedAbsenceReportFields.degree?.value}
                                className="xs:w-60"
                            />
                        </AbsenceReportFieldWrapper>

                        {absenceCauses && (
                            <AbsenceReportFieldWrapper heading={t("absenceCause")} isMandatory>
                                <Select
                                    id="absenceCauseSelect"
                                    placeholder={t("chooseValue")}
                                    hidePlaceHolderInList
                                    values={absenceCauses}
                                    onChange={(v) => setFieldValue("absenceCause", v!)}
                                    selectedValue={finishedAbsenceReportFields.absenceCause?.value}
                                    className="xs:w-60"
                                />
                            </AbsenceReportFieldWrapper>
                        )}
                        {userContext.user?.countryCode?.toLowerCase() === "se" && displayFK && (
                            <AbsenceReportFieldWrapper
                                heading={t("socialInsurance")}
                                isMandatory
                                modalHeading={t("sendToSocialInsurance")}
                                modalTopContent={t("socialInsuranceInfoTop")}
                                modalBottomContent={t("socialInsuranceInfoBottom")}
                            >
                                <Select
                                    id="socialInsuranceReport"
                                    placeholder={t("sendToSocialInsurance")}
                                    hidePlaceHolderInList
                                    values={[
                                        {
                                            value: "true",
                                            description: t("yesRegister"),
                                        },
                                        {
                                            value: "false",
                                            description: t("noRegister"),
                                        },
                                    ]}
                                    onChange={(v) => setFieldValue("sendToSocialInsurance", v!)}
                                    selectedValue={finishedAbsenceReportFields.sendToSocialInsurance?.value}
                                    className="xs:w-60"
                                />
                            </AbsenceReportFieldWrapper>
                        )}
                    </>
                )}
            </div>
            <div className="mt-10 flex justify-between xxs:mr-10 xxs:mt-8 lg:absolute lg:right-0 lg:top-0">
                <SecondaryButton
                    id="registerHistArCancelBtn"
                    text={t("abort")}
                    onClick={cancelOnClick}
                    className="mr-1 flex w-full justify-center"
                />
                <PrimaryButton
                    id="registerHistArSaveBtn"
                    text={t("save")}
                    onClick={handleSaveOnClick}
                    className="ml-1 flex w-full justify-center"
                    disabled={isInValid}
                />
            </div>
            {children}
        </div>
    );
};
export default AbsenceReportCreateFinishedCard;
