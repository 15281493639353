import Icon from "components/Icon";
import { isEmpty } from "lodash/fp";
import ErrorWidget from "../ErrorWidget";
import { useRetailerColors } from "hooks";
import HCMTodoLoader from "./HCMTodoLoader";
import { useEffect, useState } from "react";
import TodoChart from "../../Charts/TodoChart";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import { getHCMTemplates, getHCMTodoAndOngoing, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const HCMTodo = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const { pageBackground } = useRetailerColors();
    const templates = useAppSelector(getHCMTemplates);
    const { data, status } = useAppSelector(getHCMTodoAndOngoing(id));
    const isDragging = useAppSelector(getIsDragging(id));

    const [currentlySelected, setCurrentlySelected] = useState(0);
    const currentData = data[currentlySelected];

    useEffect(() => {
        if (!isEmpty(templates) && !isDragging) {
            dispatch(fetchWidgetData({ id, type }));
        }
    }, [dispatch, id, type, templates, isDragging]);

    if (status === Status.PENDING) {
        return <HCMTodoLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <>
            <div className="flex w-full justify-between px-6 pb-4 pt-5 text-sm">
                {data.map((value, index) => (
                    <div key={value.key} className="flex items-center pr-2">
                        <label
                            htmlFor={value.key}
                            onChange={() => setCurrentlySelected(index)}
                            className="flex cursor-pointer items-center"
                        >
                            <input className="hidden" type="radio" id={value.key} name="todo" />
                            <div>
                                <Icon
                                    icon={index === currentlySelected ? "radioActive" : "radio"}
                                    fill={pageBackground}
                                    stroke={pageBackground}
                                />
                            </div>
                            <p className="pl-1 font-sans text-xs text-pageBg">
                                {`${t(value.key)} (${data[index]?.data?.length})`}
                            </p>
                        </label>
                    </div>
                ))}
            </div>
            <TodoChart data={currentData.data ?? []} type={currentData.key} />
        </>
    );
};

export default HCMTodo;
