import { ReactNode, useState } from "react";
import { ReactComponent as AccordionArrowDown } from "icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "icons/accordionArrowUp.svg";

export interface IProps {
    heading: string;
    intro: string | ReactNode;
    children?: ReactNode;
}

const SupportAccordion = ({ heading, intro, children }: IProps) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="border-b">
            <div onClick={() => setOpen(!open)} className="flex h-15 cursor-pointer items-center justify-between px-6">
                <h3>{heading}</h3>
                {open ? <AccordionArrowUp /> : <AccordionArrowDown />}
            </div>
            {open && (
                <div className="px-6 pb-9 pt-3">
                    <p className="border-b pb-7 text-lg">{intro}</p>
                    {children}
                </div>
            )}
        </div>
    );
};

export default SupportAccordion;
