const SpinnerPage = () => (
    <div className="flex h-80vh flex-col pt-4">
        <div className="m-auto flex">
            <div className="lds-ring -mt-20">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default SpinnerPage;
