import { createPasswordReset, getResetRequest, passwordReset } from "pages/login/redux/loginAsyncActions";
import { loginActions } from "pages/login/redux/loginSlice";
import { IResetPassSliceProps, ResetPassTranslations } from "pages/login/types";
import { getPwdMissing } from "pages/UserSettings/utils";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getTranslationsFromArray } from "utils/functions";
import { selectors as LoginSelectors } from "./../../redux";
import ResetPass from "./ResetPass";

export default function Index() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("login");
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const resetPassProps = useAppSelector(LoginSelectors.getResetPassProps);
    const failedAt = useAppSelector(LoginSelectors.failedAt);
    const isLoading = useAppSelector(LoginSelectors.isLoading);
    const userIdentifier = useAppSelector(LoginSelectors.userIdentifier);
    const pwdMissing = useAppSelector(LoginSelectors.pwdMissing);
    const newPass = useAppSelector(LoginSelectors.newPass);
    const confirmNewPass = useAppSelector(LoginSelectors.confirmNewPass);
    const translations = useMemo(() => getTranslationsFromArray(ResetPassTranslations, t), [t]);
    const renderPasswordStrength: ReactNode = useMemo(() => {
        if (pwdMissing.length > 2) {
            return (
                <>
                    <div className="absolute h-1.5 w-1/3 bg-mhred-alert3" />
                    <p className="text-right text-sm">{t("weakPwd")}</p>
                </>
            );
        } else if (pwdMissing.length === 0) {
            return (
                <>
                    <div className="w-3/3 absolute h-1.5 bg-primary-700" />
                    <p className="text-right text-sm">{t("strongPwd")}</p>
                </>
            );
        } else {
            return (
                <>
                    <div className="absolute h-1.5 w-2/3 bg-mhblue" />
                    <p className="text-right text-sm">{t("averagePwd")}</p>
                </>
            );
        }
    }, [pwdMissing.length, t]);

    const newPassErrorMessage = useMemo(
        () =>
            pwdMissing.length !== 0 && pwdMissing.length !== 5
                ? `${t("pwdIsMissingA")} ${pwdMissing.join(", ")}`
                : t("mustStateNewPass"),
        [pwdMissing, t],
    );

    const confirmNewPassError = useMemo(
        () => !!confirmNewPass && confirmNewPass !== newPass,
        [confirmNewPass, newPass],
    );

    const newPassError = useMemo(() => pwdMissing.length !== 0 && pwdMissing.length !== 5, [pwdMissing.length]);

    const formIsInvalid = useMemo(
        () => newPassError || !confirmNewPass || confirmNewPassError,
        [confirmNewPass, confirmNewPassError, newPassError],
    );

    const userIdentifierInvalid = useMemo(() => {
        const emailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
        const ssnRegex = new RegExp(/^(\d+-?)+\d+$/);
        return emailRegex.test(userIdentifier) || (userIdentifier.length > 9 && ssnRegex.test(userIdentifier));
    }, [userIdentifier]);

    const handleSubmit = useCallback(
        (isResetInit?: boolean) => {
            isResetInit
                ? dispatch(
                      createPasswordReset({
                          accountIdentifier: userIdentifier,
                      }),
                  )
                : dispatch(
                      passwordReset({
                          accountIdentifier: resetPassProps.passResetViewModel.accountIdentifier,
                          requestIdentifier: resetPassProps.passResetViewModel.requestIdentifier,
                          newPassword: newPass,
                          newPasswordMatch: confirmNewPass,
                      }),
                  );
        },
        [
            confirmNewPass,
            dispatch,
            newPass,
            resetPassProps.passResetViewModel.accountIdentifier,
            resetPassProps.passResetViewModel.requestIdentifier,
            userIdentifier,
        ],
    );

    const handleResetPassSetValues = useCallback(
        (newValue: string | string[], prop: keyof IResetPassSliceProps) => {
            dispatch(loginActions.setResetPassPropValue({ newValue, prop }));
        },
        [dispatch],
    );

    const handleNavigate = useCallback(
        (navigateTo: string) => {
            navigate(navigateTo);
        },
        [navigate],
    );

    const identifierFromQuery = useMemo(() => searchParams.get("id"), [searchParams]);

    useEffect(() => {
        if (identifierFromQuery && identifierFromQuery.length === 65) {
            dispatch(getResetRequest(identifierFromQuery));
        }
    }, [dispatch, identifierFromQuery]);

    useEffect(() => {
        handleResetPassSetValues(getPwdMissing(newPass, t), "pwdMissing");
    }, [handleResetPassSetValues, newPass, t]);

    return (
        <ResetPass
            {...resetPassProps}
            isLoading={isLoading}
            failedAt={failedAt}
            formIsInvalid={formIsInvalid}
            newPassError={newPassError}
            newPassErrorMessage={newPassErrorMessage}
            confirmNewPassError={confirmNewPassError}
            setResetPassPropValue={handleResetPassSetValues}
            handleSubmit={handleSubmit}
            translations={translations}
            navigate={handleNavigate}
            renderPasswordStrength={renderPasswordStrength}
            userIdentifierInvalid={userIdentifierInvalid}
        />
    );
}
