import { NewsModal } from "@medhelp/ui";
import { useTranslation } from "react-i18next";

const FortnoxNewsModal = () => {
    const { t } = useTranslation();
    return (
        <NewsModal
            header={t("addManagers")}
            body={
                <div className="text-left">
                    <p className="text-bold">{t("managersCanAccess")}</p>
                    <br />
                    <ul className="ml-7 list-outside list-disc">
                        <li>{t("loginViaBankID")}</li>
                        <li>{t("employeesAbsenceHistory")}</li>
                        <li>{t("statisticsAndReports")}</li>
                        <li>{t("rehabTools")}</li>
                    </ul>
                </div>
            }
            buttonText={t("close")}
            theme={"Medhelp"}
            storage="hide-fortnox-administration"
            icon="dashboardSketch"
        />
    );
};

export default FortnoxNewsModal;
