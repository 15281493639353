import Icon from "components/Icon";
import PowerbarSearch from "components/PowerbarSearch";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import { LanguageDropdownMenu } from "./LanguageDropdownMenu";
import { SettingsDropdownMenu } from "./SettingsDropdownMenu";

const MenuNavBar = () => {
    const hasCustomerSupport = useAppSelector(UserSelectors.getHasCustomerSupport);
    const hasFileImportAdministration = useAppSelector(UserSelectors.getHasFileImportAdministration);

    return (
        <div className="z-10 flex items-center justify-between px-0 xl:sticky xl:mt-0 xl:h-14 xl:w-full xl:bg-white xl:pr-14 xl:shadow-topNav">
            <div className="flex h-full items-start xl:w-[340px]">{hasCustomerSupport && <PowerbarSearch />}</div>
            <div className="z-11 flex items-center gap-8 text-menuNavColorSecondary max-xl:hidden">
                <LanguageDropdownMenu />
                {(hasFileImportAdministration || hasCustomerSupport) && <SettingsDropdownMenu />}
                <a href="https://helpcenter.medhelp.se/" className="flex items-center justify-center" target="_blank">
                    <Icon icon="questionMarkCircle" size={16} />
                    <span className="pl-1.5 text-sm capitalize text-menuNavColorSecondary">Helpcenter</span>
                </a>
            </div>
            <div className="z-11 flex gap-5 text-menuNavColorPrimary xl:hidden">
                <LanguageDropdownMenu />
                {(hasFileImportAdministration || hasCustomerSupport) && <SettingsDropdownMenu />}
                <a
                    className="flex items-center justify-center"
                    aria-hidden="true"
                    tabIndex={-1}
                    href="https://helpcenter.medhelp.se/"
                >
                    <Icon icon="questionMarkCircle" size={28} />
                </a>
            </div>
        </div>
    );
};

export default MenuNavBar;
