import { useState } from "react";
import { Link } from "react-router-dom";
import { ModalFrame } from "@medhelp/ui";
import { getDateDisplayValue } from "utils/date";
import EmployeeSummaryTable from "../Tables/EmployeeSummaryTable";
import { IEmployeeDetails } from "pages/dashboard/utils/dashboardTypes";
import OngoingRehabProcessesTable from "../Tables/OngoingRehabProcessesTable";
export interface IListData {
    heading: string;
    data: number | string;
    modalHeading?: string;
    employmentId?: string;
    hasFollowUp?: boolean;
    employeeDetails?: IEmployeeDetails[];
    type?: string;
}
interface IListChart {
    locale?: string;
    keyHeading: string;
    dataHeading: string;
    hasDate: boolean;
    hasModal: boolean;
    color: string;
    id: string;
    employeeCardButton?: boolean;
    data: IListData[];
    isRehab?: boolean;
}

const ListChart = ({
    locale,
    keyHeading,
    dataHeading,
    hasDate,
    hasModal,
    color,
    employeeCardButton,
    data,
    isRehab,
}: IListChart) => {
    const [type, setType] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [heading, setHeading] = useState<string | undefined>("");

    const showData = (listData: IListData) => {
        setType(listData.type || "");
        setHeading(listData.modalHeading);
        setOpenModal(true);
    };

    const closeModal = () => setOpenModal(false);

    return (
        <div className="flex h-full justify-center pb-10 pt-3">
            <ul className="flex w-full flex-col px-6 text-sm">
                <li className="flex h-10 items-center justify-between pb-2 font-bold">
                    <div>{keyHeading}</div>
                    <div>{dataHeading}</div>
                </li>
                {data.map((item, i) => (
                    <li
                        key={i + item.heading + item.data}
                        className={`flex h-11 items-center justify-between border-t px-2 py-3 ${
                            item.heading === "Total" && "font-bold"
                        } ${color === "primary" ? "border-primaryBorder" : "border-secondaryBorder"}`}
                    >
                        {employeeCardButton && item.hasFollowUp ? (
                            <Link to={`/followup/employee/${item.employmentId}/absence`} className="underline">
                                {item.heading}
                            </Link>
                        ) : (
                            <div>{item.heading}</div>
                        )}
                        {item.heading === "Total" || !hasModal || item.data === 0 ? (
                            <div className={`${item.heading === "Total" && "font-bold"}`}>
                                {hasDate ? getDateDisplayValue(item.data.toString(), "dd MMM yyyy", locale) : item.data}
                            </div>
                        ) : (
                            <button type="button" onClick={() => showData(item)} className="underline">
                                {item.data}
                            </button>
                        )}
                    </li>
                ))}
            </ul>
            <ModalFrame
                id="widgetEmployeeModal"
                open={openModal}
                setOpen={closeModal}
                header={<h3>{heading}</h3>}
                preset="full"
                content={
                    isRehab ? (
                        <OngoingRehabProcessesTable type={type} data={data} />
                    ) : (
                        <EmployeeSummaryTable
                            keyName={type}
                            filter={data.find((x) => x.type === type)?.employeeDetails?.map((x) => x.employmentId)}
                        />
                    )
                }
            />
        </div>
    );
};
export default ListChart;
