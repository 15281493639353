import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { thunkActions } from "../../../redux";
import { IHealthCase, IHealthCaseDocument } from "pages/FollowUp/redux/followUpStateTypes";
import { getDateTimeDisplayValue } from "utils/date";
import { activeCaseTypes } from "../../../utils/case";
import { toast } from "react-toastify";
import { PdfViewerModal } from "..";
import Icon, { Icons } from "components/Icon";
import MiniMenu from "components/MiniMenu";

interface IDocumentActionValues {
    text: string;
    onClick: () => void;
    icon: Icons;
    key: string;
    id: string;
}

interface IProps {
    healthCase: IHealthCase;
    document: IHealthCaseDocument;
}

const CaseDocumentItem = (props: IProps) => {
    const { healthCase, document } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const getDocumentActionValues = () => {
        const actionValues: IDocumentActionValues[] = [
            {
                text: t("editDocument"),
                onClick: () => {
                    document.templateDocumentId
                        ? setModalIsOpen(!modalIsOpen)
                        : dispatch(
                              thunkActions.downloadCaseDocument({
                                  healthCaseId: healthCase.id!,
                                  documentId: document.id!,
                              }),
                          );
                },
                icon: "edit",
                key: `case-document-item-edit-${document?.id}`,
                id: "edit",
            },
            {
                text: t("deleteDocument"),
                onClick: () => {
                    if (document.id) {
                        dispatch(
                            thunkActions.deleteCaseDocument({
                                healthCaseId: healthCase.id!,
                                documentId: document.id,
                            }),
                        )
                            .then(unwrapResult)
                            .then(() => toast(t("documentRemoved")))
                            .catch((e) =>
                                toast(
                                    `${e?.response?.status ? `${e.response.status} - ` : ""}${t("documentNotRemoved")}`,
                                    {
                                        type: "error",
                                    },
                                ),
                            );
                    }
                },
                icon: "trashCan",
                key: `case-document-item-delete-${document?.id}`,
                id: "delete",
            },
        ];
        if (document.type === "file") {
            return actionValues;
        } else {
            return actionValues.filter((action) => action.id === "delete");
        }
    };

    const getCreatedEditedDateText = () => {
        if (document.updated) {
            return `${`${t("editedBy")} ${
                document.createdByUserName || t("createdByUnknown").toLowerCase()
            }`} ${getDateTimeDisplayValue(document.updated, "d MMM yyyy, HH:mm")}`;
        } else {
            return `${`${t("addedBy")} ${
                document.createdByUserName || t("createdByUnknown").toLowerCase()
            }`} ${getDateTimeDisplayValue(document.created, "d MMM yyyy, HH:mm")}`;
        }
    };

    return (
        <div className="flex justify-between border-t border-grey-300 px-5 py-3 sm:px-8">
            <div className="flex items-center">
                <div className="mr-3 sm:mr-8">
                    <Icon icon="document" size={25} strokeWidth={1.5} />
                </div>
                <div>
                    <p className="text-xs">{getCreatedEditedDateText()}</p>
                    <p
                        onClick={() => {
                            document.templateDocumentId
                                ? setModalIsOpen(!modalIsOpen)
                                : dispatch(
                                      thunkActions.downloadCaseDocument({
                                          healthCaseId: healthCase.id!,
                                          documentId: document.id!,
                                      }),
                                  );
                        }}
                        className="cursor-pointer font-bold"
                    >
                        {document.title}
                    </p>
                </div>
            </div>
            <div className="flex items-center">
                {healthCase.status && activeCaseTypes.includes(healthCase.status) && (
                    <MiniMenu
                        id="document-actions"
                        preset="white"
                        buttonValues={getDocumentActionValues()}
                        buttonRight
                        containerClassName="flex justify-end"
                    />
                )}
            </div>
            {modalIsOpen && (
                <PdfViewerModal
                    isEdit
                    documentId={document.id ?? ""}
                    pdfDocumentTitle={document.title}
                    healthCase={healthCase}
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                />
            )}
        </div>
    );
};

export default CaseDocumentItem;
