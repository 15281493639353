import { useElementClicked } from "hooks";

export interface IProps {
    heading: React.ReactElement | string;
    items?: React.ReactElement;
    children?: React.ReactNode;
    open: boolean;
    overflowText?: string;
    isOverflowing?: boolean;
    onClickOutside?: (value: boolean) => void;
}

const Minimal = ({
    heading,
    open,
    items,
    children,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onClickOutside = (value: boolean) => {},
}: IProps) => {
    const ref = useElementClicked(onClickOutside);
    return (
        <div ref={ref}>
            <div className="flex flex-1 items-center">{heading}</div>
            <div className="relative z-10">
                {open && (
                    <div className="absolute z-10 w-full cursor-pointer bg-pageBg">
                        <div className="w-full bg-white">{children ? children : items}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Minimal;
