import { isNull } from "lodash/fp";
import ReactModal from "react-modal";
import Icon from "components/Icon/Icon";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { WidgetDropdown } from "../WidgetDropdown";
import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IDynamicData } from "pages/dashboard/utils/dashboardTypes";
import { ReactComponent as GuideStep } from "icons/guide/guideStep.svg";
import { ReactComponent as GuideStepCurrent } from "icons/guide/guideStepCurrent.svg";
import { getUserAccessRights, getUserContext, getHasCustomerSupport } from "store/userSelectors";
import { getCompanyGroupForCompany, getWidgets } from "pages/dashboard/redux/dashboardSelectors";

const HIDE_GUIDE_STORAGE = "localStorage/HIDE_GUIDE";

const Guide = ({ open, setOpen }: { open: boolean; setOpen: (value: boolean) => void }) => {
    const { t } = useTranslation("dashboard");
    const userContext = useAppSelector(getUserContext);
    const [currentStep, setCurrentStep] = useState(0);
    const customerSupport = useAppSelector(getHasCustomerSupport);
    const companyData = useAppSelector(getCompanyGroupForCompany);
    const accessRights = useAppSelector(getUserAccessRights);
    const widgets = useAppSelector(getWidgets);

    const setShowGuide = useCallback(
        (value: boolean) => {
            if (isNull(localStorage.getItem(HIDE_GUIDE_STORAGE))) {
                localStorage.setItem(HIDE_GUIDE_STORAGE, "true");
                setOpen(value);
                return;
            }
        },
        [setOpen],
    );

    useEffect(() => {
        if (widgets?.every((x) => x.filter === null) && !customerSupport) {
            setShowGuide(true);
        }
    }, [widgets, setShowGuide, customerSupport]);

    const context = userContext?.activeRetailer;
    let modalBg;
    switch (context) {
        case "Avonova":
        case "Feelgood":
        case "Falck":
            modalBg = "#FFFFFF";
            break;
        default:
            modalBg = "#fffaf5";
    }

    const checkIfAnyWidgetRight = accessRights?.filter(
        ({ accessRightType }) =>
            accessRightType === 2 ||
            accessRightType === 3 ||
            accessRightType === 5 ||
            accessRightType === 11 ||
            accessRightType === 12 ||
            accessRightType === 15,
    );

    const getCompaniesSelectionForGuide = useCallback((): IDynamicData[] => {
        const data = customerSupport
            ? companyData
            : companyData?.filter((x) => checkIfAnyWidgetRight?.some((y) => y.companyId === x.id));
        return data ? data : [];
    }, [checkIfAnyWidgetRight, companyData, customerSupport]);

    const guideObject = useMemo(() => {
        return [
            {
                id: "introduction",
                headerMedia: <Icon icon="dashboardSketch" size={275} />,
                heading: t("guide.newFunctionality"),
                subHeading1: t("guide.paragraph1"),
                subHeading2: t("guide.paragraph2"),
                primaryButtonText: t("guide.getStarted"),
            },
            ...(getCompaniesSelectionForGuide().length > 1
                ? [
                      {
                          id: t("guide."),
                          heading: t("guide.getStartedHeading"),
                          subHeading1: t("guide.getStartedText"),
                          contentTitle: t("mySelection"),
                          guideContent: (
                              <WidgetDropdown
                                  dynamicData={getCompaniesSelectionForGuide()}
                                  absolutePositioning
                                  id="companies"
                                  isGuide={true}
                              />
                          ),
                          primaryButtonText: t("guide.saveSelection"),
                      },
                  ]
                : []),
        ];
    }, [getCompaniesSelectionForGuide, t]);

    const guidePages = guideObject.map((item) => (
        <div key={item.id} className={`${context} flex w-full flex-col`}>
            <div className={`flex items-center justify-center bg-guideModalBlue ${item.headerMedia ? "h-72" : "h-16"}`}>
                {item.headerMedia}
            </div>
            <div className="flex flex-grow flex-col items-center p-12 text-center">
                <div>
                    <h2> {item.heading}</h2>
                    <div className="pt-6 text-lg">
                        {item.subHeading1}
                        <br /> <br />
                        {item.subHeading2}
                    </div>
                </div>
                <div className="w-full pt-6 text-left">
                    <p className="pb-2 font-bold">{item.contentTitle}</p>
                    {item.guideContent}
                </div>
                <div className="flex flex-grow flex-col place-content-end">
                    <div className="flex items-center justify-center pb-7">
                        {guideObject.length > 1 &&
                            guideObject.map((x, i) => (
                                <button type="button" key={x.id} className="p-1" onClick={() => setCurrentStep(i)}>
                                    {i === currentStep ? <GuideStepCurrent /> : <GuideStep />}
                                </button>
                            ))}
                    </div>
                    <div>
                        <PrimaryButton
                            id="guide-button"
                            text={item.primaryButtonText ?? ""}
                            onClick={
                                currentStep === guideObject.length - 1
                                    ? () => setOpen(false)
                                    : () => setCurrentStep(currentStep + 1)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <ReactModal
            isOpen={open}
            className="guide-modal-container relative flex h-screen w-screen"
            style={{
                overlay: {
                    zIndex: 20,
                    backgroundColor: "rgba(94, 95, 86, 0.5)",
                },
                content: { background: modalBg },
            }}
        >
            {guidePages[currentStep]}
        </ReactModal>
    );
};
export default Guide;
