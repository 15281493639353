import { ColumnText, ModalCommonFooter, ModalFrame, SingleDatepicker } from "@medhelp/ui";
import Icon from "components/Icon";
import Select from "components/Select";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { thunkActions } from "pages/FollowUp/redux";
import {
    getHasManualInitiationByType,
    getTemplateIdByType,
    getTemplateReasonsSelectValues,
} from "../../../redux/followUpSelectors";
import {
    getSelectedEmployeeInfo,
    getUserManagerName,
} from "pages/Absence/pages/absencereporting/redux/absenceReportsSelectors";
import { getUserFullName, getUserAccountId } from "store/userSelectors";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSingleDatepickerProps } from "hooks";
import { useTranslation } from "react-i18next";
import { HealthCaseType } from "pages/FollowUp/types";
import ManualInitiationRadioButtons from "../ManualInitiationTypeSelector";
import { useAppDispatch, useAppSelector } from "store/hooks";

interface IStartCaseModal {
    modalIsOpen: boolean;
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
}

const StartCaseModal = ({ modalIsOpen, setModalIsOpen }: IStartCaseModal) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("followup");
    const userName = useAppSelector(getUserFullName);
    const userAccountId = useAppSelector(getUserAccountId);
    const managerName = useAppSelector(getUserManagerName);
    const selectedEmployee = useAppSelector(getSelectedEmployeeInfo);
    const rehabTemplateId = useAppSelector(getTemplateIdByType("rehab"));
    const healthPromotionTemplateId = useAppSelector(getTemplateIdByType("healthpromotion"));
    const hasManualRehabCaseInitiation = useAppSelector(getHasManualInitiationByType("rehab"));
    const reasonsSelectValues = useAppSelector(getTemplateReasonsSelectValues("healthpromotion"));

    const [caseReason, setCaseReason] = useState<string | undefined>(undefined);

    const [selectedCaseType, setSelectedCaseType] = useState<HealthCaseType | undefined>(
        hasManualRehabCaseInitiation ? undefined : "healthpromotion",
    );

    const startDateProps = useSingleDatepickerProps({
        onChangeDate: () => {},
        givenSelectedDate: "",
        placeholder: t("chooseDate"),
    });

    const submitDisabled =
        selectedCaseType === undefined ||
        (selectedCaseType === "healthpromotion" && caseReason === undefined) ||
        (selectedCaseType === "rehab" && startDateProps.selectedDate === undefined);

    const bottomRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [startDateProps.open]);

    const handleSelectHealthCaseType = (type: HealthCaseType) => {
        startDateProps.setSelectedDate(undefined);
        setCaseReason(undefined);
        setSelectedCaseType(type);
    };

    const employeeDetails = [
        {
            header: t("ssn"),
            body: selectedEmployee?.referenceKey ? selectedEmployee?.socialSecurityNumber : "",
            key: "ssn",
        },
        {
            header: t("company"),
            body: selectedEmployee?.referenceKey ? selectedEmployee?.companyName : "",
            key: "company",
        },
        {
            header: t("closestManager"),
            body: managerName,
            key: "manager",
        },
    ];

    const handleStartCase = () => {
        if (!selectedCaseType) return;
        const templateId = selectedCaseType === "rehab" ? rehabTemplateId : healthPromotionTemplateId;
        dispatch(
            thunkActions.saveHealthCase({
                type: selectedCaseType,
                createHealthCaseModel: {
                    employmentId: selectedEmployee?.referenceKey,
                    templateId,
                    responsibleUserAccountId: userAccountId,
                    responsibleName: userName,
                    initiationCauseCode: caseReason ? caseReason : undefined,
                    startDate: startDateProps.selectedDate ? startDateProps.selectedDate : undefined,
                },
            }),
        )
            .then(unwrapResult)
            .then(() => toast(t("caseStarted")))
            .catch((e) =>
                toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("caseNotStarted")}`, {
                    type: "error",
                }),
            );
        setModalIsOpen(false);
    };

    return (
        <ModalFrame
            id="start-case-modal"
            header={<h1>{hasManualRehabCaseInitiation ? t("startCase") : t("startHealthPromotionCase")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            noPaddingTop
            content={
                <div className="px-5 py-8 sm:px-0">
                    <h5 className="font-bold text-grey-700">{`${selectedEmployee?.firstName} ${selectedEmployee?.lastName}`}</h5>
                    <div className="flex flex-col flex-wrap gap-4 border-b border-grey-200 pb-7 pt-4 sm:flex-row sm:gap-10">
                        {employeeDetails.map(
                            (employeeDetail) =>
                                employeeDetail.body && (
                                    <ColumnText
                                        key={employeeDetail.key}
                                        header={employeeDetail.header}
                                        className="pl-0 pr-6 text-grey-700"
                                        body={employeeDetail.body}
                                    />
                                ),
                        )}
                    </div>
                    {hasManualRehabCaseInitiation && (
                        <ManualInitiationRadioButtons
                            selectedCaseType={selectedCaseType}
                            setSelectedCaseType={handleSelectHealthCaseType}
                        />
                    )}
                    <div className="py-8">
                        {selectedCaseType === "healthpromotion" && (
                            <>
                                <h5 className="font-bold">{t("chooseReason")} *</h5>
                                <p className="py-2">{t("infoHealthPromotionCase")}</p>
                                <Select
                                    id="select-reason"
                                    placeholder={t("chooseReason")}
                                    selectedValue={caseReason ?? ""}
                                    onChange={setCaseReason}
                                    hidePlaceHolderInList
                                    values={reasonsSelectValues}
                                />
                            </>
                        )}
                        {selectedCaseType === "rehab" && (
                            <>
                                <p className="font-bold">{t("firstAbsenceDay")} *</p>
                                <p className="py-2">{t("infoRehabCase")}</p>
                                <div className={`${startDateProps.open && "pb-80"}`}>
                                    <SingleDatepicker {...startDateProps} />
                                </div>
                                <div ref={bottomRef} />
                            </>
                        )}
                    </div>
                </div>
            }
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={() => setModalIsOpen(false)}
                    primaryText={t("start")}
                    primaryOnClick={handleStartCase}
                    primaryRightIcon={<Icon icon="checkMark" stroke="#192D05" />}
                    primaryDisabled={submitDisabled}
                />
            }
        />
    );
};

export default StartCaseModal;
