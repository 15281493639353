import PrimaryButton from "components/PrimaryButton";
import ReactModal from "react-modal";
import Icon from "components/Icon";
import { useCallback } from "react";
import SecondaryButton from "components/SecondaryButton";

interface IProps {
    heading: string;
    onClick: () => void;
    onClose?: () => void;
    secondaryButtonClick?: () => void;
    secondaryButtonText?: string;
    buttonText: string;
    open: boolean;
    testId?: string;
    children?: React.ReactNode;
}
const Popout = ({
    heading,
    open,
    testId = "medhelp-popout",
    children,
    onClick,
    buttonText,
    secondaryButtonClick,
    onClose,
    secondaryButtonText = "Add secondary button text",
}: IProps) => {
    const onModalClose = useCallback(() => {
        if (onClose) return onClose();
        return onClick();
    }, [onClick, onClose]);
    return (
        <ReactModal
            id="popout-some-files-failed-certificate-upload"
            isOpen={open}
            onRequestClose={onModalClose}
            onAfterClose={onModalClose}
            className="absolute top-0 h-auto w-full bg-white"
        >
            <div data-testid={testId} className="ml-0 xxl:ml-72">
                <button className="absolute right-0 m-6 xxs:m-8" type="button" onClick={onModalClose}>
                    <Icon icon="close" width="20" height="20" fill="black" />
                </button>
                <h2 className="p-5">{heading}</h2>
                {children && <div className="p-5">{children}</div>}
                <div className="my-16 flex w-full justify-center xxs:my-8 xxs:justify-start">
                    <PrimaryButton
                        id="poput-button"
                        data-cy="poput-button"
                        data-testid="poput-button-primary-button"
                        text={buttonText}
                        onClick={onClick}
                        rightIcon={<Icon icon="checkMark" stroke="currentcolor" />}
                    />
                    {secondaryButtonClick && (
                        <SecondaryButton
                            id="poput-button"
                            data-cy="poput-button"
                            data-testid="poput-button-primary-button"
                            text={secondaryButtonText || ""}
                            onClick={secondaryButtonClick}
                        />
                    )}
                </div>
            </div>
        </ReactModal>
    );
};
export default Popout;
