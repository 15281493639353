import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { templateBuilder } from "./builders";
import {
    ILoginAccordions,
    ILoginInputSharedProps,
    ILoginsStateProps,
    ILoginCredentialsExtended,
    IAddUserStateProps,
    ISetUserStringProp,
    ISetUserNumberProp,
    IErrorMessages,
    IEmploymentStateProps,
    IPermissionsStateProps,
    IPermissionAccordions,
    ISetAccessRightBooleanValue,
    ISetAccessRightStringValue,
    IPermissionData,
    CompanyAccessRightsRecursive,
    IRoleSubscriptionsSettingsData,
    IRoleSubscriptionsSettingsStateProps,
    Role,
    ICompanyErasureConfig,
    IErasureFinishedSettingsStateProps,
    IErasureActiveSettingsStateProps,
    ActiveErasurePropNames,
    EmployeeHistory,
    IEmploymentData,
    ILoginsData,
    ISubscriptionStateProps,
    Tab,
    ISubscriptionData,
    ICheckBoxStateProps,
    ISubscriptionAccordionStateProps,
    IUserEmploymentStateProps,
    ICreateOrUpdateUserAccountExtended,
} from "../types";
import {
    getInputValues,
    initialLoginsProps,
    getAccordionName,
    initialAddUserProps,
    getCreateOrUpdateUserAccountModel,
    initialSelectedCompany,
    initialEmploymentProps,
    initialPermissionProps,
    getAccessRightsArrayClone,
    createIdNameMap,
    selectAllAccessRights,
    isRecursive,
    getTopDepWithUnderlayingOrg,
    initialRoleSubscriptionProps,
    filterSubscription,
    getSavedAsConfig,
    createRoleSubscriptions,
    initialErasureFinishedSettingsProps,
    initialErasureActiveSettingsProps,
    initialActiveErasureConfig,
    getLoginDisplayValues,
    initialSubscriptionStateProps,
    addRemovePostItem,
    getSubscriptionOptionGroups,
    initialAccordionProps,
    getPostModelFromAbsenceGroup,
    initialEmploymentStateProps,
    initialUserEmployment,
    addRemoveDepartment,
    getDepartmentsSaveModel,
    createCompanyUserFromCreateOrUpdateModel,
    getLoginAccordions,
} from "../utils";
import {
    createOrUpdateUserAccount,
    deleteLoginCredential,
    getCompaniesForUser,
    getCompanyUserFromAccountEdit,
    getRoleSubscriptionSettingsData,
    saveRoleSubscriptionSettings,
    saveLoginConfiguration,
    searchCompanyGroupUserBySsn,
    searchCompanyGroupUserByName,
    createOrUpdateEmployment,
    getPermissionData,
    createAccessRights,
    getCompanyDataErasureFinishedConfiguration,
    postCompanyDataErasureFinishedConfiguration,
    getCompanyDataErasureActiveConfiguration,
    postCompanyDataErasureActiveConfiguration,
    getEmploymentData,
    getLoginsData,
    getSubscriptionData,
    createOrUpdateEventSubscription,
    removeSubscription,
    getSubscriberTableItemsByCompanyAndUserAccount,
    getSubscriptionTableItems,
    getOrganizationTreeView,
    searchUserAndCompanyToAdd,
    getCompanyGroupRegion,
    checkExternalSubscription,
    getCompanyUserInCompanyGroup,
} from "./administrationAsyncActions";
import {
    Company,
    CompanyDataErasureAvailableConfiguration,
    CompanyUser,
    CreateOrUpdateAccessRights,
    CreateOrUpdateEmployment,
    IdNameCompanyMap,
    LoginCredentials,
} from "swagger/customercompany";
import { getCountryPrefix, getPhoneNumber } from "utils/phone";
import { AddRoleSubscriptionConfigration, RoleSubscriptionConfigration } from "swagger/messenger";
import {
    CompanyRegion,
    SubscriberTableItem,
    SubscriptionInformation,
    SubscriptionLookup,
    SubscriptionMonitoringType,
    SubscriptionTableItem,
    TreeViewNode,
} from "swagger/employeeattendance";
import _ from "lodash";
import { UpdateProps } from "../components/Subscriptions/CreateNewSubscription/CreateNewSubscription";

export interface IAdministrationSlice {
    administrationIsLoading: boolean;
    usersIsLoading: boolean;
    saveOrUpdateSuccessful: boolean;
    selectedCompanyId: number | null;
    selectedUserAccountId: number | null;
    loggedInUserCompanies: Company[] | null;
    selectedCompany: Company;
    selectedCompanyUser: CompanyUser | null;
    userEmploymentStateProps: IUserEmploymentStateProps;
    userEmploymentData: IEmploymentData | null;
    organizationTreeView: TreeViewNode[] | null;
    addUserStateProps: IAddUserStateProps;
    employmentStateProps: IEmploymentStateProps;
    subscriptionsProps: ISubscriptionStateProps;
    subscriptionsData: ISubscriptionData | null;
    permissionData: IPermissionData | null;
    permissionsStateProps: IPermissionsStateProps;
    loginsStateProps: ILoginsStateProps;
    loginsData: ILoginsData | null;
    erasureFinishedSettingsData: ICompanyErasureConfig | null;
    erasureFinishedSettingsStateProps: IErasureFinishedSettingsStateProps;
    erasureActiveIsLoading: boolean;
    erasureActiveSettingsData: CompanyDataErasureAvailableConfiguration | null;
    erasureActiveSettingsStateProps: IErasureActiveSettingsStateProps;
    roleSubscriptionData: IRoleSubscriptionsSettingsData | null;
    roleSubscriptionProps: IRoleSubscriptionsSettingsStateProps;
}

const initialAdministrationState: IAdministrationSlice = {
    administrationIsLoading: false,
    usersIsLoading: false,
    saveOrUpdateSuccessful: false,
    selectedCompanyId: null,
    selectedUserAccountId: null,
    loggedInUserCompanies: null,
    selectedCompany: initialSelectedCompany,
    selectedCompanyUser: null,
    userEmploymentStateProps: initialEmploymentStateProps,
    userEmploymentData: null,
    organizationTreeView: null,
    addUserStateProps: initialAddUserProps,
    employmentStateProps: initialEmploymentProps,
    subscriptionsProps: initialSubscriptionStateProps,
    subscriptionsData: null,
    permissionData: null,
    permissionsStateProps: initialPermissionProps,
    loginsStateProps: initialLoginsProps,
    loginsData: null,
    erasureFinishedSettingsData: null,
    erasureFinishedSettingsStateProps: initialErasureFinishedSettingsProps,
    erasureActiveIsLoading: false,
    erasureActiveSettingsData: null,
    erasureActiveSettingsStateProps: initialErasureActiveSettingsProps,
    roleSubscriptionData: null,
    roleSubscriptionProps: initialRoleSubscriptionProps,
};
interface ISetErrorMsg {
    propKey: keyof IErrorMessages;
    errorMessage: string;
}
interface ISetEmploymentProp {
    propKey: keyof CreateOrUpdateEmployment;
    value: string;
}
interface ISetAccordionOpen {
    accordionKey: keyof ILoginAccordions | keyof IPermissionAccordions;
    newValue: boolean;
}
interface ISetInputValue {
    accordionKey: keyof ILoginAccordions;
    inputId: number;
    newValue?: string;
}
interface IAddInputItem {
    accordionKey: keyof ILoginAccordions;
    inputProps: ILoginInputSharedProps;
}

const administrationSlice = createSlice({
    name: "administration",
    initialState: initialAdministrationState,
    // add actions to the reducers here
    reducers: {
        resetAdminState: () => initialAdministrationState,

        setSelectedCompanyId(state, action: PayloadAction<number>) {
            state.selectedCompanyId = action.payload;
        },
        setSelectedUserAccountId(state, action: PayloadAction<number | null>) {
            state.selectedUserAccountId = action.payload;
        },
        resetUserAccountIdStates(state) {
            state.selectedUserAccountId = null;
            state.selectedCompanyUser = null;
            state.userEmploymentStateProps = initialEmploymentStateProps;
            state.permissionData = null;
            state.subscriptionsData = null;
            state.loginsData = null;
        },
        setUserStringProp(state, action: PayloadAction<ISetUserStringProp>) {
            const { propKey, value } = action.payload;
            if (propKey === "mobileNumber") {
                state.addUserStateProps.userProps.mobileCountryPrefix = getCountryPrefix(value);
                state.addUserStateProps.userProps.mobileNumber = getPhoneNumber(value);
            } else if (propKey === "secondaryMobileNumber") {
                state.addUserStateProps.userProps.secondaryMobileCountryPrefix = getCountryPrefix(value);
                state.addUserStateProps.userProps.secondaryMobileNumber = getPhoneNumber(value);
            } else if (propKey === "telephoneNumber") {
                state.addUserStateProps.userProps.telephoneCountryPrefix = getCountryPrefix(value);
                state.addUserStateProps.userProps.telephoneNumber = getPhoneNumber(value);
            } else state.addUserStateProps.userProps[propKey] = value;
        },
        setUserNumberProp(state, action: PayloadAction<ISetUserNumberProp>) {
            const { propKey, value } = action.payload;
            state.addUserStateProps.userProps[propKey] = value;
        },
        setAddUserErrorMsg(state, action: PayloadAction<ISetErrorMsg>) {
            const { propKey, errorMessage: value } = action.payload;
            state.addUserStateProps.errorMessages[propKey] = value;
            if (propKey === "ssnErrorMsg") state.addUserStateProps.ssnSearchProps.searchResultOpen = false;
        },
        setAddUserSsnSearchOpen(state, action: PayloadAction<boolean>) {
            state.addUserStateProps.ssnSearchProps.searchResultOpen = action.payload;
        },
        resetSaveOrUpdateIsSuccessful(state) {
            state.saveOrUpdateSuccessful = false;
        },
        setEmploymentProp(state, action: PayloadAction<ISetEmploymentProp>) {
            const { propKey, value } = action.payload;
            if (state.userEmploymentStateProps.userEmployment) {
                if (propKey === "employmentGroups") {
                    const selectedGroup =
                        state.userEmploymentData?.companyEmploymentGroups.filter(
                            (group) => group.id?.toString() === value,
                        ) ?? [];
                    state.userEmploymentStateProps.userEmployment.employmentGroups = selectedGroup;
                }

                if (propKey === "mainManager") {
                    state.userEmploymentStateProps.mainManagagerSearchValue = value;
                }
                if (propKey === "departments") {
                    const stateDepartments = state.userEmploymentStateProps.userEmployment.departments ?? [];
                    state.userEmploymentStateProps.userEmployment.departments = addRemoveDepartment(
                        stateDepartments,
                        Number(value),
                        state.organizationTreeView,
                    );
                }
                if (propKey === "employmentNumber" || propKey === "costCenter" || propKey === "organizationNumber") {
                    state.userEmploymentStateProps.userEmployment[propKey] = value;
                }
            }
        },
        setMainManagerModel(state, action: PayloadAction<number>) {
            const searchResults = state.employmentStateProps.mainManagerSearchProps.searchResults;
            if (searchResults) {
                const mainManagerUserAccountId = action.payload;
                let mainManager: IdNameCompanyMap | undefined;
                searchResults.forEach((companyUser) => {
                    if (companyUser.userAccountId === mainManagerUserAccountId)
                        mainManager = {
                            companyId: companyUser.companyId,
                            companyName: companyUser.primaryCompany?.name,
                            id: companyUser.id,
                            userAccountId: mainManagerUserAccountId,
                            name: companyUser.name,
                            recursive: false,
                        };
                });
                if (mainManager && state.userEmploymentStateProps.userEmployment) {
                    state.employmentStateProps.mainManagerSearchProps.searchResultOpen = false;
                    state.userEmploymentStateProps.userEmployment.mainManager = mainManager;
                    state.userEmploymentStateProps.mainManagagerSearchValue = undefined;
                }
            }
        },
        setEmploymentErrorMessage(state, action: PayloadAction<string>) {
            state.employmentStateProps.organizationNumberErrorMsg = action.payload;
        },
        resetSubscriptionData(state) {
            state.subscriptionsData = null;
        },
        setActiveSubscriptionTab(state, action: PayloadAction<Tab>) {
            state.subscriptionsProps.currentlyEditingAccordion = initialAccordionProps;
            state.subscriptionsProps.activeTab = action.payload;
        },
        setSubscriptionAccordionOpen(state, action: PayloadAction<ISubscriptionAccordionStateProps>) {
            let accordion = _.cloneDeep(action.payload);
            const open = action.payload.open;
            accordion.open = !open;
            state.subscriptionsProps.currentlyEditingAccordion = accordion;
        },
        setNotificationTypeChecked(state, action: PayloadAction<ICheckBoxStateProps[]>) {
            //Todo kolla om modal är öppen

            action.payload.forEach((cb) => {
                state.subscriptionsProps.currentlyEditingAccordion.postItems = addRemovePostItem(
                    cb,
                    state.subscriptionsProps.currentlyEditingAccordion.postItems,
                );
            });
        },
        setSubscriptionStartdate(state, action: PayloadAction<string | undefined>) {
            state.subscriptionsProps.currentlyEditingAccordion.startDate = action.payload;
        },
        setSubscriptionEnddate(state, action: PayloadAction<string | undefined>) {
            state.subscriptionsProps.currentlyEditingAccordion.endDate = action.payload;
        },
        setDeleteSubscriptionId(state, action: PayloadAction<number | undefined>) {
            state.subscriptionsProps.deleteSubscriptionId = action.payload;
        },
        setAccordionSaveBtnLoading(state, action: PayloadAction<number>) {
            state.subscriptionsProps.userSubsOnAccordionProps[action.payload].saveIsLoading = true;
        },
        setModalOpen(state) {
            const modalIsOpen = state.subscriptionsProps.modalOpen === true;
            if (modalIsOpen) {
                //Clear searchdata when modal closes
                if (state.subscriptionsData?.searchData) {
                    state.subscriptionsData.searchData = undefined;
                    state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.currentSearchQuery = "";
                }
            }
            state.subscriptionsProps.modalOpen = !state.subscriptionsProps.modalOpen;
        },
        setModalHeader(state, action: PayloadAction<string>) {
            state.subscriptionsProps.modalHeader = action.payload;
        },
        setNewSubscriptionInitialProps(state) {
            const groups = getSubscriptionOptionGroups(
                state.selectedCompanyId,
                state.subscriptionsData?.defaultSubscriptionTypes,
            );
            const isUserSubsOnTab = state.subscriptionsProps.activeTab === "userSubsOn";
            const currentlyEditingAccordion: ISubscriptionAccordionStateProps = {
                ...initialAccordionProps,
                isSubsOnUserTab: !isUserSubsOnTab,
                postItems: getPostModelFromAbsenceGroup(groups.find((group) => group.groupName === "absence")),
                subscriptionMonitorType: 1,
                subscribingUserAccountId: isUserSubsOnTab ? state.selectedUserAccountId : null,
                monitoredIds: isUserSubsOnTab ? [] : [state.selectedCompanyUser?.employmentId ?? -1],
                subscriptionOptionProps: {
                    subscriptionGroups: groups,
                    missingContactKey: "",
                    contactKey: [],
                    selectedUserName: isUserSubsOnTab ? (state.selectedCompanyUser?.name ?? "") : "",
                },
            };
            state.subscriptionsProps.currentlyEditingAccordion = currentlyEditingAccordion;
        },
        setNewSubscriptionProp(
            state,
            action: PayloadAction<{
                eventName: UpdateProps;
                value: string | number | SubscriptionLookup;
            }>,
        ) {
            const { eventName, value } = action.payload;

            switch (eventName) {
                case "selectedSearchRadioValue":
                    if (state.subscriptionsProps.activeTab === "userSubsOn")
                        state.subscriptionsProps.currentlyEditingAccordion.monitoredIds = [];
                    else {
                        state.subscriptionsProps.currentlyEditingAccordion.subscribingUserAccountId = null;
                        state.subscriptionsProps.currentlyEditingAccordion.newExternalContactKey = null;
                    }
                    state.subscriptionsProps.currentlyEditingAccordion.subscriptionMonitorType =
                        value as SubscriptionMonitoringType;
                    break;

                case "selectedSearchResult":
                    //Todo kolla vad monitoredIds är (det är inte userAccountID)
                    state.subscriptionsProps.activeTab === "userSubsOn"
                        ? (state.subscriptionsProps.currentlyEditingAccordion.monitoredIds = [
                              (value as SubscriptionLookup).id ?? -1,
                          ])
                        : (state.subscriptionsProps.currentlyEditingAccordion.subscribingUserAccountId =
                              (value as SubscriptionLookup).userAccountId ?? -1);
                    //     state.subscriptionsProps.currentlyEditingAccordion.subscriptionOptionProps.selectedUserName =
                    //         (value as SubscriptionLookup).name ?? "";
                    // }
                    state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchOpen = true;
                    break;
                // This is removed in this version but will be used once we start using name in messenger
                case "newExternalSubName":
                    state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.name = value.toString();
                    break;
                case "newExternalSubChannel":
                    state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.selectedChannel =
                        Number(value);
                    state.subscriptionsProps.currentlyEditingAccordion.newExternalContactKey = "";
                    break;
                case "externalUserContactKey":
                    state.subscriptionsProps.currentlyEditingAccordion.newExternalContactKey = value.toString();
                    // Hide email or sms checkboxes depending on selected contact key is email or sms
                    state.subscriptionsProps.currentlyEditingAccordion.subscriptionOptionProps.subscriptionGroups =
                        getSubscriptionOptionGroups(
                            state.selectedCompanyId,
                            state.subscriptionsData?.defaultSubscriptionTypes,
                            undefined,
                            value.toString().includes("@") ? 1 : 2,
                        );
                    break;
                default:
                    break;
            }
        },
        setNewSubscriptionSearchQuery(state, action: PayloadAction<string>) {
            state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.currentSearchQuery = action.payload;
        },
        setSelectedDepartmentIdsAsMonitoredIds(state, action: PayloadAction<number[]>) {
            state.subscriptionsProps.currentlyEditingAccordion.monitoredIds = action.payload;
        },
        setNewSubscriptionOpen(state) {
            state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchOpen =
                !state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchOpen;
        },
        setPermissionAccordionOpen(state, action: PayloadAction<ISetAccordionOpen>) {
            const { accordionKey, newValue } = action.payload;
            state.permissionsStateProps.accordionProps[accordionKey as keyof IPermissionAccordions].open = newValue;
        },
        setPermissionCurrentAccordion(state, action: PayloadAction<keyof IPermissionAccordions>) {
            state.permissionsStateProps.currentAccordion = action.payload;
        },
        setAccessRightBoolenValue(state, action: PayloadAction<ISetAccessRightBooleanValue>) {
            const { accessRightKey, newValue } = action.payload;
            if (state.permissionData?.selectedCompanyAccessRights) {
                state.permissionsStateProps.accordionProps[accessRightKey as keyof IPermissionAccordions].isDirty =
                    true;

                state.permissionData.selectedCompanyAccessRights[accessRightKey] = newValue as boolean;
            }
        },
        setAccessRightStringValue(state, action: PayloadAction<ISetAccessRightStringValue>) {
            const { accessRightKey, newValue } = action.payload;
            if (state.permissionData?.selectedCompanyAccessRights) {
                let accessRightsToChange = getAccessRightsArrayClone(
                    state.permissionData.selectedCompanyAccessRights,
                    accessRightKey,
                );
                if (newValue === "select") {
                    accessRightsToChange = selectAllAccessRights(
                        //Todo take user selected from powerbar into account?
                        state.selectedUserAccountId,
                        state.organizationTreeView,
                        isRecursive(accessRightKey, state.permissionData.companyAccessRightsRecursive),
                    );
                } else if (newValue === "deselect") {
                    accessRightsToChange = [];
                } else if (newValue.includes("-")) {
                    const arId = newValue.split("-")[1];
                    const topNodes = getTopDepWithUnderlayingOrg(arId, state.organizationTreeView);
                    accessRightsToChange = selectAllAccessRights(
                        //Todo take user selected from powerbar into account?
                        state.selectedUserAccountId,
                        topNodes,
                        isRecursive(accessRightKey, state.permissionData.companyAccessRightsRecursive),
                    );
                } else {
                    const remove = accessRightsToChange.some((c) => c.id?.toString() === newValue);
                    if (remove) {
                        accessRightsToChange = accessRightsToChange.filter((dep) => dep.id?.toString() !== newValue);
                    } else {
                        const newDep = createIdNameMap(
                            Number(newValue),
                            //Todo take user selected from powerbar into account?
                            state.selectedUserAccountId,
                            state.organizationTreeView,
                            isRecursive(accessRightKey, state.permissionData.companyAccessRightsRecursive),
                        );
                        accessRightsToChange.push(newDep);
                    }
                }
                state.permissionData.selectedCompanyAccessRights[accessRightKey] = accessRightsToChange;
                state.permissionsStateProps.accordionProps[accessRightKey as keyof IPermissionAccordions].isDirty =
                    true;
            }
        },
        setAccordionOpen(state, action: PayloadAction<ISetAccordionOpen>) {
            const { accordionKey, newValue } = action.payload;
            state.loginsStateProps.accordionProps[accordionKey as keyof ILoginAccordions].open = newValue;
        },
        setInputEnabled(state, action: PayloadAction<ISetInputValue>) {
            const { accordionKey, inputId } = action.payload;

            const inputProps = state.loginsStateProps.accordionProps[accordionKey].inputProps.find(
                (c) => c.loginCredential.id === inputId,
            );
            if (inputProps) inputProps.disabled = false;
        },
        setInputValue(state, action: PayloadAction<ISetInputValue>) {
            const { accordionKey, inputId, newValue } = action.payload;
            const input = state.loginsStateProps.accordionProps[accordionKey].inputProps.find(
                (c) => c.loginCredential.id === inputId,
            );
            if (input) {
                input.isDirty = newValue !== input.loginCredential.identifier;
                input.errorMessage = "";
                input.inputValue = newValue ?? "";
            }
        },
        addInputItem(state, action: PayloadAction<IAddInputItem>) {
            const { accordionKey, inputProps } = action.payload;
            const inputs = state.loginsStateProps.accordionProps[accordionKey].inputProps;
            state.loginsStateProps.accordionProps[accordionKey].inputProps = [...inputs, inputProps];
        },
        removeEmptyInputItem(state, action: PayloadAction<keyof ILoginAccordions>) {
            state.loginsStateProps.accordionProps[action.payload].inputProps = state.loginsStateProps.accordionProps[
                action.payload
            ].inputProps.filter((c) => c.loginCredential.id !== 0);
        },
        setInputLoading(state, action: PayloadAction<ISetInputValue>) {
            const { accordionKey, inputId } = action.payload;
            const input = state.loginsStateProps.accordionProps[accordionKey].inputProps.find(
                (c) => c.loginCredential.id === inputId,
            );
            if (input) input.isLoading = true;
        },
        setInputErrorMessage(state, action: PayloadAction<ISetInputValue>) {
            const { accordionKey, inputId, newValue } = action.payload;
            const input = state.loginsStateProps.accordionProps[accordionKey].inputProps.find(
                (c) => c.loginCredential.id === inputId,
            );
            if (input) input.errorMessage = newValue ?? "";
        },
        setActiveTab(state, action: PayloadAction<string>) {
            state.loginsStateProps.accordionProps.oneTimePassAccordion.activeTab = action.payload;
        },
        setErasureFinishedValue(
            state,
            action: PayloadAction<{
                key: keyof ICompanyErasureConfig;
                value: number | null | boolean;
            }>,
        ) {
            const { key, value } = action.payload;
            //@ts-expect-error
            state.erasureFinishedSettingsStateProps.companyErasureConfig[key] = value;
        },
        setErasureActiveValue(
            state,
            action: PayloadAction<{
                propName: ActiveErasurePropNames;
                value: number | null;
            }>,
        ) {
            const { propName, value } = action.payload;
            state.erasureActiveSettingsStateProps.activeErasureConfig[propName] = value;
        },
        setFinishedErasureActiveInput(state, action: PayloadAction<EmployeeHistory | undefined>) {
            state.erasureFinishedSettingsStateProps.activeInput = action.payload;
        },
        wipeSubscriptionData(state) {
            state.roleSubscriptionData = null;
            state.roleSubscriptionProps = initialRoleSubscriptionProps;
        },
        deleteNotificationSetting(
            state,
            action: PayloadAction<{
                subscription?: AddRoleSubscriptionConfigration;
            }>,
        ) {
            const { subscription } = action.payload;
            if (state.roleSubscriptionData) {
                if (subscription) {
                    state.roleSubscriptionProps.selectedRoleSubscriptions = filterSubscription(
                        state.roleSubscriptionProps.selectedRoleSubscriptions,
                        subscription,
                    );
                } else {
                    state.roleSubscriptionProps.newRoleSubscription = null;
                    state.roleSubscriptionProps.showStandardSubscriptionOptions = false;
                    state.roleSubscriptionProps.selectedRole = null;
                }
            }
        },
        createNewRow(state) {
            state.roleSubscriptionProps.newRoleSubscription = {
                notificationType: undefined,
                eventCode: "",
                role: undefined,
                channel: undefined,
            };
        },
        createStandardRoleOptions(state) {
            if (state.roleSubscriptionProps.showStandardSubscriptionOptions) {
                state.roleSubscriptionProps.showStandardSubscriptionOptions = false;
                if (state.roleSubscriptionProps.selectedRole && state.roleSubscriptionData?.availableNotifications) {
                    state.roleSubscriptionProps.selectedRoleSubscriptions = createRoleSubscriptions(
                        state.roleSubscriptionProps.selectedRole,
                        state.roleSubscriptionData?.availableNotifications,
                    );
                }
            } else state.roleSubscriptionProps.showStandardSubscriptionOptions = true;
        },
        removeUnsavedStandardRoleOptions(state) {
            state.roleSubscriptionProps.selectedRoleSubscriptions = [];
            state.roleSubscriptionProps.selectedRole = null;
        },
        setNotificationOpen(state, action: PayloadAction<boolean>) {
            state.roleSubscriptionProps.notificationSelectOpen = action.payload;
        },
        updateNewRowValue(
            state,
            action: PayloadAction<{
                row: AddRoleSubscriptionConfigration;
            }>,
        ) {
            if (state.roleSubscriptionProps) {
                state.roleSubscriptionProps.newRoleSubscription = action.payload.row;
            }
        },
        updateStandardRoleValue(state, action: PayloadAction<Role>) {
            state.roleSubscriptionProps.selectedRole = action.payload;
        },
    },
    // add builders for the `async actions` to the reducers here
    extraReducers: (builder) => {
        // add own builder here
        templateBuilder(builder);
        builder
            .addCase(getCompaniesForUser.pending, (state) => {
                state.administrationIsLoading = true;
            })
            .addCase(getCompaniesForUser.fulfilled, (state, action: PayloadAction<Company[]>) => {
                state.administrationIsLoading = false;
                state.loggedInUserCompanies = action.payload;
                const selectedCompany = state.loggedInUserCompanies.find((c) => c.id === state.selectedCompanyId);
                if (selectedCompany) {
                    state.selectedCompany = selectedCompany;
                }
            })
            .addCase(getCompaniesForUser.rejected, (state) => {
                state.administrationIsLoading = false;
                console.error("failed at administrationSlice, getCompaniesForUser call rejected");
            })
            .addCase(searchCompanyGroupUserBySsn.pending, (state) => {
                state.addUserStateProps.ssnSearchProps.searchIsLoading = true;
            })
            .addCase(searchCompanyGroupUserBySsn.fulfilled, (state, action: PayloadAction<CompanyUser>) => {
                state.addUserStateProps.ssnSearchProps.searchIsLoading = false;
                state.addUserStateProps.ssnSearchProps.searchResults = [action.payload];
                state.addUserStateProps.ssnSearchProps.searchResultOpen = action.payload.editable === true;
            })
            .addCase(searchCompanyGroupUserBySsn.rejected, (state) => {
                state.addUserStateProps.ssnSearchProps.searchIsLoading = false;
                console.error("failed at administrationSlice, searchCompanyGroupUserBySsn call rejected");
            })
            .addCase(getCompanyUserFromAccountEdit.pending, (state) => {
                state.usersIsLoading = true;
            })
            .addCase(getCompanyUserFromAccountEdit.fulfilled, (state, action: PayloadAction<CompanyUser>) => {
                state.selectedUserAccountId = action.payload.userAccountId ?? null;
                state.selectedCompanyUser = action.payload;
                state.addUserStateProps.userProps = getCreateOrUpdateUserAccountModel(action.payload);
                state.addUserStateProps.ssnSearchProps.searchResultOpen = false;
                state.usersIsLoading = false;
            })
            .addCase(getCompanyUserFromAccountEdit.rejected, (state) => {
                state.usersIsLoading = true;
                console.error("failed at administrationSlice, getCompanyUserFromAccountEdit call rejected");
            })
            .addCase(getCompanyGroupRegion.pending, (state) => {
                state.usersIsLoading = true;
            })
            .addCase(getCompanyGroupRegion.fulfilled, (state, action: PayloadAction<CompanyRegion[]>) => {
                const region = action.payload.find((region) => region.id === state.selectedCompanyId);
                if (region) state.addUserStateProps.selectedCompanyRegion = region;
                state.usersIsLoading = false;
            })
            .addCase(getCompanyGroupRegion.rejected, (state) => {
                state.usersIsLoading = true;
                console.error("failed at administrationSlice, getCompanyUserFromAccountEdit call rejected");
            })
            .addCase(createOrUpdateUserAccount.pending, (state) => {
                state.addUserStateProps.saveButtonLoading = true;
            })
            .addCase(
                createOrUpdateUserAccount.fulfilled,
                (state, action: PayloadAction<ICreateOrUpdateUserAccountExtended>) => {
                    const { id, type, allLoginsConfig } = action.payload;
                    state.addUserStateProps.saveButtonLoading = false;
                    state.addUserStateProps.userProps.id = id;
                    state.selectedUserAccountId = id;
                    if (type === "Success") {
                        // Update selectedCompanyUser with changes
                        if (state.selectedCompanyUser)
                            state.selectedCompanyUser = createCompanyUserFromCreateOrUpdateModel(
                                state.selectedCompanyUser,
                                state.addUserStateProps.userProps,
                            );
                        //Update loginCredentials if they've been fetched
                        if (allLoginsConfig && state.loginsData) {
                            state.loginsData.allLoginsConfig = allLoginsConfig;
                            state.loginsStateProps.accordionProps = getLoginAccordions(
                                state.loginsStateProps.accordionProps,
                                allLoginsConfig,
                                state.loginsData.loginConfig,
                            );
                        }
                        state.saveOrUpdateSuccessful = true;
                    }
                },
            )
            .addCase(createOrUpdateUserAccount.rejected, (state) => {
                state.addUserStateProps.saveButtonLoading = false;
                console.error("failed at administrationSlice, createOrUpdateUserAccount call rejected");
            })
            .addCase(getEmploymentData.pending, (state) => {
                state.usersIsLoading = true;
            })
            .addCase(
                getEmploymentData.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        employmentData: IEmploymentData;
                        organizationTreeView: TreeViewNode[];
                    }>,
                ) => {
                    const { employmentData, organizationTreeView } = action.payload;

                    state.userEmploymentData = employmentData;

                    if (employmentData.employment) {
                        state.userEmploymentStateProps.userEmployment = employmentData.employment;

                        state.userEmploymentStateProps.userEmployment.departments = getDepartmentsSaveModel(
                            employmentData.employment.departments,
                        );
                    } else
                        state.userEmploymentStateProps.userEmployment = initialUserEmployment(
                            state.selectedCompanyId,
                            state.selectedUserAccountId,
                        );
                    state.organizationTreeView = organizationTreeView;
                    state.usersIsLoading = false;
                },
            )
            .addCase(getEmploymentData.rejected, (state) => {
                state.usersIsLoading = false;
                console.error("failed at administrationSlice, getEmploymentData call rejected");
            })
            .addCase(searchCompanyGroupUserByName.pending, (state) => {
                state.employmentStateProps.mainManagerSearchProps.searchIsLoading = true;
            })
            .addCase(searchCompanyGroupUserByName.fulfilled, (state, action: PayloadAction<CompanyUser[]>) => {
                state.employmentStateProps.mainManagerSearchProps.searchResults = action.payload;
                state.employmentStateProps.mainManagerSearchProps.searchIsLoading = false;
                state.employmentStateProps.mainManagerSearchProps.searchResultOpen = true;
            })
            .addCase(searchCompanyGroupUserByName.rejected, (state) => {
                state.employmentStateProps.mainManagerSearchProps.searchIsLoading = false;
                console.error("failed at administrationSlice, searchCompanyGroupUserByName call rejected");
            })
            .addCase(getCompanyUserInCompanyGroup.pending, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = true;
            })
            .addCase(getCompanyUserInCompanyGroup.fulfilled, (state, action: PayloadAction<CompanyUser>) => {
                // (state, action: PayloadAction<CompanyUser>) => {
                //     state.selectedUserAccountId =
                //         action.payload.userAccountId ?? null;
                //     state.selectedCompanyUser = action.payload;
                //     state.addUserStateProps.userProps =
                //         getCreateOrUpdateUserAccountModel(action.payload);
                //     state.addUserStateProps.ssnSearchProps.searchResultOpen =
                //         false;
                //     state.usersIsLoading = false;
                // },
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = false;
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.selectedSearchCompanyUser =
                    action.payload;
                state.subscriptionsProps.currentlyEditingAccordion.currentTableItemUserAccountId =
                    action.payload.userAccountId;
                state.subscriptionsProps.currentlyEditingAccordion.subscriptionOptionProps.selectedUserName =
                    action.payload.name ?? "";
                // state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.setContactKeyProps =
                //     true;
            })
            .addCase(getCompanyUserInCompanyGroup.rejected, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = false;
                console.error("failed at administrationSlice, getCompanyUserInCompanyGroup call rejected");
            })

            .addCase(createOrUpdateEmployment.pending, (state) => {
                state.employmentStateProps.saveButtonLoading = true;
            })
            .addCase(createOrUpdateEmployment.fulfilled, (state, action: PayloadAction<number>) => {
                //Update data with changes
                if (state.userEmploymentData?.employment && state.userEmploymentStateProps.userEmployment)
                    state.userEmploymentData.employment = state.userEmploymentStateProps.userEmployment;
                if (state.selectedCompanyUser) state.selectedCompanyUser.employmentId = action.payload;
                state.employmentStateProps.saveButtonLoading = false;
                state.saveOrUpdateSuccessful = true;
            })
            .addCase(createOrUpdateEmployment.rejected, (state) => {
                state.employmentStateProps.saveButtonLoading = false;
                console.error("failed at administrationSlice, createOrUpdateEmployment call rejected");
            })
            .addCase(getSubscriptionData.pending, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = true;
            })
            .addCase(getSubscriptionData.fulfilled, (state, action: PayloadAction<ISubscriptionData>) => {
                state.subscriptionsData = action.payload;
                // console.log({
                //     userSubsOnData: action.payload.userSubsOnTableItems,
                //     subsOnUserData: action.payload.subsOnUserTableItems,
                // });
                state.subscriptionsProps.subscriptionIsLoading = false;
            })
            .addCase(getSubscriptionData.rejected, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = false;

                console.error("failed at administrationSlice, getSubscriptionData call rejected");
            })
            .addCase(createOrUpdateEventSubscription.pending, (state) => {
                state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = true;
            })
            .addCase(
                createOrUpdateEventSubscription.fulfilled,
                (state, action: PayloadAction<SubscriptionInformation[]>) => {
                    if (action.payload.length > 0) state.subscriptionsProps.createOrUpdateSuccessful = true;
                    state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = false;
                    state.subscriptionsProps.modalOpen = false;
                },
            )
            .addCase(createOrUpdateEventSubscription.rejected, (state) => {
                state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = false;
                state.subscriptionsProps.createOrUpdateSuccessful = false;
                console.error("failed at administrationSlice, createOrUpdateEventSubscription call rejected");
            })
            .addCase(removeSubscription.pending, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = true;
            })
            .addCase(
                removeSubscription.fulfilled,
                (state, action: PayloadAction<SubscriberTableItem[] | SubscriptionTableItem[]>) => {
                    if (state.subscriptionsData)
                        state.subscriptionsProps.activeTab === "userSubsOn"
                            ? (state.subscriptionsData.userSubsOnTableItems = action.payload)
                            : (state.subscriptionsData.subsOnUserTableItems = action.payload);
                    state.subscriptionsProps.subscriptionIsLoading = false;
                },
            )
            .addCase(removeSubscription.rejected, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = false;
                console.error("failed at administrationSlice, removeSubscription call rejected");
            })
            .addCase(getSubscriberTableItemsByCompanyAndUserAccount.pending, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = true;
            })
            .addCase(
                getSubscriberTableItemsByCompanyAndUserAccount.fulfilled,
                (state, action: PayloadAction<SubscriberTableItem[]>) => {
                    console.log({ subsOn: action.payload });
                    if (state.subscriptionsData) {
                        // state.subscriptionsProps.deleteSuccessful = null;
                        state.subscriptionsData.userSubsOnTableItems = action.payload;
                        state.subscriptionsProps.subscriptionIsLoading = false;
                    }
                },
            )
            .addCase(getSubscriberTableItemsByCompanyAndUserAccount.rejected, (state) => {
                state.subscriptionsProps.subscriptionIsLoading = false;
                console.error(
                    "failed at administrationSlice, getSubscriberTableItemsByCompanyAndUserAccount call rejected",
                );
            })
            .addCase(getSubscriptionTableItems.pending, (state) => {
                state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = true;
            })
            .addCase(
                getSubscriptionTableItems.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        userSubsOnTableItems: SubscriberTableItem[];
                        subsOnUserTableItems: SubscriptionTableItem[];
                    }>,
                ) => {
                    const { userSubsOnTableItems, subsOnUserTableItems } = action.payload;
                    state.subscriptionsProps.createOrUpdateSuccessful = null;

                    if (state.subscriptionsData) {
                        state.subscriptionsData.userSubsOnTableItems = userSubsOnTableItems;
                        state.subscriptionsData.subsOnUserTableItems = subsOnUserTableItems;

                        state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = false;
                    }
                },
            )
            .addCase(getSubscriptionTableItems.rejected, (state) => {
                state.subscriptionsProps.currentlyEditingAccordion.saveIsLoading = false;
                console.error("failed at administrationSlice, getSubscriptionTableItems call rejected");
            })
            .addCase(getOrganizationTreeView.pending, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.departmentsIsLoading = true;
            })
            .addCase(getOrganizationTreeView.fulfilled, (state, action: PayloadAction<TreeViewNode[]>) => {
                state.organizationTreeView = action.payload;
                state.subscriptionsProps.newSubscriptionStateProps.departmentsIsLoading = false;
            })
            .addCase(getOrganizationTreeView.rejected, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.departmentsIsLoading = false;
                console.error("failed at administrationSlice, getOrganizationTreeView call rejected");
            })
            .addCase(searchUserAndCompanyToAdd.pending, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = true;
            })
            .addCase(searchUserAndCompanyToAdd.fulfilled, (state, action: PayloadAction<SubscriptionLookup[]>) => {
                if (state.subscriptionsData) state.subscriptionsData.searchData = action.payload;
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = false;
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchOpen = true;
            })
            .addCase(searchUserAndCompanyToAdd.rejected, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchIsLoading = false;
                console.error("failed at administrationSlice, getOrganizationTreeView call rejected");
            })
            .addCase(checkExternalSubscription.pending, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.searchIsLoading = true;
            })
            .addCase(checkExternalSubscription.fulfilled, (state, action: PayloadAction<SubscriptionLookup>) => {
                state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.searchIsLoading = false;
                //if email or sms number exists already
                if (action.payload) {
                    state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.newSubscriberExists =
                        action.payload;
                } else {
                    state.subscriptionsProps.newSubscriptionStateProps.newSubSearchProps.searchOpen = true;
                }
            })
            .addCase(checkExternalSubscription.rejected, (state) => {
                state.subscriptionsProps.newSubscriptionStateProps.newExternalSubscriber.searchIsLoading = false;
                console.error("failed at administrationSlice, checkExternalSubscription call rejected");
            })
            .addCase(getPermissionData.pending, (state) => {
                state.usersIsLoading = true;
            })
            .addCase(
                getPermissionData.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        companyAccessRights: CreateOrUpdateAccessRights[];
                        companyAccessRightsRecursive: CompanyAccessRightsRecursive;
                        organizationTreeView: TreeViewNode[];
                    }>,
                ) => {
                    const accesRights = action.payload.companyAccessRights.find(
                        (c) => c.companyId === state.selectedCompanyUser?.primaryCompany?.id,
                    );
                    if (accesRights) {
                        state.permissionData = {
                            selectedCompanyAccessRights: accesRights,
                            companyAccessRightsRecursive: action.payload.companyAccessRightsRecursive,
                        };
                    }
                    state.organizationTreeView = action.payload.organizationTreeView;
                    state.usersIsLoading = false;
                },
            )
            .addCase(getPermissionData.rejected, (state) => {
                state.usersIsLoading = false;
                console.error("failed at administrationSlice, getPermissionData call rejected");
            })
            .addCase(createAccessRights.pending, (state) => {
                const currentAccordion = state.permissionsStateProps.currentAccordion;
                if (currentAccordion) state.permissionsStateProps.accordionProps[currentAccordion].isLoading = true;
            })
            .addCase(createAccessRights.fulfilled, (state) => {
                const currentAccordion = state.permissionsStateProps.currentAccordion;
                if (currentAccordion) {
                    state.permissionsStateProps.accordionProps[currentAccordion].isLoading = false;
                    state.permissionsStateProps.accordionProps[currentAccordion].isDirty = false;
                }
            })
            .addCase(createAccessRights.rejected, (state) => {
                const currentAccordion = state.permissionsStateProps.currentAccordion;
                if (currentAccordion) state.permissionsStateProps.accordionProps[currentAccordion].isLoading = false;
                console.error("failed at administrationSlice, createAccessRights call rejected");
            })
            .addCase(getLoginsData.pending, (state) => {
                state.usersIsLoading = true;
            })
            .addCase(getLoginsData.fulfilled, (state, action: PayloadAction<ILoginsData>) => {
                state.loginsData = action.payload;
                const { allLoginsConfig, loginConfig } = action.payload;
                state.loginsStateProps.accordionProps = getLoginAccordions(
                    state.loginsStateProps.accordionProps,
                    allLoginsConfig,
                    loginConfig,
                );
                state.usersIsLoading = false;
            })
            .addCase(getLoginsData.rejected, (state) => {
                state.usersIsLoading = false;
                console.error("failed at administrationSlice, getLoginsData call rejected");
            })
            .addCase(saveLoginConfiguration.fulfilled, (state, action: PayloadAction<ILoginCredentialsExtended>) => {
                const { success, duplicate, inputCredential, loginCredentials } = action.payload;
                let accordion =
                    state.loginsStateProps.accordionProps[getAccordionName(inputCredential.credentialType ?? 3)];
                let input = accordion.inputProps.find((c) => c.loginCredential.id === inputCredential.id);
                if (input) input.isLoading = false;
                if (!success) {
                    if (input) input.errorMessage = duplicate ? "usernameAlreadyInUse" : "saveFailed";
                } else {
                    const existingLoginCredentials = loginCredentials.filter(
                        (c) => c.credentialType === accordion.credentialType,
                    );
                    accordion.displayValues = getLoginDisplayValues(existingLoginCredentials, accordion.credentialType);
                    accordion.inputProps = getInputValues(existingLoginCredentials);
                }
            })
            .addCase(saveLoginConfiguration.rejected, (state) => {
                const loadingAccordion = Object.values(state.loginsStateProps.accordionProps).find((accordion) =>
                    accordion.inputProps.find((c) => c.isLoading),
                );
                if (loadingAccordion) {
                    const input = loadingAccordion.inputProps.find((c) => c.isLoading);
                    if (input) input.isLoading = false;
                }
                console.error("failed at administrationSlice, saveLoginConfiguration call rejected");
            })
            .addCase(deleteLoginCredential.fulfilled, (state, action: PayloadAction<LoginCredentials[]>) => {
                const loadingAccordion = Object.values(state.loginsStateProps.accordionProps).find((accordion) =>
                    accordion.inputProps.find((c) => c.isLoading),
                );
                if (loadingAccordion) {
                    const input = loadingAccordion.inputProps.find((c) => c.isLoading);
                    if (input) input.isLoading = false;
                }
                Object.values(state.loginsStateProps.accordionProps).map((accordion) => {
                    const existingLoginCredentials = action.payload.filter(
                        (c) => c.credentialType === accordion.credentialType,
                    );
                    accordion.displayValues = getLoginDisplayValues(existingLoginCredentials, accordion.credentialType);
                    accordion.inputProps = getInputValues(existingLoginCredentials);
                });
            })
            .addCase(deleteLoginCredential.rejected, (state) => {
                const loadingAccordion = Object.values(state.loginsStateProps.accordionProps).find((accordion) =>
                    accordion.inputProps.find((c) => c.isLoading),
                );
                if (loadingAccordion) {
                    const input = loadingAccordion.inputProps.find((c) => c.isLoading);
                    if (input) input.isLoading = false;
                }

                console.error("failed at administrationSlice, deleteLoginCredential call rejected");
            })
            .addCase(
                getCompanyDataErasureFinishedConfiguration.fulfilled,
                (state, action: PayloadAction<ICompanyErasureConfig>) => {
                    state.erasureFinishedSettingsData = action.payload;
                    state.erasureFinishedSettingsStateProps.companyErasureConfig = action.payload;
                    state.erasureFinishedSettingsStateProps.isLoading = false;
                },
            )
            .addCase(getCompanyDataErasureFinishedConfiguration.rejected, (state) => {
                state.erasureFinishedSettingsStateProps.isLoading = false;
                console.error("failed at administrationSlice, getCompanyDataErasureConfiguration call rejected");
            })
            .addCase(getCompanyDataErasureFinishedConfiguration.pending, (state) => {
                state.erasureFinishedSettingsStateProps.isLoading = true;
            })
            .addCase(
                postCompanyDataErasureFinishedConfiguration.fulfilled,
                (state, action: PayloadAction<ICompanyErasureConfig>) => {
                    state.erasureFinishedSettingsData = action.payload;
                    state.erasureFinishedSettingsStateProps.companyErasureConfig = action.payload;
                    state.erasureFinishedSettingsStateProps.isLoading = false;
                },
            )
            .addCase(postCompanyDataErasureFinishedConfiguration.rejected, (state) => {
                state.erasureFinishedSettingsStateProps.isLoading = false;
                console.error("failed at administrationSlice, postCompanyDataErasureConfiguration call rejected");
            })
            .addCase(postCompanyDataErasureFinishedConfiguration.pending, (state) => {
                state.erasureFinishedSettingsStateProps.isLoading = true;
            })
            .addCase(
                getCompanyDataErasureActiveConfiguration.fulfilled,
                (state, action: PayloadAction<CompanyDataErasureAvailableConfiguration>) => {
                    state.erasureActiveSettingsData = action.payload || null;
                    state.erasureActiveSettingsStateProps.activeErasureConfig =
                        action.payload || initialActiveErasureConfig;
                    state.erasureActiveIsLoading = false;
                },
            )
            .addCase(getCompanyDataErasureActiveConfiguration.rejected, (state) => {
                state.erasureActiveIsLoading = false;
                console.error("failed at administrationSlice, getCompanyDataErasureActiveConfiguration call rejected");
            })
            .addCase(getCompanyDataErasureActiveConfiguration.pending, (state) => {
                state.erasureActiveIsLoading = true;
            })
            .addCase(
                postCompanyDataErasureActiveConfiguration.fulfilled,
                (state, action: PayloadAction<CompanyDataErasureAvailableConfiguration>) => {
                    state.erasureActiveSettingsData = action.payload;
                    state.erasureActiveSettingsStateProps.activeErasureConfig = action.payload;
                    state.erasureActiveSettingsStateProps.isLoading = false;
                },
            )
            .addCase(postCompanyDataErasureActiveConfiguration.rejected, (state) => {
                state.erasureActiveSettingsStateProps.isLoading = false;
                console.error("failed at administrationSlice, postCompanyDataErasureActiveConfiguration call rejected");
            })
            .addCase(postCompanyDataErasureActiveConfiguration.pending, (state) => {
                state.erasureActiveSettingsStateProps.isLoading = true;
            })
            .addCase(
                getRoleSubscriptionSettingsData.fulfilled,
                (state, action: PayloadAction<IRoleSubscriptionsSettingsData>) => {
                    state.roleSubscriptionData = action.payload;
                    state.roleSubscriptionProps.isLoading = false;
                    state.roleSubscriptionProps.selectedRoleSubscriptions = getSavedAsConfig(
                        action.payload.selectedRoles,
                    );
                },
            )
            .addCase(getRoleSubscriptionSettingsData.rejected, (state) => {
                state.roleSubscriptionProps.isLoading = false;
                console.error("failed at administrationSlice, getDepartmentManagerSettingsData call rejected");
            })
            .addCase(getRoleSubscriptionSettingsData.pending, (state) => {
                state.roleSubscriptionProps.isLoading = true;
            })
            .addCase(
                saveRoleSubscriptionSettings.fulfilled,
                (state, action: PayloadAction<RoleSubscriptionConfigration[]>) => {
                    if (state.roleSubscriptionData) state.roleSubscriptionData.selectedRoles = action.payload;

                    state.roleSubscriptionProps.saveIsLoading = false;
                    state.roleSubscriptionProps.newRoleSubscription = null;
                    state.roleSubscriptionProps.selectedRole = null;
                    state.roleSubscriptionProps.selectedRoleSubscriptions = getSavedAsConfig(action.payload);
                },
            )
            .addCase(saveRoleSubscriptionSettings.rejected, (state) => {
                console.error("failed at administrationSlice, saveDepartmentManagerSettingsData call rejected");
                state.roleSubscriptionProps.saveIsLoading = false;
            })
            .addCase(saveRoleSubscriptionSettings.pending, (state) => {
                state.roleSubscriptionProps.saveIsLoading = true;
            });
    },
});

export const administrationActions = administrationSlice.actions;

export default administrationSlice.reducer;
