import Checkbox from "@medhelp/ui/Checkbox";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { getAllIsChecked, getCheckedTopNodes } from "pages/Administration/utils";
import DropdownCheckboxFilter from "pages/FollowUp/components/DropdownCheckboxFilter";
import { useTranslation } from "react-i18next";

export interface IDepartmentSelectorProps {
    companyName: string;
    depDisplayList: IDynamicData[];
    onChange: (value: string) => void;
    allChecked: boolean;
}

const DepartmentSelectorAccChild = ({
    companyName,
    depDisplayList,
    onChange,
    allChecked,
}: IDepartmentSelectorProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="flex w-full flex-wrap sm:flex-nowrap">
            <div className="mr-10 flex flex-col">
                <div className="mb-3 flex items-center">
                    <Checkbox
                        checked={allChecked}
                        onChange={() => onChange(allChecked ? "deselect" : "select")}
                        size={24}
                    />
                    <p className="ml-3">
                        {t("checkAllDepartments", {
                            company: companyName,
                        })}
                    </p>
                </div>
                <div className="mb-4 flex xxs:w-[308px]">
                    <DropdownCheckboxFilter
                        header=""
                        keepClose={false}
                        loading={false}
                        viewElements={{
                            selectAll: t("selectAll"),
                            search: false,
                        }}
                        data={depDisplayList}
                        setData={onChange}
                        variants="fullWidth"
                    />
                </div>
            </div>
            <div>
                {getCheckedTopNodes(depDisplayList).map((c, i) => (
                    <div key={c.id} className="flex flex-col">
                        {i === 0 && (
                            <div className="mb-2 flex w-full">
                                <p className="mr-3 max-w-[308px]">{t("chosenDepartmentsWithUnderlayingOrg")}</p>
                                <p>{t("includeOrg")}</p>
                            </div>
                        )}
                        <div className={`flex border-b py-2 ${i === 0 ? "border-t" : ""}`}>
                            <p className="mr-3 w-[308px]">{c.label}</p>
                            <Checkbox
                                checked={getAllIsChecked(c.children)}
                                onChange={() => onChange(`checkChildren-${c.id}`)}
                                size={24}
                                disabled={getAllIsChecked(c.children)}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DepartmentSelectorAccChild;
