import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRetailerColors } from "hooks";
import { ChartTypeRegistry } from "chart.js";
import Chart from "chart.js/auto";
import { ModalFrame } from "@medhelp/ui";
import EmployeeSummaryTable from "../Tables/EmployeeSummaryTable";
import { IEmployeeDetails } from "pages/dashboard/utils/dashboardTypes";

interface IBarChartData {
    name: string;
    firstDatasetValue: string | number;
    secondDatasetValue: string | number;
    modalHeading?: string;
    tooltipHeading?: string;
    tooltipText?: string;
    tooltipTextSecondDataset?: string;
    employeeDetails?: IEmployeeDetails[];
}
interface IBarChart {
    id: string;
    mySelection: string;
    data: IBarChartData[];
}
interface IChartConfig {
    type: keyof ChartTypeRegistry;
    data: any;
    options: any;
}

const StaticBarChart = ({ mySelection, data }: IBarChart) => {
    const { t } = useTranslation("dashboard");
    const { stackedChartPrimary, stackedChartSecondary } = useRetailerColors();
    const chartContainer = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [heading, setHeading] = useState("");
    let newChartInstance: any;

    const [employeeIds, setEmployeeIds] = useState([""]);

    const showData = (event: any) => {
        const activePoints = newChartInstance?.getElementsAtEventForMode(event, "point", newChartInstance?.options);
        if (activePoints.length > 0) {
            const label = newChartInstance?.data?.xAxisLabels[activePoints[0]?.index];
            const barData = data.filter((x: any) => t(x.name) === label)[0];
            setHeading(barData?.modalHeading || "");
            setEmployeeIds(barData.employeeDetails?.map((x) => x.employmentId) || [""]);
            setOpenModal(true);
        }
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const chartConfig: IChartConfig = {
        type: "bar",
        data: {
            labels: data.map((x) => x.tooltipHeading),
            xAxisLabels: data.map((x) => t(x.name)),
            datasets: [
                {
                    backgroundColor: stackedChartPrimary,
                    data: data.map((x) => x.firstDatasetValue),
                    barThickness: 32,
                },
                {
                    backgroundColor: stackedChartSecondary,
                    data: data.map((x) => x.secondDatasetValue),
                    barThickness: 32,
                },
            ],
        },
        options: {
            interaction: { mode: "index" },
            onHover: function (e: any) {
                const points = this.getElementsAtEventForMode(e, "index", { axis: "x", intersect: true }, false);

                if (points.length) e.native.target.style.cursor = "pointer";
                else e.native.target.style.cursor = "default";
            },

            animation: {
                duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                tooltip: {
                    displayColors: false,
                    caretSize: 0,
                    mode: "x",
                    intersect: false,
                    titleMarginBottom: 10,
                    titleColor: "#192D05",
                    bodyColor: "#192D05",
                    backgroundColor: "#FFFFFF",
                    cornerRadius: 0,
                    padding: 18,
                    bodyFont: {
                        size: 12,
                    },
                    titleFont: {
                        size: 12,
                    },
                    callbacks: {
                        label: (i: any) => {
                            const firstDatasetText = data.map((x) => x.tooltipText ?? "");
                            const secondDatasetText = data.map((x) => x.tooltipTextSecondDataset ?? "");
                            if (i.datasetIndex === 0 && firstDatasetText) {
                                return firstDatasetText[i.dataIndex].split(/((?:.*?\s){7}.*?)\s/g);
                            } else {
                                if (secondDatasetText) {
                                    return secondDatasetText[i.dataIndex].split(/((?:.*?\s){7}.*?)\s/g);
                                }
                            }
                        },
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        callback: (i: any) => data.map((x) => t(x.name))[i],
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    stacked: true,
                    min: 0,
                    ticks: {
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        lineWidth: 0.5,
                        drawBorder: false,
                        color: "#8F957F",
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            newChartInstance = new Chart(chartContainer.current, chartConfig);
            return () => newChartInstance.destroy();
        }
    }, [chartContainer, data, showData]);

    return (
        <div>
            <div className="flex h-19 items-center px-6 pt-3">
                <p className="text-xs">{mySelection}</p>
            </div>
            <div>
                <div className="chart-container">
                    <canvas onClick={showData} ref={chartContainer} />
                </div>
            </div>
            {data?.length > 0 && (
                <ModalFrame
                    id="widgetEmployeeModal"
                    open={openModal}
                    setOpen={closeModal}
                    header={<h3>{heading}</h3>}
                    preset="full"
                    content={<EmployeeSummaryTable keyName={"ongoing"} filter={employeeIds} />}
                />
            )}
        </div>
    );
};

export default StaticBarChart;
