import { ReactNode } from "react";
import Icon from "components/Icon";
import { useDetectOutsideClick } from "hooks";

interface IClickablePlaceholder {
    children: ReactNode;
    label: ReactNode;
    reset?: () => void;
    disabled?: boolean;
}

const ClickablePlaceholder = ({ children, label, reset, disabled }: IClickablePlaceholder) => {
    const { isOutside: toggled, setIsOutside: setToggled, setContainer } = useDetectOutsideClick(false, false);
    return (
        <div ref={setContainer} className="w-40">
            {!toggled ? (
                <button
                    onClick={() => setToggled(true)}
                    disabled={disabled}
                    className={`flex h-8 w-full items-center justify-between px-3 ${
                        disabled ? "cursor-default" : "cursor-pointer hover:bg-grey-100"
                    }`}
                >
                    <div className="mr-3">{label}</div>
                    {!disabled && <Icon icon="pencil" />}
                </button>
            ) : (
                <div className="relative">
                    <div className="flex items-center">{children}</div>
                    <div className="absolute right-0 top-0">
                        <div
                            onClick={() => {
                                reset && reset();
                                setToggled(false);
                            }}
                            className="flex h-8 w-8 cursor-pointer items-center justify-center border-1 bg-gray-50 text-xl"
                        >
                            <Icon icon="close" size={12} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ClickablePlaceholder;
