import { Heading } from "@medhelp/ui";
import Icon from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import TertiaryButton from "components/TertiaryButton";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export interface IAdminAccordionProps {
    heading: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    primaryButtonClicked?: () => void;
    primaryButtonText: string;
    primaryButtonIcon?: React.ReactElement;
    primaryButtonDisabled?: boolean;
    onClose?: () => void;
    active: boolean;
    children: ReactNode;
    displayValues: string[];
    isLoading?: boolean;
    infoText?: string;
}

const AdminAccordion = ({
    heading,
    open,
    setOpen,
    primaryButtonClicked,
    primaryButtonText,
    primaryButtonIcon,
    primaryButtonDisabled,
    onClose,
    active,
    children,
    displayValues,
    isLoading,
    infoText,
}: IAdminAccordionProps) => {
    const { t } = useTranslation("administration");
    const showProps = !open && !!displayValues;

    const handleOpenAndClose = () => {
        if (onClose && open) onClose();
        setOpen(!open);
    };

    return (
        <div className="relative flex w-full flex-col items-center border-b border-grey-200" id="user-accordion">
            <div className={`flex w-full flex-col ${open ? "pb-6" : ""}`}>
                <div className="flex items-center px-10 py-6">
                    <div className="flex w-full flex-col">
                        <div className="flex items-center">
                            <Icon
                                icon={active ? "checkMark" : "close"}
                                size={20}
                                className={active ? "text-[#11B05A]" : "text-mhred-alert1"}
                            />
                            <Heading heading={heading} infoText={infoText} className="mb-0 ml-3" />
                        </div>
                        {showProps &&
                            displayValues.map((value, i) => (
                                <div
                                    key={value + i}
                                    className="ml-8 mr-16 mt-3 flex flex-col text-grey-700 md:flex-row"
                                >
                                    <p className="mr-1 text-sm">{value}</p>
                                </div>
                            ))}
                    </div>
                    <button
                        type="button"
                        className="flex h-16 w-16 items-center justify-center"
                        onClick={handleOpenAndClose}
                    >
                        <Icon icon={open ? "caretUp" : "caretDown"} size={16} />
                    </button>
                </div>
                {open && (
                    <>
                        <div id="user-accordion-children" className="mb-10 ml-[70px] mr-[100px] md:mb-0">
                            {children}
                        </div>
                        <div className="flex justify-center md:absolute md:right-30 md:top-9">
                            <TertiaryButton
                                id="modalActionButton"
                                text={t("abort")}
                                className={`mr-6 flex self-center`}
                                onClick={handleOpenAndClose}
                            />
                            <PrimaryButton
                                id="admin-accordion-save"
                                text={primaryButtonText}
                                disabled={primaryButtonDisabled}
                                onClick={primaryButtonClicked}
                                className="flex self-center"
                                isLoading={isLoading}
                                leftIcon={primaryButtonIcon}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminAccordion;
