import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import RadioButtonGroup from "components/RadioButtonGroup";
import Accordion from "components/Accordion/Accordion";
import {
    MedHelpAbsenceReportingDomainFieldTemplate,
    MedHelpAbsenceReportingDomainTemplate,
} from "swagger/absencereportapi";
import { thunkActions, actions } from "../../redux";
import { IFieldTemplateExtended } from "interfaces/IFieldTemplateExtended";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import * as UserSelectors from "store/userSelectors";
import { getAbsenceReportFieldDisplayValue, getAbsenceReportFieldValueByName } from "../../utils/absenceReports";
import AbsenceReportFieldWrapper from "../AbsenceReportFieldWrapper";

interface IProps {
    absenceReport: IAbsenceReportExtended;
    template: MedHelpAbsenceReportingDomainTemplate;
    absenceReportFields: IFieldTemplateExtended[];
    isEdit?: boolean;
    valueChanged?: (name: string, value: string) => void;
}
const AbsenceCauseDK = ({ absenceReport, template, absenceReportFields, isEdit, valueChanged }: IProps) => {
    const dispatch = useAppDispatch();
    const absenceCauseFieldTemplate = template.fields?.find((d) => d.name === "AbsenceCause");
    const consentFieldTemplate = template.fields?.find((d) => d.name === "Consent");

    const handleUpdateField = useCallback(
        (fieldName: string, value: string) => {
            const absenceCause = absenceReport.fields?.find(
                (x: IFieldTemplateExtended) => x.name?.toLowerCase() === "absencecause",
            );
            dispatch(
                actions.updateSelectedAbsenceReportField({
                    fieldName,
                    value,
                    templateFields: template.fields ?? [],
                }),
            );

            if (
                absenceCause &&
                absenceCause.value !== "" &&
                fieldName.toLowerCase() === "consent" &&
                value === "false"
            ) {
                dispatch(thunkActions.deleteSelectedAbsenceReportField(absenceCause));
            }
            if (valueChanged) {
                valueChanged(fieldName, value);
            }
        },
        [absenceReport.fields, dispatch, template.fields, valueChanged],
    );

    return (
        <AbsenceCauseDKLayoutComponent
            absenceCauseChanged={(v) => {
                handleUpdateField(absenceCauseFieldTemplate?.name!, v);
            }}
            consentChanged={(value) => {
                handleUpdateField("Consent", value);
            }}
            absenceCauseFieldTemplate={absenceCauseFieldTemplate!}
            consentFieldTemplate={consentFieldTemplate!}
            absenceReportFields={absenceReportFields}
            isEdit={isEdit}
        />
    );
};

interface AbsenceCauseDKLayoutComponentProps {
    consentChanged: (c: string) => void;
    absenceCauseFieldTemplate: MedHelpAbsenceReportingDomainFieldTemplate;
    consentFieldTemplate: MedHelpAbsenceReportingDomainFieldTemplate;
    absenceReportFields: IFieldTemplateExtended[];
    absenceCauseChanged: (c: string) => void;
    isEdit?: boolean;
}
const AbsenceCauseDKLayoutComponent = ({
    absenceReportFields,
    consentChanged,
    absenceCauseFieldTemplate,
    consentFieldTemplate,
    absenceCauseChanged,
    isEdit,
}: AbsenceCauseDKLayoutComponentProps) => {
    const { t } = useTranslation();
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    const getValues = useMemo(() => {
        const test = absenceCauseFieldTemplate.options?.map((c) => {
            return {
                value: c.value!,
                description: c.description!,
            };
        });
        return test;
    }, [absenceCauseFieldTemplate.options]);

    const consent = useMemo(() => {
        return getAbsenceReportFieldValueByName("Consent", absenceReportFields);
    }, [absenceReportFields]);

    const absenceCause = useMemo(() => {
        return getAbsenceReportFieldValueByName("AbsenceCause", absenceReportFields);
    }, [absenceReportFields]);

    if (isManage) {
        return (
            <>
                <AbsenceReportFieldWrapper
                    heading={consentFieldTemplate.header!}
                    isMandatory={consentFieldTemplate.mandatory!}
                    modalHeading={consentFieldTemplate.header!}
                    modalTopContent={consentFieldTemplate.description!}
                >
                    <RadioButtonGroup
                        id="RadioButtonGroupConfidential"
                        isHorizontal
                        withPlaceholder
                        values={[
                            {
                                description: t("yes"),
                                value: "true",
                            },
                            {
                                description: t("no"),
                                value: "false",
                            },
                        ]}
                        selectedValue={consent}
                        clicked={(clickedValue) => {
                            consentChanged(clickedValue ?? "");
                        }}
                    />
                </AbsenceReportFieldWrapper>
                {consent === "true" && (
                    <>
                        <AbsenceReportFieldWrapper
                            heading={absenceCauseFieldTemplate.header!}
                            isMandatory={absenceCauseFieldTemplate.mandatory!}
                            modalHeading={absenceCauseFieldTemplate.header!}
                            modalTopContent={absenceCauseFieldTemplate.description!}
                        >
                            <RadioButtonGroup
                                id="RadioButtonGroup"
                                values={getValues!}
                                selectedValue={absenceCause}
                                clicked={(clickedValue) => {
                                    absenceCauseChanged(clickedValue!);
                                }}
                                withPlaceholder
                            />
                        </AbsenceReportFieldWrapper>
                    </>
                )}
            </>
        );
    }

    if (isEdit) {
        return (
            <>
                <Accordion
                    key={`${consentFieldTemplate.header}-${consentFieldTemplate.id}`}
                    heading={consentFieldTemplate.header!}
                    value={getAbsenceReportFieldDisplayValue(consentFieldTemplate)}
                    isMandatory={consentFieldTemplate.mandatory!}
                    dataCy={consentFieldTemplate.name!}
                >
                    <RadioButtonGroup
                        id="RadioButtonGroupConfidential"
                        isHorizontal
                        withPlaceholder
                        values={[
                            {
                                description: t("yes"),
                                value: "true",
                            },
                            {
                                description: t("no"),
                                value: "false",
                            },
                        ]}
                        selectedValue={consent}
                        clicked={(clickedValue) => {
                            consentChanged(clickedValue ?? "");
                        }}
                    />
                </Accordion>
                {consent === "true" && (
                    <>
                        <Accordion
                            key={`${absenceCauseFieldTemplate.header}-${absenceCauseFieldTemplate.id}`}
                            heading={absenceCauseFieldTemplate.header!}
                            value={getAbsenceReportFieldDisplayValue(consentFieldTemplate)}
                            isMandatory={absenceCauseFieldTemplate.mandatory!}
                            dataCy={absenceCauseFieldTemplate.name!}
                        >
                            <RadioButtonGroup
                                id="RadioButtonGroup"
                                values={getValues!}
                                selectedValue={consent}
                                clicked={(clickedValue) => {
                                    absenceCauseChanged(clickedValue!);
                                }}
                                withPlaceholder
                            />
                        </Accordion>
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <div className="flex flex-col justify-center pb-8 pt-4">
                <p className="mb-8">{consentFieldTemplate.description}</p>
                <RadioButtonGroup
                    id="RadioButtonGroupConfidential"
                    isHorizontal
                    values={[
                        {
                            description: t("yes"),
                            value: "true",
                        },
                        {
                            description: t("no"),
                            value: "false",
                        },
                    ]}
                    selectedValue={consent}
                    clicked={(clickedValue) => {
                        consentChanged(clickedValue ?? "");
                    }}
                />
            </div>
            {consent === "true" && (
                <>
                    <div className="pt-4">
                        <RadioButtonGroup
                            id="RadioButtonGroup"
                            values={getValues!}
                            selectedValue={absenceCause}
                            clicked={(clickedValue) => {
                                absenceCauseChanged(clickedValue!);
                            }}
                        />
                    </div>
                </>
            )}
        </>
    );
};
export default AbsenceCauseDK;
