import { twMerge } from "tailwind-merge";
import { ISelectValue } from "components/Select";

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string;
    className?: string;
    clicked: (value: string) => void;
    selected: boolean;
    values?: ISelectValue;
    disabled?: boolean;
    variant?: "default" | "small";
}

const sizePresets = {
    default: "h-10 w-10",
    small: "h-4.5 w-4.5",
};

const RadioButton = ({ id, disabled = false, values, clicked, selected, variant = "default" }: RadioButtonProps) => (
    <label className={twMerge("flex w-fit items-center gap-3", disabled ? "cursor-not-allowed" : "cursor-pointer")}>
        <div
            id={id}
            className={twMerge(
                "custom-radio-button rounded-full border",
                sizePresets[variant],
                selected ? "set-radio-selected" : "",
                disabled ? "border-mhgrey-darkdisabled" : "border-mhgrey-dark",
            )}
            data-testid="radio-button"
        >
            <input
                name={id}
                data-testid={id}
                id="hiddenInput"
                type="radio"
                className={twMerge(
                    "opacity-0",
                    sizePresets[variant],
                    disabled ? "cursor-not-allowed" : "cursor-pointer",
                )}
                disabled={disabled}
                onChange={() => values && clicked(values.value)}
            />
        </div>
        {values?.description && (
            <div className={twMerge(disabled && "text-mhgrey-mediumdisabled")}>{values.description}</div>
        )}
    </label>
);

export default RadioButton;
