import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { IAdminSearchCompProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const AdminSearchResult = ({
    searchResults,
    setSearchResultOpen,
    onSelectUserClick,
    isExistingUser,
}: IAdminSearchCompProps) => {
    const { t } = useTranslation("administration");
    const ref = useElementClicked(setSearchResultOpen);
    return (
        <div
            ref={ref}
            className={twMerge(
                "absolute z-10 max-h-90 w-full overflow-auto bg-white shadow-md xxs:w-[308px]",
                isExistingUser ? "p-2" : "",
            )}
        >
            {searchResults.map((searchResult) => (
                <div
                    key={`${searchResult.userAccountId}${searchResult.socialSecurityNumber}`}
                    className="flex cursor-pointer flex-col"
                    onClick={() => onSelectUserClick(searchResult.userAccountId ?? -1)}
                >
                    {isExistingUser && <p className="mb-2">{t("userExists")}</p>}
                    <div className={twMerge(isExistingUser ? "" : "border-b")}>
                        <div className={twMerge("flex items-center", isExistingUser ? "" : "p-4")}>
                            <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-mhgrey-light">
                                <Icon icon="person" />
                            </div>
                            <div className="flex flex-col font-bold text-mhdarkgreen-original">
                                <div>{searchResult.name}</div>
                                <div className="font-normal">{searchResult.socialSecurityNumber}</div>
                                <p>{searchResult.primaryCompany?.name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AdminSearchResult;
