import {
    getProduct,
    getCustomerData,
    getOrganizationInfo,
    getSocialInsuranceDeviantRule,
    getHealthCaseManagementWithDeviant,
} from "pages/service/redux/serviceSelectors";
import {
    MedHelpHCMDomainTemplate,
    MedHelpHCMWebApiModelsTemplateRule,
    MedHelpHCMDomainTemplateFormDocument,
} from "swagger/healthCaseManagement";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { capitalize, isEmpty } from "lodash/fp";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useMemo, useState } from "react";
import TemplateFormDocuments from "./TemplateFormDocuments";
import { AccordionBooleanContent, AccordionStringContent, ServiceAccordion } from "../ServiceAccordion";
import { ServiceCheckboxContainer, ServiceCheckboxRow } from "../ServiceCheckbox";
import {
    riskInitiationHCMRuleObject,
    socialInsuranceHCMRuleObject,
    manualInitiationHCMRuleObject,
} from "pages/service/utils/ruleObjects";
import {
    deleteTemplateRule,
    fetchTemplateFormDocuments,
    saveTemplateRules,
} from "pages/service/redux/serviceAsyncActions";

interface IChecked {
    id: string | null;
    key: string;
    checked: boolean;
    value?: string;
    interval?: string;
}

export interface IExtendedTemplate extends MedHelpHCMDomainTemplate {
    templateFormDocuments: (MedHelpHCMDomainTemplateFormDocument & { checked: boolean })[];
    healthPromotionDeviantRules?: IChecked[];
    rehabDeviantRules?: IChecked[];
    socialInsuranceRule?: IChecked;
    riskInitiationRule?: IChecked;
    manualInitiationRule?: IChecked;
}

const HCMConfiguration = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("service");
    const product = useAppSelector(getProduct);
    const { region } = useAppSelector(getOrganizationInfo);
    const { retailerId, customerId } = useAppSelector(getCustomerData);
    const hcmTemplates = useAppSelector(getHealthCaseManagementWithDeviant);
    const { hasSocialInsuranceDeviantRule } = useAppSelector(getSocialInsuranceDeviantRule);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo(
        () => ({ templatesWithDeviant: hcmTemplates as IExtendedTemplate[] }),
        [hcmTemplates],
    );

    const {
        reset,
        watch,
        register,
        handleSubmit,
        formState: { isDirty, dirtyFields },
    } = useForm({ defaultValues });

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    const onSubmit = (data: { templatesWithDeviant: IExtendedTemplate[] }) => {
        setIsLoading(true);
        const promises: Promise<any>[] = [];
        const healthPromotionRules: MedHelpHCMWebApiModelsTemplateRule[] = [];

        // Health promotion requests
        data.templatesWithDeviant.forEach((template, templateIndex) => {
            if (
                template.id &&
                dirtyFields.templatesWithDeviant?.[templateIndex] &&
                dirtyFields.templatesWithDeviant[templateIndex].riskInitiationRule
            ) {
                if (template.riskInitiationRule?.checked) {
                    healthPromotionRules.push(riskInitiationHCMRuleObject);
                } else {
                    if (template.riskInitiationRule?.id)
                        promises.push(
                            dispatch(
                                deleteTemplateRule({
                                    templateId: template.id,
                                    templateRuleId: template.riskInitiationRule?.id,
                                }),
                            ),
                        );
                }
            }

            template.healthPromotionDeviantRules?.forEach((rule, ruleIndex) => {
                if (
                    template.id &&
                    dirtyFields.templatesWithDeviant?.[templateIndex] &&
                    dirtyFields.templatesWithDeviant[templateIndex].healthPromotionDeviantRules &&
                    dirtyFields.templatesWithDeviant[templateIndex].healthPromotionDeviantRules?.[ruleIndex]
                ) {
                    if (rule.checked) {
                        healthPromotionRules.push({
                            ruleType: "initiation",
                            ruleName: "DeviantInitiation",
                            params: rule.key,
                            startDirect: false,
                        });
                    } else {
                        promises.push(
                            dispatch(deleteTemplateRule({ templateId: template.id, templateRuleId: rule.id || "" })),
                        );
                    }
                }
            });
        });

        const healthPromotionTemplate = data.templatesWithDeviant.find((x) => x.name === "healthpromotion");

        if (healthPromotionRules.length > 0 && healthPromotionTemplate && healthPromotionTemplate.id) {
            promises.push(
                dispatch(
                    saveTemplateRules({
                        templateId: healthPromotionTemplate.id,
                        templateRules: { rules: healthPromotionRules },
                    }),
                ),
            );
        }

        // Rehab rule requests
        const rehabRules: MedHelpHCMWebApiModelsTemplateRule[] = [];

        data.templatesWithDeviant.forEach((template, templateIndex) => {
            if (
                template.id &&
                dirtyFields.templatesWithDeviant?.[templateIndex] &&
                (dirtyFields.templatesWithDeviant[templateIndex].socialInsuranceRule ||
                    dirtyFields.templatesWithDeviant[templateIndex].manualInitiationRule)
            ) {
                if (template.socialInsuranceRule?.checked) {
                    rehabRules.push(socialInsuranceHCMRuleObject);
                } else {
                    if (template.socialInsuranceRule?.id)
                        promises.push(
                            dispatch(
                                deleteTemplateRule({
                                    templateId: template.id,
                                    templateRuleId: template.socialInsuranceRule?.id,
                                }),
                            ),
                        );
                }

                if (template.manualInitiationRule?.checked) {
                    rehabRules.push(manualInitiationHCMRuleObject);
                } else {
                    if (template.manualInitiationRule?.id)
                        promises.push(
                            dispatch(
                                deleteTemplateRule({
                                    templateId: template.id,
                                    templateRuleId: template.manualInitiationRule.id,
                                }),
                            ),
                        );
                }
            }

            template.rehabDeviantRules?.forEach((rule, ruleIndex) => {
                if (
                    template.id &&
                    dirtyFields.templatesWithDeviant?.[templateIndex] &&
                    dirtyFields.templatesWithDeviant[templateIndex].rehabDeviantRules &&
                    dirtyFields.templatesWithDeviant[templateIndex].rehabDeviantRules?.[ruleIndex]
                ) {
                    if (rule.checked) {
                        rehabRules.push({
                            ruleType: "initiation",
                            ruleName: "DeviantInitiation",
                            params: rule.key,
                            startDirect: false,
                        });
                    } else {
                        promises.push(
                            dispatch(deleteTemplateRule({ templateId: template.id, templateRuleId: rule.id || "" })),
                        );
                    }
                }
            });
        });

        const rehabTemplate = data.templatesWithDeviant.find((x) => x.name === "rehab");

        if (rehabRules.length > 0 && rehabTemplate && rehabTemplate.id) {
            promises.push(
                dispatch(saveTemplateRules({ templateId: rehabTemplate.id, templateRules: { rules: rehabRules } })),
            );
        }

        Promise.allSettled([promises, rehabRules, healthPromotionRules]).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (retailerId)
            dispatch(fetchTemplateFormDocuments({ retailerId, customerId, region: region?.language || "se" }));
    }, [dispatch, retailerId, customerId, region?.language]);

    const parseDeviantRule = (rule: string, value?: string, interval?: string) => {
        const [key, values] = rule.split(",");

        if (key === "recurringperiod") {
            const result = t("times", { count: Number(value) }) + " " + t("inMonths", { count: Number(interval) });
            return result;
        }

        if (key === "recurring") {
            const result = `${value} ${t(Number(value) === 1 ? "day" : "days")} 
                                ${t("inTotalDuring")} ${interval}
                                ${t(Number(interval) === 1 ? "month" : "months")}`;
            return result;
        }

        if (key === "longterm") {
            if (values === "14FK")
                return t("afterDays", { count: 14 }) + " " + t("withDaysInbetween", { count: 5 }) + " " + "(FK)";
            const result =
                t("afterDays", { count: Number(value) }) + " " + t("withDaysInbetween", { count: Number(interval) });
            return result;
        }

        return key;
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ServiceAccordion
                heading={t("rehabSettings")}
                reset={() => reset(defaultValues)}
                disabled={!isDirty}
                isLoading={isLoading}
                content={
                    <div className="flex flex-col gap-2">
                        {hcmTemplates.map((template) => (
                            <div key={template.id}>
                                <AccordionStringContent value={t(template?.name || "")} />
                                <div>
                                    {template.riskInitiationRule?.checked && (
                                        <AccordionBooleanContent label={t("RiskInitiation")} />
                                    )}
                                    {template.manualInitiationRule?.checked && (
                                        <AccordionBooleanContent label={t("ManualInitiation")} />
                                    )}
                                    {template.socialInsuranceRule?.checked && (
                                        <AccordionBooleanContent label={t("socialInsurance")} />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            >
                {hcmTemplates.map((template, templateIndex) => (
                    <div key={template.id} className="border-b py-10 pb-20">
                        <div className="pl-4 text-xl font-bold">{t(template?.name || "")}</div>
                        <div className="md:px-10">
                            {template.name === "healthpromotion" && (
                                <>
                                    <ServiceCheckboxContainer heading={t("healthPromotionCaseRecommendedAfter")}>
                                        {template.healthPromotionDeviantRules?.map((x, index) => (
                                            <ServiceCheckboxRow
                                                key={x.key}
                                                label={parseDeviantRule(x.key, x.value, x.interval)}
                                                register={register(
                                                    `templatesWithDeviant.${templateIndex}.healthPromotionDeviantRules.${index}.checked`,
                                                )}
                                            />
                                        ))}

                                        <ServiceCheckboxRow
                                            label={t("RiskInitiation")}
                                            register={register(
                                                `templatesWithDeviant.${templateIndex}.riskInitiationRule.checked`,
                                            )}
                                        />
                                        <ServiceCheckboxRow
                                            label={t("ManualInitiation")}
                                            disabled={watch(
                                                `templatesWithDeviant.${templateIndex}.manualInitiationRule.checked`,
                                            )}
                                            register={register(
                                                `templatesWithDeviant.${templateIndex}.manualInitiationRule.checked`,
                                            )}
                                        />
                                    </ServiceCheckboxContainer>
                                </>
                            )}
                            {template.name === "rehab" && (
                                <>
                                    <ServiceCheckboxContainer heading={t("rehabCaseInitiatedAfter")}>
                                        {template.rehabDeviantRules?.map((x, index) => (
                                            <ServiceCheckboxRow
                                                key={x.key}
                                                label={parseDeviantRule(x.key, x.value, x.interval)}
                                                register={register(
                                                    `templatesWithDeviant.${templateIndex}.rehabDeviantRules.${index}.checked`,
                                                )}
                                            />
                                        ))}

                                        <ServiceCheckboxRow
                                            label={parseDeviantRule("longterm,14FK")}
                                            register={register(
                                                `templatesWithDeviant.${templateIndex}.socialInsuranceRule.checked`,
                                            )}
                                            disabled={
                                                // disabled if no social insurance deviant rule unless already checked
                                                !hasSocialInsuranceDeviantRule &&
                                                !watch(
                                                    `templatesWithDeviant.${templateIndex}.socialInsuranceRule.checked`,
                                                )
                                            }
                                        />

                                        <ServiceCheckboxRow
                                            label={t("ManualInitiation")}
                                            disabled={
                                                product !== "base" &&
                                                !watch(
                                                    `templatesWithDeviant.${templateIndex}.manualInitiationRule.checked`,
                                                )
                                            }
                                            register={register(
                                                `templatesWithDeviant.${templateIndex}.manualInitiationRule.checked`,
                                            )}
                                        />
                                    </ServiceCheckboxContainer>
                                </>
                            )}
                            <ServiceCheckboxContainer heading={t("rules")}>
                                {template.ruleTemplates
                                    ?.filter((x) => x.ruleType !== "initiation")
                                    ?.map((ruleTemplate) => {
                                        return (
                                            <div
                                                key={ruleTemplate.id}
                                                className="flex flex-col border-b p-4 md:flex-row"
                                            >
                                                <div className="w-full md:w-1/3">
                                                    {t("name")}:{" "}
                                                    <span className="font-bold">{t(ruleTemplate.ruleName || "")}</span>
                                                </div>
                                                <div className="w-full md:w-1/3">
                                                    {t("type")}:{" "}
                                                    <span className="font-bold">{t(ruleTemplate.ruleType || "")}</span>
                                                </div>
                                                <div className="w-full md:w-1/3">
                                                    {t("params")}:{" "}
                                                    <span className="font-bold">{ruleTemplate.params}</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </ServiceCheckboxContainer>
                            {!isEmpty(template?.activityTemplates) && (
                                <ServiceCheckboxContainer heading={t("rehabChain")}>
                                    {template.activityTemplates &&
                                        template?.activityTemplates?.map((activityTemplate) => (
                                            <ServiceCheckboxRow
                                                key={activityTemplate.id}
                                                label={`${capitalize(t("day"))} ${activityTemplate.day} - ${
                                                    activityTemplate.title
                                                }`}
                                                disabled
                                                checked
                                            />
                                        ))}
                                </ServiceCheckboxContainer>
                            )}
                            <TemplateFormDocuments
                                templateId={template.id || ""}
                                documentTemplates={template.templateFormDocuments || []}
                            />
                        </div>
                    </div>
                ))}
            </ServiceAccordion>
        </form>
    );
};

export default HCMConfiguration;
