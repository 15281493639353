import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import IconModal from "components/IconModal";
import * as UserSelectors from "store/userSelectors";
import { AbsenceReportEditModal, AbsenceReportHealthyModal } from "./pages/absencereporting/components/Modals";
import AbsenceReportHistoryCard from "./pages/absencereporting/components/AbsenceReportHistoryCard";
import AbsenceReportCreate from "./pages/absencereporting/components/AbsenceReportCreate";
import AbsenceReportCreateFinishedCard from "./pages/absencereporting/components/AbsenceReportCreateFinishedCard";
import AbsenceDegreeWarning from "./pages/absencereporting/components/AbsenceDegreeWarning";
import {
    selectors as absenceReportsSelectors,
    actions as absenceReportsActions,
    thunkActions,
} from "./pages/absencereporting/redux";
import AbsenceReportButton from "./pages/absencereporting/components/AbsenceReportButton";
import { OngoingAbsence } from "./components";
import Icon from "components/Icon";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";
import { ModalFrame } from "@medhelp/ui";
import AbsenceNotification from "./pages/absencereporting/components/AbsenceReportFinished/AbsenceNotification";
import { Guid } from "utils/guid";
import AbsenceReportCallNurse from "./pages/absencereporting/components/AbsenceReportCallNurse";

const MAX_ONGOINGREPORTS = 4;

const Absence = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [registerFinishedAbsenceReportVisible, setRegisterFinishedAbsenceReportVisible] = useState(false);
    const [createAbsenceReportVisible, setCreateAbsenceReportVisible] = useState(false);
    const [createAbsenceReportConfirmationModalOpen, setCreateAbsenceReportConfirmationModalOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [reportHealthyModalIsOpen, setReportHealthyModalIsOpen] = useState(false);
    const [absenceNotificationModalIsOpen, setAbsenceNotificationModalIsOpen] = useState(false);
    const [typeOfRegistration, setTypeOfRegistration] = useState("");

    const selectedAbsenceReportType = useAppSelector(absenceReportsSelectors.getAbsenceReportType);
    const selectedAbsenceReport = useAppSelector(absenceReportsSelectors.getSelectedAbsenceReport);
    const isCallNurse = useAppSelector(absenceReportsSelectors.getConfigurationByName("callNurse"));
    const adminWithMedAdvisAndCustomrAccess = useAppSelector(UserSelectors.getMedicalOrCustomOrAdminAccess);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    const absenceTemplateTypes = useAppSelector(absenceReportsSelectors.getAbsenceTemplatesDescription(t));
    const onGoingAbsenceReportsIsNotPreliminary = useAppSelector(
        absenceReportsSelectors.getAbsencesBasedOnPreliminary(false),
    );
    const { Templates, SelectedEmployee } = useAppSelector(absenceReportsSelectors.getAbsenceReportsSlice);
    const hasAbsenceReports = useAppSelector(absenceReportsSelectors.hasAbsenceReport);
    const totalDegree = useAppSelector(absenceReportsSelectors.getAbsenceReportsTotalDegree);
    const isOwnReport = useAppSelector(absenceReportsSelectors.getIsOwnReport);

    const handleAbsenceReportDeleteModal = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            setDeleteModalIsOpen(true);
            dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
        },
        [dispatch],
    );

    const handleAbsenceReportDelete = (absenceReport: IAbsenceReportExtended) => {
        dispatch(thunkActions.deleteAbsenceReport(absenceReport));
        setDeleteModalIsOpen(false);
    };

    const handleAbsenceReportEdit = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            if (isOwnReport || !absenceReport.isEdit) {
                dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
                dispatch(absenceReportsActions.updateSuppressNotification(false));
            }

            if (isManage && !isOwnReport) {
                dispatch(
                    absenceReportsActions.updateAbsenceReportIsEdit({
                        id: absenceReport.id,
                        isEdit: absenceReport.isEdit ? false : true,
                    }),
                );
                if (
                    absenceReport.id &&
                    selectedAbsenceReport?.id !== absenceReport.id &&
                    Guid.isValid(absenceReport.id)
                ) {
                    dispatch(
                        thunkActions.getAbsenceReportById(
                            absenceReport.id,
                            absenceReport.isEdit ? false : true,
                            absenceReport.fields,
                        ),
                    );
                }
            } else {
                setEditModalIsOpen(true);
            }
        },
        [dispatch, isManage, isOwnReport, selectedAbsenceReport?.id],
    );
    const handleAbsenceReportHealthyModal = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
            setReportHealthyModalIsOpen(true);
        },
        [dispatch],
    );

    const handleAbsenceReportShowMore = (absenceReport: IAbsenceReportExtended) => {
        if (!absenceReport.fields) dispatch(thunkActions.getAbsenceReportFields(absenceReport.id!, false));
    };

    const handleAbsenceReportRegisterFinished = () => {
        dispatch(absenceReportsActions.updateCreateFinishedAbsenceReportOK(false));
        setRegisterFinishedAbsenceReportVisible(false);
    };

    const hideCreateButton = (isCallNurse && isOwnReport) || createAbsenceReportVisible;

    const showCallNurse = isCallNurse && isOwnReport;

    return (
        <>
            <OngoingAbsence
                handleAbsenceReportEdit={handleAbsenceReportEdit}
                handleAbsenceReportDeleteModal={handleAbsenceReportDeleteModal}
                handleAbsenceReportHealthyModal={handleAbsenceReportHealthyModal}
                handleAbsenceReportShowMore={handleAbsenceReportShowMore}
                isCallNurse={isCallNurse}
            />

            {showCallNurse && <AbsenceReportCallNurse />}
            {!hideCreateButton && SelectedEmployee && (
                <AbsenceReportButton
                    testId="absence-report-button-oon-ar-page"
                    createIconClicked={() => {
                        if (hasAbsenceReports) {
                            setCreateAbsenceReportConfirmationModalOpen(true);
                        } else {
                            navigate(`/absencereporting/me/create/`);
                        }
                    }}
                    onChange={(value?: string) => {
                        if (value && value !== t("createNewAr")) {
                            dispatch(absenceReportsActions.updateSelectedAbsenceType(value));
                            if (hasAbsenceReports) {
                                setCreateAbsenceReportConfirmationModalOpen(true);
                            } else {
                                setCreateAbsenceReportVisible(true);
                            }
                        }
                    }}
                    selectedValue={selectedAbsenceReportType || ""}
                    maxOngoing={Boolean(onGoingAbsenceReportsIsNotPreliminary.length >= MAX_ONGOINGREPORTS)}
                />
            )}
            {createAbsenceReportVisible && (
                <AbsenceReportCreate
                    onCancel={() => {
                        setCreateAbsenceReportVisible(false);
                    }}
                    onSubmit={() => {
                        setCreateAbsenceReportVisible(false);
                    }}
                    show={createAbsenceReportVisible}
                />
            )}
            {SelectedEmployee && (
                <div className="relative mt-10 flex w-full flex-col xs:mt-20">
                    <div
                        className={`${
                            adminWithMedAdvisAndCustomrAccess ? "xxs:justify-between md:flex" : "flex items-center"
                        }`}
                    >
                        <div className="flex items-center">
                            <h2 className="ml-5 xs:ml-0">{t("history")}</h2>
                            {!adminWithMedAdvisAndCustomrAccess && (
                                <IconModal
                                    id="historyInfo"
                                    heading={t("history")}
                                    topContent={t("noAccessRightsEditHistory")}
                                />
                            )}
                        </div>
                        {adminWithMedAdvisAndCustomrAccess && (
                            <div className="mt-4 flex-col xxs:mt-0 xxs:flex xxs:justify-center">
                                <button
                                    type="button"
                                    className={`flex items-center xxl:justify-self-end`}
                                    onClick={() =>
                                        setRegisterFinishedAbsenceReportVisible(!registerFinishedAbsenceReportVisible)
                                    }
                                >
                                    <Icon
                                        icon={"plus"}
                                        className={`${
                                            registerFinishedAbsenceReportVisible
                                                ? "text-mhgrey-darkdisabled"
                                                : "border-black"
                                        }`}
                                    />
                                    <p
                                        className={`ml-2 border-b font-bold ${
                                            registerFinishedAbsenceReportVisible
                                                ? "border-mhgrey-darkdisabled text-mhgrey-darkdisabled"
                                                : "border-black"
                                        }`}
                                    >
                                        {t("registerHistoricalAr")}
                                    </p>
                                </button>
                            </div>
                        )}
                    </div>

                    {registerFinishedAbsenceReportVisible && (
                        <div className="mt-4">
                            <AbsenceReportCreateFinishedCard
                                id="arListPage"
                                preset="background"
                                employeeId={SelectedEmployee?.referenceKey!}
                                cancelOnClick={() => setRegisterFinishedAbsenceReportVisible(false)}
                                saveOnClick={handleAbsenceReportRegisterFinished}
                                absenceTypes={absenceTemplateTypes}
                                templates={Templates!}
                            />
                        </div>
                    )}
                </div>
            )}

            <AbsenceReportHistoryCard
                id="AbsenceReportHistoryCard"
                preset="background"
                headers={[t("absenceType"), t("startDate"), t("endDate"), t("absenceDegree")]}
            />

            {totalDegree && totalDegree > 100 ? (
                <div className="relative">
                    <AbsenceDegreeWarning
                        id="AbsenceDegreeWarning"
                        preset="white"
                        header={t("absenceDegreeWarningHeader")}
                        subHeader={
                            t("absenceDegreeWarningSubHeader") +
                            `${SelectedEmployee?.firstName} ${SelectedEmployee?.lastName}`
                        }
                        className="vertical-center fixed bottom-0 mb-4 xs:left-0 xs:mb-4 md:ml-4 xl:ml-80"
                    />
                </div>
            ) : null}
            {editModalIsOpen && selectedAbsenceReport && Templates && (
                <AbsenceReportEditModal
                    id="EditAbsence"
                    open={editModalIsOpen}
                    setOpen={() => {
                        setEditModalIsOpen(!editModalIsOpen);
                    }}
                    setOpenAbsenceNotificationModal={() => {
                        setAbsenceNotificationModalIsOpen(true);
                        setTypeOfRegistration("editReport");
                    }}
                />
            )}
            {reportHealthyModalIsOpen && selectedAbsenceReport && (
                <AbsenceReportHealthyModal
                    id="ReportHealthyBack"
                    selectedEmployee={SelectedEmployee!}
                    open={reportHealthyModalIsOpen}
                    setOpen={() => {
                        setReportHealthyModalIsOpen(!reportHealthyModalIsOpen);
                    }}
                    setOpenAbsenceNotificationModal={() => {
                        setAbsenceNotificationModalIsOpen(true);
                        setTypeOfRegistration("reportHealthy");
                    }}
                    absenceReport={selectedAbsenceReport}
                />
            )}
            {createAbsenceReportConfirmationModalOpen && (
                <ModalFrame
                    id="createArConfirmationModal"
                    open={createAbsenceReportConfirmationModalOpen}
                    setOpen={setCreateAbsenceReportConfirmationModalOpen}
                    header={<h1>{t("editExistingAr")}</h1>}
                    content={<p>{t("editExistingArSubHeadingOne")}</p>}
                    footer={
                        <ModalCommonFooter
                            secondaryText={t("createCompletelyNewAr")}
                            secondaryOnClick={() => {
                                if (isManage && !isOwnReport) {
                                    setCreateAbsenceReportConfirmationModalOpen(false);
                                    setCreateAbsenceReportVisible(true);
                                } else {
                                    navigate(`/absencereporting/me/create/`);
                                }
                            }}
                            primaryText={t("abortAndEditExisting")}
                            primaryOnClick={() => {
                                setCreateAbsenceReportConfirmationModalOpen(false);
                            }}
                        />
                    }
                />
            )}
            {deleteModalIsOpen && (
                <ModalFrame
                    id="deleteModal"
                    open={deleteModalIsOpen}
                    setOpen={setDeleteModalIsOpen}
                    header={
                        <h1>{selectedAbsenceReport?.isPreliminary ? t("removeDraft") : t("removeAbsenceReport")}</h1>
                    }
                    content={<p>{t("arRemovalWarning")}</p>}
                    footer={
                        <ModalCommonFooter
                            tertiaryOnClick={() => setDeleteModalIsOpen(false)}
                            tertiaryText={t("abort")}
                            primaryOnClick={() => {
                                handleAbsenceReportDelete(selectedAbsenceReport!);
                                setCreateAbsenceReportVisible(false);
                            }}
                            primaryText={t("yesRemove")}
                        />
                    }
                />
            )}
            {absenceNotificationModalIsOpen && (
                <AbsenceNotification
                    modalIsOpen={absenceNotificationModalIsOpen}
                    setModalIsOpen={() => setAbsenceNotificationModalIsOpen(false)}
                    absenceReportId={selectedAbsenceReport?.id ?? ""}
                    typeOfRegistration={typeOfRegistration}
                />
            )}
        </>
    );
};
export default Absence;
