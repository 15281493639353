import { useState } from "react";
import { ReactComponent as AccordionArrowDown } from "icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "icons/accordionArrowUp.svg";
import { Trans } from "react-i18next";
import { uniqueId } from "lodash";

export interface IProps {
    heading: string;
    content: string[];
    last: boolean;
}

const SupportSubAccordion = ({ heading, content, last }: IProps) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`px-6 ${open && last ? "border-none" : "border-b"}`}>
            <div onClick={() => setOpen(!open)} className="flex h-14 cursor-pointer items-center justify-between">
                <p className="font-bold">{heading}</p>
                {open ? <AccordionArrowUp /> : <AccordionArrowDown />}
            </div>
            {open && (
                <ul className="pl-6 pt-6">
                    {content?.map((item) => (
                        <li className="list-disc pb-5" key={uniqueId()}>
                            <Trans i18nKey={item} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SupportSubAccordion;
