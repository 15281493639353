interface IProps {
    header: string;
    body: string[];
}
const SubscribeCell = ({ header, body }: IProps) => (
    <div className="flex flex-col">
        <div className="flex gap-2">
            <p className="truncate text-sm font-bold">{header}</p>
        </div>
        {body.map((text) => (
            <p className="truncate text-sm">{text}</p>
        ))}
    </div>
);
export default SubscribeCell;
