import { useTranslation } from "react-i18next";

const HCMStatusOverviewLoader = () => {
    const { t } = useTranslation("dashboard");
    return (
        <div className="flex h-full animate-pulse justify-center pb-10 pt-3">
            <ul className="flex w-full flex-col px-6 text-sm">
                <li className="flex h-10 items-end justify-between pb-2">
                    <p className="text-xs">{t("status")}</p>
                    <div className="flex">
                        <p className="flex w-18 justify-center text-xs">{t("rehab")}</p>
                        <p className="flex w-18 justify-center text-xs">{t("healthpromotion")}</p>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-30 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="mr-7 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                        <div className="mr-3 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-30 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="mr-7 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                        <div className="mr-3 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-30 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="mr-7 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                        <div className="mr-3 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                    </div>
                </li>
                <li className="flex h-11 items-center justify-between border-t border-secondaryBorder px-2 py-3">
                    <div>
                        <div className="h-2.5 w-30 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex justify-between">
                        <div className="mr-7 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                        <div className="mr-3 h-2.5 w-10 rounded-full bg-gray-200 text-center dark:bg-gray-700"></div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default HCMStatusOverviewLoader;
