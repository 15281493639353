import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { actions, thunkActions } from "pages/Inbox/redux";
import {
    getSubscriptionMessagesTotalCount,
    getSubscriptionMessagesStatus,
    getSubscriptionMessages,
    getSubscriptionMessagesCurrentPage,
    getSubscriptionMessagesPageCount,
    getSubscriptionMessagesPageNumber,
} from "../../redux/inboxSelectors";
import { isEmpty } from "lodash/fp";
import { Status } from "pages/Inbox/types";
import { Card, CardAlign, Error, PaginationFooter, Table } from "@medhelp/ui";
import MiniMenu from "components/MiniMenu/MiniMenu";
import MessageCard from "../../components/MessageCard";
import InboxLoading from "../../InboxLoading";

const SubscriptionMessages = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const messages = useAppSelector(getSubscriptionMessages);
    const currentPage = useAppSelector(getSubscriptionMessagesCurrentPage);
    const messageCount = useAppSelector(getSubscriptionMessagesTotalCount);
    const pageCount = useAppSelector(getSubscriptionMessagesPageCount);
    const pageNumber = useAppSelector(getSubscriptionMessagesPageNumber);
    const status = useAppSelector(getSubscriptionMessagesStatus);
    const MESSAGES_LIMIT = 10;
    const headers = [
        { label: t("name"), accessor: "name" },
        { label: t("subject"), accessor: "subject" },
        { label: t("date"), accessor: "date" },
    ];
    const actionValues = [
        {
            text: t("markAllAsRead"),
            onClick: () => dispatch(thunkActions.updateSubscriptionMessagesReadAll()),
        },
    ];

    useEffect(() => {
        dispatch(
            thunkActions.fetchSubscriptionMessages({
                offset: 0,
                limit: MESSAGES_LIMIT,
            }),
        );
    }, [dispatch]);

    const onPageChange = (page: number) => {
        const existingPage = messages?.inbox?.find((message) => message.page === page);
        if (!existingPage) {
            dispatch(
                thunkActions.fetchSubscriptionMessages({
                    offset: (page - 1) * 10,
                    limit: MESSAGES_LIMIT,
                }),
            );
        }
        dispatch(
            actions.setMessagesPageNumber({
                pageNumber: page,
                type: "subscriptionMessages",
            }),
        );
    };

    if (status === Status.PENDING) {
        return <InboxLoading />;
    }

    if (status === Status.ERROR) {
        return <Error text={t("couldNotFetchContent")} />;
    }

    return (
        <Card align={CardAlign.start} className="block p-0">
            {isEmpty(currentPage) ? (
                <div className="p-8 text-center">{t("noSubscriptions")}</div>
            ) : (
                <>
                    <Table
                        headers={headers}
                        extra={
                            <div className="px-6 pb-5 pt-8">
                                <MiniMenu
                                    id="subscription-messages-actions"
                                    preset="white"
                                    buttonValues={actionValues}
                                    buttonRight
                                    containerClassName="flex"
                                />
                            </div>
                        }
                    >
                        {currentPage?.map((message) => (
                            <MessageCard key={message.id} message={message} type="subscriptionMessages" />
                        ))}
                    </Table>
                    <PaginationFooter
                        itemsCount={messageCount}
                        activePage={pageNumber}
                        pageCount={pageCount}
                        onClick={onPageChange}
                    />
                </>
            )}
        </Card>
    );
};

export default SubscriptionMessages;
