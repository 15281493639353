import { orderBy, trim } from "lodash";
import { absenceList } from "pages/FollowUp/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree,
    MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty,
} from "swagger/absencefollowup";
import { getDegreePeriodString } from "utils/degrees";

const selectOnlyCauseFromHeader = (header: string) => trim(header.split(":")?.[1]);

interface IProps {
    type?: string | null;
    header: string;
    endDate?: string | null;
    degree?: number;
    degrees?: MedHelpAbsenceFollowUpWebApiResponseAbsenceDegree[] | null;
    properties?: MedHelpAbsenceFollowUpWebApiResponseAbsenceProperty[] | null;
    showWorkRelated: boolean;
}

const FollowUpActivityLogsMobileHeader = ({
    type,
    header,
    endDate,
    degree,
    degrees,
    showWorkRelated,
    properties,
}: IProps) => {
    const { t } = useTranslation("followup");
    const orderedDegrees = orderBy(degrees, ["timeStamp"], "desc");
    const Properties = useMemo(() => {
        const workRelated = properties ? absenceList.getProperty(properties, "WorkRelated") : null;

        const workplaceAccident = properties ? absenceList.getProperty(properties, "WorkplaceAccident") : null;
        return { workRelated, workplaceAccident };
    }, [properties]);

    return (
        <div className="flex flex-col gap-3">
            <div className="font-bold">{`${type === "Sick" ? t("sick") : t("onlyVabText")}, ${degree}%`}</div>
            {type === "Sick" && (
                <div className="text-sm">
                    {selectOnlyCauseFromHeader(header) || t("followUpAbsence.summaryOfAbsence.None")}
                </div>
            )}
            {orderedDegrees?.map((item, index) => (
                <div className="flex" key={`${item.id}-${index}-${type}}`}>
                    {item.timeStamp && (
                        <div className="text-sm">{`${getDegreePeriodString(
                            item.timeStamp,
                            orderedDegrees,
                            index,
                            t,
                            endDate,
                        )} | ${degree || ""}%`}</div>
                    )}
                </div>
            ))}
            {showWorkRelated && (
                <div>
                    <div className="mt-4 flex">
                        <p className="text-sm">{t("followUpAbsence.workRelated")}:</p>
                        <p className="text-sm font-bold">
                            {Properties?.workRelated?.value === "true" ? t("yes") : t("no")}
                        </p>
                    </div>
                    <div className="flex">
                        <p className="text-sm">{t("followUpAbsence.workAccident")}:</p>
                        <p className="text-sm font-bold">
                            {Properties?.workplaceAccident?.value === "true" ? t("yes") : t("no")}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FollowUpActivityLogsMobileHeader;
