import { Card, CardAlign, Switch } from "@medhelp/ui";
import { SyntheticEvent } from "react";
import PrimaryButton from "components/PrimaryButton";
import FollowUpDateView from "../FollowUpDateView";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import TertiaryButton from "components/TertiaryButton";
import { flow } from "lodash/fp";
import { ISingleDatepickerProps } from "@medhelp/ui/Datepicker/types";

interface IProps {
    active: boolean;
    header: string;
    startDatepickerProps: ISingleDatepickerProps;
    EndDatepickerProps: ISingleDatepickerProps;
    onChange: (v: SyntheticEvent) => void;
    hasEdited: boolean;
    resetDates: (event: SyntheticEvent) => SyntheticEvent;
    onSubmit: (event: SyntheticEvent) => SyntheticEvent;
    isXMediumscreen: boolean;
}
const FollowUpMedicalCertificate = ({
    active,
    header,
    startDatepickerProps,
    EndDatepickerProps,
    onChange,
    hasEdited,
    onSubmit,
    resetDates,
    isXMediumscreen,
}: IProps) => {
    const { t } = useTranslation(["dashboard", "translation"]);
    return (
        <Card align={CardAlign.start} className="min-h-[66px] w-full flex-col p-0 px-0 py-0">
            <div className="flex flex-1 justify-between px-8 py-6 msd:py-2">
                <div className="flex self-center" data-testid="followup-medical-certificate-require-text">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: header,
                        }}
                    />
                </div>
                {!isXMediumscreen && active && (
                    <div className="flex">
                        <FollowUpDateView singleDatepickerProps={startDatepickerProps} changeBorder />
                        <FollowUpDateView singleDatepickerProps={EndDatepickerProps} />
                    </div>
                )}

                <div className="self-center">
                    <Switch
                        onChange={onChange}
                        value={active}
                        className="m-0 self-center"
                        name="hasMedicalRequired"
                        data-testid="followup-firstdayrequire-medical-certificate"
                    />
                </div>
            </div>
            {isXMediumscreen && active && (
                <>
                    <hr />
                    <div className="flex flex-col px-4" data-testid="medical-require-certificate-mobile">
                        <FollowUpDateView singleDatepickerProps={startDatepickerProps} changeBorder />
                        <FollowUpDateView singleDatepickerProps={EndDatepickerProps} />
                    </div>
                </>
            )}

            {active && hasEdited && (
                <div className="my-2 flex gap-2 px-8 msd:justify-end">
                    <TertiaryButton
                        id="cancel-need-for-medicalcertificate"
                        onClick={resetDates}
                        text={t("cancel", { ns: "dashboard" })}
                    />
                    <PrimaryButton
                        id="add-need-for-medicalcertificate"
                        onClick={flow(onSubmit, resetDates)}
                        text={t("save", { ns: "translation" })}
                        rightIcon={<Icon icon="checkMark" stroke="currentColor" />}
                        disabled={Boolean(!startDatepickerProps.selectedDate || !EndDatepickerProps.selectedDate)}
                    />
                </div>
            )}
        </Card>
    );
};
export default FollowUpMedicalCertificate;
