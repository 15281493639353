import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { thunkActions } from "../../../redux";
import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IProps {
    healthCase: IHealthCase;
    modalIsOpen: boolean;
    setModalIsOpen: () => void;
}

const RemoveCaseModal = (props: IProps) => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { healthCase, modalIsOpen, setModalIsOpen } = props;

    const handleOnDelete = () => {
        dispatch(thunkActions.deleteHealthCase(healthCase.id ?? ""))
            .then(unwrapResult)
            .then(() => toast(t("caseRemoved")))
            .then(() => {
                navigate(`/followup/employee/${healthCase.employmentId}/case`, {
                    replace: true,
                });
            })
            .catch((e) => toast.error(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("caseNotRemoved")}`));
    };

    return (
        <ModalFrame
            id="remove-case-modal"
            header={<h1>{t("deleteCase")}</h1>}
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            content={<p className="px-3 pb-2 pt-8 sm:px-0">{t("infoRemoveCase")}</p>}
            footer={
                <ModalCommonFooter
                    tertiaryText={t("cancel")}
                    tertiaryOnClick={setModalIsOpen}
                    primaryText={t("delete")}
                    primaryOnClick={handleOnDelete}
                />
            }
        />
    );
};

export default RemoveCaseModal;
