import { ReactNode } from "react";
import { ModalFrame } from "@medhelp/ui";
import Icon from "components/Icon/Icon";
import { useState } from "react";

interface IIconModalProps {
    id: string;
    className?: string;
    heading?: string;
    topContent: string | ReactNode;
    bottomContent?: string;
    isMandatory?: boolean;
    isH1?: boolean;
}

const IconModal = (props: IIconModalProps) => {
    const { id, className, heading, topContent, bottomContent, isMandatory, isH1 = false } = props;
    const [open, setOpen] = useState(false);

    const header = (
        <>
            <div className="mr-3">
                <Icon icon="info" size={24} fill="transparent" color="#9C9E8D" />
            </div>
            <h1>{heading}</h1>
        </>
    );

    const content = topContent && (
        <div className="leading-8">
            <p>{topContent}</p>
            <br />
            <p>{bottomContent}</p>
        </div>
    );

    return (
        <div className="flex">
            {isMandatory && (
                <div className={`ml-1 ${isH1 ? "font-serif text-3xl" : "text-2xl leading-6 text-mhred-alert2"}`}>*</div>
            )}
            {topContent && (
                <>
                    <button
                        id={id}
                        type="button"
                        className={`ml-2 h-5 w-5 rounded-full ${className} ${isH1 && "mt-4"}`}
                        onClick={() => setOpen(!open)}
                    >
                        <Icon icon="info" size={20} stroke="#9C9E8D" fill="transparent" />
                    </button>
                    <ModalFrame id={id} header={header} content={content} open={open} setOpen={setOpen} />
                </>
            )}
        </div>
    );
};
export default IconModal;
