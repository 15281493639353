import TopLinks from "components/TopLinks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { subcontractorAccess } from "routes";

const About = () => {
    const { t } = useTranslation("about");
    const Routes = useMemo(
        () => [
            {
                route: "privacyPolicy",
                name: t("privacyPolicy"),
                children: [],
            },
            {
                route: "cookiePolicy",
                name: t("cookiePolicy"),
                children: [],
            },
            {
                route: "subcontractors",
                name: t("subcontractors"),
                children: [],
                whitelist: subcontractorAccess,
            },
        ],
        [t],
    );
    return (
        <>
            <TopLinks routes={Routes} headRoute="about" header={t("aboutOurService")} />
            <div className="w-full justify-center px-4 pb-20 pt-12 xl:flex xl:px-0">
                <div className="w-full max-w-6xl">
                    <Outlet />
                </div>
            </div>
        </>
    );
};

export default About;
