interface InputTextAreaProps {
    onChange: (value: string) => void;
    textAreaValue?: string;
    disabled?: boolean;
}

const InputTextArea = ({ onChange, textAreaValue, disabled }: InputTextAreaProps) => (
    <textarea
        data-testid="input-text-area"
        className="mt-2 w-full border border-mhgrey-dark bg-transparent p-2 md:w-[624px]"
        rows={3}
        onChange={(e) => onChange(e.target.value)}
        value={textAreaValue}
        disabled={disabled}
    />
);

export default InputTextArea;
