import React from "react";
import { ReactComponent as LightBulbIcon } from "icons/menu/about.svg";
import { useTranslation } from "react-i18next";
function ProductSettingsInfoBox() {
    const { t } = useTranslation("administration");
    return (
        <div className="mx-6 flex gap-4 rounded-tl-lg bg-grey-100 p-8 px-4 lg:mx-0">
            <LightBulbIcon />
            <div className="ml-4 w-full text-lg lg:w-3/5">{t("manageSettingsDescription")}</div>
        </div>
    );
}

export default ProductSettingsInfoBox;
