import { twMerge } from "tailwind-merge";
import { MiniModal } from "../Modals";

export interface IHeadingProps {
    heading: string;
    mandatory?: boolean;
    infoText?: string;
    className?: string;
    disabled?: boolean;
}
const Heading = ({ heading, mandatory, infoText, className, disabled }: IHeadingProps) => (
    <div className={twMerge("mb-2 flex w-full items-center text-base font-bold", className)}>
        <div className={twMerge(mandatory ? "mr-1" : "mr-2", disabled ? "text-mhgrey-medium" : "")}>{heading}</div>
        {mandatory && <div className="mr-1 text-2xl leading-6 text-mhred-alert2">*</div>}
        {infoText && (
            <MiniModal heading={heading} size={20}>
                <p className="opacity-50">{infoText}</p>
            </MiniModal>
        )}
    </div>
);

export default Heading;
