import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { getUnreadCount } from "./redux/inboxSelectors";
import Icon from "components/Icon";

const InboxLoading = () => {
    const { t } = useTranslation();
    const unreadCount = useAppSelector(getUnreadCount);
    const headers = [
        { label: t("eventType"), accessor: "eventType" },
        { label: t("subject"), accessor: "subject" },
        { label: t("date"), accessor: "date" },
    ];
    const numberOfLoadingRows = Math.min(3 + unreadCount, 10);
    const widths = ["w-56", "w-44", "w-40", "w-48", "w-43", "w-52", "w-40", "w-44", "w-56", "w-48"];
    const loadingRowsWidths = widths.slice(0, numberOfLoadingRows);

    return (
        <div className="block animate-pulse justify-start bg-primaryBg p-0 shadow-lg">
            <div className="flex justify-between border-b">
                <div className="flex h-23 w-full items-center justify-between">
                    {headers.map(({ label }) => (
                        <div key={label} className="w-full px-6 pb-5 pt-8 text-left font-bold">
                            {label}
                        </div>
                    ))}
                </div>
                <div className="flex items-center justify-end px-6 pb-5 pt-8">
                    <Icon icon="miniMenu" />
                </div>
            </div>
            {loadingRowsWidths.map((width, i) => (
                <div key={`inbox-loading-${i}`} className="flex justify-between border-b">
                    <div className="flex h-23 w-full items-center justify-between">
                        <div className="flex w-full items-center">
                            <div className="m-6 h-11 w-11 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                            <div className={`${width} m-6 h-5 w-52 rounded-full bg-gray-200 dark:bg-gray-700`}></div>
                        </div>
                        <div className="w-full">
                            <div className={`${width} m-6 h-5 w-60 rounded-full bg-gray-200 dark:bg-gray-700`}></div>
                        </div>
                        <div className="w-full">
                            <div className="m-6 h-5 w-40 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end">
                        <div className="m-6 h-5 w-6 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </div>
            ))}
            <div className="flex">
                <div className="m-6 mr-90 h-5 w-27 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                <div className="m-6 h-5 w-52 rounded-full bg-gray-200 dark:bg-gray-700"></div>
            </div>
        </div>
    );
};

export default InboxLoading;
