import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useBreakpoints } from "hooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { actions, thunkActions } from "pages/FollowUp/redux";
import { IHealthCase } from "pages/FollowUp/redux/followUpStateTypes";
import { activeCaseTypes, inactiveCaseTypes } from "../../../utils/case";
import { PageTypes } from "../OngoingCaseItem/OngoingCaseItem";
import { toast } from "react-toastify";
import { ModalFrame } from "@medhelp/ui";
import { Spinner } from "components/Spinner";
import { Status } from "@medhelp/ui/LoadableComponent";
import { PdfViewer } from "..";
import { useAppDispatch } from "store/hooks";

interface IProps {
    healthCase: IHealthCase;
    documentId: string;
    pdfDocumentReferenceKey?: string;
    modalIsOpen: boolean;
    setModalIsOpen: (openModal: boolean) => void;
    setPage?: (page: PageTypes) => void;
    isEdit?: boolean;
    pdfDocumentTitle?: string | null;
}

const PdfViewerModal = (props: IProps) => {
    const {
        pdfDocumentReferenceKey,
        healthCase,
        documentId,
        modalIsOpen,
        setModalIsOpen,
        setPage,
        isEdit,
        pdfDocumentTitle,
    } = props;
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const breakpoints = useBreakpoints();
    const [isSaving, setIsSaving] = useState(false);

    const getModalHeader = () => {
        if (healthCase.status && inactiveCaseTypes.includes(healthCase.status)) {
            return t("notPossibleToEdit");
        } else if (breakpoints.isSmallscreen) {
            return t("notPossibleToEditOnCurrentDevice");
        } else {
            return t("editDocument");
        }
    };

    useEffect(() => {
        if (healthCase.healthCaseSubStatus.pdfDocumentTemplate === Status.PENDING) {
            if (!pdfDocumentReferenceKey) {
                dispatch(
                    thunkActions.downloadFileWithTemplate({
                        healthCaseId: healthCase.id!,
                        documentId,
                    }),
                );
            } else {
                dispatch(
                    thunkActions.fetchTemplateDocumentLink({
                        pdfDocumentReferenceKey,
                        employmentId: healthCase.employmentId,
                        healthCaseId: healthCase.id!,
                    }),
                );
            }
        }
    }, [dispatch, healthCase, documentId, pdfDocumentReferenceKey]);

    useEffect(() => {
        if (healthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved === Status.OK) {
            setIsSaving(false);
            setModalIsOpen(false);
        }
    }, [healthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved, setModalIsOpen]);

    useEffect(() => {
        return () => {
            dispatch(actions.clearHealthCasePdfInitState(healthCase?.id ?? ""));
        };
    }, [dispatch, healthCase?.id]);

    const handleSaveAnnotation = (annotation: string) => {
        setIsSaving(true);
        dispatch(
            thunkActions.uploadFileWithTemplate({
                isEdit,
                healthCaseId: healthCase.id!,
                // documentTemplateId...
                documentTemplateId: documentId,
                annotation,
                type: "file",
                title: pdfDocumentTitle ?? "",
                documentId,
            }),
        )
            .then(unwrapResult)
            .then(() => setPage && setPage("caseDocuments"))
            .then(() => toast(t("documentSaved")))
            .catch((e) =>
                toast(`${e?.response?.status ? `${e.response.status} - ` : ""}${t("documentNotSaved")}`, {
                    type: "error",
                }),
            );
    };

    return (
        <ModalFrame
            id="pdf-viewer-modal"
            open={modalIsOpen}
            setOpen={setModalIsOpen}
            header={<h1 className="text-sm sm:text-2xl">{getModalHeader()}</h1>}
            preset="full"
            content={
                healthCase.healthCaseSubStatus.pdfDocumentTemplate === Status.PENDING || !healthCase.pdfUrl ? (
                    <div className="flex flex-col">
                        <div className="my-auto mt-10 place-self-center">
                            <Spinner />
                        </div>
                    </div>
                ) : healthCase.healthCaseSubStatus.pdfDocumentTemplate === Status.ERROR ? (
                    <div className="flex flex-col">
                        <h2 className="mt-10 place-self-center">Could not load PDF</h2>
                    </div>
                ) : (
                    <div className="h-full w-full">
                        {healthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved === Status.ERROR && (
                            <div className="relative">
                                <div className="absolute left-0 right-0 z-10 m-auto flex w-2/3 justify-center">
                                    <span className="animate-bounce rounded-b-sm bg-mhred-alert2 px-6 py-4 font-bold">
                                        Could not save documentation
                                    </span>
                                </div>
                            </div>
                        )}
                        <PdfViewer
                            pdfUrl={healthCase.pdfUrl}
                            pdfFileName={pdfDocumentTitle ? `${pdfDocumentTitle}.pdf` : "file.pdf"}
                            isSaving={
                                isSaving &&
                                healthCase.healthCaseSubStatus.pdfDocumentBasedOnTemplateSaved !== Status.ERROR
                            }
                            annotation={healthCase.annotation}
                            saveAnnotation={handleSaveAnnotation}
                            saveButtonText="Spara"
                            readonly={
                                healthCase.status &&
                                activeCaseTypes.includes(healthCase.status) &&
                                !breakpoints.isSmallscreen
                                    ? false
                                    : true
                            }
                        />
                    </div>
                )
            }
        />
    );
};

export default PdfViewerModal;
