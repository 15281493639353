interface StepperProps {
    numberOfSteps?: number;
    currentStep: number;
}

const Stepper = ({ numberOfSteps, currentStep }: StepperProps) => (
    <div className="absolute left-0 top-[5.875-rem] w-full xl:top-[3.5rem]">
        <div className="flex flex-row shadow-inner">
            {Array.from(Array(numberOfSteps)).map((_, i) => (
                <div
                    className={`flex h-2 flex-grow items-center justify-center xxs:h-3 ${
                        currentStep >= i + 1 && numberOfSteps && numberOfSteps !== 1
                            ? "bg-stepper"
                            : "bg-mhgrey-light bg-opacity-50"
                    }`}
                />
            ))}
        </div>
    </div>
);

export default Stepper;
