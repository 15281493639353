import * as React from "react";
import Icon, { Icons } from "components/Icon";
import { LoadableComponent, LoadableComponentStatus } from "@medhelp/ui";
import Notification from "../Notification";

interface IEmployeeCardContent {
    header: string;
    text: string | null;
}
export interface IEmployeeCard {
    data: IEmployeeCardContent[];
    header: string;
    active: boolean;
    extra: React.ReactNode | null;
    status: LoadableComponentStatus;
    icon?: Icons;
    disabled?: boolean;
    id?: string;
}

const EmployeeCard = ({ data, header, active, extra, status, icon, disabled }: IEmployeeCard) => (
    <div className="flex h-full min-h-[150px] min-w-80 flex-col justify-between bg-secondaryBg px-3 py-6 text-mhbeige">
        <div className="px-2">
            <div className="flex">
                <LoadableComponent status={status} errorMessage="" color="white">
                    <div className="flex flex-col gap-5">
                        <div className="flex items-center">
                            {icon && (
                                <div className="flex pr-4 pt-1 text-profileCardIcon">
                                    {<Icon size={18} icon={icon} className={`${disabled ? "opacity-50" : ""}`} />}
                                </div>
                            )}
                            <h3 className={`${disabled ? "opacity-50" : ""}`}>{header}</h3>
                            <div className={`pl-3 ${disabled ? "invisible" : ""}`}>
                                <Notification seen={!active} />
                            </div>
                        </div>
                        {data.map(({ header, text }, index) => (
                            <div key={`employee-card-${index}`} className="relative ml-[34px]">
                                <div className="flex flex-col">
                                    <p className="font-bold">{header}</p>
                                    {text && <p className={`mb-0 ${disabled ? "opacity-50" : ""}`}>{text}</p>}
                                </div>
                            </div>
                        ))}
                    </div>
                </LoadableComponent>
            </div>
        </div>
        {extra && <div className="ml-[34px] mt-6 h-9 px-2">{extra}</div>}
    </div>
);
export default EmployeeCard;
