import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { getWorkOrder, saveWorkOrder } from "./redux/workOrderAsyncActions";
import * as WorkOrderSelectors from "./redux/workOrderSelectors";
import { workOrderActions } from "./redux/workOrderSlice";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import Card, { CardAlign } from "@medhelp/ui/Card";
import PrimaryButton from "components/PrimaryButton";
import { Config } from "config";
import { SpinnerWithOverlay } from "components/Spinner";
import { IWorkOrderParams } from "./types";
import { isQueryParamsValid, getBaseParameters, birthdateAndAge } from "./utils";

// https://mymedhelp.medhelp.se/medicaladvisory/?BaseServiceUrl=tcp:%2F%2F10.17.0.5:8095%2FBaseService.soap&Instance=medhelp&AgentSessionID=5524223&AgentID=92&ServiceID=3069&From=sip:anonymous@medhelp.proxy.medhelp.se:5060&To=sip:0727145141@medhelp.proxy.medhelp.se:5060&ContactID=791848&SessionID=9470979&AgentName=ÅsaBlom&ServiceName=Test

//https://mymedhelp.medhelp.se/medicaladvisory/?
//BaseServiceUrl=tcp:%2F%2F10.17.0.5:8095%2FBaseService.soap
//Instance=medhelp
//AgentSessionID=5524223
//AgentID=92
//ServiceID=3069
//From=sip:anonymous@medhelp.proxy.medhelp.se:5060
//To=sip:0727145141@medhelp.proxy.medhelp.se:5060
//ContactID=791848
//SessionID=9470979
//AgentName=%C3%85saBlom
//ServiceName=Test

const WorkOrder = () => {
    const getWorkOrderParamsInit = (): IWorkOrderParams => ({
        baseServiceUrl: undefined,
        instance: undefined,
        agentSessionId: undefined,
        agentId: undefined,
        serviceId: undefined,
        from: undefined,
        to: undefined,
        contactId: undefined,
        sessionId: undefined,
        agentName: undefined,
        serviceName: undefined,
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation("workOrder");
    const { isLoading, isError, workOrderReservation, isSaving, isSaved, isSavedError } = useAppSelector(
        WorkOrderSelectors.getWorkOrderSlice,
    );
    const [searchParams] = useSearchParams();
    const [allParamsIsValid, setAllParamsIsValid] = useState(false);
    const [workOrderParams, setWorkOrderParams] = useState<IWorkOrderParams>(getWorkOrderParamsInit());

    useEffect(() => {
        let params: IWorkOrderParams = getWorkOrderParamsInit();

        searchParams.forEach((value: string, key: string) => {
            if (_.camelCase(key) in workOrderParams !== undefined) {
                params = { ...params, [_.camelCase(key)]: value };
            }
        });
        setAllParamsIsValid(isQueryParamsValid(params));
        setWorkOrderParams({ ...params });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (allParamsIsValid) {
            dispatch(
                getWorkOrder({
                    workOrderId: Number(workOrderParams.contactId),
                    agentSessionId: Number(workOrderParams.agentSessionId),
                }),
            );
        }
    }, [allParamsIsValid, workOrderParams, dispatch]);

    useEffect(() => {
        // Redirect to princess
        if (isSaved && workOrderParams && workOrderReservation) {
            const baseParams = getBaseParameters(workOrderParams, workOrderReservation);

            const url = `${Config.princessDomain}/princess/init.asp${baseParams.toString()}`;
            window.location.href = url;
        }
    }, [isSaved, workOrderParams, workOrderReservation]);

    if (!allParamsIsValid) {
        return (
            <>
                <div className="mb-10 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                    <h1>Work order</h1>
                </div>
                <div className="my-10 xxs:mt-0">
                    <div className={`background flex border border-background-300`}>
                        <div className="bg-alert-400 h-auto bg-opacity-25 px-3 pt-9 xxs:px-8">
                            <WarningIcon className="w-6 text-black" />
                        </div>
                        <div className="p-8 pl-6">
                            <h2 className="mb-3 text-mhred-alert1">Error</h2>
                            <p>Parameter not valid!</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (isError) {
        return (
            <>
                <div className="mb-10 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                    <h1>Work order</h1>
                </div>
                <div className="my-10 xxs:mt-0">
                    <div className={`background flex border border-background-300`}>
                        <div className="bg-alert-400 h-auto bg-opacity-25 px-3 pt-9 xxs:px-8">
                            <WarningIcon className="w-6 text-black" />
                        </div>
                        <div className="p-8 pl-6">
                            <h2 className="mb-3 text-mhred-alert1">Error</h2>
                            <p>Something went wrong with fetching workorder</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (isLoading) {
        return (
            <>
                <div className="mb-10 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                    <h1>Work order</h1>
                </div>
                <Card align={CardAlign.center} className="animate-pulse flex-col">
                    <h2 className="mb-5">
                        <div className="mb-4 h-2.5 w-56 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </h2>
                    <h3 className="mb-2">
                        <div className="mb-4 h-2.5 w-60 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    </h3>
                    <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="mb-2 h-2.5 w-42 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="mb-10 h-2.5 w-28 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                    <div className="mb-2 h-2.5 w-42 rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </Card>
            </>
        );
    }

    return (
        <>
            <div className="mb-10 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                <h1>Work order</h1>
            </div>
            <Card align={CardAlign.center} className="relative flex-col">
                {isSaving && <SpinnerWithOverlay />}
                {isSavedError && <h1 className="mb-3 text-mhred-alert1">Something went wrong with saving</h1>}
                <h2 className="mb-5">{t(`workOrderType.enum${workOrderReservation?.workOrderType}`)}</h2>
                <h3 className="mb-2">{workOrderReservation?.company?.name}</h3>
                <p className="font-bold">{workOrderReservation?.userAccount?.name}</p>
                <p>{birthdateAndAge(t, workOrderReservation?.userAccount?.socialSecurityNumber)}</p>
                <p className="text-sm">{workOrderReservation?.userAccount?.socialSecurityNumber}</p>
                {workOrderReservation?.notes && (
                    <div className="mt-3">
                        <p>{t("notesFromLastTry")}</p>
                        <p>{workOrderReservation.notes}</p>
                    </div>
                )}
                <div className="mt-10 flex flex-row">
                    <PrimaryButton
                        id="workorder-continue"
                        onClick={() => {
                            dispatch(workOrderActions.setIsSaving());
                            dispatch(
                                saveWorkOrder({
                                    workOrderId: Number(workOrderParams.contactId),
                                    agentSessionId: Number(workOrderParams.agentSessionId),
                                }),
                            );
                        }}
                        text={t("saveCallStatusButton")}
                    />
                </div>
            </Card>
        </>
    );
};

export default WorkOrder;
