import React from "react";

interface IProps {
    onChange: (value: React.SyntheticEvent) => void;
    value: any;
    name: string;
    className?: string;
    disabled?: boolean;
}
const Switch = ({ onChange, value, className, name, disabled, ...rest }: IProps) => {
    return (
        <label className="relative flex cursor-pointer items-center">
            <input
                type="checkbox"
                id="toggle-example"
                className="sr-only"
                name={name}
                value={value}
                onChange={(e) => {
                    return onChange(e);
                }}
                checked={value}
                disabled={disabled}
                {...rest}
            />
            <div className={`toggle-bg h-4 w-11 rounded-full border-2 border-gray-200 bg-gray-200 ${className}`}></div>
        </label>
    );
};

export default Switch;
