import { Card } from "@medhelp/ui";

interface IProps {
    body: string;
}
const NoData = ({ body }: IProps) => (
    <div className="mt-9">
        <Card>
            <div className="flex max-w-[480px] flex-col items-center justify-center gap-5">
                <div className="text-center">{body}</div>
            </div>
        </Card>
    </div>
);

export default NoData;
