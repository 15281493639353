import _ from "lodash";
import { useState, useCallback, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import NoUserDataMessage from "components/NoUserDataMessage";
import Icon from "components/Icon";
import { WindowSizes } from "hooks/getWindowSize";
import {
    getMedicalOrCustomOrAdminAccess,
    getCustomerSupportOrAdminAccess,
    getCustomerSupportAccess,
    getAbsenceAdministrationAccess,
} from "store/userSelectors";
import { actions as absenceReportsActions, thunkActions } from "../../redux";
import {
    FinishedReportsSortEnum,
    getSortedFinishedAbsenceReports,
    getOngoingReports,
    getSelectedAbsenceReport,
} from "../../redux/absenceReportsSelectors";
import SmallHistoryCard from "./SmallHistoryCard";
import AbsenceHistoryReports from "./AbsenceHistoryReports";
import * as styles from "./AbsenceReportHistoryCard.styles";
import { ModalCommonFooter, ModalFrame } from "@medhelp/ui";
import { getDate, getDateForwardOrBack, getStartOfDate, isSameOrAfterDate } from "utils/date";

interface ITranslatedObject {
    header?: string;
    list: string[];
}

interface IAbsenceReportHistoryCard {
    id: string;
    preset: styles.IAbsenceReportHistoryCardPresets;
    className?: string;
    headers: string[];
}

const absenceReportHistoryCardPresets = {
    background: "pageBg",
    red: "bg-red-500",
};

export type IAbsenceReportHistoryCardPresets = keyof typeof absenceReportHistoryCardPresets;

const AbsenceReportHistoryCard = ({
    children,
    id,
    preset,
    className,
    headers,
}: IAbsenceReportHistoryCard & { children?: ReactNode }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const windowSize = WindowSizes();
    const [sortValue, setSortValue] = useState<{
        type: FinishedReportsSortEnum;
        order: "asc" | "desc";
    }>({
        type: FinishedReportsSortEnum.start,
        order: "desc",
    });
    const [reopenModalIsOpen, setReopenModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const customerSupportAccess = useAppSelector(getCustomerSupportAccess);
    const medicalOrCustomOrAdminAccess = useAppSelector(getMedicalOrCustomOrAdminAccess);
    const customerSupportOrAdminAccess = useAppSelector(getCustomerSupportOrAdminAccess);
    const absenceAdministrationAccess = useAppSelector(getAbsenceAdministrationAccess);
    const sortedFinishedAbsenceReports = useAppSelector(getSortedFinishedAbsenceReports(sortValue));
    const ongoingReports = useAppSelector(getOngoingReports);
    const selectedAbsenceReport = useAppSelector(getSelectedAbsenceReport);

    const reopenInformation: ITranslatedObject[] = t("reopenHistoricalReport.list", {
        returnObjects: true,
    });

    const handleReopen = useCallback(() => {
        if (selectedAbsenceReport?.id && selectedAbsenceReport?.employmentId) {
            dispatch(
                thunkActions.openHistoricalAbsenceReport({
                    id: selectedAbsenceReport.id,
                    employmentId: selectedAbsenceReport.employmentId,
                }),
            );
            setReopenModalIsOpen(false);
        }
    }, [dispatch, selectedAbsenceReport?.employmentId, selectedAbsenceReport?.id]);

    const handleEdit = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            dispatch(
                absenceReportsActions.updateAbsenceReportIsEdit({
                    id: absenceReport.id,
                    isEdit: absenceReport.isEdit ? false : true,
                }),
            );
        },
        [dispatch],
    );

    const handleDelete = useCallback(() => {
        if (selectedAbsenceReport) {
            dispatch(thunkActions.deleteAbsenceReport(selectedAbsenceReport));
            setDeleteModalIsOpen(false);
        }
    }, [dispatch, selectedAbsenceReport]);

    const handleClose = useCallback(() => {
        setDeleteModalIsOpen(false);
        setReopenModalIsOpen(false);
        dispatch(absenceReportsActions.resetSelectedAbsenceReport());
    }, [dispatch]);

    const isWithinNumberOfPastMonths = useCallback((date: string, months: number): boolean => {
        return isSameOrAfterDate(
            getStartOfDate("days", getDate(date)),
            getStartOfDate("days", getDateForwardOrBack(months, "months", "backwards")),
        );
    }, []);

    const getIsEditVisible = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            if (customerSupportAccess) {
                return isWithinNumberOfPastMonths(absenceReport.end ?? "", 36);
            } else if (absenceAdministrationAccess) {
                return isWithinNumberOfPastMonths(absenceReport.end ?? "", 12);
            } else {
                return isWithinNumberOfPastMonths(absenceReport.end ?? "", 2);
            }
        },
        [absenceAdministrationAccess, customerSupportAccess, isWithinNumberOfPastMonths],
    );

    const getMiniMenuItems = useCallback(
        (absenceReport: IAbsenceReportExtended) => {
            const miniMenuItems = [
                {
                    text: t("open"),
                    onClick: () => {
                        dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
                        setReopenModalIsOpen(true);
                    },
                    visible:
                        customerSupportOrAdminAccess &&
                        ongoingReports.length < 4 &&
                        isWithinNumberOfPastMonths(absenceReport.end ?? "", 12),
                },
                {
                    text: t("edit"),
                    onClick: () => {
                        handleEdit(absenceReport);
                    },
                    visible: getIsEditVisible(absenceReport),
                },
                {
                    text: t("delete"),
                    onClick: () => {
                        dispatch(absenceReportsActions.updateSelectedAbsenceReport(absenceReport));
                        setDeleteModalIsOpen(true);
                    },
                    visible: true,
                },
            ];

            return miniMenuItems
                .filter((item) => item.visible)
                .map((item) => {
                    return {
                        text: item.text,
                        onClick: item.onClick,
                    };
                });
        },
        [
            t,
            customerSupportOrAdminAccess,
            ongoingReports.length,
            isWithinNumberOfPastMonths,
            getIsEditVisible,
            dispatch,
            handleEdit,
        ],
    );

    const historyModals = useMemo(() => {
        return (
            <>
                <ModalFrame
                    id="reopen-history-report-modal"
                    open={reopenModalIsOpen}
                    setOpen={handleClose}
                    header={<h4>{t("reopenHistoricalReport.header")}</h4>}
                    content={
                        <>
                            <p className="mb-3 font-bold">{t("reopenHistoricalReport.text")}</p>
                            <ul className="list-inside list-disc text-base">
                                {reopenInformation.map((info, i) => (
                                    <div key={info.header ? info.header + i : "unique-key" + i}>
                                        {info.header && <p className="mt-4">{info.header}</p>}

                                        {info.list.map((text) => (
                                            <li key={text} className={`pb-1 ${info.header ? "ml-5" : ""}`}>
                                                {text}
                                            </li>
                                        ))}
                                    </div>
                                ))}
                            </ul>
                        </>
                    }
                    footer={
                        <ModalCommonFooter
                            secondaryOnClick={handleClose}
                            secondaryText={t("abort")}
                            primaryOnClick={handleReopen}
                            primaryText={t("yesReopen")}
                        />
                    }
                />
                <ModalFrame
                    id="delete-history-report-modal"
                    open={deleteModalIsOpen}
                    setOpen={handleClose}
                    header={<h4>{t("deleteHistoricalReport")}</h4>}
                    content={t("deleteHistoricalReportText")}
                    footer={
                        <ModalCommonFooter
                            secondaryOnClick={handleClose}
                            secondaryText={t("abort")}
                            primaryOnClick={handleDelete}
                            primaryText={t("yesDelete")}
                        />
                    }
                />
            </>
        );
    }, [deleteModalIsOpen, handleClose, handleDelete, handleReopen, reopenInformation, reopenModalIsOpen, t]);

    if (_.isEmpty(sortedFinishedAbsenceReports))
        return <NoUserDataMessage id="noUserHistoryMessage" message={t("noHistory")} />;

    if (windowSize.width < 640) {
        return (
            <>
                <SmallHistoryCard
                    getMiniMenuItems={getMiniMenuItems}
                    id={id}
                    preset={preset}
                    sortedFinishedAbsenceReports={sortedFinishedAbsenceReports}
                />
                {historyModals}
            </>
        );
    }

    return (
        <div className="mt-6 shadow-lg xs:bg-primaryBg xs:bg-opacity-25 xs:p-10">
            <div>
                <div id={id} className={`${styles.absenceReportHistoryCardPresets[preset]} ${className}`}>
                    <div className={`flex pb-4 ${medicalOrCustomOrAdminAccess ? "w-11/12" : "w-full"}`}>
                        {headers.map((header, index) => {
                            return (
                                <div key={header} className={`flex w-1/4 font-bold`}>
                                    <p className="mr-2 font-bold">{header}</p>
                                    <button
                                        className="mt-1 flex"
                                        type="button"
                                        onClick={() =>
                                            setSortValue((state) => ({
                                                type: index,
                                                order: state.order === "asc" ? "desc" : "asc",
                                            }))
                                        }
                                    >
                                        <Icon icon="sort" />
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                    <div className="border-b border-mhgrey-mediumdisabled" />
                    <AbsenceHistoryReports
                        sortedFinishedAbsenceReports={sortedFinishedAbsenceReports}
                        preset={preset}
                        getMiniMenuItems={getMiniMenuItems}
                    />
                    {children}
                </div>
            </div>
            {historyModals}
        </div>
    );
};
export default AbsenceReportHistoryCard;
