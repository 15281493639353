import Checkbox from "@medhelp/ui/Checkbox";
import { ICheckBoxStateProps, ISubscriptionGroup } from "pages/Administration/types";
import {
    getAllIsCheckedForCurrentChannel,
    getArrayOfAvailableChannels,
    handleAllCheckboxesOnChange,
} from "pages/Administration/utils";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ISubscriptionGroupPageProps extends ISubscriptionGroup {
    onCheckboxChange: (checkboxProps: ICheckBoxStateProps[]) => void;
    disableEditSubscription?: boolean;
    children: ReactNode;
}

const SubscriptionGroup = ({
    groupName,
    notificationTypeRows,
    disableEditSubscription,
    onCheckboxChange,
    children,
}: ISubscriptionGroupPageProps) => {
    const { t } = useTranslation("administration");

    return (
        <div key={groupName} className="flex flex-col">
            <div className="flex justify-between border-b py-10">
                <p className="font-bold">{t(groupName)}</p>
                <div className="mr-10 flex flex-col">
                    <div className="flex justify-between">
                        {getArrayOfAvailableChannels(notificationTypeRows).map((channel) => (
                            <div key={"select-all" + channel} className="flex w-18 flex-col items-center">
                                <p className="mb-1 px-2 text-center font-bold">{t("selectAll")}</p>
                                <Checkbox
                                    checked={getAllIsCheckedForCurrentChannel(notificationTypeRows, channel)}
                                    onChange={(v) =>
                                        onCheckboxChange(
                                            handleAllCheckboxesOnChange(
                                                channel,
                                                v.target.checked,
                                                notificationTypeRows,
                                            ),
                                        )
                                    }
                                    disabled={disableEditSubscription}
                                />
                                <p className="mt-1">{t(channel === 1 ? "eMail" : "Sms")}</p>
                            </div>
                        ))}
                        {getArrayOfAvailableChannels(notificationTypeRows).length < 2 && <span className="w-18" />}
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default SubscriptionGroup;
