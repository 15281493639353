import Icon, { Icons } from "components/Icon";
import PrimaryButton from "components/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { Retailers } from "globalTypes";
import { Modal } from "../Modal";
import { isArray } from "lodash/fp";

interface IProps {
    header: string;
    body: string | string[] | JSX.Element;
    buttonText: string;
    theme: Retailers;
    background?: string;
    storage?: string;
    icon?: Icons;
}
const NewsModal = ({ header, body, buttonText, background, theme, storage, icon = "employeeCardNews" }: IProps) => {
    const [open, setOpenState] = useState(true);

    const setOpen = useCallback(() => {
        if (storage) {
            window.localStorage.setItem(storage, "true");
        }
        setOpenState(false);
    }, [storage]);

    useEffect(() => {
        if (!storage) {
            setOpenState(true);
            return;
        }
        if (window.localStorage.getItem(storage) && window.localStorage.getItem(storage) === "true") {
            setOpenState(false);
            return;
        }
    }, [storage]);
    return (
        <Modal id="news-modal" open={open} setOpen={setOpen} background={background} theme={theme} hideOnClickOutside>
            <div
                className={`${theme} m-auto flex h-full flex-col items-center justify-between overflow-auto bg-pageBg md:mt-8 md:h-content md:max-h-screen md:w-[530px]`}
            >
                <div className="w-full">
                    <div className="flex w-full justify-center bg-mhblue/25">
                        <Icon icon={icon} size={275} />
                    </div>
                    <div className="item-center flex flex-col">
                        <div className="py-8 text-center text-2xl">{header}</div>
                        <div className="px-12 pb-10 text-center">
                            {isArray(body)
                                ? body.map((x, index) => (
                                      <p
                                          key={`newmodal-${body}-${index}`}
                                          className={`${index === body.length - 1 ? "" : "pb-8"}`}
                                      >
                                          {x}
                                      </p>
                                  ))
                                : body}
                        </div>
                    </div>
                </div>
                <div className="pb-10">
                    <PrimaryButton id="news-modal-followup-employee" onClick={setOpen}>
                        <div className="flex items-center justify-center gap-3">
                            <p className="font-bold">{buttonText}</p>
                        </div>
                    </PrimaryButton>
                </div>
            </div>
        </Modal>
    );
};

export default NewsModal;
