import * as React from "react";
import SecondaryButton from "components/SecondaryButton";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import Icon from "components/Icon";

interface IProps {
    text: string;
    getInputProps: () => DropzoneInputProps;
    getRootProps: ({ className }: { className: string }) => DropzoneRootProps;
    open: () => void;
}
const UploadButton = ({ getInputProps, getRootProps, text, open }: IProps) => (
    <div {...getRootProps({ className: "dropzone" })}>
        <input data-testid="certificate-upload-button" {...getInputProps()} />
        <SecondaryButton
            leftIcon={<Icon icon="attach" className="w-3" />}
            id="medical-certificate"
            onClick={(event) => {
                // otherwise dropzone event will be triggered
                event.stopPropagation();
                open();
            }}
            text={text}
            className="flex w-full justify-center msd:w-auto"
        />
    </div>
);
export default UploadButton;
