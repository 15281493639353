import PrimaryButton from "components/PrimaryButton";
import SecondaryButton from "components/SecondaryButton";
import BackButtonHeader from "../BackButtonHeader";
import { ReactComponent as BankIdIcon } from "icons/login/bankId.svg";
import UsernameOrPwdInput from "../UsernameOrPwdInput";
import Tabs, { ITabsObject } from "../Tabs/Tabs";
import { IOtpCompProps } from "pages/login/types";
import PhoneNumberComponent from "components/PhoneNumberComponent";

const OneTimePass = (otpCompProps: IOtpCompProps) => {
    const eMailTab = (
        <UsernameOrPwdInput
            placeholder={otpCompProps.translations.eMail}
            inputValue={otpCompProps.emailInput}
            inputChanged={(v) => otpCompProps.setOtpPropValue(v, "emailInput")}
            heading={`${otpCompProps.translations.eMail} (${otpCompProps.translations.work})`}
            containerClassName="mb-10 xxs:w-full"
            className="h-12"
            type="email"
            name="emailInput"
            autoComplete="email"
        />
    );
    const smsTab = (
        <div className={`mb-10 flex flex-col`}>
            <PhoneNumberComponent
                countryHeader={otpCompProps.translations.selectCountryForNumber + "*"}
                phoneNumberHeader={`${otpCompProps.translations.mobileNumber} *`}
                value={otpCompProps.phoneNumber}
                onChange={(v) => otpCompProps.setOtpPropValue(v, "phoneNumber")}
                useCustomCountryList
                className="mb-6"
                validateFields
                placeholder={otpCompProps.translations.smsInputPlaceholder}
                showMandatory
            />
        </div>
    );
    const tabsObject: ITabsObject[] = [
        {
            tabName: "eMail",
            tabContent: eMailTab,
        },
        {
            tabName: "SMS",
            tabContent: smsTab,
        },
    ];

    return (
        <div
            data-cy="one-time-pass-component"
            className={`flex flex-col ${otpCompProps.currentStep === "submitPass" ? "mb-6" : "mb-14"}`}
        >
            {otpCompProps.currentStep === "requestPass" && (
                <>
                    <BackButtonHeader
                        heading={otpCompProps.translations.oneTimePass}
                        onClick={() => otpCompProps.navigate(`/login/${otpCompProps.selectedMarket}`)}
                    />
                    <Tabs
                        tabs={tabsObject}
                        activeTab={otpCompProps.activeTab}
                        setActiveTab={(v) => otpCompProps.setOtpPropValue(v, "activeTab")}
                    >
                        {otpCompProps.countdownComp}
                        <PrimaryButton
                            id="oneTimePassButton"
                            text={otpCompProps.translations.requestPass}
                            className="flex h-12 w-full justify-center"
                            onClick={() => otpCompProps.handleOtpButtonClicked()}
                            disabled={!otpCompProps.validInput}
                            isLoading={otpCompProps.isLoading}
                        />
                    </Tabs>
                </>
            )}
            {otpCompProps.currentStep === "submitPass" && (
                <>
                    <BackButtonHeader
                        heading={otpCompProps.translations.enterOneTimePass}
                        onClick={() => otpCompProps.setOtpPropValue("requestPass", "currentStep")}
                    />
                    <UsernameOrPwdInput
                        placeholder={otpCompProps.translations.oneTimePass}
                        inputChanged={(v) => otpCompProps.setOtpPropValue(v, "otpInput")}
                        inputValue={otpCompProps.otpInput}
                        heading={otpCompProps.translations.oneTimePass}
                        containerClassName="mb-10 xxs:w-full"
                        className="h-12"
                        type="password"
                        name="oneTime-password"
                        autoComplete="one-time-code"
                    />
                    {otpCompProps.countdownComp}
                    <PrimaryButton
                        id="oneTimePassButton"
                        text={otpCompProps.translations.login}
                        className="flex h-12 justify-center"
                        onClick={() => otpCompProps.handleOtpButtonClicked(true)}
                        disabled={!otpCompProps.otpInput}
                        isLoading={otpCompProps.isLoading}
                    />
                    <div className="mt-14 flex w-full justify-center">
                        <button
                            type="button"
                            className="border-b border-linkBorderBottom hover:border-linkBorderBottomHover"
                            onClick={() => otpCompProps.setOtpPropValue("noCode", "currentStep")}
                        >
                            {otpCompProps.translations.noCodeReceived}
                        </button>
                    </div>
                </>
            )}
            {otpCompProps.currentStep === "noCode" && (
                <>
                    <BackButtonHeader
                        heading={otpCompProps.translations.noCodeReceived}
                        onClick={() => otpCompProps.setOtpPropValue("submitPass", "currentStep")}
                    />
                    <p className="mb-3">{otpCompProps.translations.noCodeExplanationStart}</p>
                    <p className="mb-10">{otpCompProps.translations.noCodeExplanationEnd}</p>
                    {otpCompProps.countdownComp}
                    <SecondaryButton
                        id="newOneTimePassButton"
                        text={otpCompProps.translations.requestNewPass}
                        className="mb-4 h-12 justify-center"
                        onClick={() => otpCompProps.handleOtpButtonClicked()}
                    />
                    <PrimaryButton
                        id="bankIdButton"
                        text={otpCompProps.translations.loginWithBankId}
                        className="flex h-12 justify-center"
                        rightIcon={<BankIdIcon className="h-6" />}
                        onClick={() => otpCompProps.navigate("/login/bankId")}
                    />
                </>
            )}
        </div>
    );
};

export default OneTimePass;
