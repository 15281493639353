import _ from "lodash";
import React, { useCallback } from "react";
import { useDetectOutsideClick } from "../../hooks";
import Icon, { Icons } from "components/Icon";
import { isEmpty } from "lodash/fp";

export interface IButtonValues {
    text: string;
    onClick: () => void;
    icon?: Icons;
}

interface IMiniMenuProps {
    id: string;
    preset: IMiniMenuPresets;
    className?: string;
    containerClassName?: string;
    width?: string;
    buttonValues: IButtonValues[];
    buttonRight?: boolean;
    children?: React.ReactNode;
    iconColor?: IMiniMenuPresets;
}

const miniMenuPresets = {
    white: "bg-white-500 hover:bg-mhgrey-light",
    beige: "text-mhbeige",
    green: "text-mhdarkgreen-original",
    opacityHover: "bg-white-500 hover:bg-mhbeige hover:bg-opacity-5",
};

export type IMiniMenuPresets = keyof typeof miniMenuPresets;

const MiniMenu = (props: IMiniMenuProps) => {
    const { children, id, preset, className, containerClassName, width, buttonValues, buttonRight, iconColor } = props;
    const { isOutside: open, setIsOutside: setOpen, setContainer } = useDetectOutsideClick(false, false);

    const handleOnClick = useCallback(
        (buttonValues: IButtonValues) => {
            buttonValues.onClick();
            setOpen(!open);
        },
        [open, setOpen],
    );

    return (
        <div
            ref={setContainer}
            className={`${buttonRight ? "relative" : ""} ${
                isEmpty(buttonValues) ? "pointer-events-none opacity-50" : ""
            } ${containerClassName}`}
        >
            <button
                type="button"
                className={`flex h-10 w-10 items-center justify-center rounded-full ${miniMenuPresets[preset]}`}
                onClick={() => {
                    setOpen(!open);
                }}
                data-cy="mini-menu"
                data-testid="mini-menu"
            >
                <Icon
                    icon="miniMenu"
                    className={`${iconColor ? miniMenuPresets[iconColor] : "text-mhdarkgreen-original"}`}
                />
            </button>
            <div
                id={id}
                className={`flex flex-col bg-white py-2 shadow-lg ${className} ${buttonRight ? "absolute right-0 mt-11 w-64" : ""} ${open ? "z-10" : "hidden"}`}
            >
                {buttonValues.map((c) => (
                    <button
                        type="button"
                        key={c.text}
                        data-cy={`miniButton-${_.camelCase(`${c.text.replace(/\s/g, "")}`)}`}
                        className={`mini-menu-foucs bg-white py-2 pl-4 text-left text-mhdarkgreen-original ${width} ${miniMenuPresets[preset]}`}
                        onClick={() => handleOnClick(c)}
                    >
                        <div className="flex">
                            {c.icon !== undefined ? (
                                <Icon icon={c.icon} size={15} className="mr-3 self-center" />
                            ) : null}
                            {c.text}
                        </div>
                    </button>
                ))}
                {children}
            </div>
        </div>
    );
};
export default MiniMenu;
