import { ReactComponent as CheckboxIcon } from "../../icons/checkbox.svg";

interface ICheckboxProps {
    id: string;
    className?: string;
    checked: boolean;
    setChecked: (checked: boolean) => void;
    label?: string;
    disabled?: boolean;
    onBlur?: () => void;
}

const Checkbox = (props: ICheckboxProps) => {
    const { id, disabled, className, checked, setChecked, label, onBlur } = props;

    return (
        <div className="flex items-center">
            <div
                className={`flex h-4 w-4 justify-center rounded border border-defaultCheckbox hover:border-hoverCheckbox ${checked ? "bg-defaultCheckbox hover:bg-hoverCheckbox" : "bg-transparent"} ${
                    disabled
                        ? "border-disabledCheckbox bg-disabledCheckbox hover:border-none hover:bg-disabledCheckbox"
                        : ""
                } ${className ?? ""}`}
                onBlur={onBlur}
            >
                <input
                    id={id}
                    type="checkbox"
                    className={`absolute opacity-0 ${!disabled && "cursor-pointer"}`}
                    checked={checked}
                    onChange={() => {
                        if (!disabled) setChecked(!checked);
                    }}
                />
                <CheckboxIcon
                    className={`w-3 fill-current ${checked ? "text-pageBg" : "text-transparent"} ${disabled ? "text-mhgrey-dark text-opacity-50" : ""}`}
                />
            </div>
            {label && <p className="ml-2">{label}</p>}
        </div>
    );
};
export default Checkbox;
