import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import DeviantAbsence from "../DeviantAbsence";
import { useOverflow } from "hooks";
import { selectors } from "../../redux";
import { getDateDisplayValue, getUnix } from "utils/date";
import { isEmpty, lowerFirst } from "lodash";
import { checkLongTermCode, extraRuleDecorated } from "pages/FollowUp/utils";
import { useAppSelector } from "store/hooks";
import { useCallback, useMemo, useState } from "react";

const FollowUpDeviantAbsenceList = () => {
    const { t } = useTranslation("followup");
    const deviant = useAppSelector(selectors.getDeviant);
    const [ref, setRefState] = useState<HTMLElement | undefined>(undefined);
    const isOverflow = useOverflow(ref);
    const longTimeAbsences = useAppSelector(selectors.getLongTimeAbsence);
    const repeatingAbsences = useAppSelector(selectors.getRepeatingAbsence);

    const setRef = useCallback((node: HTMLElement | undefined) => {
        setRefState(node);
    }, []);
    const Items = useMemo(() => {
        const header = [
            {
                header: t("deviantAbsenceLastThreeYears"),
                body: t("basisForRehabSupport"),
                className: "sticky top-0 bg-primaryBg",
            },
        ];
        if (isEmpty(deviant)) {
            return header;
        }
        const longTimeAbsenceUI = longTimeAbsences?.map((longTimeAbsence) => ({
            header: `${t("longTermAbsence")} - ${checkLongTermCode(longTimeAbsence.days)} ${t("days")} ${lowerFirst(
                t(extraRuleDecorated(longTimeAbsence.days) || ""),
            )}`,
            body: `${t("initialized")}: ${getDateDisplayValue(longTimeAbsence.validFrom, "d MMMM yyyy")}`,
            icon: <Icon icon="calendar" size={18} fill="white" stroke="black" strokeWidth={2} />,
            validFrom: longTimeAbsence.validFrom,
        }));
        const repeatingAbsence = repeatingAbsences.map((x) => ({
            header: `${t("repeatedAbsence")} - ${t("followUpAbsence.repeatedAbsence", {
                times: x?.times,
                months: x?.numberOfMonths,
            })} `,
            body: `${t("initialized")}: ${getDateDisplayValue(x?.validFrom, "d MMMM yyyy")}`,
            icon: <Icon icon="refresh" size={16} fill="white" stroke="black" />,
            validFrom: x?.validFrom,
        }));
        const orderedDeviantByDate = [...longTimeAbsenceUI, ...repeatingAbsence].sort(
            (a, b) => getUnix(b.validFrom) - getUnix(a.validFrom),
        );

        return [...header, ...orderedDeviantByDate];
    }, [deviant, longTimeAbsences, repeatingAbsences, t]);

    if (isEmpty(deviant))
        return (
            <div className={`mt-8 h-[376px] flex-1 bg-primaryBg shadow-lg`}>
                <div className="relative h-full">
                    {Items.map((item, index) => (
                        <DeviantAbsence {...item} key={`${item.header}-deviantemptylist-${index}`} />
                    ))}
                    <div className="flex min-h-[280px] flex-col items-center justify-center">
                        <Icon icon="shieldCheck" size={30} />
                        <p>{t("followUpAbsence.noDeviantAbsence")}</p>
                    </div>
                </div>
            </div>
        );

    return (
        <div
            className={`mt-8 h-[376px] flex-1 bg-primaryBg shadow-lg ${isOverflow && "overflow-y-scroll"}`}
            // @ts-expect-error
            ref={setRef}
        >
            {Items.map((item, index) => (
                <DeviantAbsence {...item} key={`${item.header}-deviantnotemptylist-${index}`} />
            ))}
        </div>
    );
};

export default FollowUpDeviantAbsenceList;
