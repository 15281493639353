import { camelCase } from "lodash";
import { useAppSelector } from "store/hooks";
import { StatusTag } from "@medhelp/ui/Tag";
import { IData } from "../Charts/TableChart";
import { Trans, useTranslation } from "react-i18next";
import { Table, TableCell, TableRow } from "@medhelp/ui";
import { getTagProps } from "pages/FollowUp/utils/case";
import SecondaryButton from "components/SecondaryButton";
import { useSortableData } from "./hooks/useSortableData";
import { getHealthCaseStatus, getHealthCases, getTotalNumberOfCases } from "pages/dashboard/redux/dashboardSelectors";
import { MedHelpHCMWebApiResponseSearchHealthCaseResponse } from "swagger/healthCaseManagement";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { SpinnerWithOverlay } from "components/Spinner";

interface IHCMCaseTable {
    data: IData | null;
    setSelectedCase: (value: MedHelpHCMWebApiResponseSearchHealthCaseResponse) => void;
}

const HCMCaseTable = ({ data, setSelectedCase }: IHCMCaseTable) => {
    const { t } = useTranslation("dashboard");
    const status = useAppSelector(getHealthCaseStatus);
    const healthCases = useAppSelector(getHealthCases);
    const totalNumberOfCases = useAppSelector(getTotalNumberOfCases);
    const [sortedData, handleSort] = useSortableData(healthCases ?? [], "name");

    const caption = (
        <div className="flex justify-between">
            <div>
                <Trans
                    t={t}
                    i18nKey={`statusOverview.${data?.type}.${camelCase(data?.status)}.subHeading`}
                    values={{ number: totalNumberOfCases || 0 }}
                />
            </div>
            <div className="hidden lg:block">
                <StatusTag
                    id="case-modal-status-tag"
                    text={t(`caseStatus.${data?.status}`)}
                    preset={getTagProps(data?.status)?.color ?? "grey"}
                    className="w-30 text-center text-sm"
                />
            </div>
        </div>
    );

    const headers = [
        { label: t("caseManager"), accessor: "name" },
        { label: t(camelCase(data?.status ?? "")), accessor: "numberOfCases" },
    ];

    if (status === Status.PENDING) return <SpinnerWithOverlay />;

    return (
        <Table className="lg:p-10" caption={caption} headers={headers} handleSort={handleSort}>
            {sortedData.map((item) => (
                <TableRow key={item.id}>
                    <TableCell showOnMobile>
                        {item.name || "-"}
                        <div className="lg:hidden">
                            <div className="mb-6 mt-3">
                                {item.healthCases ? item.healthCases.length : ""} {t("expired")}
                            </div>
                            <SecondaryButton
                                id="case-responsible-show-employees"
                                text={t("showCases")}
                                onClick={() => setSelectedCase(item)}
                            />
                        </div>
                    </TableCell>
                    <TableCell>{item.healthCases ? item.healthCases.length : ""}</TableCell>
                    <TableCell className="w-56">
                        <SecondaryButton
                            id="case-responsible-show-employees"
                            text={t("showCases")}
                            onClick={() => setSelectedCase(item)}
                        />
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    );
};

export default HCMCaseTable;
