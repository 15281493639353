import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { Languages } from "./medhelpNews";
import { useTranslation } from "react-i18next";
import NewsWidgetLoader from "./NewsWidgetLoader";
import Carousel from "components/Carousel/Carousel";
import { getUserLanguage } from "store/userSelectors";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { getNews } from "pages/dashboard/redux/dashboardSelectors";

const NewsWidget = () => {
    const { t } = useTranslation("dashboard");
    const { data, status } = useAppSelector(getNews);
    const userLanguage = useAppSelector(getUserLanguage) as keyof Languages;

    const slides = useMemo(
        () =>
            data.slice(0, 5).map((item) => {
                const language = item[userLanguage] ? userLanguage : "en";
                return (
                    <div className="height-236 scrollbar-hide overflow-auto">
                        <div className="flex flex-col gap-2 p-4 xs:px-1">
                            <div className="font-bold">{item[language]?.header ?? ""}</div>
                            <div className="text-sm text-grey-600">{item.publishedDate}</div>
                            <div>
                                {item[language]?.text ?? ""}{" "}
                                {item[language]?.link && (
                                    <a className="underline" href={item[language]?.link} target="_blank">
                                        {item[language]?.linkText}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }),
        [data, userLanguage],
    );

    if (status === Status.PENDING) return <NewsWidgetLoader />;

    return (
        <div className="widget flex flex-col bg-primaryBg text-primaryText shadow-lg">
            <div className="widget-container-header flex flex-row items-center justify-between border-b border-mhgrey-light pl-6 pr-3">
                <p className="font-bold">{t("medhelpNews")}</p>
            </div>
            <div className="height-276">
                <Carousel slides={slides} />
            </div>
        </div>
    );
};
export default NewsWidget;
