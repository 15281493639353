import { LangServiceTypes } from "pages/service/utils/serviceTypes";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "store/hooks";
import { updateUserLang } from "store/userActions";
import MenuNavDropdownMenu from "./MenuNavDropdownMenu";

export const LanguageDropdownMenu = () => {
    const { t } = useTranslation(["userSettings"]);

    const items = LangServiceTypes.map((language) => ({
        value: language,
        label: t(language),
    }));

    const dispatch = useAppDispatch();

    const handleClick = (value: string) => dispatch(updateUserLang({ language: value }));

    return <MenuNavDropdownMenu label={t("language")} icon="globeAlt" handleClick={handleClick} items={items} />;
};
