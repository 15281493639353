import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getCurrentUser, getHealthCase } from "../../../../redux/followUpSelectors";
import { CardWithTitle } from "@medhelp/ui";
import { CaseMenu, CaseTitle } from "../../../../components/HealthCaseManagement";
import Icon from "components/Icon";

const CaseInfo = () => {
    const { t } = useTranslation("followup");
    const { routeCaseId } = useParams();
    const employmentId = useAppSelector(getCurrentUser);
    const healthCase = useAppSelector(getHealthCase(routeCaseId ?? ""));

    return healthCase ? (
        <div className="mx-6 mb-52 xl:mx-0">
            <div className="flex">
                <Link key={"case-link"} to={`/followup/employee/${employmentId}/case`}>
                    <div className="mb-8 mt-10 flex items-center">
                        <Icon icon="back" />
                        <p className="ml-2 font-bold">{t("goBack")}</p>
                    </div>
                </Link>
            </div>
            <CardWithTitle
                title={
                    <CaseTitle
                        healthCase={healthCase}
                        expanded={healthCase.expanded}
                        isPreliminary={healthCase.status === "preliminary"}
                    />
                }
                className="h-fit border-none px-6 py-8 sm:h-19 sm:border-b sm:border-solid sm:border-grey-200 sm:px-8 sm:py-0"
            >
                <div className="mt-8">
                    <CaseMenu />
                </div>
            </CardWithTitle>
        </div>
    ) : null;
};

export default CaseInfo;
