import { ColumnText } from "@medhelp/ui";
import Icon from "components/Icon";
import { isArray } from "lodash/fp";
import { ITable, SearchTableResult } from "pages/FollowUp/types";
import { useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IProfile {
    body?: string | string[] | null;
    header: string;
    text?: string | null;
}

interface IItems {
    employee: IProfile;
    company?: { header?: string | null }[];
    affectedDepartments?: { texts: string[]; type: string }[];
    employmentId: {
        id: string;
        type: string;
    };
}
const ColumnTextRow = ({ body }: { body?: string | string[] | null }) => {
    return useMemo(() => {
        if (!body) return null;
        if (isArray(body)) {
            return (
                <div className="flex">
                    {body.map((x, index) => (
                        <>
                            <p className="text-sm text-mhgrey-dark">{x}</p>
                            {index < body.length - 1 && <div className="px-1 text-sm text-mhgrey-dark">|</div>}
                        </>
                    ))}
                </div>
            );
        }
        return <p className="text-sm text-mhgrey-dark">{body}</p>;
    }, [body]);
};
interface IPropsView {
    items: IItems[];
}
const Row = ({ items }: IPropsView) => {
    return (
        <div className="flex h-full w-full flex-col px-3 py-4">
            {items.map(({ employee, company, affectedDepartments, employmentId }, index) => (
                <div key={`${employee.header}-${index}`}>
                    <div className="flex items-start justify-between">
                        <div className="flex w-full items-start gap-2 text-base">
                            <div className="pr-2">
                                <div className="flex h-11 w-11 items-center justify-center rounded-full bg-mhgrey-light">
                                    <Icon icon="person" className="h-4 w-4" />
                                </div>
                            </div>
                            <div className="flex flex-col text-left">
                                <p className="font-bold text-mhdarkgreen-original">{employee.header}</p>
                                <ColumnTextRow body={employee.body} />
                                {employee?.text && (
                                    <div>
                                        <p className="inline-block bg-mhred-alert4 px-1 text-xs text-mhred-alert3">
                                            {employee.text}
                                        </p>
                                    </div>
                                )}
                                {company?.map(({ header }) => (
                                    <ColumnText className="mt-4 px-0">
                                        <div className="flex gap-2">
                                            <p className="text-sm font-bold">{header}</p>
                                        </div>
                                    </ColumnText>
                                ))}
                                {affectedDepartments?.map((affectedDepartments) => (
                                    <div className="whitespace-pre-wrap text-sm">
                                        {affectedDepartments.texts.join(",")}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Link to={`/followup/employee/${employmentId.id}/absence`}>
                            <Icon icon="caretRight" size={10} />
                        </Link>
                    </div>
                </div>
            ))}
        </div>
    );
};
const useRows = (tableProps: ITable) => {
    const navigate = useNavigate();
    const getElement = useCallback((cells: SearchTableResult[]) => {
        // @ts-expect-error
        const mobileCells: IItems = cells.reduce((prev, curr) => {
            if (curr.id) {
                return {
                    ...prev,
                    [curr.id]: curr.data,
                };
            }
            return prev;
        }, {});
        return [
            {
                data: [<Row items={[mobileCells]} />],
                id: "mobile-table-row",
            },
        ];
    }, []);
    const onRowClick = useCallback(
        (id?: string | null) => {
            if (id) {
                navigate(`/followup/employee/${id}/absence`);
            }
        },
        [navigate],
    );
    const rows = useMemo(
        () =>
            tableProps?.data.map((props) => ({
                cells: getElement(props.cells),
                rowProps: {
                    onClick: props.id ? () => onRowClick(props.id) : undefined,
                    theme: props.theme,
                },
            })),
        [getElement, onRowClick, tableProps?.data],
    );
    return rows;
};

export default useRows;
