import { useTranslation } from "react-i18next";
import { CardWithTitle, ColumnText } from "@medhelp/ui";
import { useBreakpoints } from "hooks";
import AbsenceHistoryStatisticsMobile from "./AbsenceHistoryStatisticsMobile";
import { MiniModal } from "@medhelp/ui/Modals";
import {
    getTwelveMonthAbsenceCount,
    getTwelveMonthAbsencePercent,
    getTwelveMonthAbsenceTypeDays,
    getTwelveMonthBradfordFactor,
    getTwelveMonthDeviants,
    getTwelveMonthTotalDays,
} from "pages/FollowUp/redux/followUpSelectors";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";

const AbsenceHistoryStatistics = () => {
    const breakpoints = useBreakpoints();
    const { t } = useTranslation("followup");
    const deviants = useAppSelector(getTwelveMonthDeviants);
    const absenceCount = useAppSelector(getTwelveMonthAbsenceCount);
    const totalAbsenceDays = useAppSelector(getTwelveMonthTotalDays);
    const absencePercent = useAppSelector(getTwelveMonthAbsencePercent);
    const bradfordFactor = useAppSelector(getTwelveMonthBradfordFactor);
    const absenceDaysSick = useAppSelector(getTwelveMonthAbsenceTypeDays("Sick"));
    const absenceDaysCareOfChild = useAppSelector(getTwelveMonthAbsenceTypeDays("CareOfChild"));

    const noInfoAboutWorkSchedule = useMemo(
        () => (
            <>
                <p>{t("noInfoAboutWorkSchedule")}</p>
                <br />
                <ul className="list-disc pl-5">
                    <li>{t("oneToFiveDays")}</li>
                    <li>{t("sixDays")}</li>
                    <li>{t("sevenOrMoreDays")}</li>
                </ul>
            </>
        ),
        [t],
    );

    const history = useMemo(
        () => [
            {
                header: t("followUpNumberOfAbsence"),
                body: `${absenceCount ? absenceCount : "-"} `,
                key: "number-of-absence",
                [`data-testid`]: "number-of-absence",
            },
            {
                header: t("absencePercent"),
                body: `${absencePercent ? `${absencePercent}%` : "-"}`,
                Icon: (
                    <MiniModal heading={t("absencePercent")}>
                        <p>{t("absencePercentInfo")}</p>
                    </MiniModal>
                ),
                key: "absence-percentage-statistic",
                [`data-testid`]: "absence-percentage-statistic",
            },
            {
                header: t("totalAbsenceDays"),
                body: `${totalAbsenceDays || "-"}`,
                Icon: <MiniModal heading={t("totalAbsenceDays")}>{noInfoAboutWorkSchedule}</MiniModal>,
                key: "absence-days-in-total-statistic",
                [`data-testid`]: "absence-days-in-total-statistic",
            },
            {
                header: t("sickDays"),
                body: `${absenceDaysSick || "-"}`,
                key: "sick-days-in-total-statistic",
                Icon: <MiniModal heading={t("sickDays")}>{noInfoAboutWorkSchedule}</MiniModal>,
                [`data-testid`]: "sick-days-in-total-statistic",
            },
            {
                header: t("careOfChildDays"),
                body: `${absenceDaysCareOfChild || "-"}`,
                key: "care-of-child-statistic",
                Icon: <MiniModal heading={t("careOfChildDays")}>{noInfoAboutWorkSchedule}</MiniModal>,
                [`data-testid`]: "care-of-child-statistic",
            },
            {
                header: t("deviantAbsence"),
                body: `${deviants ? `${deviants} ${t("deviantBody")}` : "-"}`,
                key: "deviant-absence-statistic",
                [`data-testid`]: "deviant-absence-statistic",
            },
            {
                header: t("bradfordFactor"),
                body: `${bradfordFactor || "-"}`,
                Icon: (
                    <MiniModal heading={t("bradfordFactor")}>
                        <p>{t("followUpAbsence.info.bradfordFactor")}</p>
                        <div className="mt-5">
                            <p className="font-bold">{t("formula")}</p>
                            <p>{t("followUpAbsence.info.bradfordFactorFormula")}</p>
                        </div>
                    </MiniModal>
                ),
                key: "bradford-factor-statistic",
                [`data-testid`]: "bradford-factor-statistic",
            },
        ],
        [
            absenceCount,
            absenceDaysCareOfChild,
            absenceDaysSick,
            absencePercent,
            bradfordFactor,
            deviants,
            totalAbsenceDays,
            noInfoAboutWorkSchedule,
            t,
        ],
    );
    if (breakpoints.isSmallscreen) {
        return <AbsenceHistoryStatisticsMobile items={history} title={t("lastTwelveMonths")} />;
    }
    return (
        <CardWithTitle title={t("lastTwelveMonths")}>
            <div className="flex flex-wrap items-start px-2 pb-8 pt-6">
                {history.map((item) => (
                    <ColumnText {...item} key={item.key} className="px-7" />
                ))}
            </div>
        </CardWithTitle>
    );
};

export default AbsenceHistoryStatistics;
