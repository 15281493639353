import * as React from "react";
import { includes } from "lodash/fp";
import { useParams, useNavigate, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IRoute } from "routes/hooks";
import { IEmploymentSearchResult } from "interfaces/IEmploymentSearchResult";
import SearchModal from "components/SearchModal";
import SearchResultUser from "components/SearchResultUser";
import { Guid } from "utils/guid";
import * as UserSelectors from "store/userSelectors";
import { Custom500 } from "pages/500/500";
import { SpinnerPage } from "components/Spinner";
import { useLoadAbsenceReport, useAbsenceLoadStatus, AbsenceLoadStatus } from "../../hooks";
import AbsenceMenu from "../../components/AbsenceMenu";
import AbsenceReportHeader from "../../components/AbsenceReportHeader";
import AbsenceReportFirstDayMedicalCertificate from "../../components/AbsenceReportFirstDayMedicalCertificate";
import { selectors as AbsenceReportsSelectors, actions as absenceReportsActions, thunkActions } from "../../redux";
import { selectors as MedicalCertsSelectors } from "packages/medical-certificate/redux";
import * as utils from "./AbsenceReporting.utils";
import TopLocation from "components/TopLocation";

const AbsenceReporting = () => {
    const [searchIsLoading, setSearchIsLoading] = React.useState(false);
    const [searchIsVisible, setSearchIsVisible] = React.useState(false);
    const [currentRoute, setCurrentRoute] = React.useState<IRoute | undefined>(undefined);
    const { routeEmploymentGuid } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const recentEmploymentSearches = useAppSelector(AbsenceReportsSelectors.parsedRecentEmploymentSearches);
    const employmentSearch = useAppSelector(AbsenceReportsSelectors.getEmploymentSearch);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);

    const status = useAbsenceLoadStatus();
    useLoadAbsenceReport();

    const getNumberOfOngoingReports = useAppSelector(AbsenceReportsSelectors.getOngoingReports);

    const getNumberOfOngoigCerts = useAppSelector(MedicalCertsSelectors.getCurrentCertificates);

    React.useEffect(() => {
        dispatch(absenceReportsActions.reset());
    }, [dispatch]);

    React.useEffect(() => {
        if (isManage) {
            if (Number(routeEmploymentGuid)) {
                dispatch(thunkActions.getAbsenceReportEmploymentByInteger(Number(routeEmploymentGuid)));
            }
            setSearchIsVisible(true);
            dispatch(thunkActions.getAbsenceReportRecentEmploymentSearches("userSearch"));
        } else {
            setSearchIsVisible(false);
        }
        if (userContext && routeEmploymentGuid && Guid.isValid(routeEmploymentGuid)) {
            dispatch(thunkActions.getAbsenceReportEmploymentByGuid(routeEmploymentGuid));
        }

        if (
            userContext &&
            Guid.isValid(userContext.EmploymentGuid) &&
            (userContext.HasAbsenceReporting || userContext.HasCustomerSupport || userContext.HasMedicalAdvisory) &&
            !routeEmploymentGuid
        ) {
            dispatch(thunkActions.getAbsenceReportEmploymentByGuid(userContext.EmploymentGuid));
        }
    }, [routeEmploymentGuid, dispatch, userContext, navigate, isManage]);

    React.useEffect(() => {
        setSearchIsLoading(false);
    }, [employmentSearch]);

    const IsMeCreate = React.useMemo(() => {
        const paths = location.pathname.split("/");
        return includes("me", paths) && includes("create", paths);
    }, [location]);

    const handleOnSearchInputChange = (query: string) => {
        setSearchIsLoading(true);
        if (userContext.HasMedicalAdvisory) {
            dispatch(thunkActions.getEmploymentsBySSN(query));
        } else {
            dispatch(thunkActions.getEmploymentsByQuery(query));
        }
    };

    const employmentSearchResultIsValid = React.useCallback(
        (employmentSearchResult: IEmploymentSearchResult) =>
            utils.employmentSearchResultIsValid(employmentSearchResult, routeEmploymentGuid),
        [routeEmploymentGuid],
    );

    const handleSearchSelectedEmployment = React.useCallback(
        (employmentSearchResult: IEmploymentSearchResult) => {
            if (employmentSearchResultIsValid(employmentSearchResult)) {
                dispatch(absenceReportsActions.reset());
                const routeTo = currentRoute?.route === "absencereporting" ? "" : `/${currentRoute?.route}`;
                navigate(`/absencereporting${routeTo}/${employmentSearchResult.referenceKey}`);
            }
        },
        [currentRoute, dispatch, employmentSearchResultIsValid, navigate],
    );

    const renderUserSearchResults = (searchItem: IEmploymentSearchResult, index: number, hideModal?: () => void) => {
        return (
            <SearchResultUser
                key={index}
                searchResult={searchItem}
                onClick={() => {
                    handleSearchSelectedEmployment(searchItem);
                    if (hideModal) {
                        hideModal();
                    }
                }}
            />
        );
    };

    if (status === AbsenceLoadStatus.LOADING) {
        return <SpinnerPage />;
    }

    if (status === AbsenceLoadStatus.ERROR) {
        return <Custom500 />;
    }

    return (
        <div id="absenceListPage" className="w-full pb-10 xl:pb-0">
            {IsMeCreate ? (
                <Outlet />
            ) : (
                <>
                    <div className="xl:hidden">
                        <TopLocation header={t("absenceReport")} />
                    </div>
                    {searchIsVisible && (
                        <div className="w-full max-w-screen-xxxxl pb-6 xl:pt-6">
                            <SearchModal
                                id="SearchModalAbsenceReport"
                                preset="large"
                                searchHistory={recentEmploymentSearches}
                                searchResult={employmentSearch}
                                isLoading={searchIsLoading}
                                onInputChange={handleOnSearchInputChange}
                                placeholder={t("searchCoWorker")}
                                searchResultItemComponent={renderUserSearchResults}
                                searchResultTitle={t("suggestedSearches")}
                            />
                        </div>
                    )}
                    <div className="px-4 xl:px-0">
                        <AbsenceReportHeader />
                        <AbsenceReportFirstDayMedicalCertificate
                            id="firstDayCertificateWarning"
                            preset="background"
                            heading={t("firstDayCertificateRequired")}
                            content={t("yourEmployerDemandsFirstDayCertificate")}
                        />
                    </div>
                    <AbsenceMenu
                        setCurrentRoute={setCurrentRoute}
                        absenceReports={getNumberOfOngoingReports.length}
                        medicalCerts={getNumberOfOngoigCerts.length}
                    />
                </>
            )}
        </div>
    );
};

export default AbsenceReporting;
