import Icon from "components/Icon";

interface IProps {
    header: string;
    name: string;
    phoneNumber?: string;
}
const PhoneContact = ({ header, name, phoneNumber }: IProps) => (
    <div className="mb-5">
        <p className="text-xs font-bold">{header}:</p>
        <div className="flex">
            <p className="mr-2 hidden lg:flex">{name}:</p>
            {phoneNumber && (
                <a href={`tel:${phoneNumber}`}>
                    <div className="flex items-center">
                        <Icon icon="phone" className="h-3 w-3 text-mhdarkgreen-original" />
                        <p className="ml-2 flex border-b border-grey-700 lg:hidden">{name}</p>
                        <p className="ml-2 hidden border-b border-grey-700 lg:flex">{phoneNumber}</p>
                    </div>
                </a>
            )}
        </div>
    </div>
);
export default PhoneContact;
