interface IProps {
    top: string;
    bottom: string;
}
const InformationText = ({ top, bottom }: IProps) => (
    <div className="mb-5 mr-12">
        <p className="text-xs font-bold">{top}:</p>
        <p>{bottom}</p>
    </div>
);

export default InformationText;
