import { isEmpty } from "lodash/fp";
import ErrorWidget from "../ErrorWidget";
import { useEffect, useMemo } from "react";
import { StatusTag } from "@medhelp/ui/Tag";
import { useTranslation } from "react-i18next";
import { ITableData } from "../../Charts/TableChart";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getTagProps } from "pages/FollowUp/utils/case";
import { Widget } from "pages/dashboard/utils/dashboardTypes";
import { TableChart } from "pages/dashboard/components/Charts";
import HCMStatusOverviewLoader from "./HCMStatusOverviewLoader";
import { Status } from "pages/dashboard/redux/dashboardStateTypes";
import { fetchWidgetData } from "pages/dashboard/redux/dashboardActions";
import { getHCMStatusOverview, getHCMTemplates, getIsDragging } from "pages/dashboard/redux/dashboardSelectors";

const HCMStatusOverview = ({ id, type }: Widget) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation("dashboard");
    const templates = useAppSelector(getHCMTemplates);
    const isDragging = useAppSelector(getIsDragging(id));
    const { data, status } = useAppSelector(getHCMStatusOverview(id));

    useEffect(() => {
        if (!isEmpty(templates) && !isDragging) {
            dispatch(fetchWidgetData({ id, type }));
        }
    }, [dispatch, id, type, templates, isDragging]);

    const tableData: ITableData[] = useMemo(
        () => [
            {
                id: "preliminary",
                heading: (
                    <StatusTag
                        id={"status-tag-preliminary"}
                        text={t("caseStatus.preliminary")}
                        preset={getTagProps("preliminary")?.color ?? "grey"}
                        className="w-30 text-center text-sm"
                    />
                ),
                data: data.preliminary,
                tooltip: t("preliminaryTooltip"),
            },
            {
                id: "declined",
                heading: (
                    <StatusTag
                        id={"status-tag-declined"}
                        text={t("caseStatus.declined")}
                        preset={getTagProps("declined")?.color ?? "grey"}
                        className="w-30 text-center text-sm"
                    />
                ),
                data: data.declined,
                tooltip: t("declinedTooltip"),
            },
            {
                id: "expired",
                heading: (
                    <StatusTag
                        id={"status-tag-expired"}
                        text={t("caseStatus.expired")}
                        preset={getTagProps("expired")?.color ?? "grey"}
                        className="w-30 text-center text-sm"
                    />
                ),
                data: data.expired,
                tooltip: t("expiredTooltip"),
            },
            {
                id: "ongoing",
                heading: (
                    <StatusTag
                        id={"status-tag-ongoing"}
                        text={t("caseStatus.ongoing")}
                        preset={getTagProps("ongoing")?.color ?? "grey"}
                        className="w-30 text-center text-sm"
                    />
                ),
                data: data.ongoing,
                tooltip: t("ongoingTooltip"),
            },
        ],
        [data.declined, data.expired, data.ongoing, data.preliminary, t],
    );

    if (status === Status.PENDING) {
        return <HCMStatusOverviewLoader />;
    }

    if (status === Status.ERROR) {
        return <ErrorWidget />;
    }

    return (
        <TableChart
            keyHeading={t("status")}
            dataHeadings={[t("rehab"), t("healthpromotion")]}
            tableData={tableData}
            widgetId={id}
        />
    );
};

export default HCMStatusOverview;
