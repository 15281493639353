import Icon from "components/Icon";
import { RadioButton } from "pages/Administration/components";
import { IStandardSubscriptionProps } from "pages/Administration/types";
import { useTranslation } from "react-i18next";

const RoleSubscriptionStandardSettings = ({
    selectedRole,
    onRoleChanged,
    deleteClicked,
}: IStandardSubscriptionProps) => {
    const { t } = useTranslation("administration");

    return (
        <div className="flex w-full justify-between">
            <div className="flex flex-col">
                <RadioButton
                    text={t("departmentmanager")}
                    checked={selectedRole === "departmentmanager"}
                    setChecked={() => onRoleChanged("departmentmanager")}
                />
                <RadioButton
                    text={t("mainmanager")}
                    checked={selectedRole === "mainmanager"}
                    setChecked={() => onRoleChanged("mainmanager")}
                />
            </div>
            <button className="flex h-10 w-10 items-center justify-center" onClick={deleteClicked}>
                <Icon icon="trashCan" size={22} />
            </button>
        </div>
    );
};

export default RoleSubscriptionStandardSettings;
