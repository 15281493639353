import Icon from "components/Icon";
import { useElementClicked } from "hooks";
import { getNotificationTypeTranslation, getSelectedTranslation } from "pages/Administration/utils";
import { useTranslation } from "react-i18next";
import { AddRoleSubscriptionConfigration, CompanySubscriptionConfiguration } from "swagger/messenger";
import { twMerge } from "tailwind-merge";

export interface IAdminSelectProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    newRow: AddRoleSubscriptionConfigration | null;
    availableNotificationTypes: CompanySubscriptionConfiguration[];
    onChange: (row: AddRoleSubscriptionConfigration) => void;
    disabled: boolean;
    className?: string;
}
const AdminSelect = ({
    open,
    setOpen,
    newRow,
    availableNotificationTypes,
    onChange,
    disabled,
    className,
}: IAdminSelectProps) => {
    const { t } = useTranslation("administration");
    const ref = useElementClicked(setOpen);
    const placeHolder = disabled ? t("noNotificationTypes") : t("selectNotification");
    return (
        <div ref={ref} className={twMerge("relative h-auto w-full xxs:max-w-[308px]", className)}>
            <button
                className={`whitespace-no-wrap h-12 w-full overflow-ellipsis border border-mhdarkgreen-original bg-pageBg ${
                    disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"
                }`}
            >
                <div className="flex w-full items-center">
                    <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap pl-2 text-left">
                        {getSelectedTranslation(availableNotificationTypes, newRow, t) ?? placeHolder}
                    </p>
                    <div className="mx-3 flex">
                        <Icon icon={open ? "caretUp" : "caretDown"} size={12} />
                    </div>
                </div>
            </button>
            {open && (
                <div className="absolute z-10 w-full">
                    <div className="mt-1 flex flex-col bg-white py-1 shadow-xl">
                        {availableNotificationTypes.map((c) => (
                            <button
                                key={c.id}
                                type="button"
                                className="flex items-center px-3 py-2 hover:bg-grey-100"
                                onClick={() => {
                                    if (!disabled) {
                                        onChange(c);
                                        setOpen(false);
                                    }
                                }}
                            >
                                <p className="ml-2 text-left">
                                    {getNotificationTypeTranslation(Number(c.notificationType), c.eventCode ?? "", t)}
                                </p>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminSelect;
