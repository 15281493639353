const AbsenceForecastCurrentMonthLoader = () => {
    return (
        <div className="mx-6 flex h-full animate-pulse flex-col justify-between pb-5 pt-10">
            <div>
                <div className="border-b border-secondaryBorder" />
                <div className="flex h-10 items-center justify-between border-b border-secondaryBorder">
                    <div className="h-2.5 w-30 rounded-full bg-gray-500 dark:bg-gray-700"></div>
                    <div className="h-2.5 w-20 rounded-full bg-gray-500 dark:bg-gray-700"></div>
                </div>
                <div className="flex h-10 items-center justify-between border-b border-secondaryBorder">
                    <div className="h-2.5 w-44 rounded-full bg-gray-500 dark:bg-gray-700"></div>
                    <div className="h-2.5 w-20 rounded-full bg-gray-500 dark:bg-gray-700"></div>
                </div>
            </div>

            <div className="flex justify-between text-secondaryText">
                <div className="h-2.5 w-72 rounded-full bg-gray-500 dark:bg-gray-700"></div>
                <div className="h-2.5 w-10 rounded-full bg-gray-500 dark:bg-gray-700"></div>
            </div>
        </div>
    );
};

export default AbsenceForecastCurrentMonthLoader;
