/* eslint-disable @typescript-eslint/no-explicit-any */
import Chart from "chart.js/auto";
import { useEffect, useRef, useState } from "react";
import { ChartTypeRegistry } from "chart.js";
import _, { uniqueId } from "lodash";
import { useTranslation } from "react-i18next";
import { ReactComponent as MySelection } from "icons/widget/checkboxMySelection.svg";
import { ReactComponent as Unchecked } from "icons/widget/checkboxUnchecked.svg";
import { ReactComponent as Checked } from "icons/widget/checkboxChecked.svg";

interface IValue {
    year?: number;
    month?: number;
    ratio?: number;
}
interface IBarChartData {
    mySelection: IValue[];
    previousYear: IValue[];
    sameIndustry: IValue[];
    sameSize: IValue[];
}
interface IBarChart {
    data: IBarChartData;
    primaryColor: string;
    secondaryColor: string;
    companyIds: number[];
}
interface IChartConfig {
    type: keyof ChartTypeRegistry;
    data: any;
    options: any;
}
interface IDynamicData {
    name: string;
    ratio: string;
    checked: boolean;
}

const BarChart = ({ data, primaryColor, secondaryColor, companyIds }: IBarChart) => {
    const chartContainer = useRef(null);
    const { t } = useTranslation("dashboard");
    const [dynamicData, setDynamicData] = useState<IDynamicData[]>([]);

    useEffect(() => {
        const dataArray: IDynamicData[] = [];
        Object.entries(data).forEach(([key, value]) => {
            const totalRatio = (
                value.map((d: IValue) => (d.ratio ?? 0) * 100).reduce((a: number, b: number) => a + b, 0) / value.length
            ).toFixed(2);
            dataArray.push({
                name: key,
                ratio: totalRatio,
                checked: true,
            });
        });

        const activeDataArray = companyIds.length === 1 ? dataArray : dataArray.filter((x) => x.name === "mySelection");

        setDynamicData(activeDataArray);
    }, [data, companyIds]);

    const handleClick = (name: string) => {
        const newArray = dynamicData.map((i) => {
            if (i.name === name) return { ...i, checked: !i.checked };
            return { ...i };
        });
        setDynamicData(newArray);
    };

    const barThickness = () => {
        if (dynamicData.filter((x) => x.checked).length < 4) return 64;
        if (dynamicData.filter((x) => x.checked).length === 4) return 48;
        return 38;
    };

    const chartConfig: IChartConfig = {
        type: "bar",
        data: {
            labels: dynamicData.filter((x) => x.checked).map((x) => t(_.camelCase(x.name))),
            datasets: [
                {
                    backgroundColor: [primaryColor, secondaryColor, secondaryColor, secondaryColor],
                    data: dynamicData.filter((x) => x.checked).map((x) => x.ratio),
                    barThickness,
                },
            ],
        },
        options: {
            animation: {
                duration: 0,
            },
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 1,
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (x: any) => ` ${x.formattedValue}%`,
                    },
                    bodySpacing: 8,
                    caretPadding: 16,
                    boxWidth: 6,
                    boxHeight: 6,
                    caretSize: 0,
                    mode: "x",
                    intersect: false,
                    titleColor: "#192D05",
                    bodyColor: "#A0A0A0",
                    backgroundColor: "#FFFFFF",
                    cornerRadius: 0,
                    padding: 20,
                    bodyFont: {
                        size: 12,
                    },
                    titleFont: {
                        size: 12,
                    },
                },
                legend: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        autoSkip: false,
                        maxRotation: 0,
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        display: false,
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        callback: (x: any) => `${x.toFixed(2)}%`,
                        count: 5,
                        font: {
                            size: 10,
                            font: "LabGrotesque",
                        },
                        color: "#5A5F4E",
                    },
                    grid: {
                        lineWidth: 0.5,
                        drawBorder: false,
                        color: "#8F957F",
                    },
                },
            },
        },
    };

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            return () => newChartInstance.destroy();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartContainer, dynamicData]);

    const uuid = uniqueId();

    return (
        <div>
            <div className="chart-selector flex flex-wrap px-6 py-4 text-sm">
                {dynamicData.map((e, i) => (
                    <div key={`${e.name}bar`} className="flex w-1/3 items-center">
                        {i === 0 ? (
                            <MySelection fill={primaryColor} />
                        ) : (
                            companyIds.length === 1 && (
                                <button type="button" id={e.name + uuid} onClick={() => handleClick(e.name)}>
                                    <div className="padding-1">
                                        {e.checked ? (
                                            <Checked fill={secondaryColor} />
                                        ) : (
                                            <Unchecked stroke={secondaryColor} />
                                        )}
                                    </div>
                                </button>
                            )
                        )}
                        <label htmlFor={e.name + uuid}>
                            <p className="cursor-pointer pl-1 font-sans text-xs">{t(_.camelCase(e.name))}</p>
                        </label>
                    </div>
                ))}
            </div>
            <div>
                <div className="chart-container">
                    <canvas ref={chartContainer} />
                </div>
            </div>
        </div>
    );
};

export default BarChart;
