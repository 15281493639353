import { lowerCase } from "lodash/fp";
import { useMemo } from "react";
import { IPhonePrefix } from "./PhonePrefix.utils";

const PhonePrefixItem = ({
    code,
    name,
    dial_code,
    hidePlaceholder = false,
    isPlaceholder = false,
    hideCountryCode,
}: IPhonePrefix) => {
    const showName = useMemo(() => !hidePlaceholder || !isPlaceholder, [hidePlaceholder, isPlaceholder]);

    return (
        <div className="flex items-center gap-1">
            {!!code && (
                <img
                    src={`https://flagcdn.com/w40/${lowerCase(code)}.png`}
                    srcSet={`https://flagcdn.com/w80/${lowerCase(code || "")}.png 2x`}
                    className="h-5 w-5 rounded-full border border-grey-500 object-cover"
                    alt={name}
                />
            )}
            <div className="flex">
                {!hideCountryCode && <p className="mx-1">{dial_code ? `(${dial_code})` : ""}</p>}
                {showName && <p>{name}</p>}
            </div>
        </div>
    );
};
export default PhonePrefixItem;
