import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const DepartmentContainer = ({ departments }: { departments: string[] }) => {
    const ref = useRef<null | HTMLDivElement>(null);
    const { t } = useTranslation("dashboard");

    useEffect(() => {
        let hiddenElementCounter = 0;
        const collection = ref?.current?.children;

        const dropdownPosition = ref?.current?.getBoundingClientRect().y;
        if (collection && dropdownPosition)
            for (let i = 0; i < collection.length; i += 1) {
                if (collection[i]?.getBoundingClientRect().y > dropdownPosition + 25) hiddenElementCounter += 1;
            }

        setHiddenDepartments(hiddenElementCounter);
    }, []);

    const [hiddenDepartments, setHiddenDepartments] = useState(0);

    return (
        <div
            className="flex max-h-16 flex-wrap overflow-y-hidden break-words pl-4 pt-1 text-sm text-mhgrey-medium"
            ref={ref}
        >
            {departments.map((dep: string, i: number) => {
                if (hiddenDepartments && i === departments.length - hiddenDepartments) {
                    return (
                        <div key={dep + i}>
                            (+{hiddenDepartments} {t("department").slice(0, 3)})
                        </div>
                    );
                } else
                    return (
                        <div
                            key={dep + i}
                            className={`pr-1 ${i > departments.length - hiddenDepartments - 1 && "invisible"}`}
                        >
                            {dep}
                            {departments.length - 1 !== i ? "," : ""}
                        </div>
                    );
            })}
        </div>
    );
};

export default DepartmentContainer;
