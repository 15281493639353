import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { IAbsenceReportExtended } from "interfaces/IAbsenceReportExtended";
import { Custom500 } from "pages/500/500";
import { SpinnerPage } from "components/Spinner";
import { useLoadAbsenceReport } from "../../hooks";
import { selectors as absenceReportsSelectors, thunkActions, actions } from "../../redux";
import * as UserSelectors from "store/userSelectors";
import AbsenceReportTypeOfAbsence from "../../components/AbsenceReportTypeOfAbsence";
import AbsenceReportBaseWizard from "../../components/AbsenceReportBaseWizard";
import AbsenceReportTemplateFields from "../../components/AbsenceReportTemplateFields";
import AbsenceReportFinished from "../../components/AbsenceReportFinished";
import { getAbsenceReportsSlice } from "../../redux/absenceReportsSelectors";
import Stepper from "components/Stepper";
import Icon from "components/Icon";
import { useTranslation } from "react-i18next";

const AbsenceReportingMeCreatePage = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(true);
    const userContext = useAppSelector(UserSelectors.getUserContext);
    const isManage = useAppSelector(UserSelectors.getIsAbsenceReportAdmin);
    const isCallNurse = useAppSelector(absenceReportsSelectors.getConfigurationByName("callNurse"));
    const currentPage = useAppSelector(absenceReportsSelectors.getAbsenceReportPageNumber);
    const templateFieldsByPages = useAppSelector(absenceReportsSelectors.getTemplateFieldsByPages);
    const isNotificationError = useAppSelector(absenceReportsSelectors.getSendAbsenceNotificationIsError);
    const { AbsenceReports, Templates, ConfigurationFields, SelectedAbsenceReport, IsOK } =
        useAppSelector(getAbsenceReportsSlice);
    const isOwnReport = useAppSelector(absenceReportsSelectors.getIsOwnReport);
    const { t } = useTranslation();

    useLoadAbsenceReport(true);

    const initialDispatch = useCallback(() => {
        setIsLoading(true);
        dispatch(thunkActions.getAbsenceReportEmploymentByGuid(userContext.EmploymentGuid!));
        dispatch(thunkActions.getCompanySpecificAbsenceConfirmation());
    }, [dispatch, userContext.EmploymentGuid]);

    useEffect(() => {
        if (userContext.EmploymentGuid) {
            if ((isManage && !isOwnReport) || isCallNurse) {
                navigate("/absencereporting/");
            } else {
                initialDispatch();
            }
        }
    }, [userContext.EmploymentGuid, initialDispatch, navigate, dispatch, isManage, isCallNurse, isOwnReport]);

    useEffect(() => {
        const absenceReportsWithIsPreliminary = AbsenceReports?.filter(
            (absenceReport: IAbsenceReportExtended) => absenceReport.isPreliminary && absenceReport.end === null,
        );
        if (absenceReportsWithIsPreliminary && absenceReportsWithIsPreliminary?.length > 0) {
            navigate("/absencereporting/");
        }
    }, [AbsenceReports, navigate]);

    useEffect(() => {
        if (Templates && ConfigurationFields && AbsenceReports) {
            setIsLoading(false);
        }
    }, [Templates, ConfigurationFields, AbsenceReports]);

    useEffect(() => {
        if ((IsOK !== null && !IsOK) || isNotificationError) {
            setIsLoading(false);
            setIsError(true);
        } else {
            setIsError(false);
        }
    }, [IsOK, isNotificationError]);

    const handleBackClick = () => {
        dispatch(actions.updateAbsenceReportPageNumber("previous"));
    };

    const handleClose = () => {
        dispatch(actions.resetSelectedAbsenceReport());
        navigate("/absencereporting/");
    };

    const getAbsenceReportPages = useMemo(() => {
        return [
            <AbsenceReportTypeOfAbsence />,
            <AbsenceReportBaseWizard />,
            <AbsenceReportTemplateFields />,
            <AbsenceReportFinished />,
        ];
    }, []);

    const getCurrentPage = useMemo(() => {
        if (currentPage < 3) {
            return getAbsenceReportPages[currentPage - 1];
        } else if (currentPage === templateFieldsByPages?.length + 3) {
            return getAbsenceReportPages[3];
        } else {
            return getAbsenceReportPages[2];
        }
    }, [currentPage, getAbsenceReportPages, templateFieldsByPages?.length]);

    if (isLoading) {
        return <SpinnerPage />;
    }
    if (isError) {
        return <Custom500 />;
    }

    const numberOfSteps = templateFieldsByPages ? Object.keys(templateFieldsByPages).length + 2 : undefined;
    const showSteps = !!SelectedAbsenceReport?.type;
    const currentStep = currentPage;
    const backButtonDisabled = currentPage === 1;

    return (
        <>
            {numberOfSteps && currentPage <= numberOfSteps && (
                <>
                    {showSteps && <Stepper numberOfSteps={numberOfSteps} currentStep={currentStep} />}
                    <div className="px-6 py-10 xl:px-0 xl:pt-0">
                        <div className="flex w-full justify-between">
                            <div className="flex items-center gap-3">
                                <button
                                    onClick={handleBackClick}
                                    disabled={backButtonDisabled}
                                    className={backButtonDisabled ? "text-mhgrey-medium" : "text-mhgrey-dark"}
                                >
                                    <Icon icon="caretLeft" size={16} />
                                </button>
                                {showSteps && (
                                    <p className="text-sm">
                                        {t("step")} {currentStep} {t("of")} {numberOfSteps}
                                    </p>
                                )}
                            </div>
                            <button onClick={handleClose}>
                                <Icon icon="close" size={24} />
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div className="px-6 pb-12 xl:p-0 xl:pb-0">{getCurrentPage}</div>
        </>
    );
};

export default AbsenceReportingMeCreatePage;
