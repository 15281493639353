import { ReactNode } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface IServiceInput {
    label: string;
    register: UseFormRegisterReturn<string>;
    bold?: boolean;
    required?: boolean;
    placeholder?: string;
}

export const ServiceInput = ({ label, register, bold, required, placeholder }: IServiceInput) => (
    <div className="flex w-full flex-col md:w-86">
        <label className={bold ? "font-bold" : ""}>
            {label} {required && "*"}
        </label>
        <input
            className="mt-2 h-12 border-1 border-mhdarkgreen-original bg-mhbeige px-3"
            placeholder={placeholder}
            {...register}
        />
    </div>
);

interface IServiceInputContainer {
    heading?: string;
    borderBottom?: boolean;
    children?: ReactNode;
}

export const ServiceInputContainer = ({ heading, borderBottom, children }: IServiceInputContainer) => (
    <div className={`py-10 ${borderBottom && "border-b-1 border-grey-300"}`}>
        {heading && <p className="mb-6 font-bold">{heading}</p>}
        <div className="flex flex-wrap gap-4 gap-y-10">{children}</div>
    </div>
);
