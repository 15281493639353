import * as React from "react";
import { useDetectOutsideClick } from "hooks";
import { ReactComponent as AccordionArrowDown } from "icons/accordionArrowDown.svg";
import { ReactComponent as AccordionArrowUp } from "icons/accordionArrowUp.svg";

interface IProps {
    children: React.ReactNode;
    heading: string;
    activeChild?: number;
    onDropdownClick: (index: number) => void;
}
const Dropdown = ({ children, onDropdownClick, heading, activeChild, ...rest }: IProps) => {
    const [show, setShow] = React.useState(false);
    const { isOutside, setContainer } = useDetectOutsideClick();

    React.useEffect(() => {
        if (isOutside) {
            setShow(false);
        }
    }, [isOutside]);
    const setNodeContainer = React.useCallback(
        (node: HTMLDivElement | null) => {
            setContainer(node);
        },
        [setContainer],
    );
    return (
        <div ref={setNodeContainer} className="flex flex-1">
            <div className="bg-colorBackground flex w-full max-w-screen-xxxxl flex-col border border-grey-500">
                <div
                    className="flex flex-1 cursor-pointer justify-between p-3"
                    onClick={() => setShow(!show)}
                    role="button"
                    {...rest}
                >
                    <div className="h-6">{heading}</div>
                    <div className="flex self-center">
                        {show ? (
                            <AccordionArrowUp className="w-3 cursor-pointer fill-none text-center text-black" />
                        ) : (
                            <AccordionArrowDown className="w-3 cursor-pointer fill-none text-center text-black" />
                        )}
                    </div>
                </div>
                {show &&
                    React.Children.map(
                        children,
                        (child, index) =>
                            index !== activeChild && (
                                <div
                                    key={index}
                                    className="cursor-pointer p-3 hover:bg-primary-500"
                                    onClick={() => {
                                        onDropdownClick(index);
                                        setShow(false);
                                    }}
                                >
                                    {child}
                                </div>
                            ),
                    )}
            </div>
        </div>
    );
};

export default Dropdown;
