import {
    Company,
    CompanyDataErasureAvailableConfiguration,
    CompanyUser,
    CostCenter,
    CreateOrUpdateAccessRights,
    CreateOrUpdateEmployment,
    CreateOrUpdateUserAccount,
    CredentialType,
    Department,
    EmploymentGroup,
    IdNameMap,
    LoginCredentials,
} from "swagger/customercompany";
import {
    ILoginAccordionSharedProps,
    ILoginAccordions,
    ILoginInputSharedProps,
    ILoginsStateProps,
    LoginCredentialType,
    IAddUserStateProps,
    IEmploymentStateProps,
    IPermissionsStateProps,
    IPermissionAccordions,
    IPermissionsAccordionSharedProps,
    CompanyAccessRightsRecursive,
    NotificationTypes,
    IRoleSubscriptionsSettingsStateProps,
    roleTypes,
    Role,
    IRoleSubscriptionGroups,
    automaticErasureOptions,
    IErasureFinishedSettingsStateProps,
    IErasureActiveSettingsStateProps,
    IAdminSearchStateProps,
    ISubscriptionStateProps,
    IDisplayValues,
    ICheckBoxStateProps,
    INotificationTypeRow,
    ISubscriptionGroup,
    ISubscriptionAccordionStateProps,
    ISubscriptionAccordionDisplayProps,
    IEmployedAtSelectProps,
    Country,
    IUserEmploymentStateProps,
    ISubscribeOnUserDisplayProps,
    ISubscribeOnUserGroups,
    SubscriptionMonitorType,
    ISubscriptionOptionsStateProps,
} from "../types";
import { isValidEmail, isValidSsn } from "pages/login/utils";
import { isValidMobileNumber } from "utils/phone";
import { LoginConfiguration } from "swagger/businessupport";
import { ISelectValue } from "@medhelp/ui/Select/Select";
import {
    Channel,
    CompanyRegion,
    DeleteSubscriptionRequest,
    EventSubscriptionPostItem,
    MonitoringType,
    SubscriberTableItem,
    SubscriptionInformation,
    SubscriptionLookupType,
    SubscriptionTableItem,
    SubscriptionType,
    TreeViewNode,
} from "swagger/employeeattendance";
import { TFunction } from "i18next";
import _ from "lodash";
import {
    AddRoleSubscriptionConfigration,
    CompanySubscriptionConfiguration,
    RoleSubscriptionConfigration,
} from "swagger/messenger";
import { IRadioButtonProps } from "../components/RoleSubscriptions/RadioButton/RadioButton";
import i18n from "i18next";
import { isAfterThisMonth } from "utils/date";
import { ICreateSubscriptionStateProps } from "../components/Subscriptions/CreateNewSubscription/CreateNewSubscription";
import { INewExternalSubscriberStateProps } from "../components/Subscriptions/NewExternalSubscriber/NewExternalSubscriber";
import { IDynamicData } from "@medhelp/ui/List/CheckboxList/types";
import { Icons } from "components/Icon";

export const initialLoggedInUserCompanies: Company = {
    id: 0,
    name: "",
    number: "",
    departmentIds: null,
    automatedOrganizationalChangeProcess: false,
    isReadOnly: false,
};

export const initialSelectedUserProps: CreateOrUpdateUserAccount = {
    id: 0,
    userId: 0,
    firstName: "",
    lastName: "",
    socialSecurityNumber: "",
    companyId: 0,
    country: "SE",
    language: "sv",
    mobileNumber: "initialValue",
    mobileCountryPrefix: "",
    telephoneNumber: "initialValue",
    telephoneCountryPrefix: "",
    secondaryMobileNumber: "initialValue",
    secondaryMobileCountryPrefix: "",
    email: null,
    homeAddress: null,
    secondaryEmail: null,
    gender: null,
    birthYear: null,
    customerId: 0,
    periodActivationDate: "",
    periodDeactivationDate: null,
};

export const initialSelectedCompany: Company = {
    id: 0,
    name: "",
    number: "",
    departmentIds: null,
    automatedOrganizationalChangeProcess: false,
    isReadOnly: false,
};

export const initialEmploymentStateProps: IUserEmploymentStateProps = {
    mainManagagerSearchValue: undefined,
    userEmployment: null,
};

const initialLoginAccordionProps = (
    accordionName: keyof ILoginAccordions,
    credentialType: CredentialType,
): ILoginAccordionSharedProps => ({
    accordionName: accordionName,
    heading: `${LoginCredentialType[credentialType]}.title`,
    open: false,
    credentialType: credentialType,
    inputProps: [],
    displayValues: [],
    disableButton: true,
    activeTab: "eMail",
    loginTypeExistsInCompanyConfig: false,
});

export const initialLoginCredentials = (
    userAccountId?: number,
    credentialType?: CredentialType,
    userId?: number,
): LoginCredentials => ({
    id: 0,
    credentialType: credentialType,
    identifier: "",
    userId: userId ?? 0,
    userAccountId: userAccountId ?? 0,
});

export const initialInputProps = (
    userAccountId?: number,
    credentialType?: CredentialType,
    userId?: number,
): ILoginInputSharedProps => ({
    inputValue: "",
    disabled: false,
    isDirty: false,
    errorMessage: "",
    loginCredential: initialLoginCredentials(userAccountId, credentialType, userId),
    isLoading: false,
    placeholder: credentialType === 2 ? "ssnFormat" : "",
});

export const initialSelectValue = (placeholder: string): ISelectValue => ({
    description: "",
    value: "",
    nonTranslatedDesc: placeholder,
});

const initialSearchProps: IAdminSearchStateProps = {
    searchIsLoading: false,
    searchResultOpen: false,
    searchResults: [],
    isExistingUser: false,
};

export const initialAddUserProps: IAddUserStateProps = {
    userProps: initialSelectedUserProps,
    errorMessages: {
        ssnErrorMsg: "",
        workEmailErrorMsg: "",
        privateEmailErrorMsg: "",
        privatePhoneErrorMsg: "",
        workPhoneErrorMsg: "",
    },
    saveButtonLoading: false,
    // saveOrUpdateSuccessful: false,
    ssnSearchProps: initialSearchProps,
    selectedCompanyRegion: null,
};

export const initialUserEmployment = (
    companyId?: number | null,
    userAccountId?: number | null,
): CreateOrUpdateEmployment => ({
    companyId: companyId ?? undefined,
    userAccountId: userAccountId ?? undefined,
    id: undefined,
    costCenter: "",
    departments: [],
    employmentGroups: [],
    employmentNumber: undefined,
    mainManager: null,
    organizationNumber: null,
});

export const initialEmploymentProps: IEmploymentStateProps = {
    mainManagerSearchProps: initialSearchProps,
    organizationNumberErrorMsg: "",
    saveButtonLoading: false,
};

export const initialAccordionDisplayValues: ISubscriptionAccordionDisplayProps = {
    subscriptionId: -1,
    subscriptionName: "",
    contactKey: [],
    missingContactKey: "",
    // email: "",
    // workPhone: "",
    displayValues: {
        isActive: false,
        subscriptionStatuses: [],
    },
    // subscriptionGroups: [],
};

const initialSubOptionProps: ISubscriptionOptionsStateProps = {
    subscriptionGroups: [],
    contactKey: [],
    missingContactKey: "",
    disableEditSubscription: undefined,
    subscribeOnUserDisplayProps: undefined,
};

export const initialAccordionProps: ISubscriptionAccordionStateProps = {
    open: false,
    startDate: undefined,
    endDate: undefined,
    saveDisabled: true,
    saveIsLoading: false,
    postItems: [],
    subscriptionMonitorType: undefined,
    monitoredIds: [],
    subscribingUserAccountId: null,
    isSubsOnUserTab: false,
    ...initialAccordionDisplayValues,
    newExternalContactKey: null,
    subscriptionOptionProps: initialSubOptionProps,
    canDeleteSubscription: false,
};

const initialNewExternalSubscriiber: INewExternalSubscriberStateProps = {
    name: "",
    selectedChannel: 1,
    searchIsLoading: false,
    newSubscriberExists: undefined,
};

export const initialNewSubscriptionProps: ICreateSubscriptionStateProps = {
    selectedLookupTypeRadioValue: 1,
    newSubSearchProps: {
        currentSearchQuery: "",
        searchIsLoading: false,
        searchOpen: false,
        searchResults: null,
        selectedSearchCompanyUser: null,
    },
    departmentsDisplayList: [],
    departmentsIsLoading: false,
    showSubscriptionOptions: false,
    newExternalSubscriber: initialNewExternalSubscriiber,
    subscriptionOptionProps: initialSubOptionProps,
};

export const initialSubscriptionStateProps: ISubscriptionStateProps = {
    subscriptionIsLoading: false,
    activeTab: "userSubsOn",
    modalOpen: false,
    modalHeader: "",
    deleteSubscriptionId: undefined,
    createOrUpdateSuccessful: null,
    currentlyEditingAccordion: initialAccordionProps,
    userSubsOnAccordionProps: [],
    subsOnUserAccordionProps: [],
    newSubscriptionStateProps: initialNewSubscriptionProps,
    newSubscriptonIsValid: false,
};

const initialPermissionSharedProps = (
    accordionName: keyof IPermissionAccordions,
    useInfoText: boolean,
    isCheckbox?: boolean,
): IPermissionsAccordionSharedProps => ({
    name: accordionName,
    open: false,
    isCheckbox: isCheckbox === true,
    infoText: useInfoText ? `${accordionName}Info` : "",
    depDisplayList: [],
    isDirty: false,
    isLoading: false,
});

const initialPermissionAccordionProps: IPermissionAccordions = {
    absenceReporting: initialPermissionSharedProps("absenceReporting", false, true),
    absenceAdministration: initialPermissionSharedProps("absenceAdministration", true),
    absenceView: initialPermissionSharedProps("absenceView", true),
    absenceFollowup: initialPermissionSharedProps("absenceFollowup", true),
    absenceStatistics: initialPermissionSharedProps("absenceStatistics", true),
    rehabProcessManagement: initialPermissionSharedProps("rehabProcessManagement", true),
    departmentAdministration: initialPermissionSharedProps("departmentAdministration", true),
    absenceBoard: initialPermissionSharedProps("absenceBoard", false),
    absenceArchive: initialPermissionSharedProps("absenceArchive", false, true),
    customerCompanyAdministration: initialPermissionSharedProps("customerCompanyAdministration", false, true),
    customerOrganizationAdministration: initialPermissionSharedProps("customerOrganizationAdministration", false, true),
    medicalAdvising: initialPermissionSharedProps("medicalAdvising", false, true),
};

export const initialPermissionProps: IPermissionsStateProps = {
    accordionProps: initialPermissionAccordionProps,
};

export const initialLoginConfig: LoginConfiguration = {
    companyId: -1,
    office365: false,
    usernameFromEmail: false,
    usernameFromSsn: false,
};

export const initialLoginsProps: ILoginsStateProps = {
    accordionProps: {
        usernameAndPassAccordion: initialLoginAccordionProps("usernameAndPassAccordion", 1),
        bankIdAccordion: initialLoginAccordionProps("bankIdAccordion", 2),
        microsoftAccordion: initialLoginAccordionProps("microsoftAccordion", 5),
        oneTimePassAccordion: initialLoginAccordionProps("oneTimePassAccordion", 6),
    },
};

export const getCredentialTranslation = (credentialType: number, identifier?: string): string => {
    if (credentialType !== 6) return `${LoginCredentialType[credentialType]}.identifier`;
    else return identifier?.includes("@") ? "eMail" : "mobilNo";
};

export const getLoginDisplayValues = (
    loginCredentials: LoginCredentials[],
    credentialType: number,
): IDisplayValues[] => {
    if (loginCredentials && loginCredentials.length > 0) {
        return loginCredentials.map((cred) => ({
            identifierTranslation: getCredentialTranslation(credentialType, cred.identifier ?? undefined),
            identifierValue: cred.identifier ?? undefined,
        }));
    } else
        return [
            {
                identifierTranslation: `${LoginCredentialType[credentialType]}.noCredentials`,
            },
        ];
};

export const getInputValues = (loginCredentials: LoginCredentials[]): ILoginInputSharedProps[] => {
    return loginCredentials.map((credential) => ({
        inputValue: credential.identifier ?? "",
        disabled: true,
        isDirty: false,
        errorMessage: "",
        loginCredential: credential,
        isLoading: false,
    }));
};

export const createLoginCredentials = (inputProps: ILoginInputSharedProps | undefined): LoginCredentials => {
    return {
        ...inputProps?.loginCredential,
        identifier:
            inputProps?.loginCredential.credentialType === 2
                ? inputProps.inputValue.replace(/[^0-9]/g, "")
                : inputProps?.inputValue,
    };
};

export const getAccordionName = (credentialType: CredentialType): keyof ILoginAccordions => {
    switch (credentialType) {
        case 1:
            return "usernameAndPassAccordion";
        case 2:
            return "bankIdAccordion";
        case 5:
            return "microsoftAccordion";
        default:
            return "oneTimePassAccordion";
    }
};

export const validateInputValue = (
    value: string,
    inputProps: ILoginInputSharedProps,
    activeTab: string | undefined,
): string => {
    const credentialType = inputProps.loginCredential.credentialType;
    if (credentialType === 2) return isValidSsn(value) ? "" : "invalidSsn";
    if (credentialType === 5 || (credentialType === 6 && activeTab !== "SMS"))
        return isValidEmail(value) ? "" : "invalidEmail";
    if (credentialType === 6 && activeTab === "SMS") return isValidMobileNumber(value) ? "" : "inValid";

    return "";
};

export const loginTypeExistsInCompanyConfig = (loginConf: LoginConfiguration, credentialType: CredentialType) => {
    switch (credentialType) {
        case 1:
            return loginConf.usernameFromEmail === true;
        case 2:
            return loginConf.usernameFromSsn === true;
        case 5:
            return loginConf.office365 === true;
        default:
            return true;
    }
};

export const countryOptions: ISelectValue[] = [
    {
        description: "",
        value: "SE",
        nonTranslatedDesc: "svCountry",
    },
    {
        description: "",
        value: "FI",
        nonTranslatedDesc: "fiCountry",
    },
    {
        description: "",
        value: "NO",
        nonTranslatedDesc: "noCountry",
    },
    {
        description: "",
        value: "DK",
        nonTranslatedDesc: "dkCountry",
    },

    {
        description: "",
        value: "OTH",
        nonTranslatedDesc: "othCountry",
    },
];

export const languageOptions: ISelectValue[] = [
    {
        description: "",
        value: "sv",
        nonTranslatedDesc: "svLang",
    },
    {
        description: "",
        value: "da",
        nonTranslatedDesc: "dkLang",
    },
    {
        description: "",
        value: "no",
        nonTranslatedDesc: "noLang",
    },
    {
        description: "",
        value: "en",
        nonTranslatedDesc: "enLang",
    },
];

export const genderOptions: ISelectValue[] = [
    {
        description: "",
        value: "1",
        nonTranslatedDesc: "man",
    },
    {
        description: "",
        value: "2",
        nonTranslatedDesc: "woman",
    },
];

export const getSelectValue = (
    value: string,
    placeHolder: "chooseCountry" | "chooseLanguage" | "choseGender",
): ISelectValue => {
    switch (placeHolder) {
        case "chooseCountry":
            return countryOptions.find((c) => c.value === value) ?? initialSelectValue("chooseCountry");
        case "chooseLanguage":
            return languageOptions.find((c) => c.value === value) ?? initialSelectValue("chooseLanguage");
        default:
            return genderOptions.find((c) => c.value === value) ?? initialSelectValue("choseGender");
    }
};

export const getRegionAsCountry = (region: CompanyRegion | null): Country => {
    switch (region?.region) {
        case "Sweden":
            return "SE";
        case "Finland":
            return "FI";
        case "Denmark":
            return "DK";
        case "Norway":
            return "NO";
        default:
            return "OTH";
    }
};
export const getRegionAsLanguage = (region: CompanyRegion | null): string => {
    switch (region?.region) {
        case "Sweden":
            return "sv";
        case "Denmark":
            return "da";
        case "Norway":
            return "no";
        default:
            return "en";
    }
};

export const getCountryAsPhonePrefix = (country: Country): string => {
    switch (country) {
        case "SE":
            return "+46";
        case "DK":
            return "+45";
        case "FI":
            return "+358";
        case "NO":
            return "+47";
        default:
            return "+46";
    }
};

const checkForInitialValue = (value?: string | null) => (value === "initialValue" ? "" : value);

export const handlePhoneNumbers = (userProps: CreateOrUpdateUserAccount): CreateOrUpdateUserAccount => ({
    ...userProps,
    mobileNumber: checkForInitialValue(userProps.mobileNumber),
    secondaryMobileNumber: checkForInitialValue(userProps.secondaryMobileNumber),
    telephoneNumber: checkForInitialValue(userProps.telephoneNumber),
    //This must be done cos BE does not set login configuration if field is empty
    mobileCountryPrefix: userProps.mobileCountryPrefix ? userProps.mobileCountryPrefix : "+46",

    secondaryMobileCountryPrefix: userProps.secondaryMobileCountryPrefix
        ? userProps.secondaryMobileCountryPrefix
        : "+46",

    telephoneCountryPrefix: userProps.telephoneCountryPrefix ? userProps.telephoneCountryPrefix : "+46",
});

export const getCreateOrUpdateUserAccountModel = (companyUserModel: CompanyUser): CreateOrUpdateUserAccount => ({
    id: companyUserModel.userAccountId,
    userId: companyUserModel.userId,
    firstName: companyUserModel.firstName,
    lastName: companyUserModel.lastName,
    socialSecurityNumber: companyUserModel.socialSecurityNumber ?? "",
    companyId: companyUserModel.companyId,
    country: companyUserModel.countryCode,
    language: companyUserModel.language,
    mobileNumber: companyUserModel.contactInformation?.mobileNumber,
    mobileCountryPrefix: companyUserModel.contactInformation?.mobileCountryPrefix,
    telephoneNumber: companyUserModel.contactInformation?.telephoneNumber,
    telephoneCountryPrefix: companyUserModel.contactInformation?.telephoneCountryPrefix,
    secondaryMobileNumber: companyUserModel.contactInformation?.secondaryMobileNumber,
    secondaryMobileCountryPrefix: companyUserModel.contactInformation?.secondaryMobileCountryPrefix,
    email: companyUserModel.contactInformation?.email,
    homeAddress: companyUserModel.contactInformation?.homeAddress,
    secondaryEmail: companyUserModel.contactInformation?.secondaryEmail,
    gender: companyUserModel.gender,
    birthYear: companyUserModel.birthYear,
    periodActivationDate: companyUserModel.accountAccessRightGroupPeriod?.activationDate,
    periodDeactivationDate: companyUserModel.accountAccessRightGroupPeriod?.deactivationDate,
});

export const getEmploymentGroupsAsSelect = (availableEmployementGroups?: EmploymentGroup[] | null): ISelectValue[] => {
    if (!availableEmployementGroups) return [];

    const selectEmploymentGroups = availableEmployementGroups.map((group) => ({
        description: "",
        value: group.id?.toString() ?? "",
        nonTranslatedDesc: group.name ?? "",
    }));

    return selectEmploymentGroups;
};

export const getEmploymentGroupValues = (
    availableEmployementGroups?: EmploymentGroup[] | null,
    selectedEmploymentGroups?: EmploymentGroup[] | null,
): IEmployedAtSelectProps => {
    const selectedEmpGrp =
        selectedEmploymentGroups && selectedEmploymentGroups?.length > 0 ? selectedEmploymentGroups[0] : null;
    const selectProps = {
        selectedValue: {
            description: "",
            value: selectedEmpGrp?.id?.toString() ?? "",
            nonTranslatedDesc: selectedEmpGrp?.name ?? "",
        },
        options: getEmploymentGroupsAsSelect(availableEmployementGroups) ?? [],
    };
    return selectProps;
};

export const getCostCenterValues = (
    selectedCostCenter: string | null | undefined,
    costCenters: CostCenter[],
): IEmployedAtSelectProps => {
    const selectProps = {
        selectedValue: {
            description: selectedCostCenter ?? "",
            value: costCenters?.find((costCenter) => costCenter.code === selectedCostCenter)?.description ?? "",
        },
        options: getDepartmentsCostCenterAsSelect(costCenters ?? []),
    };
    return selectProps;
};

export const getDepartmentsCostCenterAsSelect = (departments: CostCenter[]): ISelectValue[] => {
    const selectDepartments = departments.map((c) => ({
        description: c.code ?? "",
        value: c.description ?? "",
        nonTranslatedDesc: "",
    }));

    return selectDepartments;
};

export const getSomeChildIsCheckedRecursive = (department: TreeViewNode, depId: number): boolean => {
    if (depId === department.id) return true;
    if (department.children && department.children.length > 0) {
        return department.children.some((depChild) => getSomeChildIsCheckedRecursive(depChild, depId));
    }
    return false;
};

export const getOrgTreeViewAsDisplayList = (
    orgTreeView: TreeViewNode[] | null,
    selectedDeptsId?: number[] | null,
    deptIdsToDisable?: number[],
    showChildren?: boolean,
) => {
    const displayList: IDynamicData[] = orgTreeView
        ? orgTreeView?.map((department) => {
              const selectable = deptIdsToDisable
                  ? !deptIdsToDisable.some((id) => id === department.id)
                  : (department.selectable ?? true);
              return {
                  label: department.label ?? "",
                  referenceKey: `${department.id}`,
                  id: department.id ?? -1,
                  selectable,
                  children: getOrgTreeViewAsDisplayList(
                      department.children ?? null,
                      selectedDeptsId,
                      deptIdsToDisable,
                      showChildren,
                  ),
                  checked: selectedDeptsId
                      ? selectedDeptsId.some((depId) => selectable && depId === department.id)
                      : false,
                  showChildren:
                      showChildren === true &&
                      selectedDeptsId?.some((deptId) => getSomeChildIsCheckedRecursive(department, deptId)),
              };
          })
        : [];
    return displayList;
};

export const getOrgTreeViewAsDisplayListWithDisabledSubscribed = (
    orgTreeView: TreeViewNode[] | null,
    selectedDeptsId?: number[],
    deptIdsToDisable?: number[],
) => {
    const displayList = getOrgTreeViewAsDisplayList(orgTreeView, selectedDeptsId, deptIdsToDisable);
    return displayList;
};

const getLabelsRecursive = (depDisplayList: IDynamicData[]): string[] => {
    let selected: string[] = [];
    depDisplayList.forEach((c) => {
        if (c.checked) selected.push(c.label);
        if (c.children && c.children.length > 0) {
            selected = selected.concat(getLabelsRecursive(c.children));
        }
    });
    return selected;
};

export const getPermissionDisplayValues = (
    accordionProps: IPermissionsAccordionSharedProps,
    t: TFunction<"administration">,
): string[] => {
    if (accordionProps.isCheckbox) return [accordionProps.checked ? t("activated") : t("inActivated")];
    else {
        let selected: string[] = getLabelsRecursive(accordionProps.depDisplayList);

        let displayStrings = [t("chosenDepartments")];
        if (selected.length > 3) {
            displayStrings = displayStrings.concat(selected.slice(0, 3));
            displayStrings.push(`+${selected.length - 3} ${t("more")}`);
        } else if (selected.length > 0) {
            displayStrings = displayStrings.concat(selected);
        } else displayStrings = [t("noDepartmentsChosen")];
        return displayStrings;
    }
};

export const getSomeIsChecked = (depDisplayList: IDynamicData[]): boolean => {
    return depDisplayList.some((c) => {
        if (c.checked) return c.checked;
        if (c.children && c.children.length > 0) return getSomeIsChecked(c.children);
    });
};

export const getSomeWithChildIsChecked = (depDisplayList: IDynamicData[]): boolean => {
    return depDisplayList.some((c) => {
        if (c.checked && c.children && c.children.length > 0) return c.checked;
        if (c.children && c.children.length > 0) return getSomeWithChildIsChecked(c.children);
    });
};

export const getAllCheckedWithChild = (depDisplayList: IDynamicData[]) => {
    let checkedWithChildren: IDynamicData[] = [];
    depDisplayList.forEach((dynamic) => {
        if (dynamic.checked && dynamic.children && dynamic.children.length > 0) checkedWithChildren.push(dynamic);
        else if (dynamic.children && dynamic.children.length > 0) getAllCheckedWithChild(dynamic.children);
    });
    return checkedWithChildren;
};

export const getAllIsChecked = (depDisplayList: IDynamicData[] | undefined): boolean => {
    if (!depDisplayList) return false;
    return depDisplayList.every((c) => {
        if (c.checked && c.children && c.children.length < 1) return c.checked;
        else if (c.checked && c.children) return getAllIsChecked(c.children);
    });
};

export const getCheckedTopNodes = (depDisplayList: IDynamicData[]): IDynamicData[] => {
    let selectedWithNonCheckedChild: IDynamicData[] = [];
    depDisplayList.map((c) => {
        if (c.children && c.children.length > 0 && (c.checked || getSomeWithChildIsChecked(c.children))) {
            if (c.checked) selectedWithNonCheckedChild.push(c);
            if (!getAllIsChecked(c.children)) {
                selectedWithNonCheckedChild = selectedWithNonCheckedChild.concat(getCheckedTopNodes(c.children));
            }
        }
    });
    return selectedWithNonCheckedChild;
};

export const checkIsActive = (accordionProps: IPermissionsAccordionSharedProps): boolean =>
    accordionProps.isCheckbox ? !!accordionProps.checked : getSomeIsChecked(accordionProps.depDisplayList);

export const createIdNameMap = (
    depId: number,
    userAccountId: number | null,
    organizationTreeView: TreeViewNode[] | null,
    recursive: boolean,
): IdNameMap => {
    const node = organizationTreeView?.find((c) => c.id === depId);

    return {
        id: depId,
        userAccountId: userAccountId ?? -1,
        name: node?.label ?? "",
        recursive,
    };
};

export const getAccessRightsArrayClone = (
    accessRights: CreateOrUpdateAccessRights,
    accessRightKey: keyof CreateOrUpdateAccessRights,
): IdNameMap[] => _.cloneDeep(accessRights[accessRightKey] as IdNameMap[]);

export const selectAllAccessRights = (
    userAccountId: number | null,
    organizationTreeView: TreeViewNode[] | null,
    recursive: boolean,
): IdNameMap[] => {
    let allChecked: IdNameMap[] = [];
    organizationTreeView?.forEach((c) => {
        allChecked.push(createIdNameMap(Number(c.id ?? -1), userAccountId, organizationTreeView, recursive));
        if (c.children && c.children.length > 0) {
            allChecked = allChecked.concat(selectAllAccessRights(userAccountId, c.children, recursive));
        }
    }) ?? [];
    return allChecked;
};

export const isRecursive = (
    accName: keyof IPermissionAccordions,
    companyRecursiveArs: CompanyAccessRightsRecursive | null,
): boolean => {
    let recursive = false;
    if (companyRecursiveArs) {
        Object.keys(companyRecursiveArs).find((arKey) => {
            if (arKey === accName) {
                recursive = companyRecursiveArs[arKey as keyof CompanyAccessRightsRecursive];
            }
        });
    }
    return recursive;
};

export const getTopDepWithUnderlayingOrg = (arId: string, orgTree: TreeViewNode[] | null) => {
    if (!orgTree) return [];
    let nodes: TreeViewNode[] = [];
    for (const node of orgTree) {
        if (node.id?.toString() === arId && node.children) {
            nodes.push(node);
            break;
        } else if (node.children && node.children.length > 0)
            nodes = nodes.concat(getTopDepWithUnderlayingOrg(arId, node.children));
    }
    return nodes;
};

export const initialErasureFinishedSettingsProps: IErasureFinishedSettingsStateProps = {
    companyErasureConfig: {
        companyId: 0,
        automatical: false,
        noAbsenceHistoryOrRehabErasureMonthInterval: null,
        absenceHistoryErasureMonthInterval: null,
        rehabErasureMonthInterval: null,
        workRelatedAbsenceErasureMonthInterval: null,
        enableHistoricalDataErasure: false,
    },
    activeInput: undefined,
    isLoading: false,
};
export const initialActiveErasureConfig: CompanyDataErasureAvailableConfiguration = {
    companyId: 0,
    absenceHistory: null,
    caseDocumentation: null,
    medicalCertificates: null,
};

export const initialErasureActiveSettingsProps: IErasureActiveSettingsStateProps = {
    activeErasureConfig: initialActiveErasureConfig,
    isLoading: false,
};

const removeRadioButtonValues = (value: number | null) =>
    automaticErasureOptions.find((option) => option === value) ? null : value;

export const getDisplayValue = (value: number | null, hasFocus: boolean): number | string => {
    const filteredValue = hasFocus ? value : removeRadioButtonValues(value);
    if (typeof filteredValue === "number" && filteredValue >= 0) return filteredValue;
    return "";
};

export const getChannelText = (channel?: number): string => {
    if (!channel) return "";
    switch (channel) {
        case 0:
            return "none";
        case 3:
            return "both";
        default:
            return channel === 1 ? "eMail" : "SMS";
    }
};
const allowSms = (notificationType?: number) =>
    !notificationType ||
    notificationType === 0 ||
    notificationType === 1 ||
    notificationType === 2 ||
    notificationType === 3 ||
    notificationType === 4 ||
    notificationType === 5;

export const showSms = (channels: number[], notificationType?: number): boolean =>
    allowSms(notificationType) && channels.length === 2;

const getFormat = (notificationType: number, eventCode: string, t: TFunction) => {
    if (eventCode.includes("/"))
        return t(NotificationTypes[notificationType], {
            times: eventCode ? eventCode.split("/")[0] : "",
            months: eventCode ? eventCode.split("/")[1] : "",
        });
    else
        return t(NotificationTypes[notificationType], {
            eventCode: eventCode,
        });
};

export const getNotificationTypeTranslation = (notificationType: number, eventCode: string, t: TFunction): string => {
    switch (notificationType) {
        case 8:
        case 9:
        case 12:
            return getFormat(notificationType, eventCode ? eventCode : "x", t);
        case 7:
        case 16:
            return getFormat(notificationType, eventCode ? eventCode : "x/y", t);
        default:
            return t(NotificationTypes[notificationType]);
    }
};

export const getNotificationTypeTranslationFromName = (
    value: keyof typeof NotificationTypes,
    eventCode: string,
    t: TFunction,
) => getNotificationTypeTranslation(NotificationTypes[value], eventCode, t);

export const eventCodesMatch = (
    eventCodeOne: string | null | undefined,
    eventCodeTwo: string | null | undefined,
): boolean => {
    if (eventCodeOne) return eventCodeOne === eventCodeTwo;
    else return !eventCodeOne === !eventCodeTwo;
};

export const notificationTypeAndEventCodeMatch = (
    subscriptionOne: RoleSubscriptionConfigration | CompanySubscriptionConfiguration,
    subscriptionTwo: AddRoleSubscriptionConfigration,
): boolean =>
    subscriptionOne.notificationType === subscriptionTwo.notificationType &&
    eventCodesMatch(subscriptionOne.eventCode, subscriptionTwo.eventCode);

export const filterSubscription = (
    selectedSubscriptions: RoleSubscriptionConfigration[],
    subscription: AddRoleSubscriptionConfigration,
): RoleSubscriptionConfigration[] => {
    return selectedSubscriptions.filter(
        (selected) =>
            selected.notificationType !== subscription.notificationType ||
            !eventCodesMatch(selected.eventCode, subscription.eventCode) ||
            selected.role !== subscription.role ||
            selected.channel !== subscription.channel,
    );
};

export const initialRoleSubscriptionProps: IRoleSubscriptionsSettingsStateProps = {
    isLoading: false,
    newRoleSubscription: null,
    showStandardSubscriptionOptions: false,
    selectedRole: null,
    selectedRoleSubscriptions: [],
    saveIsLoading: false,
    notificationSelectOpen: false,
};

export const getAvailableNotificationTypes = (
    available: CompanySubscriptionConfiguration[] | undefined,
    selected: RoleSubscriptionConfigration[] | undefined,
): CompanySubscriptionConfiguration[] => {
    if (!available) return [];
    if (!selected || selected.length < 1) return available;

    const selectedWithChannels = available.filter((availableSub) =>
        selected.find((selectedSub) => notificationTypeAndEventCodeMatch(availableSub, selectedSub)),
    );

    const subscriptionsWithAllChannelsChecked = selectedWithChannels.filter((c) =>
        c.channels?.every((channel) => getChannelDisabled(c, channel, selected)),
    );

    const filteredSubscriptions: CompanySubscriptionConfiguration[] = available.filter(
        (c) => !subscriptionsWithAllChannelsChecked.includes(c),
    );
    return filteredSubscriptions;
};

const getChannelDisabled = (
    dropdownNotification: CompanySubscriptionConfiguration,
    currentChannel: number,
    savedNotificationTypes: AddRoleSubscriptionConfigration[] | undefined,
): boolean => {
    if (!dropdownNotification.channels?.includes(currentChannel)) return true;

    const savedNotificationsSameAsDropdown = savedNotificationTypes?.filter((saved) =>
        notificationTypeAndEventCodeMatch(dropdownNotification, saved),
    );

    const channelsSelected = savedNotificationsSameAsDropdown?.filter((c) => c.channel === currentChannel);

    return channelsSelected?.length === 3;
};

const createRadioButton = (channel: number, checked: boolean, disabled: boolean): IRadioButtonProps => ({
    text: channel.toString(),
    checked,
    setChecked() {},
    disabled,
});

export const getChannels = (
    newRow: AddRoleSubscriptionConfigration | null,
    savedNotificationTypes: AddRoleSubscriptionConfigration[] | undefined,
    availableNotificationTypes: CompanySubscriptionConfiguration[] | undefined,
): IRadioButtonProps[] => {
    if (!newRow || !allowSms || !availableNotificationTypes)
        return [1, 2, 3].map((channel) => createRadioButton(channel, false, channel !== 1));
    const dropdownSelectedNotification = availableNotificationTypes.find((type) =>
        notificationTypeAndEventCodeMatch(newRow, type),
    );

    if (dropdownSelectedNotification && dropdownSelectedNotification.channels) {
        let disableBoth = false;
        let emailAndSmsRadioButtons = [1, 2].map((channel) => {
            const radioIsDisabled = getChannelDisabled(dropdownSelectedNotification, channel, savedNotificationTypes);

            if (radioIsDisabled) disableBoth = true;

            return createRadioButton(channel, newRow.channel === channel, radioIsDisabled);
        });
        const bothRadioButton = createRadioButton(3, newRow.channel === 3, disableBoth);
        return [...emailAndSmsRadioButtons, bothRadioButton];
    }

    return [1, 2, 3].map((channel) => createRadioButton(channel, newRow.channel === channel, true));
};

const roleExists = (
    newRow: AddRoleSubscriptionConfigration,
    selectedSubscriptions: RoleSubscriptionConfigration[] | undefined,
    currentRole: Role,
): RoleSubscriptionConfigration | undefined => {
    if (!selectedSubscriptions || selectedSubscriptions.length < 1) return undefined;

    const exists = selectedSubscriptions.find(
        (subscription) =>
            notificationTypeAndEventCodeMatch(subscription, newRow) &&
            (newRow.channel === 3
                ? (subscription.channel === 1 && currentRole === subscription.role) ||
                  (subscription.channel === 2 && currentRole === subscription.role)
                : newRow.channel === subscription.channel) &&
            currentRole === subscription.role,
    );

    return exists;
};

export const getRoles = (
    newRow: AddRoleSubscriptionConfigration | null,
    selectedSubscriptions: RoleSubscriptionConfigration[] | undefined,
): IRadioButtonProps[] => {
    if (!newRow || !newRow.channel || !validateNotificationType(newRow.notificationType)) {
        return roleTypes.map((role) => ({
            text: role,
            checked: false,
            setChecked: () => {},
            disabled: true,
        }));
    }

    return roleTypes.map((role) => ({
        text: role,
        checked: newRow.role === role,
        setChecked: () => {},
        disabled: !!roleExists(newRow, selectedSubscriptions, role),
    }));
};

export const validateNotificationType = (type?: number): boolean =>
    type === undefined ? false : type > -1 && type < 29;

export const getSavedAsConfig = (
    saved: RoleSubscriptionConfigration[] | undefined,
): AddRoleSubscriptionConfigration[] => {
    if (!saved) return [];
    const config = saved?.map((c) => ({
        notificationType: c.notificationType,
        eventCode: c.eventCode,
        role: c.role ?? "",
        channel: c.channel as unknown as Channel,
    }));
    const sorted = config.sort((a, b) =>
        i18n.t(`administration:${a.role}`).localeCompare(i18n.t(`administration:${b.role}`), i18n.language),
    );
    return sorted;
};

export const getSelectedTranslation = (
    availableNotificationTypes: CompanySubscriptionConfiguration[],
    newRow: AddRoleSubscriptionConfigration | null,
    t: TFunction,
): string | undefined => {
    const selected = availableNotificationTypes.find((type) =>
        newRow ? notificationTypeAndEventCodeMatch(type, newRow) : false,
    );
    return selected
        ? t(getNotificationTypeTranslation(Number(selected.notificationType), selected.eventCode ?? "", t))
        : selected;
};

export const createRoleSubscriptions = (
    selectedRole: Role,
    availableNotificationTypes: CompanySubscriptionConfiguration[],
): AddRoleSubscriptionConfigration[] =>
    availableNotificationTypes.map((available) => ({
        notificationType: available.notificationType,
        eventCode: available.eventCode,
        channel: 1,
        role: selectedRole,
    }));

const getNotificationTypeGroupNameFromType = (type?: number) => {
    switch (type) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 10:
        case 11:
        case 13:
        case 21:
        case 22:
            return "absence";
        case 9:
        case 12:
        case 17:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
            return "cases";
        case 7:
        case 8:
        case 16:
            return "deviant";
        default:
            return "missingEventType";
    }
};
export const getNotificationTypeGroupNameFromName = (type?: string | null) => {
    switch (type) {
        case "ReportedSick":
        case "EditedSickReport":
        case "ReportedRecovery":
        case "ReportedVAB":
        case "EditedVABReport":
        case "ReportedVABRecovery":
        case "AbsenceReminder":
        case "AbsenceReportedToSocialInsurrance":
        case "MedicalCertificateMessageReminder":
        case "MedicalCertificate":
        case "MedicalCertificateUploaded":
        case "MedicalCertificateExpired":
            return "absence";
        case "RehabProcessPlannedActivityReminder":
        case "RehabProcessInitiated":
        case "RehabProcessClosingReminder":
        case "HealthCaseRehab":
        case "HealthCaseHealthPromotion":
        case "HealthCaseRehabReminder":
        case "HealthCaseHealthPromotionReminder":
        case "HealthCaseActivityReminder":
        case "HealthCaseClosingReminder":
            return "cases";
        case "DeviantAbsenceSickInShortPeriods":
        case "DeviantAbsenceLongerThenXDays":
        case "DeviantAbsenceXDaysInYMonths":
            return "deviant";
        default:
            return "missingEventType";
    }
};

const createCheckboxModel = (
    channel: Channel,
    checked: boolean,
    subscriptionTypeId: number,
    eventSubscriptionId?: number,
): ICheckBoxStateProps => ({
    channel,
    checked,
    subscriptionTypeId,
    eventSubscriptionId,
});

const createCheckbox = (
    channel: Channel,
    postItems: EventSubscriptionPostItem[],
    subTypeId?: number,
    selectedSubscription?: SubscriptionInformation,
) => {
    return createCheckboxModel(
        channel,
        postItems.some((item) => item.subscriptionTypeId === subTypeId && item.channel === channel),
        subTypeId ?? -1,
        selectedSubscription?.subscriptionInformationItems?.find((item) =>
            channel === 1 ? item?.channel === "Mail" : item?.channel === "Sms",
        )?.id,
    );
};

const createNotificationRowModel = (
    availableSubscription: SubscriptionType,
    postItems: EventSubscriptionPostItem[],
    selectedSubscription?: SubscriptionInformation,
    allowedChannel?: Channel,
): INotificationTypeRow => {
    let checkboxes: ICheckBoxStateProps[] = [];
    if (allowedChannel) {
        checkboxes = [createCheckbox(allowedChannel, postItems, availableSubscription.id, selectedSubscription)];
    } else {
        checkboxes = [createCheckbox(1, postItems, availableSubscription.id, selectedSubscription)];
        if (availableSubscription.allowSms) {
            checkboxes.push(createCheckbox(2, postItems, availableSubscription.id, selectedSubscription));
        }
    }
    const childProps: INotificationTypeRow = {
        notificationTypeName: availableSubscription.notificationEventType as keyof typeof NotificationTypes,
        eventCode: availableSubscription.eventCode ?? "",
        checkboxesProps: checkboxes,
        isRoleSubscription: undefined,
    };
    return childProps;
};

const setAbsenceGroupFirst = (groups: any[]) => _.sortBy(groups, "absence");

export const getSubscriptionOptionGroups = (
    companyId?: number | null,
    defaultSubscriptionTypes?: SubscriptionType[],
    selectedSubscriptionTypes?: SubscriptionInformation[] | null,
    allowedChannel?: Channel,
): ISubscriptionGroup[] => {
    if (!companyId || !defaultSubscriptionTypes) return [];
    const availableSubscriptionsTypes = defaultSubscriptionTypes.filter(
        (defaultSub) => defaultSub.companyId === companyId,
    );

    let subscriptionTypes: ISubscriptionGroup[] = [];
    availableSubscriptionsTypes.forEach((availableSub) => {
        const savedSubType = selectedSubscriptionTypes
            ? selectedSubscriptionTypes.find((saved) => {
                  return saved.subscriptionInformationItems && saved.subscriptionInformationItems?.length > 0
                      ? saved.subscriptionInformationItems[0].subscriptionTypeId === availableSub.id
                      : undefined;
              })
            : undefined;

        // Only display available subs and groups for sms
        if (allowedChannel !== 2 || availableSub.allowSms) {
            const groupName = getNotificationTypeGroupNameFromType(availableSub.notificationEventTypeKey);
            const exists = subscriptionTypes.find((c) => c.groupName === groupName);
            exists
                ? exists.notificationTypeRows.push(
                      createNotificationRowModel(
                          availableSub,
                          createPostModel(savedSubType),
                          savedSubType,
                          allowedChannel,
                      ),
                  )
                : subscriptionTypes.push({
                      subscriptionId: availableSub.id ?? -1,
                      groupName: groupName,
                      notificationTypeRows: [
                          createNotificationRowModel(
                              availableSub,
                              createPostModel(savedSubType),
                              savedSubType,
                              allowedChannel,
                          ),
                      ],
                  });
        }
    });

    const sorted = setAbsenceGroupFirst(subscriptionTypes);
    return sorted ?? [];
};

export const getNotificationTypeGroups = (
    selectedRoleSubscriptions: AddRoleSubscriptionConfigration[],
): IRoleSubscriptionGroups[] => {
    let groups: IRoleSubscriptionGroups[] = [];
    selectedRoleSubscriptions.forEach((roleSub) => {
        const groupName = getNotificationTypeGroupNameFromType(roleSub.notificationType);
        const exists = groups.find((c) => c.groupName === groupName);
        exists
            ? exists.selectedRoleSubscriptions.push(roleSub)
            : groups.push({
                  groupName: groupName,
                  selectedRoleSubscriptions: [roleSub],
              });
    });
    const sorted = groups.sort((a, b) =>
        i18n.t(`administration:${a.groupName}`).localeCompare(i18n.t(`administration:${b.groupName}`), i18n.language),
    );
    return sorted;
};

export const getNotificationStatusTexts = (status: boolean | undefined | null): string => {
    if (status === undefined || status === null) return "none";
    return status ? "all" : "some";
};

export const createPostModel = (subInfoItem?: SubscriptionInformation) => {
    if (!subInfoItem) return [];
    const uniqueInfos = _.uniqBy(subInfoItem.subscriptionInformationItems, "channel");
    const postModel: EventSubscriptionPostItem[] = uniqueInfos?.map((item) => ({
        channel: item.channel === "Mail" ? 1 : 2,
        subscriptionTypeId: item.subscriptionTypeId,
        eventSubscriptionId: item.id ?? 0,
    }));
    return postModel;
};

export const getPostModelFromAbsenceGroup = (absenceGroup: ISubscriptionGroup | undefined) => {
    if (!absenceGroup) return [];
    const postItems = absenceGroup.notificationTypeRows
        .flatMap((row) => row.checkboxesProps)
        .filter((cb) => cb.channel === 1);
    return postItems;
};
export const getPostModelsForSelected = (subInfos?: SubscriptionInformation[] | null) => {
    if (!subInfos || subInfos.length < 1) return [];
    let postItems: EventSubscriptionPostItem[] = [];
    subInfos.forEach((info) => {
        postItems = [...postItems, ...createPostModel(info)];
    });
    return postItems;
};

export const getAllowedChannelForAcc = (
    tableItem: SubscriberTableItem | SubscriptionTableItem,
): Channel | undefined => {
    if (tableItem.monitoringType !== 4 || !tableItem.contactKey || tableItem.contactKey.length < 1) return undefined;
    return tableItem.contactKey[0].startsWith("+") ? 2 : 1;
};

export const getContactKeyFromCompanyUser = (user: CompanyUser | null): string[] => {
    if (!user) return [];
    const contactKey = [];
    if (user.contactInformation?.email) contactKey.push(user.contactInformation?.email);
    if (user.contactInformation?.secondaryMobileNumber) contactKey.push(user.contactInformation?.secondaryMobile ?? "");
    return contactKey;
};

export const getMissingContactKeyFromCompanyUser = (
    user: CompanyUser | null,
    monitoringType?: SubscriptionMonitorType,
): "subIsMissingEmail" | "subIsMissingPhone" | "subIsMissingBoth" | "" => {
    if ((monitoringType && monitoringType === 4) || monitoringType === 5) return "";
    if (
        !user ||
        !user.contactInformation ||
        (!user.contactInformation.email && !user.contactInformation.secondaryMobileNumber)
    )
        return "subIsMissingBoth";
    if (!user.contactInformation.email) return "subIsMissingEmail";
    if (!user.contactInformation.secondaryMobileNumber) return "subIsMissingPhone";
    return "";
};

export const getMissingContactKey = (
    tableItem: SubscriberTableItem | SubscriptionTableItem,
    defaultSubscriptionTypes?: SubscriptionType[],
): "subIsMissingEmail" | "subIsMissingPhone" | "subIsMissingBoth" | "" => {
    if (!tableItem.contactKey || tableItem.contactKey.length === 2) return "";
    if (tableItem.monitoringType === 4 && tableItem.contactKey.length === 1) return "";

    const savedDefaultTypes: SubscriptionType[] = [];
    let subInfo: SubscriptionInformation[] = [];
    tableItem.directSubscriptionInformation && tableItem.directSubscriptionInformation?.length > 0
        ? (subInfo = tableItem.directSubscriptionInformation)
        : (subInfo = tableItem.indirectSubscriptionInformation ?? []);
    subInfo?.forEach((info) =>
        info.subscriptionInformationItems?.forEach((item) => {
            const saved = defaultSubscriptionTypes?.find((defSub) => defSub.id === item.subscriptionTypeId);
            if (saved) savedDefaultTypes.push(saved);
        }),
    );
    const allowSms = savedDefaultTypes.some((saved) => saved.allowSms);
    if (!allowSms && tableItem.contactKey.length === 1) return "";

    if (!tableItem.contactKey[0]) return allowSms ? "subIsMissingBoth" : "subIsMissingEmail";

    return tableItem.contactKey[0].startsWith("+") ? "subIsMissingEmail" : "subIsMissingPhone";
};

const canDeleteSubscription = (
    tableItem: SubscriberTableItem | SubscriptionTableItem,
    selectedCompanyId: number | null,
) => {
    //Todo kolla upp this.searchItem.lookupType === SubscriptionLookupType.external
    return (
        tableItem.companyId === selectedCompanyId &&
        !!tableItem.directSubscriptionInformation &&
        tableItem.directSubscriptionInformation.length > 0 &&
        tableItem.monitoringType === 4
    );
};

export const getAccordionProps = (
    tableItem: SubscriberTableItem | SubscriptionTableItem,
    subscriptionId: number,
    subscriptionOptionProps: ISubscriptionOptionsStateProps,
    selectedCompanyId: number | null,
): ISubscriptionAccordionStateProps => {
    return {
        subscriptionId: subscriptionId,
        open: false,
        startDate: tableItem.activationDate,
        endDate: tableItem.deactivationDate ?? undefined,
        saveDisabled: true,
        postItems: getPostModelsForSelected(tableItem.directSubscriptionInformation),
        subscriptionMonitorType: tableItem.monitoringType,
        subscribingUserAccountId: -1,
        newExternalContactKey: null,
        subscriptionName: tableItem.name
            ? `${tableItem.name} (${
                  tableItem.departmentName ? `${tableItem.departmentName}, ` : ""
              }${tableItem.companyName})`
            : "",
        contactKey: tableItem.contactKey ?? [],
        missingContactKey: subscriptionOptionProps.missingContactKey,
        displayValues: {
            isActive: tableItem.deactivationDate ? isAfterThisMonth(tableItem.deactivationDate) : true,
            subscriptionStatuses: [
                getNotificationStatusTexts(tableItem.sick),
                getNotificationStatusTexts(tableItem.deviant),
                getNotificationStatusTexts(tableItem.rehab),
            ],
        },
        subscribesOnUserName: tableItem.name ?? "",
        monitoredIds: [],
        subscriptionOptionProps: subscriptionOptionProps,
        canDeleteSubscription: canDeleteSubscription(tableItem, selectedCompanyId),
        currentTableItemUserAccountId: tableItem.userAccountId ?? -1,
    };
};

export const getCheckboxAsPostItem = (checkbox: ICheckBoxStateProps): EventSubscriptionPostItem => {
    return {
        channel: checkbox.channel,
        subscriptionTypeId: checkbox.subscriptionTypeId,
        eventSubscriptionId: checkbox.eventSubscriptionId ?? 0,
    };
};

export const addRemovePostItem = (
    checkbox: ICheckBoxStateProps,
    postItems: EventSubscriptionPostItem[],
): EventSubscriptionPostItem[] => {
    if (checkbox.checked) {
        //New value is unchecked, i.e. remove from postItems
        const filtered = postItems.filter(
            (item) => item.subscriptionTypeId !== checkbox.subscriptionTypeId || item.channel !== checkbox.channel,
        );
        return filtered;
    } else {
        // Add
        postItems.push(getCheckboxAsPostItem(checkbox));
        return postItems;
    }
};

export const handleCheckboxChangeFromPostItems = (
    accordion: ISubscriptionAccordionStateProps,
): ISubscriptionAccordionStateProps => {
    const cloned = _.cloneDeep(accordion);
    cloned.subscriptionOptionProps.subscriptionGroups.forEach((subGrp) =>
        subGrp.notificationTypeRows.forEach((row) =>
            row.checkboxesProps?.forEach((cb) => {
                cb.checked = accordion.postItems.some(
                    (item) => item.subscriptionTypeId === cb.subscriptionTypeId && item.channel === cb.channel,
                );
            }),
        ),
    );
    return cloned;
};

export const getDeleteSubscriptionRequestModel = (
    subscriptionToDelete: SubscriberTableItem,
): DeleteSubscriptionRequest[] =>
    subscriptionToDelete?.directSubscriptionInformation?.flatMap(
        (subInfo) =>
            subInfo.subscriptionInformationItems?.map((infoItem) => ({
                receiver: infoItem.receiver,
                userAccountId: infoItem.userAccountId,
                companyId: infoItem.companyId,
                scope: infoItem.scope,
                targetId: infoItem.targetId,
                isRoleSubscription: infoItem.isRoleSubscription,
            })) ?? [],
    ) ?? [];

export const getChildIdsRecursive = (items: IDynamicData[] | undefined): number[] => {
    let recursiveIds: number[] = [];
    if (!items) return recursiveIds;
    items.forEach((item) => {
        recursiveIds.push(Number(item.id));
        if (item.children && item.children?.length > 0)
            recursiveIds = recursiveIds.concat(getChildIdsRecursive(item.children));
    });
    return recursiveIds;
};

export const getSelectedDepartmentIds = (
    existingDepartmentIds: number[],
    displayItem: IDynamicData | undefined,
): number[] => {
    if (!displayItem) return existingDepartmentIds;
    const idsToAddOrRemove = new Set([Number(displayItem.id), ...getChildIdsRecursive(displayItem.children)]);
    //If existing means we should uncheck i.e remove ids
    const existing = existingDepartmentIds.find((existingId) => existingId === displayItem.id);

    let departmentIds: number[] = existing
        ? existingDepartmentIds.filter((existingId) => !idsToAddOrRemove.has(existingId))
        : _.uniq([...existingDepartmentIds, ...idsToAddOrRemove]);
    return departmentIds;
};

export const getNewSubscriptionRbName = (radioValue: number): string => {
    switch (radioValue) {
        case 1:
            //Todo hantera anställd prenumerant
            return "employee";
        case 2:
            return "department";
        case 3:
            return "company";
        case 4:
            return "existingExternal";
        default:
            return "newExternal";
    }
};

export const handleAllCheckboxesOnChange = (
    channel: number,
    checked: boolean,
    notificationTypeRows: INotificationTypeRow[],
): ICheckBoxStateProps[] =>
    notificationTypeRows.flatMap((row) =>
        row.checkboxesProps.filter((cb) => cb.channel === channel && checked !== cb.checked),
    ) ?? [];

export const getClickedDisplayItemRecursive = (
    clickedItemId: number,
    displayList: IDynamicData[],
): IDynamicData | undefined => {
    let listItem: IDynamicData | undefined = undefined;

    displayList.find((item) => {
        if (item.id === clickedItemId) listItem = item;
        if (!listItem && item.children && item.children.length > 0)
            listItem = getClickedDisplayItemRecursive(clickedItemId, item.children);
    });
    return listItem;
};

export const getMonitoredIdsFromClickedDepartment = (
    clickedDepartmentId: number,
    departmentDisplayList: IDynamicData[] | undefined,
    monitoredIds: number[],
): number[] => {
    const clickedDepWithChildren = getClickedDisplayItemRecursive(clickedDepartmentId, departmentDisplayList ?? []);
    const selectedDepartmentIds = getSelectedDepartmentIds(monitoredIds, clickedDepWithChildren);
    return selectedDepartmentIds;
};

export const getArrayOfAvailableChannels = (notificationTypeRows: INotificationTypeRow[]): number[] => {
    const channels = [];
    const emailAvailable = notificationTypeRows.some((row) => row.checkboxesProps.some((cb) => cb.channel === 1));
    if (emailAvailable) channels.push(1);
    const smsAvailable = notificationTypeRows.some((row) => row.checkboxesProps.some((cb) => cb.channel === 2));
    if (smsAvailable) channels.push(2);

    return channels;
};

export const getAllIsCheckedForCurrentChannel = (
    notificationTypeRows: INotificationTypeRow[],
    channel: number,
): boolean =>
    notificationTypeRows.every((row) =>
        row.checkboxesProps.filter((prop) => prop.channel === channel).every((mailProp) => mailProp.checked),
    );

export const getDepartmentRecursiveSingleByDepId = (
    departmentId: number,
    department: TreeViewNode | null,
): TreeViewNode | undefined => {
    if (!department) return undefined;
    if (department?.id === departmentId) return department;
    if (!department.children) return undefined;
    for (let i = 0; i < department.children.length - 1; i++) {
        const result = getDepartmentRecursiveSingleByDepId(departmentId, department.children[i]);
        if (result) {
            return result;
        }
    }
    return undefined;
};

export const getDepartmentRecursiveByDepId = (
    departmentId: number,
    orgTreeView: TreeViewNode[] | null,
): TreeViewNode | undefined => {
    if (!orgTreeView) return undefined;
    let department: TreeViewNode | undefined = undefined;
    orgTreeView.find((c) => {
        if (c.id === departmentId) department = c;
        if (!department && c.children && c.children.length > 0)
            department = getDepartmentRecursiveByDepId(departmentId, c.children);
    });
    return department;
};

export const getDepartmentSaveModel = (depId?: number, name?: string | null) => ({
    id: depId,
    name,
    recursive: false,
});

export const addRemoveDepartment = (
    checkedDepartments: Department[],
    departmentId: number,
    orgTreeView: TreeViewNode[] | null,
): Department[] => {
    const removeDept = checkedDepartments.some((c) => c.id === departmentId);
    if (removeDept) return checkedDepartments.filter((c) => c.id !== departmentId);
    else {
        const department = getDepartmentRecursiveByDepId(departmentId, orgTreeView);
        if (department)
            return _.orderBy([...checkedDepartments, getDepartmentSaveModel(department.id, department.label)], "id");
    }
    return checkedDepartments;
};

export const getDepartmentsSaveModel = (departments?: Department[] | null) =>
    departments?.map((dep) => getDepartmentSaveModel(dep.id, dep.name));

export const createCompanyUserFromCreateOrUpdateModel = (
    selectedCompanyUser: CompanyUser,
    updatedUser: CreateOrUpdateUserAccount,
): CompanyUser => ({
    ...selectedCompanyUser,
    birthYear: updatedUser.birthYear,
    companyId: updatedUser.companyId,
    countryCode: updatedUser.country,
    contactInformation: {
        ...selectedCompanyUser.contactInformation,
        email: updatedUser.email,
        homeAddress: updatedUser.homeAddress,
        mobileCountryPrefix: updatedUser.mobileCountryPrefix,
        mobileNumber: updatedUser.mobileNumber,
        secondaryEmail: updatedUser.secondaryEmail,
        secondaryMobileCountryPrefix: updatedUser.secondaryMobileCountryPrefix,
        secondaryMobileNumber: updatedUser.secondaryMobileNumber,
        telephoneCountryPrefix: updatedUser.telephoneCountryPrefix,
        telephoneNumber: updatedUser.telephoneNumber,
    },
    firstName: updatedUser.firstName,
    gender: updatedUser.gender,
    userAccountId: updatedUser.id,
    language: updatedUser.language,
    lastName: updatedUser.lastName,
    accountAccessRightGroupPeriod: {
        activationDate: updatedUser.periodActivationDate,
        deactivationDate: updatedUser.periodDeactivationDate,
    },
    socialSecurityNumber: updatedUser.socialSecurityNumber,
    userId: updatedUser.userId,
    employmentId: undefined,
});

export const getLoginAccordions = (
    stateAccordions: ILoginAccordions,
    allLoginConfigs: LoginCredentials[],
    loginConf: LoginConfiguration,
) => {
    let clonedAccordions = _.cloneDeep(stateAccordions);
    Object.values(clonedAccordions).forEach((accordion: ILoginAccordionSharedProps) => {
        const existingLoginCredentials = allLoginConfigs.filter(
            (config) => config.credentialType === accordion.credentialType,
        );
        accordion.displayValues = getLoginDisplayValues(existingLoginCredentials, accordion.credentialType);
        accordion.inputProps = getInputValues(existingLoginCredentials);
        accordion.loginTypeExistsInCompanyConfig = loginTypeExistsInCompanyConfig(loginConf, accordion.credentialType);
    });
    return clonedAccordions;
};

export const getIconByMonitorType = (
    subscriptionMonitorType?: SubscriptionLookupType,
    email?: string,
    isSubsOnUserTab?: boolean,
): Icons => {
    switch (subscriptionMonitorType) {
        case 1:
            return "person";
        case 4:
            return email?.includes("+") ? "mobilePhone" : "mail";
        default:
            return isSubsOnUserTab ? "person" : "building";
    }
};
export const getIconByLookupType = (subscriptionLookupType?: SubscriptionLookupType): Icons => {
    switch (subscriptionLookupType) {
        case 1:
            return "person";
        case 4:
            return "externalUser";
        default:
            return "building";
    }
};
const getSubscriptionName = (subscriptionInfo: SubscriptionInformation) =>
    `${i18n.t(`administration:${subscriptionInfo.notificationEventType}`)}-(${subscriptionInfo.name})`;

const getSubscribesOnUserDisplayGroups = (subInfos: SubscriptionInformation[]) => {
    let groups: ISubscribeOnUserGroups[] = [];
    subInfos.forEach((subInfo) => {
        const groupName = getNotificationTypeGroupNameFromName(subInfo.notificationEventType);
        const existingGroupName = groups.find((group) => group.groupName === groupName);

        existingGroupName
            ? existingGroupName.subscriptionLabels.push(getSubscriptionName(subInfo))
            : groups.push({
                  groupName,
                  subscriptionLabels: [getSubscriptionName(subInfo)],
              });
    });
    return groups;
};

export const getSubscribeOnUserDisplayProps = (
    subscriptionName: string,
    selectedCompanyUser?: string,
    subInfos?: SubscriptionInformation[] | null,
): ISubscribeOnUserDisplayProps | undefined => {
    if (!subInfos || !selectedCompanyUser) return undefined;
    return {
        subscriber: subscriptionName,
        user: selectedCompanyUser,
        groups: getSubscribesOnUserDisplayGroups(subInfos),
    };
};

export const getMonitorType = (subscriptionMonitorType?: SubscriptionMonitorType) => {
    if (subscriptionMonitorType === 4 || subscriptionMonitorType === 5) return 1;
    else return subscriptionMonitorType as MonitoringType;
};

export const showSubscriptionOptions = (
    currentAccordion: ISubscriptionAccordionStateProps,
    newExternalIsLoading: boolean,
    searchIsLoading: boolean,
) => {
    if (currentAccordion.isSubsOnUserTab) {
        if (newExternalIsLoading) return false;
        return !!currentAccordion.subscribingUserAccountId || !!currentAccordion.newExternalContactKey;
    } else return !searchIsLoading && currentAccordion.monitoredIds.length > 0;
};

export const subscriptionIsValid = (accordion: ISubscriptionAccordionStateProps, deleteSubId?: number) =>
    !deleteSubId && !!accordion.startDate && accordion.monitoredIds?.length > 0 && accordion.postItems.length > 0;

const disableEditSubscription = (
    currentSub: SubscriberTableItem | SubscriptionTableItem,
    selectedCompanyId: number | null,
): boolean => {
    return (
        selectedCompanyId !== currentSub.companyId ||
        (!!currentSub.indirectSubscriptionInformation && currentSub.indirectSubscriptionInformation.length > 0) ||
        (!!currentSub.directSubscriptionInformation && currentSub.directSubscriptionInformation.length === 0)
    );
};

export const updateAccordion = (
    currentAcc: ISubscriptionAccordionStateProps,
    currentSub: SubscriberTableItem | SubscriptionTableItem,
    deleteSubId: number | undefined,
    selectedCompanyId: number | null,
    selectedCompanyUserName?: string,
): ISubscriptionAccordionStateProps => {
    //Todo något stämmer inte, save är disablad när man klickar ur en befintlig sub
    const updated = handleCheckboxChangeFromPostItems(currentAcc);
    const accModel = {
        ...updated,
        saveDisabled:
            !subscriptionIsValid(currentAcc, deleteSubId) ||
            _.isEqual(updated.postItems, getPostModelsForSelected(currentSub.directSubscriptionInformation)),
        subscriptionOptionProps: {
            ...updated.subscriptionOptionProps,
            disableEditSubscription: disableEditSubscription(currentSub, selectedCompanyId),
            subscribeOnUserDisplayProps: getSubscribeOnUserDisplayProps(
                currentAcc.subscribesOnUserName ?? "",
                selectedCompanyUserName,
                currentSub.indirectSubscriptionInformation,
            ),
        },
    };
    return accModel;
};
