import { isArray } from "lodash/fp";
import { useMemo } from "react";

interface IProps {
    header?: string | null;
    body?: string | string[] | null;
}
const ColumnTextRow = ({ header, body }: IProps) => {
    const bodyContent = useMemo(() => {
        if (!body) return null;
        if (isArray(body)) {
            return (
                <div className="flex">
                    {body.map((x) => (
                        <>
                            <p className="max-w-[240px] whitespace-normal break-words text-sm text-grey-600 lg:text-base">
                                {x}
                            </p>
                            <div>|</div>
                        </>
                    ))}
                </div>
            );
        }
        return <p className="max-w-[240px] whitespace-normal break-words text-sm text-grey-600 lg:text-base">{body}</p>;
    }, [body]);
    return (
        <div className="flex h-full flex-col items-start px-2">
            <div className="min-h-[70px]">
                <div>
                    {header && (
                        <p className="max-w-[240px] whitespace-normal break-words text-sm text-mhdarkgreen-original lg:text-base">
                            {header}
                        </p>
                    )}
                </div>
                <div>{bodyContent}</div>
            </div>
        </div>
    );
};
export default ColumnTextRow;
