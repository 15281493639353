const RecurringSickLeaveWidgetLoader = () => {
    return (
        <div className="animate-pulse">
            <div className="chart-selector flex flex-wrap px-6 py-4 text-sm">
                <div className="flex w-full items-center">
                    <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </div>
            </div>
            <div className="chart-container">
                <div className="chart-selector flex flex-wrap px-6 py-4 text-sm">
                    <div className="flex w-1/6 items-end">
                        <div className="h-20 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/6 items-end">
                        <div className="h-36 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/6 items-end">
                        <div className="h-15 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/6 items-end">
                        <div className="h-2.5 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/6 items-end">
                        <div className="h-30 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex w-1/6 items-end">
                        <div className="h-10 w-10 rounded-sm bg-gray-200 dark:bg-gray-700"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecurringSickLeaveWidgetLoader;
