import { useAppDispatch, useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";
import { selectors, MedicalCertificateModals, actions, thunkActions } from "../../redux";
import { LoadableComponent } from "@medhelp/ui";
import { Status } from "@medhelp/ui/LoadableComponent";
import { ModalFrame } from "@medhelp/ui";
import { ReactComponent as CheckMarkIcon } from "../../../../icons/checkMarkBlack.svg";
import ModalCommonFooter from "@medhelp/ui/Modal/ModalCommonFooter";

const DeleteMedicalModal = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const deleteMedicalCertificateConfirm = useAppSelector(
        selectors.getModal(MedicalCertificateModals.deleteMedicalCertificateConfirm),
    );
    const loadingStatus = useAppSelector(selectors.getFileStatus);
    const { owner, groupKey } = useAppSelector(selectors.getPendingDelete);

    if (!deleteMedicalCertificateConfirm) return <></>;
    if (!groupKey || !owner) {
        console.error("Pending delete has no values");
        return <></>;
    }

    const content = (
        <div className="flex max-w-lg flex-col gap-10">
            <LoadableComponent
                // @ts-expect-error
                status={loadingStatus as Status}
                errorMessage="could not delete"
            >
                <p>
                    {t("removeCertificateDescription", {
                        ns: "followup",
                    })}
                </p>
            </LoadableComponent>
        </div>
    );

    return (
        <ModalFrame
            id="delete-doctor-certificate-modal"
            header={<h1>{t("removeCertificate", { ns: "followup" })}</h1>}
            content={content}
            open={deleteMedicalCertificateConfirm}
            setOpen={() => dispatch(actions.cancelDeleteMedicalCertificate())}
            footer={
                <ModalCommonFooter
                    tertiaryText={t("abort")}
                    tertiaryOnClick={() => dispatch(actions.cancelDeleteMedicalCertificate())}
                    primaryText={t("delete")}
                    primaryOnClick={() =>
                        dispatch(
                            thunkActions.removeCertificate({
                                owner,
                                groupKey,
                            }),
                        )
                    }
                    primaryRightIcon={<CheckMarkIcon stroke="currentcolor" />}
                />
            }
        />
    );
};

export default DeleteMedicalModal;
