import { Outlet } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import ServiceTopLinks from "./components/ServiceTopLinks";
import { AccessRightsCustomerSupport } from "domain/accessRights";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getActivePowerbarSearch } from "store/searchSelectors";
import { actions } from "./redux";
import AddCustomerCompany from "./components/AddCustomerCompany";

const ServicePage = () => {
    const activePowerBarSeach = useAppSelector(getActivePowerbarSearch);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actions.resetSelectedCompany());
    }, [activePowerBarSeach, dispatch]);

    return (
        <ProtectedRoute accessRightData={AccessRightsCustomerSupport}>
            <>
                <div className="mb-8 mt-6 flex justify-between px-5 max-xl:hidden xl:mt-0 xl:px-0">
                    <h1>Service</h1>
                    <AddCustomerCompany />
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className="mb-2 w-full">
                        <ServiceTopLinks />
                    </div>
                    <br />
                    <div className="dashboard-width flex xl:hidden">
                        <AddCustomerCompany />
                    </div>
                </div>

                <Outlet />
            </>
        </ProtectedRoute>
    );
};

export default ServicePage;
