import React from "react";
import ReactModal from "react-modal";
import { useAppSelector } from "store/hooks";
import * as UserSelectors from "store/userSelectors";
import useDetectOutsideClick from "hooks/useDetectOutsideClick";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import PrimaryButton from "../PrimaryButton";
import { ReactComponent as CheckMarkIcon } from "icons/checkMarkBlack.svg";

interface IWelcomeMessageProps {
    id: string;
    className?: string;
    message: string;
    buttonText: string;
}

ReactModal.setAppElement("body");

const WelcomeMessage: React.FunctionComponent<IWelcomeMessageProps> = (props) => {
    const { id, className, message, buttonText } = props;
    const handleClose = () => {
        sessionStorage.setItem("hideWelcomeText", "true");
        setOpen(false);
    };
    const context = useAppSelector(UserSelectors.getUserRetailer);
    const { isOutside: open, setIsOutside: setOpen, setContainer } = useDetectOutsideClick(true, true);

    return (
        <div id={id} data-cy="welcomeMessage">
            <ReactModal
                id={id}
                isOpen={open}
                className="absolute top-0 h-auto w-full bg-white"
                onRequestClose={() => handleClose()}
                onAfterClose={() => handleClose()}
                style={{
                    overlay: {
                        zIndex: 10,
                        backgroundColor: "rgba(94, 95, 86, 0.5)",
                    },
                }}
            >
                <div
                    data-cy="modal-content"
                    className={`${context} relative z-50 flex h-auto w-full flex-col ${className}`}
                    ref={setContainer}
                >
                    <button
                        className="absolute right-0 m-6 xxs:m-8"
                        type="button"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon width="20" height="20" fill="black" />
                    </button>
                    <div className="mx-6 mt-12 xxs:mx-14 xxs:mt-8">
                        <div dangerouslySetInnerHTML={{ __html: message }}></div>
                        <div className="my-16 flex w-full justify-center xxs:my-8 xxs:justify-start">
                            <PrimaryButton
                                id="welcomeMessageButton"
                                data-cy="welcomeMessageButton"
                                text={buttonText}
                                onClick={() => {
                                    handleClose();
                                }}
                                rightIcon={<CheckMarkIcon stroke="currentcolor" />}
                            />
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};

export default WelcomeMessage;
