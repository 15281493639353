const SpinnerWithOverlay = () => (
    <div className="absolute inset-0 z-20 h-full min-h-64 w-full cursor-default bg-loadingBg opacity-90">
        <div className="flex h-full">
            <div className="lds-ring m-auto">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);

export default SpinnerWithOverlay;
