import Icon from "components/Icon";
import { useAppSelector } from "store/hooks";
import Modal from "components/Modal";
import IMG1 from "assets/fortnoxWizard1.png";
import IMG2 from "assets/fortnoxWizard2.png";
import IMG3 from "assets/fortnoxWizard3.png";
import IMG4 from "assets/fortnoxWizard4.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserContext } from "store/userSelectors";
import PrimaryButton from "components/PrimaryButton";

const FortnoxWelcomeWizard = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const userContext = useAppSelector(getUserContext);

    const [isOpen, setIsOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const isAdmin = userContext.HasCustomerCompanyAdministration;
    const steps = isAdmin ? 5 : 3;

    useEffect(() => {
        const hideWizard = localStorage.getItem("hide-fortnox-wizard");
        if (!hideWizard) {
            setIsOpen(true);
            localStorage.setItem("hide-fortnox-wizard", "true");
        }
    }, []);

    const nextPage = () => setCurrentStep((prev) => (prev < steps - 1 ? prev + 1 : prev));
    const prevPage = () => setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));

    const getContent = (key: string) => {
        // if key is not found in translations, don't show anything
        if (!i18n.exists(`fortnoxWizard.${currentStep}.${key}`)) return <></>;
        return (
            <>
                {t(`fortnoxWizard.${currentStep}.${key}`, {
                    contextSeparator: "*",
                    returnObjects: true,
                    defaultValue: null,
                })}
            </>
        );
    };

    const listItems = getContent("list");

    const imageArray = [IMG1, IMG2, IMG2, IMG3, IMG4];

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableOutsideClick
            className="h-full max-w-[960px] bg-pageBg md:h-[600px] md:rounded"
        >
            <div className="relative flex h-full w-content md:w-[504px] xl:w-full">
                {currentStep > 0 && (
                    <div>
                        <button className="absolute left-12 top-3/4 md:-left-18 md:top-1/2" onClick={prevPage}>
                            <Icon icon="circleBack" size={40} />
                        </button>
                    </div>
                )}

                <div>
                    {currentStep === steps - 1 ? (
                        <div className="absolute right-12 top-3/4 md:-right-18 md:top-1/2 md:hidden">
                            <PrimaryButton
                                className="bg-mhdarkgreen-original font-normal text-white hover:text-mhdarkgreen-original"
                                id="base-wizard-get-started"
                                onClick={() => {
                                    setIsOpen(false);
                                    navigate(isAdmin ? "fortnoxAdministration" : "dashboard");
                                }}
                            >
                                {t("getStarted")}
                            </PrimaryButton>
                        </div>
                    ) : (
                        <button className="absolute right-12 top-3/4 md:-right-18 md:top-1/2" onClick={nextPage}>
                            <Icon icon="circleForward" size={40} />
                        </button>
                    )}
                </div>

                <img className="hidden xl:flex" src={imageArray[currentStep]} alt="" />
                <div className="flex h-full flex-col justify-between p-12">
                    <div className="flex h-full flex-col gap-8 md:leading-7">
                        <h1>{getContent("heading")}</h1>
                        <p>{getContent("text")}</p>
                        <p>{getContent("text2")}</p>
                        <div className="flex flex-col gap-1 md:leading-6">
                            <p className="font-bold">{getContent("subheading")}</p>
                            {Array.isArray(listItems) && (
                                <ul className="list-inside list-[\\2713]">
                                    {listItems.map((item) => (
                                        <li>
                                            <span className="ml-2">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="hidden pt-6 md:flex">
                            {currentStep === steps - 1 && (
                                <PrimaryButton
                                    className="bg-mhdarkgreen-original font-normal text-white hover:text-mhdarkgreen-original"
                                    id="base-wizard-get-started"
                                    onClick={() => {
                                        setIsOpen(false);
                                        navigate(isAdmin ? "fortnoxAdministration" : "dashboard");
                                    }}
                                >
                                    {t("getStarted")}
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-mhgrey-medium underline hover:cursor-pointer">
                            <p onClick={() => setIsOpen(false)}>{t("skip")}</p>
                        </div>
                        <div className="flex items-center gap-1">
                            {Array.from({ length: steps }, (_, i) => (
                                <div onClick={() => setCurrentStep(i)} className="p-1 hover:cursor-pointer">
                                    <Icon
                                        size={i === currentStep ? 10 : 6}
                                        icon="progressCircle"
                                        className={`${
                                            i > currentStep ? "text-mhgrey-medium" : "text-mhdarkgreen-original"
                                        }`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default FortnoxWelcomeWizard;
