import * as React from "react";

interface IProps {
    seen: boolean;
    className?: string;
}
const Notification = ({ seen = false, className }: IProps) => (
    <div
        className={`h-2 w-2 rounded ${
            seen ? "border-2 border-solid border-primary-700" : "border-none bg-mhred-alert3"
        } ${className}`}
    />
);

export default Notification;
