import * as React from "react";
import { has } from "lodash/fp";
import * as Arrow from "./Arrow";
import * as Triangle from "./Triangle";
import * as Caret from "./Caret";
import { ReactComponent as Absence } from "icons/followup/absence.svg";
import { ReactComponent as MedicalCertificate } from "icons/followup/medicalCertificate.svg";
import { ReactComponent as Plus } from "icons/plus.svg";
import { ReactComponent as PlusCircle } from "icons/newicons/plus-circle.svg";
import { ReactComponent as Info } from "icons/info.svg";
import { ReactComponent as Checked } from "icons/widget/checkboxChecked.svg";
import { ReactComponent as Unchecked } from "icons/widget/checkboxUnchecked.svg";
import { ReactComponent as RoundedSquare } from "../../icons/widget/checkboxMySelection.svg";
import { ReactComponent as Refresh } from "icons/refresh.svg";
import { ReactComponent as Loader } from "icons/loader.svg";
import { ReactComponent as CheckCircle } from "icons/checkCircle.svg";
import { ReactComponent as Notes } from "icons/newicons/notes.svg";
import { ReactComponent as Edit } from "icons/edit.svg";
import { ReactComponent as Pencil } from "icons/pencil.svg";
import { ReactComponent as Close } from "icons/close.svg";
import { ReactComponent as CheckMark } from "icons/checkMarkBlack.svg";
import { ReactComponent as ChatNotification } from "icons/newicons/chat-alt.svg";
import { ReactComponent as MiniMenu } from "icons/miniMenu.svg";
import { ReactComponent as BarChartPercentWidget } from "icons/barChartPercentWidget.svg";
import { ReactComponent as BarChartNumberWeekWidget } from "icons/barChartNumberWeekWidget.svg";
import { ReactComponent as LineChartPercentWidget } from "icons/lineChartPercentWidget.svg";
import { ReactComponent as TableWidget } from "icons/tableWidget.svg";
import { ReactComponent as DigitWidget } from "icons/digitWidget.svg";
import { ReactComponent as BarChartNumberWidget } from "icons/barChartNumberWidget.svg";
import { ReactComponent as Dot } from "icons/dot.svg";
import { ReactComponent as Ring } from "icons/ring.svg";
import { ReactComponent as ForecastWidget } from "icons/forecastWidget.svg";
import { ReactComponent as RadioActive } from "icons/widget/radioActive.svg";
import { ReactComponent as Radio } from "icons/widget/radio.svg";
import { ReactComponent as TrashCan } from "icons/trashCan.svg";
import { ReactComponent as Save } from "icons/save-icon.svg";
import { ReactComponent as Magnifier } from "icons/magnifier.svg";
import { ReactComponent as SearchMenu } from "../../components/Icon/assets/search-menu.svg";
import { ReactComponent as Attach } from "icons/attach.svg";
import { ReactComponent as Company } from "./assets/building.svg";
import { ReactComponent as CheckmarkCloud } from "icons/checkmarkCloud.svg";
import { ReactComponent as Key } from "icons/key.svg";
import { ReactComponent as Bell } from "icons/bell.svg";
import { ReactComponent as SpeakerPhone } from "icons/speakerPhone.svg";
import { ReactComponent as Phone } from "icons/phone.svg";
import { ReactComponent as DocumentAdd } from "./assets/document-add.svg";
import { ReactComponent as Heart } from "./assets/heart.svg";
import { ReactComponent as Excel } from "./assets/excel.svg";
import { ReactComponent as Notelist } from "./assets/notelist.svg";
import { ReactComponent as Filter } from "./assets/filtericon.svg";
import { ReactComponent as Sort } from "icons/sortIcon.svg";
import { ReactComponent as xCircle } from "icons/xCircle.svg";
import { ReactComponent as Person } from "./assets/person.svg";
import { ReactComponent as ShieldCheck } from "./assets/shield-check.svg";
import { ReactComponent as Add } from "./assets/add.svg";
import { ReactComponent as EmployeeCardNews } from "./assets/employeecardnews.svg";
import { ReactComponent as Warning } from "./assets/warning.svg";
import { ReactComponent as Clock } from "./assets/clock.svg";
import { ReactComponent as Shift } from "./assets/shift.svg";
import { ReactComponent as WinLogo } from "./assets/winLogo.svg";
import { ReactComponent as MacLogo } from "./assets/macLogo.svg";
import { ReactComponent as WidgetTitle } from "icons/widget/widgetTitleIcon.svg";
import { ReactComponent as WidgetFilter } from "icons/widget/widgetFilterIcon.svg";
import { ReactComponent as PauseCircle } from "./assets/pauseCircle.svg";
import { ReactComponent as Back } from "./assets/back.svg";
import { ReactComponent as Document } from "./assets/document.svg";
import { ReactComponent as Template } from "./assets/template.svg";
import { ReactComponent as Circle } from "./assets/circle.svg";
import { ReactComponent as CircleFull } from "./assets/circleFull.svg";
import { ReactComponent as PersonOutlined } from "./assets/personOutlined.svg";
import { ReactComponent as Link } from "./assets/link.svg";
import { ReactComponent as Search } from "../../icons/widget/search.svg";
import { ReactComponent as Download } from "icons/download.svg";
import { ReactComponent as Globe } from "./assets/globe.svg";
import { ReactComponent as DocumentLarge } from "./assets/document-large.svg";
import { ReactComponent as Calendar } from "./assets/calendar.svg";
import { ReactComponent as DocumentSearch } from "./assets/document-search.svg";
import { ReactComponent as ExternalLink } from "./assets/external-link.svg";
import { ReactComponent as DashboardSketch } from "./assets/dashboard-sketch.svg";
import { ReactComponent as StatusOverviewWidget } from "./assets/status-overview-widget.svg";
import { ReactComponent as TodoWidget } from "./assets/todo-widget.svg";
import { ReactComponent as SwitchAccount } from "./assets/account-switch.svg";
import { ReactComponent as NotificationRead } from "./assets/notificationRead.svg";
import { ReactComponent as NotificationUnread } from "./assets/notificationUnread.svg";
import { ReactComponent as Building } from "./assets/building.svg";
import { ReactComponent as PhoneMissedCall } from "icons/phoneMissedCall.svg";
import { ReactComponent as Upload } from "./assets/upload.svg";
import { ReactComponent as SwitchToggled } from "icons/switchToggled.svg";
import { ReactComponent as SwitchUntoggled } from "icons/switchUntoggled.svg";
import { ReactComponent as Adjustments } from "./assets/adjustments.svg";
import { ReactComponent as Logo } from "icons/logo.svg";
import { ReactComponent as XCircleFull } from "icons/xCircleFull.svg";
import { ReactComponent as WarningCircle } from "icons/warningCircle.svg";
import { ReactComponent as ProgressCircle } from "icons/progressCircle.svg";
import { ReactComponent as CircleBack } from "icons/circleBack.svg";
import { ReactComponent as CircleForward } from "icons/circleForward.svg";
import { ReactComponent as LightBulb } from "icons/lightBulb.svg";
import { ReactComponent as Squircle } from "icons/squircle.svg";
import { ReactComponent as ArrowSwoosh } from "icons/arrowSwoosh.svg";
import { ReactComponent as Copy } from "icons/copy.svg";
import { ReactComponent as Mail } from "./assets/mail.svg";
import { ReactComponent as ExternalUser } from "./assets/external-user.svg";
import { ReactComponent as MobilePhone } from "./assets/mobile-phone.svg";
import { ReactComponent as QuestionMark } from "icons/questionMark.svg";
import { ReactComponent as QuestionMarkCircle } from "./assets/question-mark-circle.svg";
import { ReactComponent as HamburgerIcon } from "icons/menu/hamburgericon.svg";
import { ReactComponent as Ellipsis } from "icons/ellipsis.svg";
import { ReactComponent as GlobeAlt } from "icons/globe-alt.svg";
import { ReactComponent as Cog } from "icons/cog.svg";

const icons = {
    arrowLeft: Arrow.ArrowLeft,
    arrowRight: Arrow.ArrowRight,
    arrowUp: Arrow.ArrowUp,
    arrowDown: Arrow.ArrowDown,
    triangleLeft: Triangle.TriangleLeft,
    triangleRight: Triangle.TriangleRight,
    triangleUp: Triangle.TriangleUp,
    triangleDown: Triangle.TriangleDown,
    caretLeft: Caret.CaretLeft,
    caretRight: Caret.CaretRight,
    caretUp: Caret.CaretUp,
    caretDown: Caret.CaretDown,
    caretFullLeft: Caret.CaretFullLeft,
    caretFullRight: Caret.CaretFullRight,
    caretFullUp: Caret.CaretFullUp,
    caretFullDown: Caret.CaretFullDown,

    absence: Absence,
    medicalCertificate: MedicalCertificate,
    plus: Plus,
    plusCircle: PlusCircle,
    xCircle: xCircle,
    info: Info,
    default: Absence,
    checkBoxChecked: Checked,
    checkBoxUncheched: Unchecked,
    roundedSquare: RoundedSquare,
    calendar: Calendar,
    refresh: Refresh,
    loader: Loader,
    checkCircle: CheckCircle,
    notes: Notes,
    edit: Edit,
    pencil: Pencil,
    close: Close,
    checkMark: CheckMark,
    chatNotification: ChatNotification,
    miniMenu: MiniMenu,
    barChartPercentWidget: BarChartPercentWidget,
    barChartNumberWeekWidget: BarChartNumberWeekWidget,
    lineChartPercentWidget: LineChartPercentWidget,
    tableWidget: TableWidget,
    digitWidget: DigitWidget,
    barChartNumberWidget: BarChartNumberWidget,
    dot: Dot,
    ring: Ring,
    forecastWidget: ForecastWidget,
    radioActive: RadioActive,
    radio: Radio,
    trashCan: TrashCan,
    save: Save,
    magnifier: Magnifier,
    documentAdd: DocumentAdd,
    heart: Heart,
    excel: Excel,
    attach: Attach,
    notelist: Notelist,
    sort: Sort,
    person: Person,
    shieldCheck: ShieldCheck,
    add: Add,
    employeeCardNews: EmployeeCardNews,
    company: Company,
    checkmarkCloud: CheckmarkCloud,
    key: Key,
    bell: Bell,
    filter: Filter,
    speakerPhone: SpeakerPhone,
    warning: Warning,
    clock: Clock,
    shift: Shift,
    winLogo: WinLogo,
    macLogo: MacLogo,
    pauseCircle: PauseCircle,
    back: Back,
    document: Document,
    template: Template,
    circle: Circle,
    circleFull: CircleFull,
    phone: Phone,
    widgetTitle: WidgetTitle,
    widgetFilter: WidgetFilter,
    personOutlined: PersonOutlined,
    link: Link,
    search: Search,
    searchMenu: SearchMenu,
    download: Download,
    globe: Globe,
    documentLarge: DocumentLarge,
    documentSearch: DocumentSearch,
    externalLink: ExternalLink,
    statusOverviewWidget: StatusOverviewWidget,
    dashboardSketch: DashboardSketch,
    todoWidget: TodoWidget,
    switchAccount: SwitchAccount,
    notificationRead: NotificationRead,
    notificationUnread: NotificationUnread,
    building: Building,
    phoneMissedCall: PhoneMissedCall,
    upload: Upload,
    switchToggled: SwitchToggled,
    switchUntoggled: SwitchUntoggled,
    adjustments: Adjustments,
    logo: Logo,
    xCircleFull: XCircleFull,
    warningCircle: WarningCircle,
    progressCircle: ProgressCircle,
    circleBack: CircleBack,
    circleForward: CircleForward,
    lightBulb: LightBulb,
    squircle: Squircle,
    arrowSwoosh: ArrowSwoosh,
    copy: Copy,
    mail: Mail,
    externalUser: ExternalUser,
    mobilePhone: MobilePhone,
    questionMark: QuestionMark,
    questionMarkCircle: QuestionMarkCircle,
    hamburgerIcon: HamburgerIcon,
    ellipsis: Ellipsis,
    globeAlt: GlobeAlt,
    cog: Cog,
};

export type Icons = keyof typeof icons;

export const checkIfIconName = (value: string): Icons => {
    if (has(value, icons)) {
        return value as Icons;
    }
    return "default";
};

export enum Size {
    small = 18,
    md = 24,
    lg = 32,
}
interface IProps extends React.SVGProps<SVGSVGElement> {
    icon: Icons;
    size?: Size | number;
    className?: string;
    strokeWidth?: number;
}

const Icon = React.memo<IProps>(({ size = Size.small, icon, className = "", strokeWidth, ...rest }: IProps) => {
    const IconElement = React.useMemo(() => {
        return icons[icon];
    }, [icon]);

    return <IconElement width={size} height={size} className={className} strokeWidth={strokeWidth} {...rest} />;
});
export default Icon;
