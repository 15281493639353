import Tag from "../Tag";

interface IProps {
    id: string;
    text: string;
}

const EventTag = (props: IProps) => {
    const { id, text } = props;

    return (
        <Tag id={id} className="h-6 bg-mhgrey-light py-1">
            <p className="text-xs font-bold text-grey-600">{text}</p>
        </Tag>
    );
};

export default EventTag;
