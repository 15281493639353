import { FieldError, UseFormRegister } from "react-hook-form";
import { IPreloadedValues } from "../../ContactSupport";

export interface IProps {
    label: string;
    value: keyof IPreloadedValues;
    prefix?: "prefix";
    required?: boolean;
    textarea?: boolean;
    register: UseFormRegister<IPreloadedValues>;
    caseNumber?: FieldError;
}

const FormTextInput = ({ label, value, prefix, required, textarea, register, caseNumber }: IProps) => (
    <div className="mb-10 flex flex-col font-bold">
        <label className="mb-1.5">
            {label} {required ? "*" : ""}
        </label>

        {caseNumber && value === "caseNumber" && <p className="pb-4 text-mhred-alert1">{caseNumber!.message}</p>}
        <div className="flex w-full">
            {prefix && (
                <input
                    className="w-18 border border-r-0 border-mhdarkgreen-original bg-transparent px-4 py-2"
                    {...register(prefix)}
                />
            )}
            {textarea ? (
                <textarea
                    className={`h-44 w-full resize-none border border-mhdarkgreen-original bg-transparent px-4 py-3`}
                    {...register(value, {
                        required: required,
                    })}
                />
            ) : (
                <input
                    className={`w-full border border-mhdarkgreen-original bg-transparent px-4 py-3 ${
                        caseNumber && value === "caseNumber" ? "border border-mhred-alert1" : ""
                    }`}
                    {...register(value, { required: required })}
                />
            )}
        </div>
    </div>
);

export default FormTextInput;
