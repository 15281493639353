import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";
import { fetchTemplateDocumentLink } from "../../redux/followUpAsyncActions";
import { MedHelpHCMDomainTemplateFormDocument } from "swagger/healthCaseManagement";
import { CaseTemplateModalContent, PdfViewer } from "../HealthCaseManagement";
import { ModalFrame } from "@medhelp/ui";
import { Spinner } from "components/Spinner";
import { unwrapResult } from "@reduxjs/toolkit";

const RehabTemplates = () => {
    const { t } = useTranslation("followup");
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState<MedHelpHCMDomainTemplateFormDocument | null>(null);

    const templateInformation: string[] = t("resources.templates.templateInformation", {
        returnObjects: true,
    });

    const handleViewTemplate = (template: MedHelpHCMDomainTemplateFormDocument) => {
        if (!template.pdfDocumentReferenceKey) {
            console.error("Required parameters are missing");
            return;
        }
        setIsLoading(true);
        setModalIsOpen(true);
        setSelectedTemplate(template);
        dispatch(
            fetchTemplateDocumentLink({
                pdfDocumentReferenceKey: template.pdfDocumentReferenceKey ?? "",
                healthCaseId: "",
            }),
        )
            .then(unwrapResult)
            .then((res) => {
                if (res.templateDocumentUrl) {
                    setPdfUrl(res.templateDocumentUrl);
                } else {
                    console.error("No PDF URL returned from the API");
                }
            })
            .catch((error) => {
                console.error("Failed to fetch template document link", error);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="ml-7 max-w-3xl">
            {modalIsOpen ? (
                <ModalFrame
                    id="rehab-templates-pdf-viewer"
                    open={modalIsOpen}
                    setOpen={setModalIsOpen}
                    header={<p className="text-sm sm:text-base">{templateInformation.map((text) => text + " ")}</p>}
                    preset="full"
                    content={
                        isLoading || !pdfUrl ? (
                            <div className="flex flex-col">
                                <div className="my-auto mt-10 place-self-center">
                                    <Spinner />
                                </div>
                            </div>
                        ) : (
                            <div className="h-full w-full">
                                <PdfViewer
                                    pdfUrl={pdfUrl}
                                    pdfFileName={selectedTemplate?.title ?? "file.pdf"}
                                    readonly
                                />
                            </div>
                        )
                    }
                />
            ) : (
                <>
                    <div className="mb-8">
                        {templateInformation.map((text) => (
                            <p>{text}</p>
                        ))}
                    </div>
                    <CaseTemplateModalContent setSelectedTemplate={(template) => handleViewTemplate(template)} />
                </>
            )}
        </div>
    );
};

export default RehabTemplates;
