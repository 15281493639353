import { SideMenu } from "@medhelp/ui";
import Icon from "components/Icon";
import SecondaryButton from "components/SecondaryButton";
import { SearchTypes } from "../../utils";
import DisplayFilters from "../DisplayFilters";
import DisplayPeriod from "../DisplayPeriod";
import ExportSearchExcel from "../ExportSearchExcel";
import FollowUpSearchFilters from "../FollowUpSearchFilters";
import ShowAffectedBasedOnFilter from "../ShowAffectedBasedOnFilter";

interface IViewProps {
    header: string;
    buttonText: string;
    sideMenuHeader: string;
    bottomContent: JSX.Element;
    excelText: string;
    open: boolean;
    setOpen: (value: boolean) => void;
    isEmpty: boolean;
    isLoading: boolean;
    smallScreen: boolean;
    searchType: SearchTypes;
}
const SearchFilter = ({
    header,
    buttonText,
    sideMenuHeader,
    bottomContent,
    open,
    setOpen,
    isEmpty,
    isLoading,
    smallScreen,
    searchType,
}: IViewProps) => (
    <div className="flex flex-1 flex-col">
        <div className="mb-[28px] font-serif text-2xl">{header}</div>
        <div className="mt-3 flex flex-1 items-center md:mt-0">
            <hr className="mr-7 hidden flex-1 md:block" />
            <SecondaryButton
                id="employee-searchfilter-button"
                onClick={() => setOpen(true)}
                leftIcon={<Icon icon="filter" size={12} />}
                className="flex h-[36px] w-full max-w-[335px] items-center justify-center px-0 md:max-w-[156px]"
                text={buttonText}
            >
                <p className="text-sm font-bold">{buttonText}</p>
            </SecondaryButton>
            <SideMenu
                className="flex h-1/2 flex-col"
                heading={sideMenuHeader}
                open={open}
                setOpen={setOpen}
                bottomContent={bottomContent}
                hideOnClickOutside={!smallScreen}
            >
                <div className="flex flex-1 flex-col">
                    <FollowUpSearchFilters />
                </div>
            </SideMenu>
        </div>
        {!isLoading && (
            <>
                <DisplayFilters />
                {searchType !== "monthlyReport" && <DisplayPeriod />}
                {!isEmpty && (
                    <div className="mb-4 hidden flex-col items-center justify-start md:flex md:flex-row md:justify-between">
                        <ShowAffectedBasedOnFilter />
                        <div className="flex gap-2">{searchType !== "monthlyReport" && <ExportSearchExcel />}</div>
                    </div>
                )}
            </>
        )}
    </div>
);

export default SearchFilter;
