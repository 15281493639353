import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import Dropdown from "../Dropdown";
import { Tag as TagType } from "../List/CheckboxList/types";
import ClickableTag from "../Tag/ClickableTag";
interface IProps {
    setOpen: () => void;
    open: boolean;
    items: React.ReactElement;
    removeTag: (referenceKey: string | number) => void;
    tags: TagType[];
    overflowText?: string | JSX.Element;
    numberOfHiddenElements?: (numberOfHiddenElements: number) => void;
    onClickOutside?: (value: boolean) => void;
    keepClose?: boolean;
    loading?: boolean;
    disabled?: boolean;
}
const Filter = ({
    open,
    setOpen,
    items,
    tags,
    removeTag,
    overflowText,
    numberOfHiddenElements,
    onClickOutside,
    keepClose,
    loading,
    disabled,
}: IProps) => {
    const [ref, currentRef] = useState<HTMLDivElement | null>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const setRef = useCallback((node: HTMLDivElement) => {
        currentRef(node);
    }, []);

    // Returns number of hidden elements
    useLayoutEffect(() => {
        if (ref) {
            let hiddenElementCounter = 0;
            const collection = ref.children;
            const dropdownPosition = ref.getBoundingClientRect().y;
            for (let i = 0; i < collection.length; i += 1) {
                if (collection[i]?.getBoundingClientRect().y > dropdownPosition) hiddenElementCounter += 1;
            }
            setIsOverflowing(Boolean(hiddenElementCounter > 0));
            if (numberOfHiddenElements) {
                numberOfHiddenElements(hiddenElementCounter);
            }
        }
    }, [ref, tags, numberOfHiddenElements]);

    const Tags = useMemo(
        () => (
            <div className="flex h-10 w-full flex-wrap gap-1 overflow-hidden" ref={setRef}>
                {tags.map(({ label, referenceKey, selectable }) => (
                    <div className="flex h-10 items-center" key={`${referenceKey}-tags-filter`}>
                        <ClickableTag
                            id={`filter-tag-${label}`}
                            text={label}
                            onClick={() => {
                                removeTag(referenceKey);
                            }}
                            disabled={!(selectable ?? true) || (keepClose ?? true)}
                        />
                    </div>
                ))}
            </div>
        ),
        [removeTag, setRef, tags, keepClose],
    );
    return (
        <Dropdown
            preset="secondary"
            header={Tags}
            items={items}
            setOpen={() => setOpen()}
            open={open}
            overflowText={overflowText}
            isOverflowing={isOverflowing}
            onClickOutside={onClickOutside}
            keepClose={keepClose}
            loading={loading}
            disabled={disabled}
        />
    );
};
export default Filter;
