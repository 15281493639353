import { jwtDecode } from "jwt-decode";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getServices } from "api/services";
import {
    hasAbsenceReporting,
    hasAbsenceFollowup,
    hasAbsenceAdministration,
    hasCustomerCompanyAdministration,
    hasCustomerSupport,
    hasAbsenceArchive,
    hasDepartmenAdministrator,
    hasRehabDepartmentManagement,
    hasAbsenceView,
    hasAbsenceStatistics,
    hasAbsenceBoard,
    hasMedicalAdvisory,
    hasCustomerOrganizationAdministration,
    hasTopLevelAbsenceStatistics,
    hasFileImportAdministration,
} from "utils/accessRights";
import { IUserContextExtended } from "interfaces/IUserContextExtended";
import { MedHelpPeopleDomainContent } from "swagger/people";
import { Config } from "config";
import axios from "axios";
import { RootState } from "store";
import { ContactInformation, UserContext, UserPreferences } from "swagger/usercontextservice";
import * as UserSelectors from "store/userSelectors";
import { IUserContextChange, IUserContextChangeExtended } from "./userSlice";
import { checkIfNativeApp } from "utils/nativeApp";
import userActionsNewTokenUtil from "./userActionsNewTokenUtil";
import userActionsNewTokenUtilChangeAccount from "./userActionsNewTokenUtilChangeAccount";

const userContextService = `${Config.api_servicesBase_url}usercontextservice/api/user/`;

export const changeUserContentExtended = (userContex: UserContext, token: string): IUserContextExtended => {
    const userContextExtended: IUserContextExtended = {
        ...userContex,
        HasAbsenceReporting: hasAbsenceReporting(userContex.userAccount?.accessRights!),
        HasAbsenceFollowup: hasAbsenceFollowup(userContex.userAccount?.accessRights!),
        HasAbsenceAdministration: hasAbsenceAdministration(userContex.userAccount?.accessRights!),
        HasCustomerCompanyAdministration: hasCustomerCompanyAdministration(userContex.userAccount?.accessRights!),
        HasCustomerSupport: hasCustomerSupport(userContex.userAccount?.accessRights!),
        HasAbsenceArchive: hasAbsenceArchive(userContex.userAccount?.accessRights!),
        HasDepartmentAdministration: hasDepartmenAdministrator(userContex.userAccount?.accessRights!),
        HasRehabDepartmentManagement: hasRehabDepartmentManagement(userContex.userAccount?.accessRights!),
        HasAbsenceView: hasAbsenceView(userContex.userAccount?.accessRights!),
        HasAbsenceStatistics: hasAbsenceStatistics(userContex.userAccount?.accessRights!),
        HasAbsenceBoard: hasAbsenceBoard(userContex.userAccount?.accessRights!),
        HasMedicalAdvisory: hasMedicalAdvisory(userContex.userAccount?.accessRights!),
        HasCustomerOrganizationAdministration: hasCustomerOrganizationAdministration(
            userContex.userAccount?.accessRights!,
        ),
        HasFileImportAdministration: hasFileImportAdministration(userContex.userAccount?.accessRights!),
        HasTopLevelAbsenceStatistics: hasTopLevelAbsenceStatistics(userContex.userAccount?.accessRights!),
        EmploymentGuid: jwtDecode<any>(token).empRef,
        Product: jwtDecode<any>(token).product,
        Subcategory: jwtDecode<any>(token).subcategory,
    };
    return userContextExtended;
};

export const setUserContextExtended = createAsyncThunk<IUserContextExtended, string>(
    "getUserContext",
    async (token: string, thunkApi) => {
        const currentState = thunkApi.getState() as RootState;
        let userContextData = currentState.user.userContext;
        if (!userContextData) {
            let { status, data: userContext } = await getServices().clients.user.getUserFromJwtToken();
            if (status !== 200) return thunkApi.rejectWithValue("rejected");
            const { overrideUserContextIfNewToken, overrideTokenIfNewToken } = await userActionsNewTokenUtil({
                userContext,
                thunkApi,
                token,
            });

            const featureFlagNewTokenIsUsedAndHasData = overrideUserContextIfNewToken && overrideTokenIfNewToken;

            if (featureFlagNewTokenIsUsedAndHasData) {
                userContextData = overrideUserContextIfNewToken;
                token = overrideTokenIfNewToken;
            } else {
                userContextData = userContext;
            }
        }
        const userContextExtended: IUserContextExtended = changeUserContentExtended(userContextData, token);
        return userContextExtended;
    },
);

export const getWelcomeMessage = createAsyncThunk(
    "getWelcomeMessage",
    async (_, { getState }): Promise<MedHelpPeopleDomainContent> => {
        const state = getState() as RootState;
        const region = UserSelectors.getRegion(state);
        const language = state.user.userContextExtended?.user?.userPreferences?.language;

        const { data } = await getServices().clients.content.contentV2Get(
            "absenceReporting",
            "welcomeMessage",
            language,
            region,
        );
        return data;
    },
);

export const changeUserContextByLogin = createAsyncThunk(
    "changeUserContextByLogin",
    async (userAccountId: number, thunkApi) => {
        const mhTokenSession = sessionStorage?.getItem("mhToken");

        const { userDataIfNewToken, requestStatusIfNewToken, tokenIfNewToken } =
            await userActionsNewTokenUtilChangeAccount({
                userAccountId,
                thunkApi,
                token: mhTokenSession,
            });

        const featureFlagNewTokenIsUsedAndHasData = userDataIfNewToken && requestStatusIfNewToken && tokenIfNewToken;

        if (featureFlagNewTokenIsUsedAndHasData) {
            if (checkIfNativeApp()) {
                window.ReactNativeWebView.postMessage(
                    `changeUserContextByLogin$v${tokenIfNewToken || ""}$v${requestStatusIfNewToken}`,
                );
                return thunkApi.rejectWithValue("rejected");
            }

            const userContextExtended: IUserContextExtended = changeUserContentExtended(
                userDataIfNewToken,
                tokenIfNewToken,
            );
            return { token: tokenIfNewToken, userContext: userContextExtended };
        } else {
            interface IChangeAccountModel {
                data: IUserContextChange;
                status: number;
            }
            const url = `${userContextService}ChangeAccount`;
            const { status, data }: IChangeAccountModel = await axios({
                method: "post",
                url: url,
                headers: {
                    Authorization: `Bearer ${mhTokenSession}`,
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(userAccountId),
            });
            if (status === 200) {
                if (checkIfNativeApp()) {
                    window.ReactNativeWebView.postMessage(`changeUserContextByLogin$v${data?.token || ""}$v${status}`);
                    return thunkApi.rejectWithValue("rejected");
                }
                return { token: data?.token, userContext: data?.userContext };
            }
            return thunkApi.rejectWithValue("rejected");
        }
    },
);

interface IUserChange {
    userAccountId: number;
    assume: boolean;
}

export const changeUserContext = createAsyncThunk(
    "changeUserContext",
    async (userContextChange: IUserChange, thunkApi) => {
        const { userAccountId, assume } = userContextChange;
        const mhTokenSession = sessionStorage?.getItem("mhToken");
        const url = assume ? `${userContextService}AssumeAccount` : `${userContextService}ChangeAccount`;

        const { userDataIfNewToken, requestStatusIfNewToken, tokenIfNewToken } =
            await userActionsNewTokenUtilChangeAccount({
                userAccountId,
                thunkApi,
                token: mhTokenSession,
            });

        const featureFlagNewTokenIsUsedAndHasData = userDataIfNewToken && requestStatusIfNewToken && tokenIfNewToken;

        if (featureFlagNewTokenIsUsedAndHasData) {
            const userContextExtended: IUserContextExtended = changeUserContentExtended(
                userDataIfNewToken,
                tokenIfNewToken,
            );
            return { token: tokenIfNewToken, userContext: userContextExtended };
        } else {
            const { request, data } = await axios({
                method: "post",
                url: url,
                headers: {
                    Authorization: `Bearer ${mhTokenSession}`,
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(userAccountId),
            });

            if (request.status === 200) {
                const { token, userContext } = data as IUserContextChange;
                if (userContext) {
                    const userContextExtended: IUserContextExtended = changeUserContentExtended(userContext, token);
                    const userContextChangeExtended: IUserContextChangeExtended = {
                        userContext: userContextExtended,
                        token,
                    };
                    if (userContextExtended) return userContextChangeExtended;
                }
            }
        }
        return thunkApi.rejectWithValue("rejected");
    },
);

export const updateUserLangPreferences = createAsyncThunk(
    "updateUserLangPreferences",
    async (userPrefModel: UserPreferences, thunkApi) => {
        const { status, data } = await getServices().clients.user.updateUserPreferences(userPrefModel);
        if (status === 200) return data;
        return thunkApi.rejectWithValue("rejected");
    },
);

export const updateUserLang = createAsyncThunk("updateUserLang", async (userPreferences: UserPreferences, thunkApi) => {
    const { status, data } = await getServices().clients.user.updateUserPreferences(userPreferences);
    if (status === 200) return data;
    return thunkApi.rejectWithValue("rejected");
});

export const updateContactInformation = createAsyncThunk(
    "updateContactInformation",
    async (contactInfoModel: ContactInformation, thunkApi) => {
        const { status, data } = await getServices().clients.user.updateContactInformation(contactInfoModel);
        if (status === 200) {
            return data;
        }
        return thunkApi.rejectWithValue("rejected");
    },
);
